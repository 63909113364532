import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import { openSnackbar } from '../../../../features/SnackBar/snackBarSlice';
import { createUser, updateUser } from '../../../../features/Auth/authAction';

const useUserManagerDialog = ({
  type, tenantId, onClose, userData,
}) => {
  const dispatch = useDispatch();

  const formData = useMemo(() => {
    let initialValues = {
      email: '',
      group: '',
    };

    let validations = Yup.object().shape({
      email: Yup.string().email('Invalid email address').required('Email is required'),
      group: Yup.string().required('Add user to a group'),
    });

    let onSubmit = ({ email, group }) => {
      dispatch(createUser({ tenantId, email, group })).then((res) => {
        if (res.payload.status === 'SUCCESS') {
          dispatch(openSnackbar({ open: true, message: res.payload.message, type: 'success' }));
        } else {
          dispatch(openSnackbar({ open: true, message: res.payload.message, type: 'error' }));
        }
      });
      onClose();
    };

    if (type === 'edit') {
      initialValues = {
        email: userData?.email,
        name: userData?.name,
        group: userData?.group,
        status: userData?.status,
      };

      validations = Yup.object().shape({
        name: Yup.string().required('Add user\'s full name'),
        group: Yup.string().required('Add user to a group'),
      });

      onSubmit = (values) => {
        const updatedFields = {
          name: userData.name !== values.name,
          group: userData.group !== values.group,
          status: userData.status !== values.status,
        };

        dispatch(updateUser({ ...values, tenantId, updatedFields })).then((res) => {
          if (res.payload.status === 'SUCCESS') {
            dispatch(openSnackbar({ open: true, message: res.payload.message, type: 'success' }));
          } else {
            dispatch(openSnackbar({ open: true, message: res.payload.message, type: 'error' }));
          }
        });
        onClose();
      };
    }

    return {
      initialValues, validations, onSubmit,
    };
  }, [type, tenantId, userData]);

  return formData;
};
export default useUserManagerDialog;
