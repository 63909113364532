import { useState } from 'react';
import {
  Grid, Tooltip, Stack, Button,
} from '@mui/material';
import Refresh from '@mui/icons-material/Refresh';
import { InfoChip } from '../../../../components/CustomChip';
import { SelectBox } from '../../../../components/FormComponents';

function generateMenuItems(start, end) {
  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(i.toString());
  }
  return result;
}

function WatchTowerForm({ formik }) {
  const { handleChange, values } = formik;
  const [formValue, setFormValue] = useState(values);
  const handleChangeFunction = (e, t) => {
    handleChange(e, t);
    formValue[e.target.name] = e.target.value;
    setFormValue(formValue);
  };
  const handleResetValues = (type, value) => {
    const data = { ...formValue };
    data[type] = value;
    setFormValue(data);
  };

  return (
    <Grid container gap={1} p={2}>
      <InfoChip icon label="You can click on reset button to reset values to default." />
      <Grid item xs={12}>
        <Stack direction="row" gap={4} alignItems="center">
          <SelectBox name="view" title="Time Frame" data={[{ id: 'monthly', name: 'monthly', value: 'monthly' }, { id: 'weekly', name: 'weekly', value: 'weekly' }]} value={formValue.view} onChange={handleChangeFunction} />
          <Button
            onClick={() => handleResetValues('view', 'monthly')}
            mt={1}
            color="primary"
            size="small"
            disabled={formValue.view === 'monthly'}
            sx={{
              padding: '8px 0',
              minWidth: 33,
              height: 33,
              borderRadius: '50%',
              backgroundColor: 'primary.lighter',
            }}
          >
            <Tooltip title="Set Default Value">
              <Refresh />
            </Tooltip>
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" gap={4} alignItems="center">
          <SelectBox name="threshold" title="Threshold" data={generateMenuItems(5, 30)} value={formValue.threshold} onChange={handleChangeFunction} />
          <Button
            onClick={() => handleResetValues('threshold', 5)}
            mt={1}
            color="primary"
            size="small"
            disabled={parseInt(formValue.threshold, 10) === 5}
            sx={{
              padding: '8px 0',
              minWidth: 33,
              height: 33,
              borderRadius: '50%',
              backgroundColor: 'primary.lighter',
            }}
          >
            <Tooltip title="Set Default Value">
              <Refresh />
            </Tooltip>
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" gap={4} alignItems="center">
          <SelectBox name="averageMonths" title="Moving Average Window" data={generateMenuItems(2, 3)} value={formValue.averageMonths} onChange={handleChangeFunction} />
          <Button
            onClick={() => handleResetValues('averageMonths', 3)}
            mt={1}
            color="primary"
            size="small"
            disabled={parseInt(formValue.averageMonths, 10) === 3}
            sx={{
              padding: '8px 0',
              minWidth: 33,
              height: 33,
              borderRadius: '50%',
              backgroundColor: 'primary.lighter',
            }}
          >
            <Tooltip title="Set Default Value">
              <Refresh />
            </Tooltip>
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default WatchTowerForm;
