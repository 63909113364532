// sections
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@mui/material';
import { TopCustomObject } from '../../../modules/Accounts';
import useAccountDetail from '../../AccountDetail/useAccountDetail';
import { getCustomObjects, getCustomObjectPinningData } from '../../../features';
import usePackageSelection from '../../../modules/Accounts/components/PackageSelection/usePackageSelection';
import useToggle from '../../../hooks/useToggle';

export default function CustomTopObjectsTable({ selectedFilters, permissions }) {
  const [refreshCustomObjHandler, setRefreshCustomObjHandler] = useToggle();
  const { activePackage } = usePackageSelection();
  const dispatch = useDispatch();

  useEffect(() => {
    if (activePackage?.packageId) {
      let filter = { type: 'all', pkgId: activePackage.packageId };
      if (selectedFilters.account !== '- all -') {
        filter = { ...filter, ...{ type: 'account', orgId: selectedFilters.account } };
      }
      if (selectedFilters.timeFrame) {
        filter = { ...filter, ...{ view: selectedFilters.timeFrame } };
      }
      dispatch(getCustomObjects(filter));
      dispatch(getCustomObjectPinningData({ packageId: activePackage.packageId }));
    }
  }, [refreshCustomObjHandler, activePackage?.packageId, selectedFilters]);

  const { customObject, customObjectPinningData } = useAccountDetail();

  return <Box>{customObject && <TopCustomObject selectedFilters={selectedFilters} pkgId={activePackage?.packageId} permissions={permissions} setRefreshCustomObjHandler={setRefreshCustomObjHandler} customObject={customObject} customObjectPinningData={customObjectPinningData} pageSize={15} />}</Box>;
}
