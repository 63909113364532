import palette from '../theme/palette';
import { BAR_GRAPH } from './constants';

// Constants

const DASHBOARD_CARDS = {
  BAR_DATASET: {
    backgroundColor: palette.secondary.mainColor,
    barThickness: 15,
    borderWidth: 0,
  },
  OPTIONS: {
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
    responsive: true,
    scales: {
      x: { grid: { display: false } },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    elements: {
      bar: { borderWidth: 2 },
    },
  },
};

const ZOOM_OPTIONS = {
  pan: {
    enabled: true,
    mode: 'x',
  },
  zoom: {
    wheel: {
      enabled: false,
    },
    pinch: {
      enabled: true,
    },
    mode: 'x',
  },
};

// Functions

const partialDataBackground = (data, partialDataLabel, defaultColor) => {
  return data.map((dateStr) => {
    if (dateStr === partialDataLabel) {
      return BAR_GRAPH.PartialData;
    }
    return defaultColor || '#4482FF';
  });
};

const getChartOptions = (config) => {
  const comboScale = {
    x: {
      grid: {
        display: false,
      },
      stacked: true,
    },
    user: {
      beginAtZero: true,
      type: 'linear',
      position: 'left',
      grid: {
        display: true,
      },
    },
    operation: {
      display: false,
      beginAtZero: true,
      type: 'linear',
      position: 'right',
    },
  };
  const barScale = {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: true,
      },
    },
  };
  const generatedOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    plugins: {
      title: {
        display: true,
        text: config.title,
        align: 'start',
        font: {
          size: 14,
        },
      },
      zoom: ZOOM_OPTIONS,
      legend: {
        align: 'end',
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          boxWidth: 7,
          boxHeight: 7,
        },
        onHover(e) {
          const { style } = e.native.target;
          style.cursor = 'pointer';
        },
        onLeave(e) {
          const { style } = e.native.target;
          style.cursor = 'default';
        },
      },
    },
    scales: config.chart === 'combo' ? {
      ...comboScale,
    } : { ...barScale },
    elements: {
      point: {
        radius: 5,
        borderWidth: 2.5,
        hoverRadius: 6.5,
        hoverBorderWidth: 2.5,
      },
    },
  };
  return generatedOptions;
};

export {
  DASHBOARD_CARDS,
  ZOOM_OPTIONS,
  partialDataBackground,
  getChartOptions,
};
