import { Grid, Stack, Button } from '@mui/material';
import useAppMenu from '../../components/AppDrawer/useAppMenu';
import { ProfileActions, ProfileCard } from '../../modules/Profile';
import useProfile from './useProfile';
import { BUTTONS } from '../../utils/constants';
import useStyles from '../../hooks/useStyles';
import styles from '../../layouts/pages/style';

function Profile({ permissions }) {
  const classes = useStyles(styles)();
  const { handleLogout } = useAppMenu({ permissions });
  const {
    activeTab,
    handleChange,
  } = useProfile();

  return (
    <Grid container gap={2}>
      <Grid item container xs={12} className={classes.cardStyles} p="5px !important">
        <Stack
          className={classes.headerStyles}
          sx={{ width: '100%', alignItems: 'center !important' }}
        >
          <ProfileActions activeTab={activeTab} handleChange={handleChange} />
          <Button variant="outlined" onClick={handleLogout}>{BUTTONS.LOGOUT}</Button>
        </Stack>
      </Grid>

      <Grid item container xs={12} className={classes.cardStyles}>
        <ProfileCard tab={activeTab} />
      </Grid>
    </Grid>
  );
}

export default Profile;
