import {
  Box, Container, Grid, Typography, styled,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FeatureUsageAction, FeatureUsageCard } from '../../modules/FeatureUsage';
import { MutedText } from '../../components/CustomTypography';
import { getCustomEntityList, getFeatureUsage } from '../../features';
import usePackageSelection from '../../modules/Accounts/components/PackageSelection/usePackageSelection';
import { PackageHeader } from '../../components/CustomHeading';
import styles from '../../layouts/pages/style';
import useStyles from '../../hooks/useStyles';
import useFeatureUsage from './useFeatureUsage';

const HeaderSection = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.grey[0],
  padding: 10,
}));

function FeatureUsage({ dashboard, permissions }) {
  const classes = useStyles(styles)();
  const dispatch = useDispatch();
  const location = useLocation();
  const orgId = location.state?.orgId;
  const accountName = location.state?.accountName;

  const { activePackage } = usePackageSelection();
  const {
    filteredFeatures,
    featuresList,
    siteWideLicense,
    totalSeats,
    dropDown,
    handlePinning,
    handleClickEdit,
    selectedFilters,
    handleFilterChange,
    getChartData,
    toggleAll,
    setToggleAll,
    refreshFeatureUsage,
  } = useFeatureUsage({ dashboard, packageId: activePackage?.packageId, orgId });

  useEffect(() => {
    if (activePackage?.packageId && selectedFilters?.featureUsageView) {
      dispatch(getFeatureUsage({
        packageId: activePackage.packageId, view: selectedFilters.featureUsageView, orgId,
      }));
    }
  }, [activePackage?.packageId, selectedFilters?.featureUsageView, refreshFeatureUsage, orgId]);

  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getCustomEntityList({ packageId: activePackage.packageId }));
    }
  }, [activePackage?.packageId]);

  return (
    <Container maxWidth="xl">
      <Grid container gap={2}>
        <Grid item xs={12} className={classes.cardStyles}>
          <Box className={classes.headerStyles}>
            {(accountName || orgId) ? (
              <HeaderSection>
                <Typography variant="h4">
                  {`KPI Usage - ${accountName || orgId}`}
                </Typography>
              </HeaderSection>
            ) : (
              <HeaderSection>
                <PackageHeader heading="KPI Usage" />
                <MutedText text="Data of active accounts" />
              </HeaderSection>
            )}
          </Box>
        </Grid>

        <Grid item container xs={12} className={classes.cardStyles}>
          <FeatureUsageAction
            toggleAll={toggleAll}
            setToggleAll={setToggleAll}
            dropDown={dropDown}
            selectedFilters={selectedFilters}
            handleFilterChange={handleFilterChange}
          />
        </Grid>

        <Grid item xs={12}>
          <FeatureUsageCard
            data={filteredFeatures?.data}
            loading={filteredFeatures?.loading}
            error={filteredFeatures?.error}
            featuresList={featuresList}
            siteWideLicense={siteWideLicense}
            totalActiveSeats={totalSeats}
            totalActiveAccounts={filteredFeatures?.totalActiveAccounts}
            getChartData={getChartData}
            viewOption={selectedFilters.featureUsageView}
            toggleAll={toggleAll}
            handlePinning={handlePinning}
            handleClickEdit={handleClickEdit}
            dashboard={dashboard}
            permissions={permissions}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default FeatureUsage;
