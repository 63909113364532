import {
  Autocomplete, Box, FormControl, Stack, TextField,
} from '@mui/material';
import { SelectBox } from '../../components/FormComponents';

function CustomObjectAction(props) {
  const {
    dropDown,
    selectedFilters,
    handleFilterChange,
    defaultValue,
  } = props;

  return (
    <Stack
      width="100%"
      justifyContent="space-between"
      direction={{ xs: 'column', md: 'row' }}
      alignItems={{ xs: 'end', md: 'center' }}
    >
      <Stack
        spacing={1}
        width={{ xs: '100%', md: '70%', lg: '50%' }}
        direction={{ xs: 'column', sm: 'row' }}
      >
        <Box flex={1}>
          <FormControl
            sx={{
              width: '100%',
              position: 'relative',
              m: '8px 0',
            }}
          >
            <Autocomplete
              onChange={(e, newVal) => handleFilterChange({ target: { name: 'account', value: newVal?.value || '- all -' } })}
              defaultValue={defaultValue || { id: '- all -', name: '- all -', value: '- all -' }}
              options={dropDown.accountMenuList
                ? [{ id: '- all -', name: '- all -', value: '- all -' }, ...dropDown.accountMenuList]
                : null}
              autoHighlight
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderInput={(params) => <TextField {...params} label="Account" />}
            />
          </FormControl>
        </Box>
        <Box flex={1}>
          <SelectBox
            name="timeFrame"
            title="Time Frame"
            data={dropDown.timeFrame
              ? [...dropDown.timeFrame]
              : null}
            value={selectedFilters.timeFrame}
            onChange={handleFilterChange}
          />
        </Box>
      </Stack>
    </Stack>
  );
}

export default CustomObjectAction;
