import useActiveUserConfig from '../../../../sections/ActiveUserConfig/useActiveUserConfig';
import ActiveUserConfigCard from './ActiveUserConfigCard';
import ActiveUserConfigDialog from './ActiveUserConfigDialog';

function ActiveUserConfig() {
  const {
    open,
    setOpen,
    filteredFeatures,
    activeUserConfig,
    handleSave,
  } = useActiveUserConfig();

  return (
    <>
      <ActiveUserConfigCard handleOpen={setOpen} activeUserConfig={activeUserConfig} filteredFeatures={filteredFeatures} />
      <ActiveUserConfigDialog open={open} handleClose={setOpen} onSubmit={handleSave} activeUserConfig={activeUserConfig} filteredFeatures={filteredFeatures} />
    </>
  );
}

export default ActiveUserConfig;
