import { CustomDataGrid } from '../../../components/CustomDataGrid';
import TenantDetailSection from './TenantDetailSection';

function TenantDetailTab({
  tab, userManagerColumns, userListData, handleChange, handleTenantDialog,
}) {
  let selectedTab;

  if (tab === 'users') {
    selectedTab = (
      <CustomDataGrid
        rowId="email"
        columns={userManagerColumns}
        rows={userListData?.data}
        footer
      />
    );
  } else {
    selectedTab = <TenantDetailSection handleChange={handleChange} handleTenantDialog={handleTenantDialog} />;
  }

  return selectedTab;
}

export default TenantDetailTab;
