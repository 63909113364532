import {
  Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { CustomIcon } from '../../../../components/CustomIcon';
import { InfoChip } from '../../../../components/CustomChip';
import { DynamicTable } from '../../../../components/CustomTable';
import useStyles from '../../../../hooks/useStyles';
import styles from '../../../../layouts/pages/style';

function TitleElement({ title }) {
  return <Typography>{title}</Typography>;
}
function ValueElement({ value }) {
  return (
    <FormControl>
      <RadioGroup row value={value}>
        <FormControlLabel value="daily" control={<Radio disabled={value !== 'daily'} />} label="Daily" />
        <FormControlLabel value="week" control={<Radio disabled={value !== 'week'} />} label="Weekly" />
        <FormControlLabel value="month" control={<Radio disabled={value !== 'month'} />} label="Monthly" />
      </RadioGroup>
    </FormControl>
  );
}

function EmailAlertSettingsCard({ notificationSettings, handleOpen }) {
  const classes = useStyles(styles)();
  const columns = [{ field: 'title' }, { field: 'value', align: 'left' }];
  const rows = [
    {
      title: <TitleElement title="Schedule:" />,
      value: <ValueElement value={notificationSettings?.frequency || ''} />,
    },
  ];
  const freqMsg = {
    daily: 'Email will be sent daily at 8 PM IST',
    week: 'Email will be sent Weekly on Monday at 8 PM IST',
    month: 'Email will be sent Monthly on 1st Date at 8 PM IST',
  };

  return (
    <Box className={classes.cardStyles} sx={{ width: '100%' }}>
      {/* Header */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography sx={{ p: '10px', fontSize: '22px', fontWeight: 500 }}>
          Email Alert
        </Typography>
        {/* Button */}
        <CustomIcon tooltip="Edit" onClick={handleOpen} Icon={EditIcon} iconSize="small" variant="outlined" sxProps={{ marginRight: 1 }} />
      </Stack>
      {/* Content */}
      <InfoChip sxProps={{ ml: '10px' }} icon label={freqMsg[notificationSettings?.frequency] || ''} />
      <DynamicTable columns={columns} rows={rows} />
    </Box>
  );
}

export default EmailAlertSettingsCard;
