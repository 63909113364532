import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { MutedText } from '../../../../components/CustomTypography';

function EmailAlertSettingsForm({ formik }) {
  const { handleChange, values } = formik;
  return (
    <Grid container gap={1} p={2}>
      <MutedText />
      <Grid item xs={12}>
        <MutedText text="Email will be sent daily at 8 PM IST, Weekly on Monday and on 1st Date Monthly respectively of your choice." />
        <Stack direction="row" alignItems="center" gap={4} mt={1}>
          <Typography>Email Schedule:</Typography>
          <FormControl>
            <RadioGroup row onChange={handleChange} name="frequency" value={values.frequency}>
              <FormControlLabel value="daily" control={<Radio />} label="Daily" />
              <FormControlLabel value="week" control={<Radio />} label="Weekly" />
              <FormControlLabel value="month" control={<Radio />} label="Monthly" />
            </RadioGroup>
          </FormControl>
        </Stack>
      </Grid>
    </Grid>
  );
}

export default EmailAlertSettingsForm;
