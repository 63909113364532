import { createSlice, current } from '@reduxjs/toolkit';
import { getActiveUserConfig, getActiveUsers, setupActiveUserConfig } from './activeUsersAction';

const activeUsersSlice = createSlice({
  name: 'activeUsers',
  initialState: {
    loading: false,
    error: null,
    dayObject: {},
    weekObject: {},
    monthObject: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActiveUsers.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getActiveUsers.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.dayObject = payload.dayObject;
      state.weekObject = payload.weekObject;
      state.monthObject = payload.monthObject;
    });
    builder.addCase(getActiveUsers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

// active user configuration

const activeUserConfigSlice = createSlice({
  name: 'activeUserConfig',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    // fetch active user config
    builder.addCase(getActiveUserConfig.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getActiveUserConfig.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.data = payload.data;
    });
    builder.addCase(getActiveUserConfig.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // create / update active user config
    builder.addCase(setupActiveUserConfig.pending, (state) => {
      const currentState = current(state);
      state.loading = true;
      state.error = null;
      state.data = currentState.data;
    });
    builder.addCase(setupActiveUserConfig.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.data = payload.data;
    });
    builder.addCase(setupActiveUserConfig.rejected, (state, action) => {
      const currentState = current(state);
      state.loading = false;
      state.error = action.payload;
      state.data = currentState.data;
    });
  },
});

export { activeUsersSlice, activeUserConfigSlice };
