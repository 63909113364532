import { forwardRef } from 'react';
import { ListItemButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

const AppListItem = styled(ListItemButton)(({ theme }) => ({
  position: 'relative',
  '&:hover': {
    background: theme.palette.primary.darker,
  },
  '&.active': {
    background: theme.palette.primary.darker,
    fontWeight: '600',
    '& .MuiListItemIcon-root': {
      color: theme.palette.grey[0],
    },
  },
}));

function AppMenuItemComponent({
  onClick, link, children, closeDropdown,
}) {
  if (!link || typeof link !== 'string') {
    return (
      <AppListItem
        onClick={onClick}
        className="dropdownTitle"
      >
        {children}
      </AppListItem>
    );
  }

  return (
    <AppListItem
      component={forwardRef((props, ref) => <NavLink {...props} ref={ref} />)}
      to={link}
      onClick={closeDropdown}
    >
      {children}
    </AppListItem>
  );
}

export default AppMenuItemComponent;
