import {
  FormControl, MenuItem, Select, Stack, Typography,
} from '@mui/material';

function CustomDropdown(props) {
  const {
    data, value, onChange, background,
  } = props;

  if (!data?.length) {
    return null;
  }

  if (data.length === 1) {
    return (
      <Stack
        alignItems="center"
        sx={{
          width: 'max-content',
          borderRadius: '6px',
        }}
      >
        <Typography variant="h4">
          {data[0].name}
        </Typography>
      </Stack>
    );
  }

  return (
    <FormControl>
      <Select
        value={value}
        onChange={onChange}
        sx={{
          ...(background && { background: (theme) => theme.palette.grey[200] }),
          fontSize: '1.5rem',
          fontWeight: 600,
          '.MuiOutlinedInput-notchedOutline': { border: 0, borderStyle: 'none' },
        }}
      >
        {data.map((item) => (
          <MenuItem key={item.id} value={item.value} sx={{ display: item.hidden && 'none' }}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CustomDropdown;
