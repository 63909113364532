import {
  Grid, Button, Box, LinearProgress,
} from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { CustomNoDataOverlay } from '../../components/CustomNoDataOverlay';
import { ConfirmDialogBox } from '../../components/CustomDialogBox';
import { UserManagerDialog } from '../../modules/UserManager';
import { userList } from '../../features/Auth/authAction';
import { BUTTONS, TITLES } from '../../utils/constants';
import { CustomDataGrid } from '../../components/CustomDataGrid';
import useUserManager from './useUserManager';
import { DynamicHeader } from '../../components/CustomHeading';
import useStyles from '../../hooks/useStyles';
import styles from '../../layouts/pages/style';

function UserManager({ permissions }) {
  const classes = useStyles(styles)();
  const dispatch = useDispatch();
  const location = useLocation();
  const tenant = location.state?.tenant;

  const {
    userListData,
    userManagerColumns,
    openUserDialog,
    setOpenUserDialog,
    actionDialogData,
    openActionDialog,
    setOpenActionDialog,
    userDialogData,
    handleOpenUserDialog,
  } = useUserManager({ permissions, tenantId: tenant?.id });

  useEffect(() => {
    dispatch(userList({ tenantId: tenant?.id }));
  }, []);

  return (
    <Grid container gap={2}>
      <Grid item xs={12} className={classes.cardStyles}>
        <Box className={classes.headerStyles}>
          <DynamicHeader heading={TITLES.USER_MANAGER} subHeading={tenant?.name} />
          <Button variant="outlined" onClick={handleOpenUserDialog}>{BUTTONS.CREATE_NEW_USER}</Button>
        </Box>
      </Grid>

      {userListData?.loading && (
      <Grid item xs={12} className={classes.tableStyles}>
        <LinearProgress />
      </Grid>
      )}

      {!userListData?.loading && !userListData?.data?.length && (
      <Grid item xs={12} className={classes.tableStyles}>
        <CustomNoDataOverlay />
      </Grid>
      )}

      {!userListData?.loading && !!userListData?.data?.length && (
      <Grid item xs={12} className={classes.tableStyles}>
        <CustomDataGrid
          rowId="email"
          columns={userManagerColumns}
          rows={userListData?.data}
          footer
        />
      </Grid>
      )}

      <UserManagerDialog
        userDialogData={userDialogData}
        openDialog={openUserDialog}
        onClose={setOpenUserDialog}
        permissions={permissions}
        tenant={tenant}
      />

      <ConfirmDialogBox
        title={actionDialogData.title}
        message={actionDialogData.message}
        openDialog={openActionDialog}
        onClose={setOpenActionDialog}
        onConfirm={actionDialogData.onConfirm}
      />
    </Grid>
  );
}

export default UserManager;
