const pageStyle = (() => ({
  main: {
    width: '100%',
  },
  multilineChipSelect: {
    height: 'auto',
    '& .MuiChip-label': {
      display: 'inline-block',
      whiteSpace: 'initial',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: '1.4em',
      height: '2.8em',
    },
    padding: 5,
  },
  info: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  banner: {
    width: '100%',
    justifyContent: 'start',
    alignItems: 'center',
    padding: '35px 10px',
    fontSize: 18,
    borderRadius: 0,
  },
  multilineChip: {
    height: 'auto',
    '& .MuiChip-label': {
      display: 'block',
      whiteSpace: 'normal',
    },
    padding: 5,
    gap: 1,
    alignItems: 'center',
  },
  leftAlignChip: {
    justifyContent: 'start',

  },
  rightAlignChip: {
    justifyContent: 'end',
  },
}));

export default pageStyle;
