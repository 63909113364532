import { createSlice, current } from '@reduxjs/toolkit';
import {
  getGeneralDetails, updateAccount, getAllRemarks, saveRemarks,
} from './generalDetailAction';

export const generalDetailSlice = createSlice({
  name: 'generalDetails',
  initialState: { data: {} },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getGeneralDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getGeneralDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.response = payload;
      state.data = { ...payload.responseData };
    });
    builder.addCase(getGeneralDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(updateAccount.pending, (state) => {
      const currentState = current(state);
      state.loading = true;
      state.error = null;
      state.data = { ...currentState.data };
    });
    builder.addCase(updateAccount.fulfilled, (state, { payload }) => {
      const currentState = current(state);
      const updatedResponseData = {
        ...currentState.data,
        ...payload,
      };
      state.loading = false;
      state.data = { ...updatedResponseData };
      state.error = null;
    });
    builder.addCase(updateAccount.rejected, (action, state) => {
      const currentState = current(state);
      state.loading = false;
      state.error = action.payload;
      state.data = { ...currentState.data };
    });
  },
});

export const allRemarksSlice = createSlice({
  name: 'allRemarks',
  initialState: { data: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllRemarks.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllRemarks.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.data = payload.data;
    });
    builder.addCase(getAllRemarks.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(saveRemarks.pending, (state) => {
      const currentState = current(state);
      state.loading = true;
      state.error = null;
      state.data = [...currentState.data];
    });
    builder.addCase(saveRemarks.fulfilled, (state, { payload }) => {
      const currentState = current(state);
      const data = payload?.data ? payload.data : {};
      const updatedResponseData = [
        data,
        ...currentState.data,
      ];
      state.loading = false;
      state.data = [...updatedResponseData];
      state.error = null;
    });
    builder.addCase(saveRemarks.rejected, (action, state) => {
      const currentState = current(state);
      state.loading = false;
      state.error = action.payload;
      state.data = [...currentState.data];
    });
  },
});
