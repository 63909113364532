import {
  FormControl, MenuItem, Select, Stack, Typography,
} from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { startTour } from '../../../../features/TourGuide/tourGuideSlice';
import useAuthorization from '../../../../hooks/useAuthorization';
import { stringify } from '../../../../utils/methods';

function PackageSelection({
  dropDownList, activeDropdown, handleChange, startTourState,
}) {
  if (!dropDownList?.length) {
    return null;
  }

  // change demo package name
  const { userInfo } = useAuthorization();
  const isHabileLabsDemo = useMemo(() => {
    return userInfo?.tenantId === 'habilelabs_1291db';
  }, [userInfo]);

  // start tour guide
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (startTourState) {
      dispatch(startTour());
      navigate(location.pathname, { replace: true });
    }
  }, [startTourState]);

  if (dropDownList?.length > 1) {
    return (
      <FormControl>
        <Select
          className="tg-package-header"
          value={stringify(activeDropdown)}
          onChange={handleChange}
          sx={{
            background: (theme) => theme.palette.grey[200],
            fontSize: '1.5rem',
            fontWeight: 600,
            '.MuiOutlinedInput-notchedOutline': { border: 0, borderStyle: 'none' },
          }}
        >
          {dropDownList.map((dropDown) => (
            <MenuItem key={dropDown.id} value={stringify(dropDown)}>
              {(isHabileLabsDemo && dropDown.id === '03324000000DD19') ? 'APP Insights Demo' : dropDown.packageLabel}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  return (
    <Stack
      className="tg-package-header"
      alignItems="center"
      sx={{
        background: (theme) => theme.palette.grey[200],
        width: 'max-content',
        p: '5px 15px',
        borderRadius: '6px',
      }}
    >
      <Typography variant="h4">
        {isHabileLabsDemo ? 'App Insights Demo' : activeDropdown.packageLabel}
      </Typography>
    </Stack>
  );
}

export default PackageSelection;
