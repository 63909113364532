import { createAsyncThunk } from '@reduxjs/toolkit';

import request from '../../modules/Request/request';

export const getAccCountByCountryLang = createAsyncThunk(
  'getAccCountByCountryLang',
  async (object, { rejectWithValue }) => {
    try {
      const response = await request('/account-count-by-country-lang', { method: 'POST', body: object });
      if (response.success) {
        return response.data;
      }
      const error = response.error && response.error.message
        ? response.error.message
        : response.error;
      throw rejectWithValue(error || 'Something went wrong');
    } catch (err) {
      throw rejectWithValue(err || 'Something went wrong');
    }
  },
);

export const getCountryKpiScore = createAsyncThunk(
  'getCountryKpiScore',
  async (object, { rejectWithValue }) => {
    const response = await request('/country-kpi-score/account', { method: 'GET', query: { pkgId: object.pkgId } });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);
