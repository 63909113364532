import { Container, Grid } from '@mui/material';
import { PackageHeader } from '../components/CustomHeading';
import CustomTopObjectsTable from '../sections/@dashboard/custom-top-objects';
import Metatags from '../components/MetaTags/MetaTags';
import useStyles from '../hooks/useStyles';
import styles from '../layouts/pages/style';
import CustomObjectAction from '../sections/CustomObject/CustomObjectAction';
import useCustomObject from '../sections/CustomObject/useCustomObject';

export default function CustomObjectPage({ permissions }) {
  const classes = useStyles(styles)();
  const {
    dropDown,
    selectedFilters,
    defaultValue,
    handleFilterChange,
  } = useCustomObject();

  return (
    <>
      <Metatags metaTagName="Custom Object | App Insights" />
      <Container maxWidth="xl">
        <Grid container gap={2}>
          <Grid item xs={12} className={classes.cardStyles}>
            <PackageHeader heading="Top Custom Object" />
          </Grid>
          <Grid item container xs={12} className={classes.cardStyles}>
            <CustomObjectAction
              dropDown={dropDown}
              selectedFilters={selectedFilters}
              defaultValue={defaultValue}
              handleFilterChange={handleFilterChange}
            />
          </Grid>

          <Grid item xs={12}>
            <CustomTopObjectsTable selectedFilters={selectedFilters} permissions={permissions} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
