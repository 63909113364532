import { createSlice } from '@reduxjs/toolkit';
import { getVersions, getEditions, getVersionKpiScore } from './versionsAction';

export const versionsSlice = createSlice({
  name: 'versions',
  initialState: {
    loading: false,
    versionObject: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVersions.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getVersions.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.versionObject = payload.versionCounts;
      state.error = null;
    });
    builder.addCase(getVersions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const editionsSlice = createSlice({
  name: 'editions',
  initialState: {
    loading: false,
    orgEdObject: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEditions.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getEditions.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.orgEdObject = payload.organizationEditionCounts;
    });
    builder.addCase(getEditions.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const versionKpiScoreSlice = createSlice({
  name: 'versionKpiScore',
  initialState: {
    loading: false,
    versionData: {},
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getVersionKpiScore.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getVersionKpiScore.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.versionData = payload.versionData;
      state.error = null;
    });
    builder.addCase(getVersionKpiScore.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});
