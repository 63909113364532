import palette from '../../../theme/palette';

const licenseInsightStyle = ((theme) => ({
  cellBoxStyle: {
    flexDirection: 'column',
    textAlign: 'center',
    minWidth: '50%',
  },
  cellTextStyle: {
    fontSize: 13,
    fontWeight: 'bold',
  },
  cellSubTextStyle: {
    fontSize: 12,
    color: 'grey',
  },
  cellSubNewTextStyle: {
    fontSize: '12px',
    color: 'green',
    position: 'relative',
    top: '-10px',
    right: '5px',
  },
  cardStyles: {
    borderRadius: 0,
    padding: 5,
    marginTop: 10,
  },
  dataGridPro: {
    borderRadius: 0,
    border: 'unset',
    '& .MuiDataGrid-main': {
      '& div:nth-child(2)': {
        height: 'auto !important',
      },
    },
    '.MuiDataGrid-root': {
      outline: 'none !important',
      border: 'none !important',
    },
    '& .MuiDataGrid-virtualScroller': {
      overflowY: 'auto !important',
      overflowX: 'auto !important',
      minHeight: '7vw !important',
      '@media (max-width: 1500px)': {
        overflowY: 'auto !important',
        overflowX: 'auto !important',
      },

      '& .MuiDataGrid-virtualScrollerContent': {
        '& .MuiDataGrid-virtualScrollerRenderZone': {
        },
      },
    },
    '& .MuiDialogContent-root': {
      overflow: 'hidden',
    },
    '& .MuiDataGrid-iconButtonContainer': {
      visibility: 'visible',
      width: '0 !important',
    },
    '& .MuiDataGrid-columnHeaders': {
      borderTop: 'none',
      fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
      fontStyle: 'bold',
      '@media (min-width: 1200px)': {
        fontSize: '0.7vw',
      },
      color: palette.secondary.mainColor,
      minHeight: '3vw !important',
      zIndex: '111 !important',
      backgroundColor: '#fff  !important',
      '@media (max-width: 1500px)': {
        minHeight: '4vw !important',
        maxHeight: '6vw !important',
      },
      '@media (max-width: 449px)': {
        minHeight: '70px !important',
        maxHeight: '70px !important',
        lineHeight: '18px !important',
      },
      '@media (min-width: 450px) and (max-width: 900px)': {
        minHeight: '70px !important',
        maxHeight: '70px !important',
        lineHeight: '18px !important',
      },
    },
    '& .MuiDataGrid-iconSeparator': {
      visibility: 'hidden',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      '@media (min-width: 1200px)': {
        fontSize: '0.7vw',
      },
      textAlign: 'left',
      '@media (max-width: 1500px)': {
        fontSize: '1.1vw',
      },
      [theme?.breakpoints?.down('md')]: {
        fontSize: 12,
      },
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      textAlign: 'left',
      border: 'none !important',
    },
    '& .accountSubHeader, .accountCell': {
      width: '30vw !important',
      minWidth: '20vw !important',
      '@media (min-width: 1200px)': {
        maxWidth: '30vw !important',
        fontSize: '0.7vw',
        maxHeight: '3vw !important',
      },
      minHeight: '3vw !important',
      '@media (max-width: 1500px)': {
        width: '30vw !important',
        fontSize: '12px',
        maxWidth: '30vw !important',
        minWidth: '30vw !important',
      },
    },
    '& .accountHeader': {
      '&.MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer': {
        outline: 'none !important',
        borderBottom: 'none !important',
      },
      width: '30vw !important',
      minWidth: '20vw !important',
      '@media (min-width: 1200px)': {
        maxWidth: '30vw !important',
        fontSize: '0.7vw',
        maxHeight: '3vw !important',
      },
      minHeight: '3vw !important',
      '@media (max-width: 1500px)': {
        marginTop: '2vw !important',
        width: '30vw !important',
        fontSize: '12px',
        maxWidth: '30vw !important',
        minWidth: '30vw !important',
      },
    },
    '& .ownerHeader, .ownerCell': {
      width: '8vw !important',
      minWidth: '11vw !important',
      '@media (min-width: 1200px)': {
        maxWidth: '8vw !important',
        fontSize: '0.7vw',
        maxHeight: '3vw !important',
      },
      minHeight: '3vw !important',
      '@media (max-width: 1500px)': {
        width: '8vw !important',
        fontSize: '12px',
        maxWidth: '8vw !important',
        minWidth: '8vw !important',
      },
    },
    '& .totalSubHeader, .totalCell': {
      width: '7vw !important',
      minWidth: '7vw !important',
      '@media (min-width: 1200px)': {
        maxWidth: '15vw !important',
        fontSize: '0.7vw',
        maxHeight: '3vw !important',
      },
      minHeight: '3vw !important',
      '@media (max-width: 1500px)': {
        width: '7vw !important',
        fontSize: '12px',
        maxWidth: '7vw !important',
        minWidth: '7vw !important',
      },
    },
    '& .totalHeader': {
      '&.MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer': {
        outline: 'none !important',
        borderBottom: 'none !important',
      },
      width: '7vw !important',
      minWidth: '7vw !important',
      '@media (min-width: 1200px)': {
        maxWidth: '15vw !important',
        fontSize: '0.7vw',
        maxHeight: '3vw !important',
      },
      minHeight: '3vw !important',
      '@media (max-width: 1500px)': {
        width: '7vw !important',
        fontSize: '12px',
        maxWidth: '7vw !important',
        minWidth: '7vw !important',
        marginTop: '2vw !important',
      },
    },
    '& .uniqueUserHeader': {
      '&.MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer': {
        outline: 'none !important',
        borderBottom: 'none !important',
      },
      width: '7vw !important',
      minWidth: '7vw !important',
      '@media (min-width: 1200px)': {
        maxWidth: '15vw !important',
        fontSize: '0.7vw',
        maxHeight: '3vw !important',
      },
      minHeight: '3vw !important',
      '@media (max-width: 1500px)': {
        width: '7vw !important',
        fontSize: '12px',
        maxWidth: '7vw !important',
        minWidth: '7vw !important',
        marginTop: '2vw !important',
      },
    },
    '& .assignedSubHeader, .assignedCell': {
      width: '7vw !important',
      minWidth: '7vw !important',
      '@media (min-width: 1200px)': {
        maxWidth: '15vw !important',
        fontSize: '0.7vw',
        maxHeight: '3vw !important',
      },
      minHeight: '3vw !important',
      '@media (max-width: 1500px)': {
        width: '7vw !important',
        fontSize: '12px',
        maxWidth: '7vw !important',
        minWidth: '7vw !important',
      },
    },
    '& .assignedHeader': {
      '&.MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer': {
        outline: 'none !important',
        borderBottom: 'none !important',
      },
      width: '7vw !important',
      minWidth: '7vw !important',
      '@media (min-width: 1200px)': {
        maxWidth: '15vw !important',
        fontSize: '0.7vw',
        maxHeight: '3vw !important',
      },
      minHeight: '3vw !important',
      '@media (max-width: 1500px)': {
        marginTop: '2vw !important',
        width: '7vw !important',
        fontSize: '12px',
        maxWidth: '7vw !important',
        minWidth: '7vw !important',
      },
    },
    '& .allHeader, .allCell': {
      width: '7vw !important',
      minWidth: '7vw !important',
      '@media (min-width: 1200px)': {
        maxWidth: '15vw !important',
        fontSize: '0.7vw',
        maxHeight: '3vw !important',
      },
      minHeight: '3vw !important',
      '@media (max-width: 1500px)': {
        width: '7vw !important',
        fontSize: '12px',
        maxWidth: '7vw !important',
        minWidth: '7vw !important',
      },
    },
    '& .percentageHeader, .percentageCell': {
      width: '7vw !important',
      minWidth: '7vw !important',
      '@media (min-width: 1200px)': {
        maxWidth: '15vw !important',
        fontSize: '0.7vw',
        maxHeight: '3vw !important',
      },
      minHeight: '3vw !important',
      '@media (max-width: 1500px)': {
        width: '7vw !important',
        fontSize: '12px',
        maxWidth: '7vw !important',
        minWidth: '7vw !important',
      },
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none',
      outlineOffset: 0,
    },
    '& .MuiTablePagination-root': {
      '& .MuiTablePagination-selectLabel, .MuiInputBase-root': {
        display: 'none',
      },
      '& svg': {
        [theme?.breakpoints?.down('md')]: {
          width: '20px !important',
          height: '20px !important',
        },
      },
      '& .MuiTablePagination-displayedRows': {
        fontSize: '1vw !important',
        [theme?.breakpoints?.down('md')]: {
          fontSize: '12px !important',
        },
      },
    },
  },
}));

export default licenseInsightStyle;
