import { Typography, Tooltip } from '@mui/material';

function MutedText({
  text, tooltip, variant, sxProps,
}) {
  const TypographyComponent = (
    <Typography
      variant={variant || 'subtitle2'}
      sx={{
        color: (theme) => theme.palette.text.greyed,
        ...sxProps,
      }}
    >
      {text}
    </Typography>
  );

  return tooltip ? <Tooltip title={tooltip}>{TypographyComponent}</Tooltip> : TypographyComponent;
}

export default MutedText;
