import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, useCallback } from 'react';
// import { setPboConnectionLoading } from '../../features/PboConnection/PboConnectionSlice';
import { openSnackbar } from '../../features/SnackBar/snackBarSlice';
import {
  disconnectPboConnection,
  getPboConnectionDetails,
  refreshPboConnection,
  savePboConnectionCode,
} from '../../features/PboConnection/PboConnectionAction';
import request from '../../modules/Request/request';
import useToggle from '../../hooks/useToggle';

export function usePboConnection() {
  const pboConnectionDetails = useSelector((state) => state.pboConnectionDetails);
  const pboConnection = useSelector((state) => state.pboConnection);
  const dispatch = useDispatch();
  const [expired, setExpired] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [connected, setConnected] = useState(pboConnectionDetails?.data?.isEnable ?? false);
  const [openActionDialog, setOpenActionDialog] = useToggle();
  const [actionDialogData, setActionDialogData] = useState({});

  useEffect(() => {
    if (!(!pboConnectionDetails?.data || !Object.keys(pboConnectionDetails?.data).length)) {
      const { expiration, isEnable } = pboConnectionDetails.data;
      const now = new Date().getTime();
      const modifiedDate = new Date(expiration * 1000).getTime();
      setExpired(now > modifiedDate);
      setConnected(isEnable);
    }
  }, [pboConnectionDetails?.data]);

  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code') ?? null;

  useEffect(() => {
    if (code) {
      window.history.replaceState({}, document.title, window.location.pathname);
      dispatch(savePboConnectionCode({ code })).then((res) => {
        if (res?.payload?.status === 'SUCCESS') {
          dispatch(openSnackbar({ open: true, message: 'Connected Successfully!', type: 'success' }));
          setConnected(true);
          dispatch(getPboConnectionDetails());
        } else {
          dispatch(openSnackbar({ open: true, message: res?.payload || 'Connection failed', type: 'error' }));
        }
      });
    }
  }, [code]);

  const handleSave = async () => {
    setRedirecting(true);
    await request('/redirect-url', { method: 'GET' }).then((res) => {
      const { success, data = { record: {} } } = res;
      if (success) {
        const { record } = data;
        if (record.salesforceUrl && record.clientId && record.redirectUrl) {
          window.location.replace(`${record.salesforceUrl}?client_id=${record.clientId}&redirect_uri=${record.redirectUrl}&response_type=code`);
        } else {
          dispatch(openSnackbar({ open: true, message: 'Failed to get Connect PBO URL', type: 'error' }));
        }
      } else {
        dispatch(openSnackbar({ open: true, message: 'Failed to get Connect PBO URL', type: 'error' }));
      }
      setRedirecting(false);
    });
  };

  const handleDelete = useCallback(() => {
    const disconnection = () => {
      dispatch(disconnectPboConnection()).then((res) => {
        if (res?.payload?.status === 'SUCCESS') {
          dispatch(getPboConnectionDetails());
          dispatch(openSnackbar({ open: true, message: 'Disconnected Successfully!', type: 'success' }));
          setConnected(false);
        } else {
          dispatch(openSnackbar({ open: true, message: 'Disconnection Failed', type: 'error' }));
        }
        setOpenActionDialog();
      });
    };
    setActionDialogData({
      title: 'Disconnect PBO',
      message: (
        <Typography>
          Are you sure you want to disconnect PBO ?
        </Typography>
      ),
      onConfirm: disconnection,
    });
    setOpenActionDialog();
  }, [dispatch]);

  const handleRefresh = useCallback(() => {
    dispatch(refreshPboConnection()).then((res) => {
      if (res?.payload?.status === 'SUCCESS') {
        dispatch(getPboConnectionDetails());
        dispatch(openSnackbar({ open: true, message: 'Refreshed Successfully!', type: 'success' }));
      } else {
        dispatch(openSnackbar({ open: true, message: 'Refresh Failed', type: 'error' }));
      }
    });
  }, [dispatch]);

  return {
    handleSave,
    handleDelete,
    handleRefresh,
    expired,
    connected,
    pboConnectionDetails,
    pboConnection,
    redirecting,
    openActionDialog,
    actionDialogData,
    setOpenActionDialog,
  };
}
