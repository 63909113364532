import styled from '@emotion/styled';
import { Typography, Button, Box } from '@mui/material';

export default function Error404Page({ title, message }) {
  const Title = styled(Typography)({
    fontSize: '3rem',
  });

  const Message = styled(Typography)({
    fontSize: '1.5rem',
    margin: '20px 0',
  });
  return (
    <Box
      style={{
        textAlign: 'center',
        marginTop: '20vh',
      }}
    >
      <Box
        style={{
          fontSize: '2rem',
          display: 'inline-block',
          margin: '0 8px',
        }}
      >
        😢
      </Box>
      <Title variant="h2">{title}</Title>
      <Message variant="h4">{message}</Message>
      <Button variant="contained" color="primary" href="/">
        Go to Home
      </Button>
    </Box>
  );
}
