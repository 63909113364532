import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useToggle from '../../hooks/useToggle';
import { openSnackbar } from '../../features/SnackBar/snackBarSlice';
import { getActiveUserConfig, setupActiveUserConfig } from '../../features/ActiveUsers/activeUsersAction';
import usePackageSelection from '../../modules/Accounts/components/PackageSelection/usePackageSelection';
import { getFeaturesList } from '../../features';
import { resetAccountsList } from '../../features/AccountList/accountListSlice';

function useActiveUserConfig() {
  const [open, setOpen] = useToggle();
  const dispatch = useDispatch();
  const { activePackage } = usePackageSelection();

  // storing redux data in local state
  const featuresList = useSelector((state) => state.featureManagerList.featureManagerList);
  const activeUserConfigData = useSelector((state) => state.activeUserConfig);

  const [activeUserConfig, setActiveUserConfig] = useState({
    loading: false,
    error: null,
    data: [],
  });

  const setActiveUserConfigData = () => {
    setActiveUserConfig({
      ...activeUserConfig,
      ...activeUserConfigData,
    });
  };

  useEffect(() => {
    setActiveUserConfigData();
  }, [activeUserConfigData]);

  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getFeaturesList({ packageId: activePackage.packageId }));
      dispatch(getActiveUserConfig({ packageId: activePackage.packageId }));
    }
  }, [activePackage?.packageId]);

  const handleSave = (values) => {
    const { config, selectedKpi } = values;
    if (config && !selectedKpi) {
      dispatch(openSnackbar({ open: true, message: 'Please select a KPI or disable active user configuration.', type: 'error' }));
      return;
    }

    if (activePackage.packageId && ((!config) || (activeUserConfig && selectedKpi))) {
      const data = { activeUserConfig: config, packageId: activePackage.packageId };
      if (config && selectedKpi) {
        data.featureId = selectedKpi;
      }

      dispatch(setupActiveUserConfig(data)).then((res) => {
        if (res?.payload?.status === 'SUCCESS') {
          dispatch(resetAccountsList());
          dispatch(openSnackbar({ open: true, message: 'Active user configuration saved successfully.', type: 'success' }));
        }
        if (res?.error) {
          dispatch(openSnackbar({ open: true, message: 'Failed to save active user configuration.', type: 'error' }));
        }
        setOpen();
        return null;
      });
    }
  };

  const filteredFeatures = useMemo(() => {
    return featuresList?.map((item) => ({
      id: item.feature_id,
      name: item.feature_name,
      value: item.feature_id,
    }));
  }, [featuresList]);

  return {
    open,
    setOpen,
    filteredFeatures,
    activeUserConfig,
    handleSave,
  };
}

export default useActiveUserConfig;
