import { useCallback } from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary, Grid, Typography,
} from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import useCustomAccordion from './useCustomAccordion';

function CustomAccordion({ accordionData, defaultExpanded, toggleAll }) {
  const { expandedAccordions, accordionToggle } = useCustomAccordion({ accordionData, defaultExpanded, toggleAll });

  const handleAccordionChange = useCallback(
    (id) => () => accordionToggle(id),
    [accordionToggle],
  );

  return (
    <Grid container gap={2}>
      {accordionData?.map((data) => (
        <Grid
          item
          xs={12}
          key={data.id}
          sx={{
            boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px',
            borderRadius: '6px',
          }}
        >
          <Accordion
            disableGutters
            onChange={handleAccordionChange(data.id)}
            expanded={expandedAccordions.includes(data.id)}
          >
            <AccordionSummary expandIcon={<KeyboardArrowDown />}>
              {data.title && <Typography>{data.title}</Typography>}
              {data.header ? data.header : null}
            </AccordionSummary>
            <AccordionDetails>
              {data.body}
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  );
}

export default CustomAccordion;
