import {
  Box, Stack, Tooltip, Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { CustomIcon } from '../../../../components/CustomIcon';
import { DynamicTable } from '../../../../components/CustomTable';
import useStyles from '../../../../hooks/useStyles';
import styles from '../../../../layouts/pages/style';
import { toolTipText } from '../../../../utils/toolTipText';

function TitleElement({ title }) {
  return <Typography>{title}</Typography>;
}
function ValueElement({ value }) {
  return (
    <Stack alignItems="flex-end">
      <Typography
        sx={(theme) => ({
          width: 'max-content',
          backgroundColor: theme.palette.primary.lighter,
          p: '2px 10px',
          borderRadius: '5px',
          fontWeight: '600',
          color: theme.palette.primary.text,
        })}
      >
        {value}
      </Typography>
    </Stack>
  );
}
function InfoElement({ info }) {
  return (
    <Tooltip title={info}>
      <Typography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: 1,
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
        }}
      >
        {info}
      </Typography>
    </Tooltip>
  );
}

function WatchTowerCard({ watchTowerData, handleOpen }) {
  const classes = useStyles(styles)();
  const columns = [{ field: 'title' }, { field: 'info', width: '70%' }, { field: 'value', align: 'right' }];
  const rows = [
    {
      title: <TitleElement title="Time Frame" />,
      info: <InfoElement info={toolTipText.watchList.VIEW} />,
      value: <ValueElement value={watchTowerData?.[0]?.view ?? 'monthly'} />,
    },
    {
      title: <TitleElement title="Threshold" />,
      info: <InfoElement info={toolTipText.watchList.THRESHOLD_INFO} />,
      value: <ValueElement value={watchTowerData?.[0]?.threshold ?? 5} />,
    },
    {
      title: <TitleElement title="Moving Average Window" />,
      info: <InfoElement info={(watchTowerData?.[0]?.view === 'weekly') ? toolTipText.watchList.MOVING_AVERAGE_WEEKLY : toolTipText.watchList.MOVING_AVERAGE_MONTH} />,
      value: <ValueElement value={watchTowerData?.[0]?.averageMonths ?? 3} />,
    },
  ];

  return (
    <Box className={classes.cardStyles} sx={{ width: '100%' }}>
      {/* Header */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography sx={{ p: '10px', fontSize: '22px', fontWeight: 500 }}>
          KPI Score Trends
        </Typography>
        <CustomIcon tooltip="Edit" onClick={handleOpen} Icon={EditIcon} iconSize="small" variant="outlined" sxProps={{ marginRight: 1 }} />
      </Stack>
      {/* Content */}
      <DynamicTable columns={columns} rows={rows} />
    </Box>
  );
}

export default WatchTowerCard;
