import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { LICENSE_ASSIGNMENT } from '../../utils/constants';

const useLicenseUtilization = () => {
  const accountListData = useSelector((state) => state.accountsList);
  const [accountList, setAccountList] = useState({
    accountsList: [],
    loading: true,
    error: '',
    percentCount: {},
  });

  const percentList = [
    [LICENSE_ASSIGNMENT.SITE_WIDE_LICENSE, -1, 0],
    [LICENSE_ASSIGNMENT.LESS_25, 0, 25],
    [LICENSE_ASSIGNMENT.FROM_25_50, 25, 50],
    [LICENSE_ASSIGNMENT.FROM_50_75, 50, 75],
    [LICENSE_ASSIGNMENT.FROM_75_100, 75, 100],
  ];

  const calculateCount = (array, lower, upper) => {
    return array.reduce((count, item) => {
      if (lower === -1) {
        return item.totalSeats <= lower ? count + 1 : count;
      }
      if (lower === 0) {
        return item.totalSeats > -1 && item.assignedSeatsPercent >= lower && item.assignedSeatsPercent <= upper ? count + 1 : count;
      }
      return (upper === 100 ? item.assignedSeatsPercent > lower : item.assignedSeatsPercent > lower && item.assignedSeatsPercent <= upper) ? count + 1 : count;
    }, 0);
  };

  const filterAccountsList = (array) => {
    let currentDate = new Date();
    currentDate = currentDate.setHours(0, 0, 0, 0);
    const filterData = (array.accountsList || []).filter((item) => (
      (new Date(item.renewalDate) >= currentDate || item.renewalDate === null) && item.status === 'Active' && item.visibility
    ));
    return filterData;
  };

  const setPercentCount = (filteredAccounts) => {
    const Obj = {};
    percentList.forEach(([condition, lower, upper]) => {
      Obj[condition] = calculateCount(filteredAccounts, lower, upper);
    });
    return Obj;
  };

  useEffect(() => {
    const filteredAccounts = filterAccountsList(accountListData);

    const dataObj = setPercentCount(filteredAccounts);

    setAccountList({
      accountsList: filteredAccounts,
      loading: accountListData.loading || false,
      error: accountListData.error || '',
      percentCount: dataObj,
    });
  }, [accountListData]);

  return accountList;
};

export default useLicenseUtilization;
