import { format, getTime, formatDistanceToNow } from 'date-fns';
import moment from 'moment';

// ----------------------------------------------------------------------

export const fDate = (date, inputFormat) => (moment(date).format(inputFormat || 'DD-MM-YYYY'));

export const identifyAndConvertDate = (date, inputFormat, apiDateFormat) => {
  return (moment(date, apiDateFormat).format(inputFormat || 'DD-MM-YYYY'));
};

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
      addSuffix: true,
    })
    : '';
}

export function monthRangeUi(dateArray) {
  const startDate = moment(dateArray[0]).format('MMM');
  const endDate = moment(dateArray[1]).format('MMM');

  const dateString = `${startDate} - ${endDate}`;
  return dateString;
}

export const toMonthString = (date) => moment(date).format('MMM YYYY');
