import {
  Divider, Stack,
} from '@mui/material';
import styled from '@emotion/styled';
import { FeatureUsageGraph } from '../../../FeatureUsage';
import { withLoadingAndOverlay } from '../../../../hoc';

function PinnedFeatureCard({
  stackHeight, graphType, pinnedFeatures, getChartData, viewOption,
}) {
  const DynamicStack = styled(Stack)(() => ({
    height: `${stackHeight}px`,
    overflowY: 'scroll',
  }));
  return (
    <DynamicStack spacing={2}>
      {pinnedFeatures?.data?.length && pinnedFeatures.data.map((feature) => {
        const { featureName } = feature;
        const userCountLen = Object.keys(feature.userCount).length;
        const operationCountLen = Object.keys(feature.operationCount).length;
        if (userCountLen && operationCountLen) {
          return (
            <>
              <FeatureUsageGraph
                getChartData={getChartData}
                feature={feature}
                viewOption={viewOption}
                graphHeight={310}
                graphWidth={200}
                graphType={graphType}
                featureName={featureName}
              />
              <Divider />
            </>
          );
        }
        return null;
      })}
    </DynamicStack>
  );
}
export default withLoadingAndOverlay(PinnedFeatureCard);
