import {
  Grid, Button, Box, Typography,
} from '@mui/material';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { ConfirmDialogBox } from '../../components/CustomDialogBox';
import { TenantDialog, TenantList } from '../../modules/TenantManager';
import { fetchTenants } from '../../features/Tenant/tenantAction';
import { BUTTONS, TITLES } from '../../utils/constants';
import useTenantManager from './useTenantManager';
import useStyles from '../../hooks/useStyles';
import styles from '../../layouts/pages/style';

function TenantManager({ permissions }) {
  const classes = useStyles(styles)();
  const dispatch = useDispatch();

  const {
    tenantsList,
    tenantsColumns,
    openDialog,
    setOpenDialog,
    openActionDialog,
    setOpenActionDialog,
    actionDialogData,
  } = useTenantManager({ permissions });

  useEffect(() => {
    dispatch(fetchTenants());
  }, []);

  const formRef = useRef(null);

  return (
    <Grid container gap={2}>
      <Grid item xs={12} className={classes.cardStyles}>
        <Box className={classes.headerStyles}>
          <Typography variant="h4" gutterBottom>{TITLES.TENANT_MANAGER}</Typography>
          <Button variant="outlined" onClick={setOpenDialog}>{BUTTONS.CREATE_TENANT}</Button>
        </Box>
      </Grid>

      <Grid item xs={12} className={classes.tableStyles}>
        <TenantList
          loading={tenantsList?.loading}
          error={tenantsList?.error}
          data={tenantsList?.data}
          columns={tenantsColumns}
        />
      </Grid>

      <TenantDialog
        formRef={formRef}
        openDialog={openDialog}
        handleClose={setOpenDialog}
      />

      <ConfirmDialogBox
        title={actionDialogData.title}
        message={actionDialogData.message}
        openDialog={openActionDialog}
        onClose={setOpenActionDialog}
        onConfirm={actionDialogData.onConfirm}
      />
    </Grid>
  );
}

export default TenantManager;
