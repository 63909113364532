import { withLoadingAndOverlay } from '../../../hoc';
import { CustomDataGrid } from '../../../components/CustomDataGrid';

function TenantList({ data, columns, id = 'tenantId' }) {
  return (
    <CustomDataGrid
      rowId={id}
      columns={columns}
      rows={data}
      footer
    />
  );
}

export default withLoadingAndOverlay(TenantList);
