import {
  Box,
  Divider, Grid, Stack, useMediaQuery,
} from '@mui/material';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getAccountsList } from '../../../../features';
import CustomIcon from '../../../../components/CustomIcon/CustomIcon';
import { SelectBox, TextFieldWithError } from '../../../../components/FormComponents';
import useScoreCriteria from './useScoreCriteria';
import ToggleCondition from './ToggleCondition';

function ScoreCriteria({ activePackage, healthScoreCriteria, setHealthScoreCriteria }) {
  const {
    accountListData,
    accountList,
    criteriaOptions,
    operatorOptions,
    handleSelectCriteria,
    handleSelectAccounts,
    handleRemoveCriteriaRow,
    handleChangeCondition,
  } = useScoreCriteria({ healthScoreCriteria, setHealthScoreCriteria });

  const dispatch = useDispatch();
  useEffect(() => {
    if (!accountListData?.accountsList?.length && activePackage?.packageId) {
      dispatch(getAccountsList({ packageId: activePackage.packageId }));
    }
  }, [accountListData?.accountsList, activePackage?.packageId]);

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <Grid container direction="column" spacing={2} pt={1}>
      {healthScoreCriteria?.map((row, index) => (
        <Fragment key={index}>
          <Grid item>
            <Stack direction={isSmallScreen ? 'column' : 'row'} spacing={2} alignItems="center" justifyContent="start">
              <>
                {/* Criteria Select Box */}
                <SelectBox
                  width="min(400px, 100%)"
                  name="criteria"
                  title="Criteria"
                  data={criteriaOptions}
                  value={row.criteria}
                  onChange={(e) => handleSelectCriteria(e, index)}
                />

                {/* If criteria is 'Accounts', show accounts select box only */}
                {row.criteria === 'accounts' ? (
                  <SelectBox
                    multiple
                    width="min(400px, 100%)"
                    name="accounts"
                    title="Accounts"
                    data={accountList}
                    value={row.accounts || []}
                    onChange={(e) => handleSelectAccounts(e, index)}
                  />
                ) : (
                  row.criteria && (
                  <>
                    {/* Show operator and value input if criteria is not 'Accounts' */}
                    <SelectBox
                      width="min(400px, 100%)"
                      name="operator"
                      title="Operator"
                      data={operatorOptions}
                      value={row.operator}
                      onChange={(e) => handleSelectCriteria(e, index)}
                    />
                    <TextFieldWithError
                      width="min(400px, 100%)"
                      name="value"
                      value={row.value || ''}
                      onChange={(e) => handleSelectCriteria(e, index)}
                    />
                  </>
                  )
                )}
              </>
              {/* Show - buttons for rows */}
              <Box alignSelf={isSmallScreen ? 'flex-end' : 'center'}>
                <CustomIcon Icon={RemoveCircleOutlineOutlinedIcon} onClick={() => handleRemoveCriteriaRow(index)} />
              </Box>
            </Stack>
          </Grid>

          {/* Show AND / OR select box between rows, except for the first row */}
          {index < healthScoreCriteria.length - 1 && (
            <Grid
              item
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                overflow: 'hidden',
                gap: 1,
              }}
            >
              <ToggleCondition
                value={row.andOr || '&&'}
                onChange={(_, newVal) => handleChangeCondition(index, newVal)}
              />
              <Divider sx={{ width: '100px' }} />
            </Grid>
          )}
        </Fragment>
      ))}
    </Grid>
  );
}

export default ScoreCriteria;
