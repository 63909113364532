import useHistogram from './useHistogram';
import Histogram from './Histogram';
import { CustomNoDataOverlay } from '../CustomNoDataOverlay';

function RangeSlider({
  threshold, graphData, setFieldValue, index, targetMetric,
}) {
  const { modifiedBarData } = useHistogram({ data: graphData });

  return (
    modifiedBarData && Object.keys(modifiedBarData).length > 0 ? (
      <Histogram
        modifiedBarData={modifiedBarData}
        targetMetric={targetMetric}
        setFieldValue={setFieldValue}
        index={index}
        threshold={threshold}
      />
    ) : <CustomNoDataOverlay message="No Data Found" />
  );
}

export default RangeSlider;
