import { useEffect } from 'react';
import { connectSocket, disconnectSocket } from '../../utils/socketService';

function Socket() {
  useEffect(() => {
    // Connect to the WebSocket server when the component is mounted
    connectSocket();

    // Clean up by disconnecting from the server when the component unmounts
    return () => {
      disconnectSocket();
    };
  }, []);

  return null;
}

export default Socket;
