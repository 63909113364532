import { Stack } from '@mui/material';
import { useRef } from 'react';
import { generateGraphLabels } from '../../../../utils/getDate';
import { Graph } from '../../../../components/Graph';
import { withLoadingAndOverlay } from '../../../../hoc';
import 'chartjs-plugin-zoom';
import palette from '../../../../theme/palette';
import { ZoomControls } from '../../../../components/ZoomControls';
import { ZOOM_OPTIONS } from '../../../../utils/chartConfigs';

function DailyUsersCard({ data, more }) {
  const chartRef = useRef(null);
  const labels = generateGraphLabels(Object.keys(data ?? {}), 'day');
  const values = Object.values(data ?? {});

  const datasets = [
    {
      label: 'Active Users',
      borderColor: palette.secondary.mainColor,
      backgroundColor: palette.info.light,
      borderWidth: 2,
      fill: true,
      data: values,
    },
  ];

  const options = {
    scales: {
      x: { grid: { display: false } },
      y: { grid: { display: false } },
    },
    elements: { point: { radius: 0 } },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: true,
        mode: 'nearest',
        intersect: false,
      },
      zoom: ZOOM_OPTIONS,
    },
  };

  return (
    <>
      <Stack sx={{
        width: '100%', alignItems: 'end',
      }}
      >
        <ZoomControls chartRef={chartRef} data={data} />
      </Stack>
      <Graph
        chartRef={chartRef}
        type="line"
        labels={labels}
        datasets={datasets}
        options={options}
        height={more ? null : 350}
      />
    </>
  );
}

export default withLoadingAndOverlay(DailyUsersCard);
