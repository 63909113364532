import { createSlice } from '@reduxjs/toolkit';
import { getObjectFilters } from './objectAnalyserAction';

const objectFiltersSlice = createSlice({
  name: 'objectFilters',
  initialState: {},
  reducers: {
    resetObjectFilters: (state) => {
      state.objectRecords = [];
      state.objectFilters = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getObjectFilters.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getObjectFilters.fulfilled, (state, { payload }) => {
      if (payload.object) {
        state.objectRecords = payload.records;
      } else {
        state.objectFilters = payload.filters;
      }
      if (state.objectRecords && state.objectFilters) {
        state.loading = false;
      }
    });
    builder.addCase(getObjectFilters.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});
export const { resetObjectFilters } = objectFiltersSlice.actions;

export default objectFiltersSlice;
