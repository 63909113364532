import useSetPassword from './useSetPassword';
import Metatags from '../../../components/MetaTags/MetaTags';
import SetPasswordForm from './SetPasswordForm';

export default function SetPassword() {
  const {
    validationSchema, initialValues, handleSubmit, handleTogglePassword, auth, showPassword, storedEmail, isReset,
  } = useSetPassword();
  return (
    <>
      <Metatags metaTagName="Set Password | App Insights" />
      <SetPasswordForm
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        handleTogglePassword={handleTogglePassword}
        auth={auth}
        showPassword={showPassword}
        storedEmail={storedEmail}
        isReset={isReset}
      />
    </>
  );
}
