import { createAsyncThunk } from '@reduxjs/toolkit';

import request from '../../modules/Request/request';

export const getActiveUsers = createAsyncThunk(
  'getActiveUsers',
  async ({
    page, orgId, packageId, isShare, tenantId, accountOrgId,
  }, { rejectWithValue }) => {
    const reqPayload = {};
    const query = {};
    reqPayload.method = 'GET';
    query.page = page;
    query.orgId = orgId;
    query.pkgId = packageId;
    if (isShare) {
      query.isShare = isShare;
      if (accountOrgId) {
        query.accountOrgId = accountOrgId;
      } else {
        query.tenantId = tenantId;
      }
    }
    reqPayload.query = query;
    const response = await request('/active-user', reqPayload);
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

// active user configuration

export const getActiveUserConfig = createAsyncThunk(
  'getActiveUserConfig',
  async ({ packageId }, { rejectWithValue }) => {
    const response = await request('/active-user-config', { method: 'GET', params: [packageId] });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const setupActiveUserConfig = createAsyncThunk(
  'setupActiveUserConfig',
  async ({ activeUserConfig, featureId, packageId }, { rejectWithValue }) => {
    const response = await request('/active-user-setup', { method: 'POST', body: { activeUserConfig, featureId, packageId } });
    if (response.success) {
      return {
        code: response.data.code,
        status: response.data.status,
        data: { activeUserConfig, featureId },
      };
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);
