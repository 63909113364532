// ----------------------------------------------------------------------

export default function Table() {
  return {
    MuiTableBody: {
      styleOverrides: {
        root: {
          overflowX: 'unset',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          overflowX: 'unset',
        },
      },
    },
  };
}
