import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '../../modules/Request/request';

export const getFeatureParameter = createAsyncThunk(
  'featureParameter',
  async ({ orgId, packageId }, { rejectWithValue }) => {
    const response = await request('/feature-parameter', { method: 'GET', params: [orgId, packageId] });
    if (response.success) {
      return response.data;
    }
    const error = response?.error?.message || response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);
