/* eslint-disable no-unused-vars */
/* eslint-disable no-eval */
import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getDate, getFiscalYear, isAfter90Days } from '../../utils/getDate';
import { parse } from '../../utils/methods';

const currentDate = getDate();
const currentMonth = currentDate.getMonth();
const currentYear = currentDate.getFullYear();

const threeMonthsLater = getDate();
threeMonthsLater.setMonth(currentMonth + 3);

const threeMonthsAgo = getDate();
threeMonthsAgo.setMonth(currentMonth - 3);

let fiscalYearStart;
let fiscalYearEnd;

const FISCAL_CONSTS = {
  YEAR_BACK: {
    NO: 0,
    ONE: 1,
    TWO: 2,
  },
  MONTH: {
    START: 3,
    END: 2,
  },
  DATE: {
    START: 1,
    END: 31,
  },
};

if (currentMonth <= 2) {
  fiscalYearStart = getFiscalYear(currentYear, FISCAL_CONSTS.YEAR_BACK.TWO, FISCAL_CONSTS.MONTH.START, FISCAL_CONSTS.DATE.START);
  fiscalYearEnd = getFiscalYear(currentYear, FISCAL_CONSTS.YEAR_BACK.ONE, FISCAL_CONSTS.MONTH.END, FISCAL_CONSTS.DATE.START);
} else {
  fiscalYearStart = getFiscalYear(currentYear, FISCAL_CONSTS.YEAR_BACK.ONE, FISCAL_CONSTS.MONTH.START, FISCAL_CONSTS.DATE.START);
  fiscalYearEnd = getFiscalYear(currentYear, FISCAL_CONSTS.YEAR_BACK.NO, FISCAL_CONSTS.MONTH.END, FISCAL_CONSTS.DATE.END);
}

const useAccountStats = () => {
  const featureUsageAccounts = useSelector((state) => state.featureUsageAccounts);
  const accountListData = useSelector((state) => state.accountsList);
  const customFilterData = useSelector((state) => state.getCustomAlertDetails.data);
  const highRiskAccountConfig = useSelector((state) => state.highRiskAccountConfig?.data);
  const [accountList, setAccountList] = useState({
    data: [],
    error: '',
    loading: true,
  });

  const navigateState = {
    risk: {
      filters: {
        licenceStatus: 'Active',
        accountVisibility: true,
        condition: highRiskAccountConfig?.highRiskAccount?.condition ?? 'healthScore <= 30',
      },
      isNavigatedFromRiskAccount: true,
    },
    stable: {
      filters: {
        licenceStatus: 'Active',
        accountVisibility: true,
        healthScoreTrend: 'stable',
      },
      showAdvanceFilter: true,
    },
  };

  const accountStatsData = useMemo(() => {
    let activeCount = 0;
    let trialCount = 0;
    let churnedCount = 0;
    let siteWideSeats = 0;
    let totalSeats = 0;
    let assignedSeats = 0;
    let threeMonthCount = 0;
    let fiscalYearCount = 0;
    let upcomingRenewalCount = 0;
    let withoutUsageCount = 0;
    let trendingCount = 0;
    let decliningCount = 0;
    let stableCount = 0;
    let riskCount = 0;
    const { error, loading } = accountList;

    const isNotNewAccount = (installDate) => !(installDate && isAfter90Days(installDate));

    const isWithinDateRange = (date, startDate, endDate) => {
      if (!startDate || !endDate) {
        return true;
      }

      const givenDate = new Date(date);
      const start = new Date(startDate);
      const end = new Date(endDate);

      return givenDate >= start && givenDate <= end;
    };

    const checkAccountStatus = (status, account) => {
      const isActive = account.status === 'Active';
      const isTrial = account.status === 'Trial';
      const isUninstalled = account.status === 'Uninstalled';
      const isNeverExpire = account.renewalDate === null;
      const isRenewalDateValid = account.renewalDate && getDate(account.renewalDate) >= currentDate.setHours(0, 0, 0, 0);

      switch (status) {
        case 'ACTIVE':
          return (isActive && (isRenewalDateValid || isNeverExpire));

        case 'TRIAL':
          return (isTrial && (isRenewalDateValid || isNeverExpire));

        case 'UNINSTALLED':
          return (isUninstalled);

        case 'ACTIVE_TRIAL':
          return ((isActive || isTrial) && (isRenewalDateValid || isNeverExpire));

        default:
          return false;
      }
    };

    const checkHighRiskAccount = (account, condition = 'healthScore <= 30') => {
      const {
        healthScore, healthScoreTrend, assignedSeatsPercent, weeklyTrend, installDate,
      } = account;
      return isNotNewAccount(installDate) && eval(condition);
    };

    const checkStableAccount = (account) => {
      const isStable = customFilterData?.[0]?.view === 'weekly' ? account.weeklyTrend === 'stable' : account.healthScoreTrend === 'stable';
      return isNotNewAccount(account.installDate) && isStable;
    };

    accountList.data.forEach((account) => {
      if (account.visibility) {
        if (checkAccountStatus('ACTIVE', account)) {
          if (checkHighRiskAccount(account, highRiskAccountConfig?.highRiskAccount?.condition)) {
            riskCount++;
          } else if (checkStableAccount(account)) {
            stableCount++;
          }
          activeCount++;

          if (parse(account.totalSeats) <= 0) {
            siteWideSeats++;
          } else {
            totalSeats += parse(account.totalSeats) || 0;
            assignedSeats += parse(account.seatsAssigned) || 0;
          }
        }
        if (checkAccountStatus('TRIAL', account)) {
          trialCount++;
        }
        if (checkAccountStatus('UNINSTALLED', account)) {
          churnedCount++;
        }
        if (isWithinDateRange(account.activationDate, threeMonthsAgo, currentDate) && checkAccountStatus('ACTIVE_TRIAL', account)) {
          threeMonthCount++;
        }
        if ((customFilterData?.[0]?.view === 'weekly' ? account.weeklyTrend === 'rising' : account.healthScoreTrend === 'rising') && checkAccountStatus('ACTIVE', account)) {
          trendingCount++;
        }
        if ((customFilterData?.[0]?.view === 'weekly' ? account.weeklyTrend === 'falling' : account.healthScoreTrend === 'falling') && checkAccountStatus('ACTIVE', account)) {
          decliningCount++;
        }
        if (isWithinDateRange(account.activationDate, fiscalYearStart, fiscalYearEnd) && checkAccountStatus('ACTIVE_TRIAL', account)) {
          fiscalYearCount++;
        }
        if (isWithinDateRange(account.renewalDate, currentDate, threeMonthsLater) && checkAccountStatus('ACTIVE_TRIAL', account)) {
          upcomingRenewalCount++;
        }
        if (checkAccountStatus('ACTIVE', account) && account.orgId && featureUsageAccounts?.data?.length && !featureUsageAccounts.data.includes(account.orgId)) {
          withoutUsageCount++;
        }
      }
    });

    return {
      activeCount, trialCount, churnedCount, siteWideSeats, totalSeats, assignedSeats, threeMonthCount, fiscalYearCount, upcomingRenewalCount, withoutUsageCount, trendingCount, decliningCount, stableCount, riskCount, error, loading,
    };
  }, [accountList.data, accountListData, highRiskAccountConfig?.highRiskAccount]);

  const setAccountListData = () => {
    const { accountsList, loading } = accountListData;
    if (accountsList) {
      setAccountList({
        ...accountList, data: accountsList, loading,
      });
    }
  };

  useEffect(() => {
    setAccountListData();
  }, [accountListData?.accountsList]);

  return {
    accountStatsData,
    currentDate,
    threeMonthsAgo,
    threeMonthsLater,
    fiscalYearStart,
    fiscalYearEnd,
    navigateState,
  };
};

export default useAccountStats;
