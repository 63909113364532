import { useDispatch } from 'react-redux';
import {
  Box, Typography, Card, CardContent, Button, Grid,
} from '@mui/material';
import CableIcon from '@mui/icons-material/Cable';
import useStyles from './style';
import request from '../../modules/Request/request';
import { openSnackbar } from '../../features/SnackBar/snackBarSlice';

function RequirementItem({ images, heading, description }) {
  const classes = useStyles();

  return (
    <Box>
      {images.map((src, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'flex-start', marginBottom: '10px' }}>
          <img src={src} alt={heading} className={classes.image} />
          <Box>
            <Typography variant="h6" component="h4" className={classes.heading}>
              <b>{heading}</b>
            </Typography>
            <Typography variant="body2" gutterBottom>
              {description}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

function PboIntroductoryScreen() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSave = async () => {
    const response = await request('/redirect-url', { method: 'GET' });
    const { success, data = { record: {} } } = response;
    if (success) {
      const { record } = data;
      if (record.salesforceUrl && record.clientId && record.redirectUrl) {
        window.location.replace(`${record.salesforceUrl}?client_id=${record.clientId}&redirect_uri=${record.redirectUrl}&response_type=code`);
      } else {
        dispatch(openSnackbar({ open: true, message: 'Failed to get Connect PBO URL', type: 'error' }));
      }
    } else {
      dispatch(openSnackbar({ open: true, message: 'Failed to get Connect PBO URL', type: 'error' }));
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.requirementItemContainer}>
        <Typography variant="h2" className={classes.welcomeText} fontWeight="bold">
          Welcome
        </Typography>
        <Typography variant="body1" marginBottom="35px" gutterBottom>
          To ensure a smooth process, please verify that your Salesforce org meets the following requirements:
        </Typography>
        <RequirementItem heading="Installed ISV Package" description="Your custom application, the ISV Package, must be installed in your Salesforce org." images={['Group 1.svg']} />
        <RequirementItem heading="Enable App Analytics API" description="The App Analytics API needs to be enabled for your org. The API provides vital usage data insights." images={['Group 2.svg']} />
        <RequirementItem heading="License Management ORG (LMO) in Place" description="Establishing a designated License Management Organisation (LMO) and subsequently deploying and activating the License Management Application (LMA) is imperative for the effective management of your Independent Software Vendor (ISV) package licenses." images={['Group 3.svg']} />
      </Box>
      <Card sx={{ width: '100%' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="body1" gutterBottom>
                Access your organization&apos;s data instantly by clicking Connect PBO
              </Typography>
              <Button onClick={() => handleSave()} variant="contained" color="primary" className={classes.connectButton} startIcon={<CableIcon />}>
                Connect PBO
              </Button>
            </Grid>
            <Grid item xs={12} md={6}>
              <img src="/pboImage.svg" alt="alt" style={{ maxWidth: '100%' }} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

export default PboIntroductoryScreen;
