import { createAsyncThunk } from '@reduxjs/toolkit';

import request from '../../modules/Request/request';

export const getCustomObjects = createAsyncThunk('getCustomObjects', async (object, { rejectWithValue }) => {
  const page = object?.type === 'all' ? 'dashboard' : 'account';
  const reqPayload = {};
  const query = {};
  reqPayload.method = 'GET';
  query.page = page;
  query.orgId = object.orgId;
  query.pkgId = object.pkgId;
  if (object?.isShare) {
    query.isShare = object.isShare;
    if (object.accountOrgId) {
      query.accountOrgId = object.accountOrgId;
    } else {
      query.tenantId = object.tenantId;
    }
  }
  if (object?.view) {
    query.view = object.view;
  }
  reqPayload.query = query;
  const response = await request('/custom-object', reqPayload);
  if (response.success) {
    return response.data;
  }
  const error = response.error && response.error.message ? response.error.message : response.error;
  throw rejectWithValue(error || 'Something went wrong');
});

export const getCustomObjectPinningData = createAsyncThunk('getCustomObjectPinningData', async (object, { rejectWithValue }) => {
  const reqPayload = {};
  const query = {};
  reqPayload.method = 'GET';
  if (object?.isShare) {
    query.isShare = object.isShare;
    if (object.accountOrgId) {
      query.accountOrgId = object.accountOrgId;
    } else {
      query.tenantId = object.tenantId;
    }
  }
  reqPayload.query = query;
  reqPayload.params = object.packageId;
  const response = await request('/custom-object-pinning-data', reqPayload);
  if (response.success) {
    return response.data;
  }
  const error = response.error && response.error.message ? response.error.message : response.error;
  throw rejectWithValue(error || 'Something went wrong');
});

export const customObjectPinningAction = createAsyncThunk('customObjectPinningAction', async (object, { rejectWithValue }) => {
  const response = await request('/custom-object-pinning-action', { method: 'POST', params: object.packageId, body: { pinningData: object.pinningData } });
  if (response.success) {
    return response.data;
  }
  const error = response.error && response.error.message ? response.error.message : response.error;
  throw rejectWithValue(error || 'Something went wrong');
});
