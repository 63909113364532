import { createSlice } from '@reduxjs/toolkit';
import { fetchWatchListAccounts, getCustomAlertDetails, saveCustomAlertDetails } from './customAlertAction';

export const watchListAccountsSlice = createSlice({
  name: 'watchListAccounts',
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWatchListAccounts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchWatchListAccounts.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
      state.error = null;
    });
    builder.addCase(fetchWatchListAccounts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const getCustomAlertSlice = createSlice({
  name: 'getCustomAlertDetails',
  initialState: { data: [], error: null, loading: false },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomAlertDetails.pending, (state) => {
      state.loading = true;
      state.data = [];
    });
    builder.addCase(getCustomAlertDetails.fulfilled, (state, { payload }) => {
      state.data = payload.customAlertDetailList;
      state.loading = false;
    });
    builder.addCase(getCustomAlertDetails.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });
  },
});

export const saveCustomAlertSlice = createSlice({
  name: 'saveCustomAlertDetails',
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveCustomAlertDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveCustomAlertDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
      state.error = null;
    });
    builder.addCase(saveCustomAlertDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});
