import Button from '@mui/material/Button';
import {
  Stack, Grid, Box,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { CustomChip, InfoChip } from '../../../../components/CustomChip';
import { SearchBar } from '../../../../components/FormComponents';
import { CustomDialogBox } from '../../../../components/CustomDialogBox';
import usePickCustomEntity from './usePickCustomEntity';

function DialogContentElement({
  label,
  handleSelectAll,
  handleUnSelectAll,
  searchTerm,
  handleSearchChange,
  data,
  handlePickClick,
  ids,
}) {
  return (
    <Box>
      <Stack direction="column" justifyContent="start">
        {/* Info */}
        {label === 'Custom Object' && (
          <InfoChip
            type="multiline"
            icon={<InfoIcon color="primary" />}
            label="In this step, please select the custom objects that should be part of your KPI. You can refine their
          scope In the next step, e.g. limit custom object components to specific CRUD types."
          />
        )}

        {/* Buttons */}
        <Stack direction="row" justifyContent="start" alignItems="center" mb={3} mt={3} gap={2}>
          <Button size="small" variant="contained" onClick={handleSelectAll}>Select All</Button>
          <Button size="small" variant="contained" onClick={handleUnSelectAll}>Unselect All</Button>
        </Stack>
      </Stack>

      {/* Search */}
      <SearchBar
        label="Search"
        value={searchTerm}
        onChange={(e) => handleSearchChange(e.target.value)}
        onClear={() => handleSearchChange('')}
        data={searchTerm}
      />

      {/* Data */}
      <Grid container spacing={1} sx={{ minWidth: 'inherit' }} mt={2}>
        {data && data.length
          ? data.map((obj) => {
            const isApex = obj.custom_entity_type?.includes('Apex') || false;
            return (
              <Grid key={obj.custom_entity_id || obj.feature_id} item xs={6}>
                <CustomChip
                  tooltip={obj.custom_entity_label || obj.feature_name}
                  type={isApex ? 'multiline' : 'info'}
                  label={obj.custom_entity_label || obj.feature_name}
                  selected={ids.includes(obj.custom_entity_id || obj.feature_id)}
                  onClick={() => handlePickClick(obj.custom_entity_id || obj.feature_id)}
                  textAlign="left"
                />
              </Grid>
            );
          })
          : null}
      </Grid>
    </Box>
  );
}

export default function PickCustomEntity({
  open, handleClose, label, type, handleSelect, editFeatureData,
}) {
  const {
    data, ids, searchTerm, handlePickClick, handleSelectAll, handleUnSelectAll, handleSearchChange,
  } = usePickCustomEntity({ type, editFeatureData });

  return (
    <CustomDialogBox
      title={label}
      open={open}
      handleClose={handleClose}
      content={(
        <DialogContentElement
          label={label}
          handleSelectAll={handleSelectAll}
          handleUnSelectAll={handleUnSelectAll}
          searchTerm={searchTerm}
          handleSearchChange={handleSearchChange}
          data={data}
          handlePickClick={handlePickClick}
          ids={ids}
        />
      )}
      actions={{ title: 'Save', onClick: () => handleSelect(ids, type) }}
    />
  );
}
