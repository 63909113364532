import {
  Box, Card, CardContent, LinearProgress, Stack, Typography,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { CustomIcon } from '../../../../components/CustomIcon';
import { CustomNoDataOverlay } from '../../../../components/CustomNoDataOverlay';

function OverviewCard(props) {
  const {
    loading, data, title, thumbnail, info, handleClick,
  } = props;

  return (
    <Card
      sx={{
        backgroundColor: 'white',
        width: '33%',
        boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px',
        borderRadius: '6px',
        cursor: 'pointer',
      }}
      onClick={handleClick}
    >
      {loading ? <LinearProgress /> : null}
      {(!data || !Object.keys(data)?.length) && !loading && <CustomNoDataOverlay />}
      {data && Object.keys(data)?.length > 0 && !loading && (
        <CardContent>
          <Box display="flex" alignItems="center" flexWrap="wrap" maxHeight="200px" gap={2}>
            <Box flex={0.8} minWidth="100px" display="flex" alignItems="center">
              {thumbnail}
            </Box>
            <Box flex={1} minWidth="100px" display="flex" alignItems="start" flexDirection="column">
              <Stack direction="row" alignItems="center">
                <Typography variant="h6">{title}</Typography>
                <CustomIcon Icon={InfoIcon} tooltip="Click to Expand!" />
              </Stack>
              <Typography variant="body2" color="text.secondary">
                {info}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      )}
    </Card>
  );
}

export default OverviewCard;
