import {
  Box, Grid, Typography, useMediaQuery,
} from '@mui/material';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

export default function AuthLayout() {
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const useStyles = makeStyles({
    loginScreen: {
      minHeight: '100vh',
      justifyContent: isSmallScreen ? 'center' : 'flex-end',
      alignItems: 'center',
      position: isSmallScreen ? 'relative' : 'auto',
      backgroundColor: isSmallScreen ? 'transparent' : 'auto',
    },
    rightSideBox: {
      backgroundColor: '#ffffff',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
      alignItems: 'center',
    },
    logo: {
      height: isSmallScreen ? '60px' : '70px',
      width: 'auto',
    },
  });

  const classes = useStyles();

  return (
    <Grid container gap={5} py={5} px={{ xs: 0, sm: 5 }} height="100%" justifyContent="center" alignItems="center">
      {/* Left column */}
      {!isSmallScreen
        ? (
          <Grid item xs={12} sm={10} md={5.5} lg={6} display="flex" alignItems="center">
            <Box>
              <img src="/App-Insights-logo-light-bg.svg" width="60%" alt="app-insights" className={classes.logo} />
              <Typography ml={2} mt={1}>Unlock Meaningful Insights of your ISV Application</Typography>
              <img src="/loginPage.png" alt="login" width="80%" style={{ paddingTop: '30px' }} />
            </Box>
          </Grid>
        )
        : null}
      {/* Right column */}
      <Grid item xs={12} sm={10} md={5} lg={4} height={{ xs: 'auto', md: '100%' }} pb={{ xs: 5, md: 0 }} display="flex" justifyContent="center" alignItems="center">
        <Box sx={{
          borderRadius: '20px',
          ...(!isSmallScreen && {
            background: '#FAFBFF',
            boxShadow: '2px 2px 6px 0px rgba(0, 0, 0, 0.25)',
          }),
          height: '90%',
          minHeight: '500px',
          p: {
            xs: 4, sm: 6, md: 7, lg: 8,
          },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
        >
          {isSmallScreen
            ? (
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: -40,
                  pl: { xs: 3, sm: 6 },
                  mr: { xs: 4, sm: 6 },
                }}
              >
                <img src="/App-Insights-logo-light-bg.svg" alt="app-insights" style={{ maxHeight: '60px' }} />
                <Typography pl={1} mt={1}>Unlock Meaningful Insights of your ISV Application</Typography>
              </Box>
            )
            : null}
          <Outlet />
          <Box sx={{ position: 'absolute', bottom: 20 }}>
            <img src="/poweredBy.png" alt="login" height="20px" />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
