import { useDispatch } from 'react-redux';
import { setupHighRiskAccount } from '../../../../features/Configuration/configurationAction';
import { openSnackbar } from '../../../../features/SnackBar/snackBarSlice';
import { FormDialogBox } from '../../../../components/CustomDialogBox';
import CustomerSizeForm from './CustomerSizeForm';

function CustomerSizeDialog(props) {
  const {
    open, handleClose, packageId, data,
  } = props;

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(setupHighRiskAccount({ customerSizeCategories: values.categories, packageId }))
      .then(() => {
        dispatch(openSnackbar({ open: true, message: 'Successfully Updated!', type: 'success' }));
      });

    handleClose();
  };

  const initialValues = {
    categories: data?.length > 0 ? data : [{ title: '', from: '', to: '' }],
  };

  return (
    <FormDialogBox
      title="Configure Customer Size"
      open={open}
      handleClose={handleClose}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      formContent={<CustomerSizeForm />}
      formAction={{ title: 'Update' }}
    />
  );
}

export default CustomerSizeDialog;
