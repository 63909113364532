import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { withLoadingAndOverlay } from '../../../../../hoc';
import { StepIconComponent } from './StepIconComponent';
import StepsDetail from './StepsDetail';

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: '30%',
    width: '100%',
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 12,
    border: 0,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 1,
  },
}));

function TimeLineComponent({ data }) {
  return (
    <Stack sx={{ width: '100%', overflow: 'auto' }} spacing={8}>
      <Stepper alternativeLabel activeStep={1} connector={<ColorlibConnector />}>
        {data.map((event) => (
          <Step key={event.id}>
            <StepLabel sx={{ marginLeft: '20px', marginRight: '20px' }} StepIconComponent={() => StepIconComponent(event)}>
              <StepsDetail event={event} />
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}

export default withLoadingAndOverlay(TimeLineComponent);
