import { createSlice } from '@reduxjs/toolkit';
import { getAccountStats } from './accountStatsAction';

const accountStatsSlice = createSlice({
  name: 'accountStats',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccountStats.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAccountStats.fulfilled, (state, { payload }) => {
      state.loading = false;
      const { data, object } = payload;

      switch (object.type) {
        case 'total-account':
          state.activeCount = data.queryResponse[0] ? data.queryResponse[0]['License.count'] : 'empty';
          state.trialCount = data.queryResponse[1] ? data.queryResponse[1]['License.count'] : 'empty';
          state.totalSeats = data.queryResponse[0] ? data.queryResponse[0]['License.sumLicSeat'] : 'empty';
          state.assignedSeats = data.queryResponse[0] ? data.queryResponse[0]['License.sumUsedSeat'] : 'empty';
          break;
        case 'recently-activated':
          state.threeMonthCount = data.queryResponse[0] ? data.queryResponse[0]['License.count'] : 'empty';
          break;
        case 'last-fiscal-year':
          state.fiscalYearCount = data.queryResponse[0] ? data.queryResponse[0]['License.count'] : 'empty';
          break;
        case 'inactive':
          state.churnedCount = data.queryResponse[0] ? data.queryResponse[0]['License.count'] : 'empty';
          break;
        case 'upcoming-renewal':
          state.upcomingRenewalCount = data.queryResponse[0] ? data.queryResponse[0]['License.count'] : 'empty';
          break;
        default:
          state.loading = true;
      }
    });
    builder.addCase(getAccountStats.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default accountStatsSlice;
