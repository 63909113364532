import { useNavigate } from 'react-router-dom';
import DashboardCards from '../../../../components/DashboardCard/DashboardCards';
import ActiveUsersCard from './ActiveUsersCard';
import DailyUsersCard from './DailyActiveUsers';

export default function ActiveUsers(props) {
  const {
    title, error, loading, resultSet, more, accountName, orgId, setRefreshUsers, page, viewOption,
  } = props;
  const navigate = useNavigate();
  const isDailyActiveUsers = title === 'Daily Active Users';
  const path = orgId === 'all' ? '/active-users' : `/account-page/active-users?orgId=${orgId}`;
  const handleClick = () => {
    navigate(path, { state: { accountName, orgId, page } });
  };
  return (
    <DashboardCards
      title={title}
      onClick={handleClick}
      showButton={more}
      buttonText="more"
    >
      {isDailyActiveUsers ? (
        <DailyUsersCard
          loading={loading}
          error={error}
          data={resultSet}
          refresh={setRefreshUsers}
          message="No Data"
        />
      ) : (
        <ActiveUsersCard
          loading={loading}
          error={error}
          data={resultSet}
          refresh={setRefreshUsers}
          more={more}
          message="No Data"
          viewOption={viewOption}
        />
      )}
    </DashboardCards>
  );
}
