import { createSlice, current } from '@reduxjs/toolkit';
import { findIndex } from 'lodash';
import { getAccountHealthScore, setAccPrimaryHealthScore } from './accountHealthScoreAction';

const accountHealthScore = createSlice({
  name: 'accountHealthScore',
  initialState: {
    loading: false,
    accountsLicense: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccountHealthScore.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAccountHealthScore.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.accountsLicense = payload.responseArray;
      state.error = null;
    });
    builder.addCase(getAccountHealthScore.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // set account specific primary KPI
    builder.addCase(setAccPrimaryHealthScore.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(setAccPrimaryHealthScore.fulfilled, (state, { payload }) => {
      const currentState = current(state);
      const healthScores = currentState.accountsLicense;
      const updatedData = healthScores.map((score) => {
        return { ...score, isPrimary: false };
      });
      if (payload?.data?.healthScoreId) {
        const index = findIndex(updatedData, { healthScoreId: payload.data.healthScoreId });
        if (index !== -1) {
          updatedData[index] = payload.data;
        }
      }
      state.accountsLicense = updatedData;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(setAccPrimaryHealthScore.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default accountHealthScore;
