import { createSlice } from '@reduxjs/toolkit';
import { getAccCountByCountryLang, getCountryKpiScore } from './geographyAction';

const accCountByCountryLang = createSlice({
  name: 'accCountByCountryLang',
  initialState: { accCountByCountry: [], accCountByLang: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccCountByCountryLang.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAccCountByCountryLang.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.accCountByCountry = payload.countryData;
      state.accCountByLang = payload.languageData;
    });
    builder.addCase(getAccCountByCountryLang.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

const countryKpiScoreSlice = createSlice({
  name: 'countryKpiScore',
  initialState: {
    loading: false,
    versionData: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCountryKpiScore.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCountryKpiScore.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.versionData = payload.countryData;
      state.error = null;
    });
    builder.addCase(getCountryKpiScore.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export { accCountByCountryLang, countryKpiScoreSlice };
