import { Tab, Tabs } from '@mui/material';

function TenantDetailActions({ activeTab, handleChange }) {
  return (
    <Tabs value={activeTab} onChange={handleChange}>
      <Tab label="Tenant" value="about" sx={{ fontSize: 18 }} />
      <Tab label="Users" value="users" sx={{ fontSize: 18 }} />
    </Tabs>
  );
}

export default TenantDetailActions;
