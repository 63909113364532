import { Box, Container, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Metatags from '../components/MetaTags/MetaTags';
import { TopCustomObject } from '../modules/Accounts';

function AccountCustomObjectPage({ permissions }) {
  const location = useLocation();
  const {
    customObject, accountName, orgId, customObjectPinningData, pkgId,
  } = location.state;
  return (
    <>
      <Metatags metaTagName="Top Custom Objects | App Insights" />
      <Container maxWidth="xl">
        <Box>
          <Typography variant="h4" component="div" sx={{ mb: 2 }}>
            {`Top Custom Objects - ${accountName || orgId}`}
          </Typography>
        </Box>
        <Box>
          {customObject && !customObject.loading && (
          <TopCustomObject
            permissions={permissions}
            customObject={customObject}
            customObjectPinningData={customObjectPinningData}
            orgId={orgId}
            pkgId={pkgId}
            accountName={accountName}
          />
          )}
        </Box>
      </Container>
    </>
  );
}
export default AccountCustomObjectPage;
