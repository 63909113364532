import DashboardCards from '../../components/DashboardCard/DashboardCards';
import { LicenseUtilizationCard } from '../../modules/LicenseUtilization';
import useLicenseUtilization from './useLicenseUtilization';

export default function LicenseUtilization(props) {
  const { title, refresh } = props;
  const { percentCount, loading, error } = useLicenseUtilization();

  return (
    <DashboardCards
      title={title}
      showButton={false}
    >
      <LicenseUtilizationCard
        loading={loading}
        error={error}
        data={percentCount}
        refresh={refresh}
        message="No Data"
      />
    </DashboardCards>
  );
}
