import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { setAccessToken } from '../../features/Auth/authSlice';

function PrivateRoute(props) {
  const { isShareableScreens } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector((state) => state.auth?.user?.accessToken);

  useEffect(() => {
    if (!accessToken) {
      if (!isShareableScreens) {
        const localStorageAccessToken = localStorage.getItem('accessToken');
        if (localStorageAccessToken) {
          dispatch(setAccessToken(localStorageAccessToken));
          return;
        }
        navigate('/login');
      }
    }
  }, [accessToken, navigate, dispatch]);

  return (isShareableScreens || accessToken) ? <Outlet /> : null;
}

export default PrivateRoute;
