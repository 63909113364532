import Button from '@mui/material/Button';
import {
  Grid, Box, Typography, Stack,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { CustomChip, InfoChip } from '../../../../components/CustomChip';
import { CustomDialogBox } from '../../../../components/CustomDialogBox';
import useRefineFilter from './useRefineFilter';

function DialogContentElement({
  crudOp, handleOnClickCrudOp, handleSelectAllOp, handleUnSelectAllOp,
}) {
  return (
    <Box>
      <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>Select the CRUD types for this components</Typography>
      <InfoChip
        type="multiline"
        icon={<InfoIcon color="primary" />}
        label="CRUD types define the types of record operations: creates, reads, updates and deletes. Use this section to limit the types of operations that you consider as KPI usage."
      />

      <Grid container spacing={1} mt={2} mb={2}>
        {crudOp.length ? crudOp.map((option) => (
          <Grid key={option.value} item xs={1.8}>
            <CustomChip
              label={option.operationName}
              selected={option.selected}
              onClick={() => handleOnClickCrudOp(option.value)}
            />
          </Grid>
        ))
          : null}
      </Grid>

      <Stack direction="row" gap={1}>
        <Button
          onClick={() => handleSelectAllOp('crud')}
          variant="contained"
          size="small"
        >
          Select All
        </Button>
        <Button
          onClick={() => handleUnSelectAllOp('crud')}
          variant="contained"
          size="small"
        >
          UnSelect All
        </Button>
      </Stack>
    </Box>
  );
}

export default function RefineFilter({
  open, handleClose, handleRefineFilterSave, customFilter,
}) {
  const {
    crudOp,
    disabled,
    handleOnClickCrudOp,
    handleSelectAllOp,
    handleUnSelectAllOp,
    handleSave,
  } = useRefineFilter({ handleRefineFilterSave, customFilter });

  return (
    <Box sx={{ minWidth: 200 }}>
      <CustomDialogBox
        title="Refine Filter"
        open={open}
        handleClose={handleClose}
        content={(
          <DialogContentElement
            crudOp={crudOp}
            handleOnClickCrudOp={handleOnClickCrudOp}
            handleSelectAllOp={handleSelectAllOp}
            handleUnSelectAllOp={handleUnSelectAllOp}
          />
        )}
        actions={{ title: 'Select', onClick: handleSave, disabled }}
      />
    </Box>
  );
}
