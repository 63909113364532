import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  image: {
    width: '50px',
    height: '50px',
    marginRight: '10px',
  },
  heading: {
    fontWeight: 600,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 80px 0px 40px',
  },
  welcomeText: {
    marginBottom: '20px',
    color: '#152b4c',
  },
  requirementItemContainer: {
    marginBottom: '20px',
  },
  connectButton: {
    marginTop: '20px',
  },
}));

export default useStyles;
