import { Stack, Typography } from '@mui/material';
import DashboardCards from '../../../../components/DashboardCard/DashboardCards';
import { DynamicTable } from '../../../../components/CustomTable';
import { MutedText } from '../../../../components/CustomTypography';
import useEngagementSection from './useEngagementSection';

export default function EngagementSection({ monthObject, weekObject }) {
  const {
    recentMonth,
    recentWeek,
    weekDiff,
    monthDiff,
    monthOp,
    weekOp,
    opObject,
  } = useEngagementSection({ monthObject, weekObject });

  function UserHeader({ label }) {
    return (
      <Stack direction="row" spacing={0.8} alignItems="center">
        <Typography variant="body1">
          {label}
          {' '}
          active users
        </Typography>
        <MutedText variant="body2" text={label === 'Monthly' ? '(latest month)' : '(latest week)'} />
      </Stack>
    );
  }

  function diffElement(diff, operation) {
    const opDifference = {
      '+': <span style={{ color: 'green', width: '0.9em', display: 'inline-table' }}>{`${operation}${diff}`}</span>,
      '-': <span style={{ color: 'red', width: '0.9em', display: 'inline-table' }}>{`${operation}${diff}`}</span>,
      '~': '-',
    };
    return opDifference[operation];
  }

  function UserElement({ label }) {
    let title;
    let operation;
    let difference;

    if (label === 'Monthly') {
      title = recentMonth;
      operation = monthOp && opObject[monthOp] ? opObject[monthOp] : '-';
      difference = monthOp ? diffElement(monthDiff, monthOp) : '';
    } else {
      title = recentWeek;
      operation = weekOp && opObject[weekOp] ? opObject[weekOp] : '-';
      difference = weekOp ? diffElement(weekDiff, weekOp) : '';
    }

    return (
      <Stack direction="row" spacing={0.8}>
        <Typography variant="subtitle1">{title}</Typography>
        <Typography variant="body2">{operation}</Typography>
        <Typography variant="body2">{difference}</Typography>
      </Stack>
    );
  }

  const columns = [{ field: 'header' }, { field: 'element' }];
  const rows = [
    {
      header: <UserHeader label="Monthly" />,
      element: <UserElement label="Monthly" />,
    },
    {
      header: <UserHeader label="Weekly" />,
      element: <UserElement label="Weekly" />,
    },
  ];
  return (
    <DashboardCards title="Engagement">
      <DynamicTable columns={columns} rows={rows} dialog />
    </DashboardCards>
  );
}
