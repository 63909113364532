import { createAsyncThunk } from '@reduxjs/toolkit';

import request from '../../modules/Request/request';

export const getAccountStats = createAsyncThunk(
  'getAccountStats',
  async (object, { rejectWithValue }) => {
    const response = await request('/statistics', { method: 'GET', query: { statsCardType: object.type, pkgId: object.pkgId } });
    if (response.success) {
      return { data: response.data, object };
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);
