import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import useToggle from '../../../hooks/useToggle';
import { getCustomFilterViewList } from '../../../features/CustomFilterView/customFilterViewAction';
import CustomPopover from '../../../components/CustomPopover/CustomPopover';
import CustomFilterContent from './CustomFilterContent';
import { getMomentDate } from '../../../utils/getDate';

const today = moment().startOf('day');
const end = today.clone().add(13, 'days');

function CustomFilterAlert({ activePackage }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getCustomFilterViewList({ packageId: activePackage.packageId }));
    }
  }, [activePackage?.packageId]);

  const customFilterViewList = useSelector((state) => state.customFilterView?.data);
  const accountListData = useSelector((state) => state.accountsList);
  const customFilterData = useSelector((state) => state.getCustomAlertDetails.data);

  const handleClick = ({ id, filter }) => {
    navigate('/account-list', {
      state: {
        filters: filter,
        selectedAlert: id,
        showAdvanceFilter: filter.toggleAdvanceFilter,
      },
    });
  };

  const data = useMemo(() => {
    const filteredItems = customFilterViewList?.filter((item) => item.alert);
    if (filteredItems.length) {
      return filteredItems?.map((item) => ({
        id: item.id,
        text: item.name,
        onClick: () => handleClick(item),
      }));
    }
    return [];
  }, [customFilterViewList]);

  const [showBadge, setShowBadge] = useToggle();

  const fixedWatchList = useMemo(() => {
    let watchListCounts = {
      expiringAcc: 0, lowUtilAcc: 0, lowScoreAcc: 0, declineScoreAcc: 0,
    };

    if (accountListData?.accountsList) {
      watchListCounts = accountListData.accountsList.reduce((counts, account) => {
        const isNeverExpire = account.renewalDate === null;
        const isRenewalDateValid = account.renewalDate && getMomentDate(account.renewalDate) >= today;
        const isActive = account.status === 'Active' && (isRenewalDateValid || isNeverExpire);

        if (account.visibility && isActive) {
          counts.expiringAcc += moment(account.renewalDate).isBetween(today, end) ? 1 : 0;
          counts.lowUtilAcc += account?.totalSeats && (parseInt(account?.totalSeats ?? -1, 10) > 0) && parseFloat(account.assignedSeatsPercent) <= 25 ? 1 : 0;
          counts.lowScoreAcc += account.healthScore <= 30 ? 1 : 0;
          counts.declineScoreAcc += (customFilterData?.[0]?.view === 'weekly' ? account.weeklyTrend === 'falling' : account.healthScoreTrend === 'falling') ? 1 : 0;
        }
        return counts;
      }, {
        expiringAcc: 0, lowUtilAcc: 0, lowScoreAcc: 0, declineScoreAcc: 0,
      });
    }

    const sum = Object.values(watchListCounts).reduce((acc, val) => acc + val, 0);
    setShowBadge(sum > 0);

    const watchList = [
      {
        id: 'expiringSoon',
        text: 'Expiring Soon',
        count: watchListCounts.expiringAcc,
      },
      {
        id: 'lowLicenseUtilization',
        text: 'Low License Utilization',
        count: watchListCounts.lowUtilAcc,
      },
      {
        id: 'lowHealthScore',
        text: 'Low KPI Score',
        count: watchListCounts.lowScoreAcc,
      },
      {
        id: 'decliningHealthScore',
        text: 'Declining KPI Score',
        count: watchListCounts.declineScoreAcc,
      },
    ];

    return Object.values(watchList);
  }, [accountListData]);

  return (
    <CustomPopover
      tooltip="Watch List"
      content={<CustomFilterContent data={data} fixedWatchList={fixedWatchList} packageId={activePackage?.packageId} />}
      imageSrc="/watchTower.svg"
      badge={showBadge}
    />
  );
}

export default CustomFilterAlert;
