import {
  Button,
  Dialog,
  IconButton, Stack, Tooltip, Typography,
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';

function ConfirmDialogBox({
  title, message, openDialog, onClose, onConfirm, cancelText = 'Cancel', confirmText = 'Confirm',
}) {
  return (
    <Dialog open={openDialog} onClose={onClose}>
      <DialogTitle align="center" py="8px !important">
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
          <Tooltip title={title}>
            <Typography noWrap variant="h6">{title}</Typography>
          </Tooltip>
          <IconButton onClick={onClose} sx={{ color: (theme) => theme.palette.grey[500], transform: 'translateX(10px)' }}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers sx={{ padding: '30px 24px' }}>
        {message}
      </DialogContent>
      <DialogActions sx={{ mx: 1, my: 0.5 }}>
        <Button variant="outlined" size="medium" mt={1} onClick={onClose}>{cancelText}</Button>
        <Button variant="contained" size="medium" mt={1} onClick={onConfirm}>{confirmText}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialogBox;
