import Login from './Login/Login';
import ResetPassword from './ResetPassword/ResetPassword';
import SetPassword from './SetPassword/SetPassword';

function Auth({ type }) {
  if (type === 'setPassword') return <SetPassword />;
  if (type === 'resetPassword') return <ResetPassword />;

  return <Login />;
}

export default Auth;
