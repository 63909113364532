import { createAsyncThunk } from '@reduxjs/toolkit';

import request from '../../modules/Request/request';

export const getFeatureMatrix = createAsyncThunk(
  'getFeatureMatrix',
  async ({ packageId, featureArray }, { rejectWithValue }) => {
    const response = await request('/feature-matrix', { method: 'GET', query: { packageId, featureArray } });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);
