import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { FormControl, FormHelperText } from '@mui/material';
import { DATE_FORMAT } from '../../utils/constants';

export default function DateField(props) {
  const {
    label,
    value,
    onChange,
    error,
    sxProps,
  } = props;

  return (
    <FormControl sx={{ width: '100%', position: 'relative' }}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <MUIDatePicker
          label={label}
          value={value || null}
          inputFormat={DATE_FORMAT.DATE_PICKER}
          onChange={onChange}
          renderInput={(params) => (<TextField {...params} sx={{ ...sxProps, width: '100%' }} />)}
        />
      </LocalizationProvider>
      <FormHelperText error={error} sx={{ position: 'absolute', bottom: '-1.5em' }}>
        {error && error}
      </FormHelperText>
    </FormControl>
  );
}
