/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudDoneRoundedIcon from '@mui/icons-material/CloudDoneRounded';
import SpatialTrackingRoundedIcon from '@mui/icons-material/SpatialTrackingRounded';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { capitalize } from 'lodash';
import useAuthorization from '../../hooks/useAuthorization';
import { impersonate } from '../../features/Auth/authAction';
import { SelectBox } from '../../components/FormComponents';
import { ClickableCell } from '../../components/CustomDataGrid';
import { openSnackbar } from '../../features/SnackBar/snackBarSlice';
import { disableTenant } from '../../features/Tenant/tenantAction';
import { resetAll } from '../../features/StateHandle/resetStateAction';
import useToggle from '../../hooks/useToggle';
import palette from '../../theme/palette';
import { DATE_FORMAT } from '../../utils/constants';
import { identifyAndConvertDate } from '../../utils/formatTime';
import { ActionCell } from '../../modules/FeatureManager';
import usePackageSelection from '../../modules/Accounts/components/PackageSelection/usePackageSelection';
import { resetPackageList } from '../../features/Package/packageSlice';

function TenantManager({ permissions }) {
  // handle api data
  const tenantsData = useSelector((state) => state.tenants);
  const [tenantsList, setTenantsList] = useState({
    loading: true,
    error: null,
    data: [],
  });

  const setTenantsData = () => {
    setTenantsList({
      ...tenantsList,
      ...tenantsData,
    });
  };

  useEffect(() => {
    setTenantsData();
  }, [tenantsData]);

  // states and functions
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useToggle();
  const [openActionDialog, setOpenActionDialog] = useToggle();
  const [actionDialogData, setActionDialogData] = useState({});
  const { userInfo } = useAuthorization();

  const handleDisableTenant = (params, string) => {
    const disableTenantFn = () => {
      dispatch(disableTenant({ tenantId: params.id })).then((res) => {
        if (res?.payload?.status === 'SUCCESS') {
          dispatch(openSnackbar({ open: true, message: res.payload.message, type: 'success' }));
        } else {
          dispatch(openSnackbar({ open: true, message: `Failed to ${string.toLowerCase()} tenant!`, type: 'error' }));
        }
      });
      setOpenActionDialog();
    };

    setActionDialogData({
      title: `${string} Tenant`,
      message: (
        <Typography>
          {`Are you sure you want to ${string.toLowerCase()} tenant`}
          {' '}
          <span style={{ color: palette.primary.main }}>{params.row.tenant}</span>
          {' ?'}
        </Typography>
      ),
      onConfirm: disableTenantFn,
    });
    setOpenActionDialog();
  };

  const handleOnRowClick = (params) => {
    const { colDef, row } = params;
    if (colDef.isRowSelectable) {
      const tenant = {
        name: row.tenant,
        id: row.tenantId,
      };
      navigate('/tenant-details', { state: { tenant, permissions } });
    }
  };

  const handleImpersonate = (params) => {
    let impersonateRole;
    const handleImpersonateFn = () => {
      const data = {
        email: userInfo?.email,
        tenant: params.row.tenantId,
        role: impersonateRole,
        refreshToken: localStorage.getItem('refreshToken'),
      };
      dispatch(impersonate(data)).then((res) => {
        if (res.payload.status === 'SUCCESS') {
          dispatch(resetAll());
          navigate('/');
          localStorage.removeItem('activePackage');
          dispatch(resetPackageList());
          usePackageSelection();
          dispatch(openSnackbar({ open: true, message: `Impersonating as ${impersonateRole} in ${params.row.tenant}!`, type: 'success' }));
        } else {
          dispatch(openSnackbar({ open: true, message: `Failed to Impersonate in ${params.row.tenant}!`, type: 'error' }));
        }
      });
      setOpenActionDialog();
    };

    setActionDialogData({
      title: 'Impersonate',
      message: (
        <Stack gap={2}>
          <Typography>
            Please select the role you want to impersonate in
            <span style={{ color: palette.primary.main }}>{` ${params.row.tenant}`}</span>
          </Typography>
          <SelectBox
            name="role"
            title="Access Role"
            onChange={(e) => {
              impersonateRole = e.target.value;
            }}
            data={[{ id: 1, name: 'User', value: 'user' }, { id: 2, name: 'Admin', value: 'admin' }]}
          />
        </Stack>
      ),
      onConfirm: () => {
        if (impersonateRole) {
          handleImpersonateFn();
        } else {
          dispatch(openSnackbar({ open: true, message: 'Please select a role!', type: 'error' }));
        }
      },
    });
    setOpenActionDialog();
  };

  // datagrid columns
  const getActionCellData = (params) => {
    const { isEnabled } = params.row;
    const string = isEnabled ? 'Disable' : 'Enable';

    const actionCellData = [
      {
        id: 'impersonate',
        info: 'Impersonate as User or Admin',
        icon: SpatialTrackingRoundedIcon,
        handleFunction: () => handleImpersonate(params),
      },
      {
        id: 'disable',
        info: `${string} Tenant`,
        icon: isEnabled ? CloudDoneRoundedIcon : CloudOffIcon,
        handleFunction: (param) => handleDisableTenant(param, string),
      },
    ];

    return actionCellData;
  };

  const tenantsColumns = [
    {
      field: 'tenant',
      headerName: 'Tenant',
      flex: 1,
      isRowSelectable: true,
      renderCell: (params) => <ClickableCell value={params.value} onClick={() => handleOnRowClick(params)} />,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.6,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
      isRowSelectable: false,
      renderCell: (params) => (
        <span style={{
          fontWeight: 500,
          color: params.value === 'active'
            ? palette.success.dark
            : params.value === 'trial'
              ? palette.warning.alt
              : palette.error.main,
        }}
        >
          {capitalize(params.value)}
        </span>
      ),
    },
    {
      field: 'expirationDate',
      headerName: 'Expiration',
      flex: 0.6,
      align: 'center',
      headerAlign: 'center',
      isRowSelectable: false,
      headerClassName: 'center-align--header',
      renderCell: (params) => <span>{params.value === null ? 'Does Not Expire' : identifyAndConvertDate(params.value, DATE_FORMAT.DAY_DATE_GRAPH, 'YYYY-MM-DD')}</span>,
    },
    {
      field: 'isEnabled',
      headerName: 'Tenant Status',
      flex: 0.6,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
      isRowSelectable: false,
      renderCell: (params) => (
        <span style={{ fontWeight: 500, color: params.value ? palette.success.dark : palette.error.main }}>
          {params.value ? 'Enabled' : 'Disabled'}
        </span>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.8,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
      isRowSelectable: false,
      renderCell: (params) => <ActionCell actionCellData={getActionCellData(params)} params={params} />,
    },
  ];

  return {
    tenantsList,
    tenantsColumns,
    openDialog,
    setOpenDialog,
    openActionDialog,
    setOpenActionDialog,
    actionDialogData,
  };
}

export default TenantManager;
