import {
  Chip, FormControl, InputLabel, OutlinedInput, Select,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  highlightedFilter: {
    backgroundColor: 'aliceblue',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.mainColor,
      borderWidth: 2,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.mainColor,
      borderWidth: 2,
    },
  },
}));

const renderValue = (selected) => {
  if (selected) {
    return <Chip label={`${Array.from(selected).length} Selected`} />;
  }
  return <Chip label="0 Selected" />;
};

export default function CustomSelect({
  title, value, open, onClick, highlighted, disabled = false,
}) {
  const classes = useStyles();
  const disabledProps = { inputProps: { readOnly: true } };
  return (
    <FormControl
      sx={{
        width: '100%',
        position: 'relative',
        m: '8px 0',
        ...(disabled && { backgroundColor: '#f5f5f5', pointerEvents: 'none' }),
      }}
    >
      <InputLabel>
        {title}
      </InputLabel>
      <Select
        multiple
        value={value}
        className={highlighted ? classes.highlightedFilter : null}
        fullWidth
        open={open && open}
        onClick={onClick}
        renderValue={renderValue}
        input={<OutlinedInput label="Chip" />}
        {...(disabled && disabledProps)}
      />
    </FormControl>
  );
}
