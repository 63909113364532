import { Grid } from '@mui/material';
import { MutedText } from '../../../../components/CustomTypography';
import { SelectBox } from '../../../../components/FormComponents';
import { InfoChip } from '../../../../components/CustomChip';
import TextFieldWithError from '../../../../components/FormComponents/TextfieldWithError';

function CreateUserForm(props) {
  const { formik, permissions, tenant } = props;

  const {
    errors, handleChange, touched, getIn,
  } = formik;

  const userGroups = [
    { id: 1, value: 'admin', name: 'Admin' },
    { id: 2, value: 'user', name: 'User' },
  ];

  return (
    <Grid container gap={1} p={2}>
      <Grid item xs={12} mb={2}>
        <InfoChip icon label="User will receive an email with account credentials and further instructions to complete the account setup." />
      </Grid>

      <Grid item xs={12}>
        <MutedText text="A user's email address can be used for sign-in, account recovery, and account confirmation." sxProps={{ marginLeft: '8px' }} />
        <TextFieldWithError
          name="email"
          onChange={handleChange}
          label="Email Address"
          error={touched.email && getIn(errors, 'email')}
        />
      </Grid>

      <Grid item xs={12} mb={2}>
        <MutedText text="User group will define what features that particular user can access." sxProps={{ marginLeft: '8px' }} />
        <SelectBox
          title="User Group"
          name="group"
          data={userGroups}
          fullWidth
          onChange={handleChange}
          error={touched.group && getIn(errors, 'group')}
          spacing
        />
      </Grid>

      {permissions.manage && (
      <Grid item xs={12} mt={1}>
        <TextFieldWithError
          name="tenant"
          label="Tenant"
          value={tenant.name}
          InputProps={{
            readOnly: true,
            style: { backgroundColor: '#f5f5f5', pointerEvents: 'none' },
          }}
        />
      </Grid>
      )}

    </Grid>
  );
}

export default CreateUserForm;
