import { createAsyncThunk } from '@reduxjs/toolkit';

import request from '../../modules/Request/request';

export const getPackageList = createAsyncThunk(
  'getPackageList',
  async (object, { rejectWithValue }) => {
    const reqPayload = {};
    reqPayload.method = 'GET';
    if (object) {
      const query = {};
      if (object.isShare) {
        query.isShare = object.isShare;
        query.tenantId = object.tenantId;
      }
      reqPayload.query = query;
    }
    const response = await request('/package-details', reqPayload);
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const getAccountPackageList = createAsyncThunk(
  'getAccountPackageList',
  async (object, { rejectWithValue }) => {
    const reqPayload = {};
    reqPayload.method = 'GET';
    if (object) {
      const query = {};
      if (object.isShare) {
        query.isShare = object.isShare;
        query.accountOrgId = object.accountOrgId;
        query.accountId = object.accountId;
        if (object.licenseId) {
          query.licenseId = object.licenseId;
        }
      }
      reqPayload.query = query;
    }
    const response = await request('/acc-package-details', reqPayload);
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const getPackageDetailList = createAsyncThunk(
  'getPackageDetailList',
  async (object, { rejectWithValue }) => {
    const reqPayload = {};
    reqPayload.method = 'GET';
    const response = await request('/package-details', reqPayload);
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const disablePackage = createAsyncThunk('disablePackage', async ({ packageId }, { rejectWithValue }) => {
  const response = await request('/package-disable', { method: 'PUT', params: packageId });
  if (response.success) {
    return response.data;
  }
  const { error } = response;
  throw rejectWithValue(error || 'Something went wrong');
});
