import { Box, Tooltip, Typography } from '@mui/material';
import { TrendingDown, TrendingUp } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import palette from '../../../theme/palette';

function PackageHeader({ heading, noPackage = false, trendData = {} }) {
  const [packageName, setPackageName] = useState('');

  useEffect(() => {
    const activePackageData = localStorage.getItem('activePackage');
    if (activePackageData) {
      const activePackageName = JSON.parse(activePackageData);
      const { packageLabel } = activePackageName;
      setPackageName(packageLabel);
    }
  }, []);

  const trendIcon = (trendType, trendValue) => {
    if (trendType === 'rising') {
      return (
        <Tooltip title="Trending">
          <Typography sx={{
            display: 'flex', flexDirection: 'row', color: 'green', alignItems: 'center',
          }}
          >
            <TrendingUp />
            {trendValue
              ? (
                <Typography sx={{ fontSize: 13 }}>
                  {` +${trendValue}`}
                </Typography>
              )
              : null}
          </Typography>
        </Tooltip>
      );
    }
    if (trendType === 'falling') {
      return (
        <Tooltip title="Declining">
          <Typography sx={{
            display: 'flex', flexDirection: 'row', color: 'red', alignItems: 'center',
          }}
          >
            <TrendingDown />
            {trendValue
              ? (
                <Typography sx={{ fontSize: 13 }}>
                  {` ${trendValue}`}
                </Typography>
              )
              : null}
          </Typography>
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="h4" gutterBottom>{heading}</Typography>
      {trendData?.healthScoreTrend
        ? (
          <Typography sx={{ marginBottom: 1 }}>
            {trendIcon(trendData.healthScoreTrend, trendData?.trendValue)}
          </Typography>
        )
        : null }
      {(!noPackage && packageName !== '') && (
      <Typography
        gutterBottom
        style={{
          display: 'inline',
          color: palette.info.dark,
          verticalAlign: 'middle',
          marginLeft: '8px',
          fontSize: '1.25rem',
        }}
      >
        (
        {packageName}
        )
      </Typography>
      )}
    </Box>
  );
}

export default PackageHeader;
