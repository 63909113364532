import { useDispatch } from 'react-redux';
import { getNotificationSettingsDetails, saveNotificationSettingsDetails } from '../../../../features';
import { openSnackbar } from '../../../../features/SnackBar/snackBarSlice';
import { FormDialogBox } from '../../../../components/CustomDialogBox';
import NotificationSettingsForm from './EmailAlertSettingsForm';

function EmailAlertSettingsDialog({
  open, handleClose, notificationSettings, packageId,
}) {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const data = {
      frequency: values.frequency,
      type: 'WATCH_LIST',
      packageId,
    };
    dispatch(saveNotificationSettingsDetails(data))
      .then(() => {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Successfully Updated !',
            type: 'success',
          }),
        );
        dispatch(getNotificationSettingsDetails({ packageId }));
      });

    handleClose();
  };

  const initialValues = {
    frequency: notificationSettings?.frequency ?? 'week',
  };

  return (
    <FormDialogBox
      title="Configure Email Alert"
      open={open}
      handleClose={handleClose}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      formContent={<NotificationSettingsForm />}
      formAction={{ title: 'Update' }}
    />
  );
}

export default EmailAlertSettingsDialog;
