import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function CustomCircularProgress(props) {
  const { value, width = 40, height = 40 } = props;

  let progressColor;
  let leftoverColor;
  if (value < 30) {
    progressColor = 'red';
    leftoverColor = '#ff9999';
  } else if (value <= 70) {
    progressColor = '#f28e00';
    leftoverColor = '#ffff99';
  } else {
    progressColor = 'green';
    leftoverColor = '#99ff99';
  }

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        value={100}
        style={{
          color: leftoverColor, strokeWidth: 6, width, height,
        }}
      />
      <CircularProgress
        variant="determinate"
        value={value}
        style={{
          color: progressColor,
          position: 'absolute',
          left: 0,
          top: 0,
          strokeWidth: 6,
          opacity: 0.5,
          width,
          height,
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {Math.round(value)}
        </Typography>
      </Box>
    </Box>
  );
}
