export const toolTipText = {
  healthInsights: {
    DELETE_CARD: 'You can\'t delete primary KPI score card',
    MAX_SCORE_CARD: 'You can\'t create more than 5 KPI score card',
    THRESHOLD_INFO: 'Values below the minimum threshold will receive 0 points, while values exceeding the ideal threshold will receive the maximum points based on the rule\'s assigned weight. Scores for values between the minimum and ideal thresholds will be calculated using linear interpolation.',
    REMOVE_RULE_SUCCESS: 'First rule can\'t be removed',
    REMOVE_RULE_FAIL: 'Remove rule',
    DUPLICATE_RULE: 'Duplicate',
    SHOW_DISTRIBUTION_SUCCESS: 'Show distribution for the selected metric',
    SHOW_DISTRIBUTION_FAIL: 'Please select target metric first',
    HEALTH_SCORE_LABEL: 'Please fill KPI score label first',
    ADD_RULE_SUCCESS: 'Add rule',
    ADD_RULE_FAIL: 'You can create a maximum of 10 rules',
    ADD_PARAMETERS: 'You can set custom criteria for this KPI Score',
    LOW_WEIGHTAGE: 'Low impact of this Rule in KPI Score calculation',
    MEDIUM_WEIGHTAGE: 'Medium impact of this Rule in KPI Score calculation',
    HIGH_WEIGHTAGE: 'High impact of this Rule in KPI Score calculation',
    RULES: 'Calculation rules.',
    PARAMETERS: 'Define the conditions under which the KPI Score becomes primary for accounts.',
    NO_PARAMETERS: 'You can not add criteria to primary KPI Score.',
  },
  featureManager: {
    REMOVE_CUSTOM_ENTITY: 'Remove custom entity',
  },
  eventTimeline: {
    USED_LICENSE_SEAT: 'used licence seats',
    TOTAL_LICENSE_SEAT: 'total seats',
    VERSION_NUMBER: 'version',
    LICENSE_STATUS: 'licence status',
  },
  activeUsersEngagement: {
    ZOOM_INFO: 'Click and drag on the graph to navigate left and right.',
  },
  accountDetail: {
    BASELINE_USERS: 'The baseline user count can be equal to or less than the number of seats sold. If left empty, it defaults to the number of seats sold. This helps establish the expected number of users who should actively interact with the application and contribute to the Key Performance Indicator (KPI). By customizing this count, you can improve the accuracy of the KPI score, as each account may have different usage expectations.',
  },
  watchList: {
    THRESHOLD_INFO: 'The KPI scores, which contain monthly data, are continually monitored. The difference between the KPI score of two consecutive months is compared against the threshold value. If the difference exceeds the threshold, it indicates either an increase or decrease in performance.',
    DURATION: 'Duration indicates the monthly data limit.',
    MOVING_AVERAGE_WEEKLY: 'Moving Average Window value represents the average of data from the selected number of weeks.',
    MOVING_AVERAGE_MONTH: 'Moving Average Window value represents the average of data from the selected number of months.',
    VIEW: 'Time Frame refers to a specific period of time that is used as a reference point for indicating when an action or event has occurred.',
  },
  customDefinition: {
    LOW_SCORE: 'A threshold value of KPI score.',
    LOW_UTIL: 'A threshold value for accounts with low utilization.',
    DECL_SCORE: 'A threshold value for KPI score declination.',
  },
};
