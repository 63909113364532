import { useEffect, useState } from 'react';
import { crudOperations, otherOperations } from '../../constants';

const useRefineFilter = ({
  handleRefineFilterSave, customFilter,
}) => {
  const [crudOp, setCrudOp] = useState(crudOperations);
  const [otherOp, setOtherOp] = useState(otherOperations);
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    if (customFilter.custom_entity_id) {
      const otherOpData = otherOperations.map((obj) => {
        if (customFilter.operation_type && customFilter.operation_type.includes(obj.value)) {
          obj.selected = true;
        } else {
          obj.selected = false;
        }
        return obj;
      });
      const crudOpData = crudOperations.map((obj) => {
        if (customFilter.operation_type && customFilter.operation_type.includes(obj.value)) {
          obj.selected = true;
        } else {
          obj.selected = false;
        }
        return obj;
      });
      setCrudOp(crudOpData);
      setOtherOp(otherOpData);
    }
  }, [customFilter.custom_entity_id]);

  const handleDisabled = () => {
    const isCrudFlag = crudOp.some((obj) => obj.selected);
    const isOtherFlag = otherOp.some((obj) => obj.selected);
    setDisabled(!(isCrudFlag || isOtherFlag));
  };

  const handleOnClickOtherOp = (value) => {
    const updateOtherOp = otherOp.map((obj) => {
      if (obj.value === value) {
        obj.selected = !obj.selected;
      }
      return obj;
    });
    setOtherOp(updateOtherOp);
    handleDisabled();
  };

  const handleOnClickCrudOp = (value) => {
    const updateCrudOp = crudOp.map((obj) => {
      if (obj.value === value) {
        obj.selected = !obj.selected;
      }
      return obj;
    });
    setCrudOp(updateCrudOp);
    handleDisabled();
  };

  const handleSelectAllOp = (type) => {
    if (type === 'crud') {
      const updateCrudOp = crudOp.map((obj) => {
        obj.selected = true;
        return obj;
      });
      setCrudOp(updateCrudOp);
    } else {
      const updateOtherOp = otherOp.map((obj) => {
        obj.selected = true;
        return obj;
      });
      setOtherOp(updateOtherOp);
    }
    handleDisabled();
  };

  const handleUnSelectAllOp = (type) => {
    if (type === 'crud') {
      const updateCrudOp = crudOperations.map((obj) => {
        obj.selected = false;
        return obj;
      });
      setCrudOp(updateCrudOp);
    } else {
      const updateOtherOp = otherOperations.map((obj) => {
        obj.selected = false;
        return obj;
      });

      setOtherOp(updateOtherOp);
    }
    handleDisabled();
  };

  const handleSave = () => {
    const crudList = [];
    crudOp.forEach((obj) => {
      if (obj.selected) {
        crudList.push(obj.value);
      }
    });
    // TODO add later

    // otherOp.forEach((obj) => {
    //   if (obj.selected) {
    //     otherList.push(obj.value);
    //   }
    // });
    handleRefineFilterSave(customFilter, [...crudList]);
  };

  return {
    crudOp,
    otherOp,
    disabled,
    handleOnClickOtherOp,
    handleOnClickCrudOp,
    handleSelectAllOp,
    handleUnSelectAllOp,
    handleSave,
  };
};

export default useRefineFilter;
