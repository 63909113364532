import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import useShareableScreen from '../../hooks/useShareableScreen';
import { setSessionTokens } from '../../features/Auth/authSlice';
import { refreshSession } from '../../features/Auth/authAction';

const idleTimeout = 24 * 60 * 60 * 1000; // 24 hours
const interval = 5 * 60 * 1000; // 5 minutes
const threshold = 60 * 60 * 1000; // 1 hour

export default function SessionProvider() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const accessToken = user?.accessToken || localStorage.getItem('accessToken');
  const refreshToken = user?.refreshToken || localStorage.getItem('refreshToken');

  const { isShareableScreen } = useShareableScreen();

  const [remainingTime, setRemainingTime] = useState(60000);

  const [isActive, setIsActive] = useState(true);
  const onIdle = () => {
    setIsActive(false);
  };

  const onActive = () => {
    setIsActive(true);
  };

  const { getRemainingTime } = useIdleTimer({
    onIdle,
    onActive,
    timeout: idleTimeout,
    throttle: 500,
  });

  useEffect(() => {
    const sessionInterval = setInterval(() => {
      setRemainingTime(getRemainingTime());
    }, interval);

    return () => {
      clearInterval(sessionInterval);
    };
  });

  useEffect(() => {
    if (!accessToken) return;
    if (!accessToken || !isActive || !remainingTime) {
      console.log('accessToken :', accessToken, isActive, remainingTime);
      localStorage.clear();
      window.location.href = '/login';
    }

    const decodedToken = jwtDecode(accessToken);
    const expirationTime = moment.unix(decodedToken.exp);
    const currentTime = moment();

    // Calculate the time remaining until the token expires
    const timeUntilExpiration = expirationTime.diff(currentTime);

    // Token is about to expire, refresh the session
    if (timeUntilExpiration < threshold && !isShareableScreen) {
      dispatch(refreshSession({ refreshToken })).then((res) => {
        const { payload: { data } } = res;
        if (data) {
          const sessionTokens = {
            idToken: data.idToken,
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
          };
          dispatch(setSessionTokens(sessionTokens));
        }
      });
    }
  }, [accessToken, refreshToken, isActive, remainingTime, dispatch]);

  return null;
}
