import { LinearProgress } from '@mui/material';
import { CustomNoDataOverlay } from '../components/CustomNoDataOverlay';
import RetryDashboardComponent from '../components/RetryDashboardComponent/RetryDashboardComponent';

const withLoadingAndOverlay = (WrappedComponent) => {
  return function LoadingOverlayHOC(props) {
    const {
      loading, error, refresh, data, message,
    } = props;

    if (loading) {
      return <LinearProgress />;
    }

    if (error && refresh) {
      return <RetryDashboardComponent refreshHandler={refresh} />;
    }

    if (data && ((Array.isArray(data) && data.length > 0) || (typeof data === 'object' && Object.keys(data).length > 0))) {
      return <WrappedComponent {...props} />;
    }

    return <CustomNoDataOverlay message={message} />;
  };
};

export default withLoadingAndOverlay;
