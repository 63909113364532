import { createSlice } from '@reduxjs/toolkit';
import { getNotificationSettingsDetails, saveNotificationSettingsDetails } from './notificationSettingsAction';

export const getNotificationSettingsSlice = createSlice({
  name: 'getNotificationSettingsDetails',
  initialState: { data: {}, error: null, loading: false },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNotificationSettingsDetails.pending, (state) => {
      state.loading = true;
      state.data = {};
    });
    builder.addCase(getNotificationSettingsDetails.fulfilled, (state, { payload }) => {
      state.data = payload.notificationSettingsDetails;
      state.loading = false;
    });
    builder.addCase(getNotificationSettingsDetails.rejected, (state, action) => {
      state.loading = false;
      state.data = {};
      state.error = action.payload;
    });
  },
});

export const saveNotificationSettingsSlice = createSlice({
  name: 'saveNotificationSettingsDetails',
  initialState: {
    loading: false,
    error: null,
    data: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveNotificationSettingsDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveNotificationSettingsDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
      state.error = null;
    });
    builder.addCase(saveNotificationSettingsDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});
