import { createSlice } from '@reduxjs/toolkit';
import { getPackageList, getAccountPackageList, getPackageDetailList } from './packageAction';

export const packageList = createSlice({
  name: 'packageList',
  initialState: {
    loading: true,
    error: null,
  },
  reducers: {
    resetPackageList: (state) => {
      state.packageMenuList = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPackageList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getPackageList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.packageMenuList = payload.data;
    });
    builder.addCase(getPackageList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const accountPackageList = createSlice({
  name: 'accountPackageList',
  initialState: {
    loading: true,
    error: null,
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccountPackageList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAccountPackageList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.data = payload.data;
    });
    builder.addCase(getAccountPackageList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const packageDetailList = createSlice({
  name: 'packageDetailList',
  initialState: {
    loading: true,
    error: null,
  },
  reducers: {
    resetPackageList: (state) => {
      state.data = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPackageDetailList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getPackageDetailList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.data = payload.data;
    });
    builder.addCase(getPackageDetailList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { resetPackageList } = packageList.actions;
