import {
  Grid, Box, Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ConfirmDialogBox } from '../../components/CustomDialogBox';
import { TenantList } from '../../modules/TenantManager';
import { TITLES } from '../../utils/constants';
import usePackageManager from './usePackageManager';
import useStyles from '../../hooks/useStyles';
import styles from '../../layouts/pages/style';
import { getPackageDetailList } from '../../features';

function PackageManager({ permissions }) {
  const classes = useStyles(styles)();
  const dispatch = useDispatch();

  const {
    packageList,
    packageColumns,
    openActionDialog,
    setOpenActionDialog,
    actionDialogData,
  } = usePackageManager({ permissions });

  useEffect(() => {
    dispatch(getPackageDetailList());
  }, []);

  return (
    <Grid container gap={2}>
      <Grid item xs={12} className={classes.cardStyles}>
        <Box className={classes.headerStyles}>
          <Typography variant="h4" gutterBottom>{TITLES.PACKAGE_MANAGER}</Typography>
        </Box>
      </Grid>

      <Grid item xs={12} className={classes.tableStyles}>
        <TenantList
          id="id"
          loading={packageList?.loading}
          error={packageList?.error}
          data={packageList?.data}
          columns={packageColumns}
        />
      </Grid>

      <ConfirmDialogBox
        title={actionDialogData.title}
        message={actionDialogData.message}
        openDialog={openActionDialog}
        onClose={setOpenActionDialog}
        onConfirm={actionDialogData.onConfirm}
      />
    </Grid>
  );
}

export default PackageManager;
