import { cloneElement } from 'react';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { formikForm } from '../../hoc';

function FormDialog({
  formContent, formAction, table, formik,
}) {
  return (
    <>
      <DialogContent dividers sx={{ padding: table ?? '10px 24px', overflow: 'auto', maxHeight: '50vh' }}>
        {formContent && cloneElement(formContent, { formik })}
      </DialogContent>
      {formAction && (
        <DialogActions sx={{ m: 1, overflow: 'visible' }}>
          <Button
            mt={1}
            color="primary"
            variant="contained"
            type="submit"
            size="medium"
          >
            {formAction.title}
          </Button>
        </DialogActions>
      )}
    </>
  );
}

export default formikForm(FormDialog);
