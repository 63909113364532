import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import MonitorHeartIcon from '@mui/icons-material/MonitorHeart';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import CableIcon from '@mui/icons-material/Cable';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import InventoryIcon from '@mui/icons-material/Inventory';

export const appMenuItems = [
  {
    name: 'Dashboard',
    link: '/dashboard',
    Icon: DashboardOutlinedIcon,
  },
  {
    name: 'Accounts',
    link: '/account-list',
    Icon: BusinessOutlinedIcon,
  },
  {
    name: 'Product Insights',
    Icon: EmojiObjectsOutlinedIcon,
    items: [
      {
        name: 'Geography',
        link: '/geographies',
        Icon: LanguageOutlinedIcon,
      },
      {
        name: 'Custom Object',
        link: '/custom-objects',
        Icon: EmojiObjectsOutlinedIcon,
      },
      {
        name: 'Engagement',
        link: '/active-users',
        Icon: HandshakeOutlinedIcon,
      },
      {
        name: 'Versions',
        link: '/versions',
        Icon: TouchAppOutlinedIcon,
      },
      {
        name: 'KPI Usage',
        link: '/kpi-usage',
        Icon: PollOutlinedIcon,
      },
    ],
  },
  {
    name: 'Score Insights',
    link: '/score-insights',
    Icon: DataUsageIcon,
  },
  {
    name: 'Log Analyzer',
    link: '/log-analyzer',
    Icon: AnalyticsOutlinedIcon,
  },
  {
    name: 'Setup',
    Icon: BuildOutlinedIcon,
    permission: 'write',
    items: [
      {
        name: 'KPI Manager',
        link: '/kpi-manager',
        Icon: ManageHistoryIcon,
      },
      {
        name: 'KPI Scores',
        link: '/kpi-score-configuration',
        Icon: MonitorHeartIcon,
      },
      {
        name: 'User Manager',
        link: '/user-manager',
        Icon: ManageAccountsIcon,
      },
      {
        name: 'Package Manager',
        link: '/package-list',
        Icon: InventoryIcon,
      },
      {
        name: 'Connect PBO',
        link: '/connect-pbo',
        Icon: CableIcon,
      },
      {
        name: 'Configuration',
        link: '/configuration',
        Icon: SettingsSuggestIcon,
      },
    ],
  },
];

export const tenantMenu = [
  {
    name: 'Tenants',
    link: '/dashboard',
    Icon: SupervisorAccountIcon,
    access: 'superAdmin',
  },
];
