import { createSlice } from '@reduxjs/toolkit';
import { getCustomObjects, getCustomObjectPinningData } from './customObjectAction';

const customObjectSlice = createSlice({
  name: 'customObjects',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomObjects.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCustomObjects.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.response = payload;
      state.error = null;
    });
    builder.addCase(getCustomObjects.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

const customObjectPinningData = createSlice({
  name: 'customObjectPinningData',
  initialState: {
    loading: true,
    error: '',
    response: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomObjectPinningData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCustomObjectPinningData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.response = payload.data || [];
      state.error = null;
    });
    builder.addCase(getCustomObjectPinningData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export { customObjectSlice, customObjectPinningData };
