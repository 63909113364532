// import palette from '../../theme/palette';

const FeatureLabelModalStyle = () => ({
  dialogTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '7px 7px 6px 5px',
    padding: '7px 5px 7px 5px',
    fontWeight: 'bold',
  },
  tableCellHeader: {
    fontSize: '18px',
    margin: '7px 50px 7px 16px',
    padding: '7px 50px 7px 16px',
  },

  eventType: {
    fontSize: '0.9rem',
    color: '#4482FF',
    maxWidth: '400px',
    marginTop: '5px',
    marginLeft: '5px',
  },
  pipe: {
    fontSize: '0.9rem',
    margin: '0 4px',
  },
  dialogContent: {
    margin: 0,
    padding: 0,
    borderTop: 'solid 1px rgb(112, 112, 112 , 0.5) ',
  },
});

export default FeatureLabelModalStyle;
