import { Grid } from '@mui/material';
import { InfoChip } from '../../../../components/CustomChip';
import { SelectBox } from '../../../../components/FormComponents';

function generateMenuItems(start, end, inc = 1) {
  const result = [];
  for (let i = start; i <= end; i += inc) {
    result.push(i.toString());
  }
  return result;
}

function CustomDefinitionForm({ formik, data, options }) {
  const { handleChange, values } = formik;

  return (
    <Grid container gap={1} p={2}>
      <InfoChip icon label="Choose metric for High-Risk accounts." />

      <Grid item xs={12}>
        <SelectBox name="metric" title="Threshold Metric" onChange={handleChange} data={data} value={values.metric} />
      </Grid>

      {(options[values.metric]?.kpiScore ?? true) && (
      <Grid item xs={12}>
        <SelectBox disabled={!options[values.metric]?.kpiScore ?? true} name="kpiScore" title="KPI Score Threshold" onChange={handleChange} data={generateMenuItems(5, 30, 5)} value={values.kpiScore} />
      </Grid>
      )}

      {(options[values.metric]?.licenseUtilization ?? true) && (
      <Grid item xs={12}>
        <SelectBox disabled={!options[values.metric]?.licenseUtilization ?? true} name="licenseUtilization" title="License Utilization Threshold (%)" onChange={handleChange} data={generateMenuItems(5, 90, 5)} value={values.licenseUtilization} />
      </Grid>
      )}
    </Grid>
  );
}

export default CustomDefinitionForm;
