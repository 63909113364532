import { withLoadingAndOverlay } from '../../../../hoc';
import { CustomDataGrid } from '../../../../components/CustomDataGrid';

function AccountHealthScoreTable({ data, columns }) {
  return (
    <CustomDataGrid
      rows={data}
      columns={columns}
      headerHeight={0}
    />
  );
}

export default withLoadingAndOverlay(AccountHealthScoreTable);
