import { VersionBreakdown } from '../VersionBreakdown';
import { OrgEdBreakdown } from '../OrgEdBreakdown';

export default function Versions({
  orgEditionBreakdown, versions, editions, more, setRefreshVersionBreakdown, setRefreshOrgEdBreakdown,
}) {
  return (
    <>
      {orgEditionBreakdown && editions && (
        <OrgEdBreakdown
          loading={editions?.loading}
          error={editions?.error}
          data={editions?.orgEdObject}
          refresh={setRefreshOrgEdBreakdown}
          message="No Data"
        />
      )}
      {!orgEditionBreakdown && versions && (
        <VersionBreakdown
          plugins
          loading={versions?.loading}
          error={versions?.error}
          data={versions?.versionObject}
          refresh={setRefreshVersionBreakdown}
          more={more}
          message="No Data"
        />
      )}
    </>
  );
}
