import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardCards from '../../../../components/DashboardCard/DashboardCards';
import { VersionBreakdown } from '../VersionBreakdown';
import { getVersions } from '../../../../features';
import { useVersions } from '../../../../sections/Versions/useVersions';

import useToggle from '../../../../hooks/useToggle';

export default function VersionsCard({ activePackage }) {
  const [refreshVersionBreakdown, setRefreshVersionBreakdown] = useToggle();
  const dispatch = useDispatch();

  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getVersions({ pkgId: activePackage.packageId }));
    }
  }, [refreshVersionBreakdown, activePackage?.packageId]);

  const { versions } = useVersions();

  const navigate = useNavigate();
  return (

    <DashboardCards
      onClick={() => navigate('/versions', { state: { more: false } })}
      title="Version Highlights"
      showButton
      buttonText="more"
    >
      <VersionBreakdown
        loading={versions?.loading}
        message="No Data"
        error={versions?.error}
        data={versions?.versionObject}
        refresh={setRefreshVersionBreakdown}
        more
      />
    </DashboardCards>

  );
}
