import { Container } from '@mui/material';
import { HealthScoreConfig } from '../sections/HealthScoreConfig';
import Metatags from '../components/MetaTags/MetaTags';

function HealthScoreConfigPage() {
  return (
    <>
      <Metatags metaTagName="KPI Score Configuration | App Insights" />
      <Container maxWidth="xl">
        <HealthScoreConfig />
      </Container>
    </>
  );
}

export default HealthScoreConfigPage;
