import {
  Box, Stack, Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import { CustomIcon } from '../../../../components/CustomIcon';
import { InfoChip } from '../../../../components/CustomChip';
import { DynamicTable } from '../../../../components/CustomTable';
import useStyles from '../../../../hooks/useStyles';
import styles from '../../../../layouts/pages/style';

function TitleElement({ title }) {
  return <Typography>{title}</Typography>;
}
function ValueElement({ value }) {
  return <Typography>{value}</Typography>;
}

function ActiveUserConfigCard({ handleOpen, activeUserConfig, filteredFeatures }) {
  const [configFeature, setConfigFeature] = useState('No config set');

  useEffect(() => {
    if (activeUserConfig?.data) {
      const matchedObj = filteredFeatures.find((item) => item.id === activeUserConfig.data.featureId);
      setConfigFeature(matchedObj?.name || 'No config set');
    }
  }, [activeUserConfig?.data]);

  const classes = useStyles(styles)();
  const columns = [{ field: 'title' }, { field: 'value', align: 'left' }];
  const rows = [
    {
      title: <TitleElement title="Config:" />,
      value: <ValueElement value={configFeature} />,
    },
  ];

  return (
    <Box className={classes.cardStyles} sx={{ width: '100%' }}>
      {/* Header */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography sx={{ p: '10px', fontSize: '22px', fontWeight: 500 }}>
          Active User
        </Typography>
        {/* Button */}
        <CustomIcon tooltip="Edit" onClick={handleOpen} Icon={EditIcon} iconSize="small" variant="outlined" sxProps={{ marginRight: 1 }} />
      </Stack>
      {/* Content */}
      <InfoChip sxProps={{ ml: '10px' }} icon label="You can enable or disable Active-User config" />
      <DynamicTable columns={columns} rows={rows} />
    </Box>
  );
}

export default ActiveUserConfigCard;
