import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '../../modules/Request/request';

export const getHighRiskAccountConfig = createAsyncThunk(
  'getHighRiskAccountConfig',
  async ({ packageId }, { rejectWithValue }) => {
    const response = await request('/get-high-risk-acc-config', { method: 'GET', params: [packageId] });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const setupHighRiskAccount = createAsyncThunk(
  'setupHighRiskAccount',
  async ({ highRiskAccount, customerSizeCategories, packageId }, { rejectWithValue }) => {
    const response = await request('/high-risk-account-setup', { method: 'POST', body: { customerSizeCategories, highRiskAccount, packageId } });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);
