import moment from 'moment';
import { DATE_FORMAT } from './constants';

export function getDate(dateString) {
  const date = dateString ? new Date(dateString) : new Date();
  return date;
}

export function getMomentDate(dateString) {
  if (!dateString) {
    return moment();
  }
  const isValidDate = moment(dateString).isValid();
  const date = isValidDate ? moment(dateString) : moment(dateString, 'MM-DD-YYYY');
  return date;
}

export function getFiscalYear(currentYear, year, month, day) {
  const fiscalYear = new Date(currentYear - year, month, day);
  return fiscalYear;
}

export function getWeekRange(date) {
  const startDate = moment(date).format('DD MMM');
  const endDate = moment(date).add(6, 'days').format("DD MMM 'YY");
  const weekRange = `${startDate} - ${endDate}`;
  return weekRange;
}

export const transformGraphLabel = (date, viewOption) => {
  const dateObj = getMomentDate(date);
  if (viewOption.includes('week')) {
    const startDate = moment(dateObj);
    const endDate = moment(dateObj).add(6, 'days');
    const formattedDateRange = `${startDate.format('DD MMM')} - ${endDate.format('DD MMM ')}'${endDate.format('YY')}`;
    return formattedDateRange;
  }
  if (viewOption.includes('month')) {
    return dateObj.format(DATE_FORMAT.MONTH_DATE_GRAPH);
  }
  return dateObj.format(DATE_FORMAT.DAY_DATE_GRAPH);
};

export const generateGraphLabels = (dataArray, viewOption = 'day') => {
  const graphLabels = dataArray.map((item) => {
    return transformGraphLabel(item, viewOption);
  });
  return graphLabels;
};

export const getPartialDataLabel = (dateArray, viewOption = 'day') => {
  if (!dateArray || viewOption.includes('day')) {
    return null;
  }

  const isWeek = viewOption.includes('week');
  const currentDate = moment();
  const daysAgo = 7;
  const weekWindow = 6;
  const targetDate = currentDate.clone().subtract(daysAgo, 'days');

  let partialDate = '';
  dateArray.forEach((dateStr) => {
    const dateObj = getMomentDate(dateStr);
    const lastDateOfMonth = moment(dateObj).endOf('month');
    const graphDate = isWeek ? moment(dateObj).add(weekWindow, 'days') : lastDateOfMonth;

    if (graphDate.isAfter(targetDate)) {
      if (!partialDate || moment(dateObj).isBefore(partialDate)) {
        partialDate = dateStr;
      }
    }
  });
  return partialDate;
};

export const getCompletedDataLabel = (dateArray, viewOption = 'day') => {
  if (!dateArray) return null;
  if (viewOption.includes('day')) return dateArray.pop();

  let completedDate = '';
  const isWeek = viewOption.includes('week');

  if (isWeek) {
    completedDate = moment().startOf('isoWeek').subtract(8, 'days').startOf('isoWeek')
      .format('MM-DD-YYYY');
  } else {
    completedDate = moment().subtract(7, 'days').subtract(1, 'months').format('MM-YYYY');
  }

  return completedDate;
};

export const getCompletedDataDate = (dateArray, viewOption = 'day') => {
  if (!dateArray) {
    return null;
  }

  if (viewOption.includes('day')) {
    return dateArray.pop();
  }

  const isWeek = viewOption.includes('week');
  const currentDate = moment();
  const daysAgo = 7;
  const weekWindow = 6;
  const targetDate = currentDate.clone().subtract(daysAgo, 'days');

  let completedDate = '';
  dateArray.forEach((dateStr) => {
    const dateObj = getMomentDate(dateStr);
    const lastDateOfMonth = moment(dateObj).endOf('month');
    const graphDate = isWeek ? moment(dateObj).add(weekWindow, 'days') : lastDateOfMonth;

    if (graphDate.isBefore(targetDate)) {
      if (!completedDate || moment(dateObj).isAfter(completedDate)) {
        completedDate = dateStr;
      }
    }
  });
  return completedDate;
};

export const getGraphDateRange = (viewOption) => {
  switch (viewOption) {
    case 'week':
      return '12 weeks';

    case 'day':
      return '60 days';

    default:
      return '12 months';
  }
};

export const isAfter90Days = (date) => {
  // Parse the install date using moment
  const parsedInstallDate = moment(date);

  // Add 90 days to the install date
  const dateAfter90Days = parsedInstallDate.add(3, 'months');

  // Get the current date
  const currentDate = moment();

  return dateAfter90Days.isAfter(currentDate);
};
