import { useDispatch } from 'react-redux';
import { getCustomAlertDetails, saveCustomAlertDetails } from '../../../../features/CustomAlert/customAlertAction';
import { openSnackbar } from '../../../../features/SnackBar/snackBarSlice';
import { FormDialogBox } from '../../../../components/CustomDialogBox';
import WatchTowerForm from './WatchTowerForm';

function WatchTowerDialog({
  open, handleClose, watchTowerData, packageId,
}) {
  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    const data = {
      threshold: parseInt(values.threshold, 10),
      averageMonths: parseInt(values.averageMonths, 10),
      view: values.view,
      packageId,
    };
    dispatch(saveCustomAlertDetails(data))
      .then(() => {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Successfully Updated !',
            type: 'success',
          }),
        );
        dispatch(getCustomAlertDetails({ pkgId: packageId }));
      });

    handleClose();
  };

  const initialValues = {
    threshold: watchTowerData?.[0]?.threshold ?? 5,
    averageMonths: watchTowerData?.[0]?.averageMonths ?? 3,
    view: watchTowerData?.[0]?.view ?? 'monthly',
  };

  return (
    <FormDialogBox
      title="Configure KPI Score Trends"
      open={open}
      handleClose={handleClose}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      formContent={<WatchTowerForm />}
      formAction={{ title: 'Update' }}
    />
  );
}

export default WatchTowerDialog;
