import { Card, Grid } from '@mui/material';
import useStyles from '../../hooks/useStyles';
import styles from '../../components/DashboardCard/style';
import VersionKpiScore from '../../modules/Versions/components/VersionKpiScore/VersionKpiScore';
import { Versions } from '../../modules/Versions';

export default function Breakdown(props) {
  const {
    versions, editions, versionKpiScore, more, setRefreshOrgEdBreakdown, setRefreshVersionBreakdown, setRefreshVersionKpiScore,
  } = props;
  const classes = useStyles(styles)();

  return (
    <Grid container style={{ justifyContent: 'space-between' }} spacing={2}>
      <Grid item lg={7}>
        <Card className={classes.cardStyle}>
          <Versions versions={versions} setRefreshVersionBreakdown={setRefreshVersionBreakdown} />
        </Card>
      </Grid>
      <Grid item lg={5}>
        <Card className={classes.cardStyle} sx={{ height: '100%' }}>
          <Versions
            orgEditionBreakdown
            editions={editions}
            more={more}
            setRefreshOrgEdBreakdown={setRefreshOrgEdBreakdown}
          />
        </Card>
      </Grid>
      <Grid item lg={12}>
        <Card className={classes.cardStyle} sx={{ height: '100%' }}>
          <VersionKpiScore
            loading={versionKpiScore?.loading}
            error={versionKpiScore?.error}
            data={versionKpiScore?.versionData}
            refresh={setRefreshVersionKpiScore}
            message="No Data"
          />
        </Card>
      </Grid>
    </Grid>
  );
}
