import { Box, Stack, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { defaultCategories } from '../../../../sections/AccountList/accountListConfig';
import useToggle from '../../../../hooks/useToggle';
import { CustomIcon } from '../../../../components/CustomIcon';
import { DynamicTable } from '../../../../components/CustomTable';
import useStyles from '../../../../hooks/useStyles';
import styles from '../../../../layouts/pages/style';
import CustomerSizeDialog from './CustomerSizeDialog';

function TitleElement({ title }) {
  return <Typography>{title}</Typography>;
}
function ValueElement({ value }) {
  return (
    <Stack alignItems="flex-start">
      <Typography
        sx={(theme) => ({
          width: 'max-content',
          backgroundColor: theme.palette.primary.lighter,
          p: '2px 10px',
          borderRadius: '5px',
          fontWeight: '600',
          color: theme.palette.primary.text,
        })}
      >
        {value}
      </Typography>
    </Stack>
  );
}

function CustomerSizeCard({ packageId }) {
  const adminSettings = useSelector((state) => state.highRiskAccountConfig?.data);
  const classes = useStyles(styles)();

  const [open, setOpen] = useToggle();

  const categoriesList = adminSettings?.customerSizeCategories?.length > 0 ? adminSettings.customerSizeCategories : defaultCategories;
  const columns = [{ field: 'title', width: '400px' }, { field: 'value', align: 'left' }];
  const rows = useMemo(() => {
    const rowsData = categoriesList.map(({ title, from, to }) => {
      let value = '';
      if (from && to) {
        value = `${from} - ${to}`; // Both from and to are provided
      } else if (from && !to) {
        value = `${from} +`; // from exists, but to is empty
      } else if (!from && !to) {
        value = 'Site Wide'; // Both from and to are empty
      }
      return {
        title: <TitleElement title={title} />,
        value: <ValueElement value={value} />,
      };
    });

    return rowsData;
  }, [categoriesList]);

  return (
    <Box className={classes.cardStyles} sx={{ width: '100%' }}>
      {/* Header */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography sx={{ p: '10px', fontSize: '22px', fontWeight: 500 }}>
          Size Of Customers
        </Typography>
        <CustomIcon tooltip="Edit" onClick={setOpen} Icon={EditIcon} iconSize="small" variant="outlined" sxProps={{ marginRight: 1 }} />
      </Stack>
      {/* Content */}
      <DynamicTable columns={columns} rows={rows} />

      <CustomerSizeDialog open={open} handleClose={setOpen} packageId={packageId} data={categoriesList} />
    </Box>
  );
}

export default CustomerSizeCard;
