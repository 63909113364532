import { createAsyncThunk } from '@reduxjs/toolkit';

import request from '../../modules/Request/request';

export const getGeneralDetails = createAsyncThunk(
  'getGeneralDetails',
  async (object, { rejectWithValue }) => {
    const reqPayload = {};
    const query = {};
    reqPayload.method = 'GET';
    query.page = 'account';
    query.orgId = object.orgId;
    query.pkgId = object.pkgId;
    if (object?.isShare) {
      query.isShare = object.isShare;
      if (object.accountOrgId) {
        query.accountOrgId = object.accountOrgId;
      } else {
        query.tenantId = object.tenantId;
      }
    }
    reqPayload.query = query;
    const response = await request('/general-detail', reqPayload);
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },

);
export const updateAccount = createAsyncThunk(
  'updateAccount',
  async ({ id, pkgId, newData }, { rejectWithValue }) => {
    const response = await request('/update-account', { method: 'PUT', body: newData, params: [id, pkgId] });
    if (response.success) {
      return newData;
    }

    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const getAllRemarks = createAsyncThunk(
  'getAllRemarks',
  async (object, { rejectWithValue }) => {
    const reqPayload = {};
    const query = {};
    reqPayload.method = 'GET';
    if (object?.isShare) {
      query.isShare = object.isShare;
      if (object.accountOrgId) {
        query.accountOrgId = object.accountOrgId;
      } else {
        query.tenantId = object.tenantId;
      }
    }
    reqPayload.query = query;
    reqPayload.params = [object.orgId, object.packageId];
    const response = await request('/get-remarks', reqPayload);
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },

);
export const saveRemarks = createAsyncThunk(
  'saveRemarks',
  async ({ accountId, packageId, body }, { rejectWithValue }) => {
    const response = await request('/remarks', { method: 'POST', body, params: [accountId, packageId] });
    if (response.success) {
      return response.data;
    }

    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);
