import DashboardCards from '../../../../components/DashboardCard/DashboardCards';
import useEventTimeline from './useEventTimeline';
import TimeLineComponent from './components/TimeLineComponent';

export default function EventTimeLine({
  activePackage, orgId, isShare, tenantId, pkgId, accountOrgId,
}) {
  const { accountsEventTimelineData } = useEventTimeline({
    orgId,
    activePackage,
    isShare,
    tenantId,
    pkgId,
    accountOrgId,
  });
  return (
    <DashboardCards title="Event Timeline">
      <TimeLineComponent data={accountsEventTimelineData} message="No Data" />
    </DashboardCards>
  );
}
