import { createAsyncThunk } from '@reduxjs/toolkit';

import request from '../../modules/Request/request';

export const getFeaturesList = createAsyncThunk(
  'getFeaturesList',
  async ({ packageId }, { rejectWithValue }) => {
    const response = await request('/feature-list', { method: 'GET', params: packageId });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const featurePinning = createAsyncThunk('featurePinning', async (object, { rejectWithValue }) => {
  const response = await request('/feature-pinning', { method: 'POST', body: object });
  if (response.success) {
    return response.data;
  }
  const error = response.error && response.error.message ? response.error.message : response.error;
  throw rejectWithValue(error || 'Something went wrong');
});

export const getFeatureDetailList = createAsyncThunk(
  'getFeatureDetailList',
  async (id, { rejectWithValue }) => {
    const response = await request('/feature-details', { method: 'GET', params: id });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const getCustomEntityList = createAsyncThunk(
  'getCustomEntityList',
  async ({ packageId, isShare }, { rejectWithValue }) => {
    const reqPayload = {};
    const query = {};
    reqPayload.method = 'GET';
    reqPayload.params = packageId;
    if (isShare) {
      query.isShare = isShare;
    }
    reqPayload.query = query;
    const response = await request('/custom-entity-list', reqPayload);
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const addFeature = createAsyncThunk(
  'addFeature',
  async (featureData, { rejectWithValue }) => {
    const { data, packageId } = featureData;
    const response = await request('/create-feature', { method: 'POST', body: data, params: packageId });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const updateFeature = createAsyncThunk(
  'updateFeature',
  async (data, { rejectWithValue }) => {
    const response = await request('/update-feature', { method: 'PUT', body: data });
    if (response.success) {
      return data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const deleteFeature = createAsyncThunk(
  'deleteFeature',
  async (id, { rejectWithValue }) => {
    const response = await request('/delete-feature', { method: 'DELETE', params: id });
    if (response.success) {
      return id;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const getKpiPreview = createAsyncThunk(
  'getKpiPreview',
  async (featureData, { rejectWithValue }) => {
    const { data, packageId } = featureData;
    const response = await request('/kpi-preview', { method: 'POST', body: data, params: packageId });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);
