import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import usePackageSelection from '../../../Accounts/components/PackageSelection/usePackageSelection';
import { setupHighRiskAccount } from '../../../../features/Configuration/configurationAction';
import { openSnackbar } from '../../../../features/SnackBar/snackBarSlice';
import { FormDialogBox } from '../../../../components/CustomDialogBox';
import CustomDefinitionForm from './CustomDefinitionForm';

const options = {
  onlyScore: {
    kpiScore: true,
    licenseUtilization: false,
    kpiTrend: false,
    value: 'healthScore <= kpiScoreValue',
  },
  onlyUtil: {
    kpiScore: false,
    licenseUtilization: true,
    kpiTrend: false,
    value: 'assignedSeatsPercent <= licenseUtilizationValue',
  },
  onlyDecline: {
    kpiScore: false,
    licenseUtilization: false,
    kpiTrend: true,
    value: 'healthScoreTrend === "falling"',
  },
  // -----
  scoreAndUtil: {
    kpiScore: true,
    licenseUtilization: true,
    kpiTrend: false,
    value: 'healthScore <= kpiScoreValue && assignedSeatsPercent <= licenseUtilizationValue',
  },
  scoreOrUtil: {
    kpiScore: true,
    licenseUtilization: true,
    kpiTrend: false,
    value: 'healthScore <= kpiScoreValue || assignedSeatsPercent <= licenseUtilizationValue',
  },
  // -----
  scoreAndDecline: {
    kpiScore: true,
    licenseUtilization: false,
    kpiTrend: true,
    value: 'healthScore <= kpiScoreValue && healthScoreTrend === "falling"',
  },
  scoreOrDecline: {
    kpiScore: true,
    licenseUtilization: false,
    kpiTrend: true,
    value: 'healthScore <= kpiScoreValue || healthScoreTrend === "falling"',
  },
  // -----
  utilAndDecline: {
    kpiScore: false,
    licenseUtilization: true,
    kpiTrend: true,
    value: 'assignedSeatsPercent <= licenseUtilizationValue && healthScoreTrend === "falling"',
  },
  utilOrDecline: {
    kpiScore: false,
    licenseUtilization: true,
    kpiTrend: true,
    value: 'assignedSeatsPercent <= licenseUtilizationValue || healthScoreTrend === "falling"',
  },
  // ----
  scoreAndDeclineOrUtil: {
    kpiScore: true,
    licenseUtilization: true,
    kpiTrend: true,
    value: '(healthScore <= kpiScoreValue && healthScoreTrend === "falling") || assignedSeatsPercent <= licenseUtilizationValue',
  },
  scoreOrDeclineAndUtil: {
    kpiScore: true,
    licenseUtilization: true,
    kpiTrend: true,
    value: 'healthScore <= kpiScoreValue || (healthScoreTrend === "falling" && assignedSeatsPercent <= licenseUtilizationValue)',
  },
  scoreAndUtilOrDecline: {
    kpiScore: true,
    licenseUtilization: true,
    kpiTrend: true,
    value: '(healthScore <= kpiScoreValue && assignedSeatsPercent <= licenseUtilizationValue) || healthScoreTrend === "falling"',
  },
  scoreOrUtilAndDecline: {
    kpiScore: true,
    licenseUtilization: true,
    kpiTrend: true,
    value: '(healthScore <= kpiScoreValue || assignedSeatsPercent <= licenseUtilizationValue) && healthScoreTrend === "falling"',
  },
  // -----
  scoreOrUtilOrDecline: {
    kpiScore: true,
    licenseUtilization: true,
    kpiTrend: true,
    value: 'healthScore <= kpiScoreValue || assignedSeatsPercent <= licenseUtilizationValue || healthScoreTrend === "falling"',
  },
  scoreAndUtilAndDecline: {
    kpiScore: true,
    licenseUtilization: true,
    kpiTrend: true,
    value: '(healthScore <= kpiScoreValue && assignedSeatsPercent <= licenseUtilizationValue) && healthScoreTrend === "falling"',
  },
};

const highRiskAccountMetric = [
  { name: 'Low Score', value: 'onlyScore' },
  { name: 'Low Utilization', value: 'onlyUtil' },
  { name: 'Declining Trend', value: 'onlyDecline' },

  { name: 'Low Score and Low Utilization', value: 'scoreAndUtil' },
  { name: 'Low Score or Low Utilization', value: 'scoreOrUtil' },
  { name: 'Low Score and Declining Trend', value: 'scoreAndDecline' },
  { name: 'Low Score or Declining Trend', value: 'scoreOrDecline' },
  { name: 'Low Utilization and Declining Trend', value: 'utilAndDecline' },
  { name: 'Low Utilization or Declining Trend', value: 'utilOrDecline' },

  { name: 'Low Score and Declining Trend or Low Utilization', value: 'scoreAndDeclineOrUtil' },
  { name: 'Low Score or Declining Trend and Low Utilization', value: 'scoreOrDeclineAndUtil' },
  { name: 'Low Score and Low Utilization or Declining Trend', value: 'scoreAndUtilOrDecline' },
  { name: 'Low Score or Low Utilization and Declining Trend', value: 'scoreOrUtilAndDecline' },

  { name: 'Low Score or Low Utilization or Declining Trend', value: 'scoreOrUtilOrDecline' },
  { name: 'Low Score and Low Utilization and Declining Trend', value: 'scoreAndUtilAndDecline' },
];

function CustomDefinitionDialog({ open, handleClose }) {
  const dispatch = useDispatch();
  const { activePackage } = usePackageSelection();

  const highRiskAccountConfig = useSelector((state) => state.highRiskAccountConfig?.data);

  const generateValidations = (values = {}) => {
    const {
      kpiScore = null, licenseUtilization = null,
    } = values;

    const validationSchema = Yup.object().shape({
      metric: Yup.string().required('Metric is required !'),
      ...(kpiScore && { kpiScore: Yup.string().required() }),
      ...(licenseUtilization && { licenseUtilization: Yup.string().required() }),
    });

    return validationSchema;
  };

  const generateCondition = (string, values) => {
    const {
      kpiScore, licenseUtilization,
    } = values;

    let condition = string;

    if (kpiScore) {
      condition = condition.replace('kpiScoreValue', kpiScore);
    }
    if (licenseUtilization) {
      condition = condition.replace('licenseUtilizationValue', licenseUtilization);
    }

    return condition;
  };

  const handleSubmit = (values) => {
    const metric = highRiskAccountMetric.find((item) => item.value === values.metric);
    const string = options[values.metric].value;
    const condition = generateCondition(string, values);

    if ((options[values.metric].kpiScore && !values.kpiScore) || (options[values.metric].licenseUtilization && !values.licenseUtilization)) {
      dispatch(openSnackbar({ open: true, message: 'Please fill all the required fields!', type: 'error' }));
      return;
    }

    const highRiskAccount = {
      condition,
      metric,
      ...(options[values.metric].kpiScore && { kpiScore: values.kpiScore }),
      ...(options[values.metric].licenseUtilization && { licenseUtilization: values.licenseUtilization }),
      ...(options[values.metric].kpiTrend && { kpiTrend: 'falling' }),
    };

    dispatch(setupHighRiskAccount({ highRiskAccount, packageId: activePackage?.packageId }))
      .then(() => {
        dispatch(openSnackbar({ open: true, message: 'Successfully Updated!', type: 'success' }));
      });

    handleClose();
  };

  const initialValues = {
    metric: highRiskAccountConfig?.highRiskAccount?.metric?.value || '',
    kpiScore: highRiskAccountConfig?.highRiskAccount?.kpiScore || '',
    licenseUtilization: highRiskAccountConfig?.highRiskAccount?.licenseUtilization || '',
  };

  return (
    <FormDialogBox
      title="Configure High-Risk Accounts"
      open={open}
      handleClose={handleClose}
      initialValues={initialValues}
      validationSchema={generateValidations}
      onSubmit={handleSubmit}
      formContent={<CustomDefinitionForm data={highRiskAccountMetric} options={options} />}
      formAction={{ title: 'Update' }}
    />
  );
}

export default CustomDefinitionDialog;
