import {
  Box, Grid, Typography, styled,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withLoadingAndOverlay } from '../../../../hoc';
import { FeatureLabelModal } from '../../../FeatureManager/components/FeatureLabelModal';
import useAddEditFeature from '../../../FeatureManager/components/AddEditFeature/useAddEditFeature';
import useToggle from '../../../../hooks/useToggle';
import { getFeatureDetailList } from '../../../../features';
import FeatureUsageHeader from './FeatureUsageHeader';
import FeatureUsageBody from './FeatureUsageBody';
import { CustomAccordion } from '../../../../components/CustomAccordion';

const EmptyFeaturesStyle = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  textAlign: 'center',
  padding: '6px 15px',
  borderRadius: 5,
}));

function FeatureUsageCard(props) {
  const {
    data: featureUsage,
    featuresList,
    toggleAll,
    getChartData,
    viewOption,
    siteWideLicense,
    totalActiveSeats,
    totalActiveAccounts,
    handlePinning,
    handleClickEdit,
    dashboard,
    permissions,
  } = props;

  const [unUsedFeatures, setUnUsedFeatures] = useState([]);
  const [accordionData, setAccordionData] = useState([]);
  const [defaultExpanded, setDefaultExpanded] = useState([]);

  const dispatch = useDispatch();
  const { customTypes } = useAddEditFeature();
  const [open, setOpen] = useToggle();
  const [label, setLabel] = useState('');
  const [featureId, setFeatureId] = useState('');

  const handleClose = () => {
    setOpen();
  };

  const handleOnRowClick = (event, featureObj) => {
    event.stopPropagation();
    setOpen();
    setLabel(featureObj.featureName);
    setFeatureId(featureObj.featureId);
    dispatch(getFeatureDetailList(featureObj.featureId));
  };

  const editFeature = () => {
    const params = {};
    params.row = { feature_id: featureId, feature_name: label };
    handleClickEdit(params);
  };

  const transformFeatureUsageData = useCallback(() => {
    const localAccordionData = [];
    const localUnusedFeatures = [];
    const localDefaultExpanded = [];

    featureUsage?.forEach((feature) => {
      const { featureId: id, isCoreFeature, accountCount } = feature;
      const userCount = feature.userCount || {};
      const operationCount = feature.operationCount || {};
      const userCountLen = Object.keys(userCount).length;
      const operationCountLen = Object.keys(operationCount).length;

      if (userCountLen && operationCountLen) {
        const localObj = {
          id,
          header: (
            <FeatureUsageHeader
              feature={feature}
              permissions={permissions}
              dashboard={dashboard}
              accountCount={accountCount}
              handlePinning={handlePinning}
              handleOnRowClick={handleOnRowClick}
            />
          ),
          body: (
            <FeatureUsageBody
              feature={feature}
              dashboard={dashboard}
              accountCount={accountCount}
              userCountLen={userCountLen}
              operationCountLen={operationCountLen}
              siteWideLicense={siteWideLicense}
              totalActiveSeats={totalActiveSeats}
              totalActiveAccounts={totalActiveAccounts}
              getChartData={getChartData}
              viewOption={viewOption}
            />
          ),
        };
        localAccordionData.push(localObj);
      } else {
        localUnusedFeatures.push(feature.featureName);
      }

      if (isCoreFeature) {
        localDefaultExpanded.push(id);
      }
    });

    setAccordionData(localAccordionData);
    setUnUsedFeatures(localUnusedFeatures);
    setDefaultExpanded(localDefaultExpanded);
  }, [featureUsage, viewOption, handlePinning, handleOnRowClick, dashboard, totalActiveSeats, totalActiveAccounts, getChartData]);

  useEffect(() => {
    transformFeatureUsageData();
  }, [featureUsage]);

  return (
    <Grid container>
      {featureUsage?.length && <CustomAccordion accordionData={accordionData} defaultExpanded={defaultExpanded} toggleAll={toggleAll} />}
      {!!unUsedFeatures?.length && (
        <Grid item xs={10} padding={3} borderTop="0.5px solid lightgrey">
          <Typography marginBottom="20px" fontWeight={600}>{`Following features have no usage data in last ${viewOption === 'month' ? '12 months' : '60 days'}`}</Typography>
          <Box display="flex" flexDirection="row" gap={4}>
            {unUsedFeatures.map((feature) => (
              <EmptyFeaturesStyle>
                {feature}
              </EmptyFeaturesStyle>
            ))}
          </Box>
        </Grid>
      )}
      {open && (
      <FeatureLabelModal
        open={open}
        handleClose={handleClose}
        label={label}
        featureId={featureId}
        handleClickEdit={editFeature}
        customTypes={customTypes}
        permissions={permissions}
        featuresList={featuresList}
      />
      )}
    </Grid>
  );
}

export default withLoadingAndOverlay(FeatureUsageCard);
