import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEventTimeLine } from '../../../../features/EventTimeline/EventTimelineAction';

export default function useEventTimeline({
  orgId, activePackage, isShare, tenantId, pkgId, accountOrgId,
}) {
  const dispatch = useDispatch();
  const packageId = pkgId || (activePackage && activePackage.packageId);
  useEffect(() => {
    if (packageId && orgId) {
      dispatch(getEventTimeLine({
        packageId, orgId, isShare, tenantId, accountOrgId,
      }));
    }
  }, [packageId, orgId, isShare, tenantId, accountOrgId]);
  const [accountsEventTimelineData, setAccountsEventTimelineData] = useState([]);
  const accountsEventTimeline = useSelector((state) => state.eventTimeline);
  useEffect(() => {
    if (accountsEventTimeline?.eventTimelineData) {
      setAccountsEventTimelineData(accountsEventTimeline.eventTimelineData);
    }
  }, [accountsEventTimeline?.eventTimelineData]);
  return {
    accountsEventTimelineData, packageId,
  };
}
