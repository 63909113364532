import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Grid, Container, Typography,
} from '@mui/material';
import useStyles from '../hooks/useStyles';
import styles from '../layouts/pages/style';
import Metatags from '../components/MetaTags/MetaTags';
import usePackageSelection from '../modules/Accounts/components/PackageSelection/usePackageSelection';
import { getActiveUsers } from '../features';
import ActiveUsers from '../modules/Accounts/components/ActiveUsers/ActiveUsers';
import useActiveUsers from '../modules/Accounts/components/ActiveUsers/useActiveUsers';
import useToggle from '../hooks/useToggle';
import { PackageHeader } from '../components/CustomHeading';

const activeUsersData = [
  { id: 1, title: 'Monthly Active Users', range: 'monthObject' },
  { id: 2, title: 'Weekly Active Users', range: 'weekObject' },
  { id: 3, title: 'Daily Active Users', range: 'dayObject' },
];

export default function ActiveUsersMorePage() {
  const [refreshUsers, setRefreshUsers] = useToggle();
  const location = useLocation();
  const accountName = location.state && location.state.accountName;
  const orgId = location.state ? location.state.orgId : 'all';
  const page = location.state ? location.state.page : 'dashboard';
  const dispatch = useDispatch();
  const { activePackage } = usePackageSelection();
  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getActiveUsers({ page, orgId, packageId: activePackage.packageId }));
    }
  }, [refreshUsers, activePackage?.packageId, orgId]);
  const { activeUsers } = useActiveUsers();
  const { error, loading } = activeUsers;
  const classes = useStyles(styles)();

  return (
    <>
      <Metatags metaTagName="Engagement | App Insights " />
      <Container maxWidth="xl">
        <Grid container gap={2}>
          <Grid item xs={12} className={classes.cardStyles}>
            {orgId === 'all'
              ? (
                <PackageHeader heading="Engagement" />
              )
              : (
                <Typography variant="h4" component="div" sx={{ mb: 2 }}>
                  {`Engagement - ${accountName || orgId}`}
                </Typography>
              )}
          </Grid>

          <Grid container spacing={3}>
            {activeUsersData
          && activeUsersData.map((items) => (
            <Grid item lg={12} key={items.id}>
              <ActiveUsers
                title={items.title}
                more={false}
                accountName={accountName}
                resultSet={activeUsers[items.range]}
                viewOption={items.range}
                error={error}
                loading={loading}
                setRefreshUsers={setRefreshUsers}
              />
            </Grid>
          ))}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
