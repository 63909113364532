import {
  Dialog, IconButton, Stack, Tooltip, Typography,
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import FormDialog from './FormDialog';

function FormDialogBox(props) {
  const {
    title,
    open,
    handleClose,
    ...restProps
  } = props;

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle align="center">
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
          <Tooltip title={title}>
            <Typography noWrap variant="h6">
              {title}
            </Typography>
          </Tooltip>
          <IconButton
            onClick={handleClose}
            sx={{ color: (theme) => theme.palette.grey[500], transform: 'translateX(10px)' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <FormDialog {...restProps} />
    </Dialog>
  );
}

export default FormDialogBox;
