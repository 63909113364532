import {
  Autocomplete, Button, FormControl, Grid, TextField,
} from '@mui/material';
import { SelectBox } from '../../../../components/FormComponents';
import PickCustomEntity from './PickCustomEntity';

const timeFrame = [
  { id: '15days', name: '15 Days', value: '15days' },
  { id: '30days', name: '30 Days', value: '30days' },
  { id: '60days', name: '60 Days', value: '60days' },
];
const customEntityType = [
  'CustomObject', 'LightningPage', 'LightningComponent', 'VisualforcePage', 'ApexExecution',
];

function LogAnalyzerFilters(props) {
  const {
    accountList, customEntityList, selectedFilters, handleFilterChange, handleApplyFilters, handleResetFilters,
  } = props;

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={12} sm={6} lg={3} sx={{ alignSelf: 'center' }}>
        <FormControl sx={{ width: '100%', position: 'relative', m: '8px 0' }}>
          <Autocomplete
            autoHighlight
            value={selectedFilters.account}
            options={accountList}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Account" />}
            onChange={(e, newVal) => handleFilterChange('account', newVal)}
          />
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <SelectBox
          multiple
          name="customEntityType"
          title="Object Type"
          data={customEntityType}
          value={selectedFilters.customEntityType}
          onChange={(e) => handleFilterChange('customEntityType', e.target.value)}
        />
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <PickCustomEntity data={customEntityList} handleFilterChange={handleFilterChange} />
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <SelectBox
          name="timeFrame"
          title="Time Frame"
          data={timeFrame}
          value={selectedFilters.timeFrame}
          onChange={(e) => handleFilterChange('timeFrame', e.target.value)}
        />
      </Grid>

      <Grid item xs={12} display="flex" alignItems="center" gap={2} justifyContent="flex-end">
        <Button size="medium" variant="outlined" onClick={handleResetFilters}>Reset</Button>
        <Button size="medium" variant="contained" onClick={handleApplyFilters}>Apply</Button>
      </Grid>
    </Grid>
  );
}

export default LogAnalyzerFilters;
