const pageStyle = ((theme) => ({
  cardStyles: {
    backgroundColor: 'white',
    padding: 15,
    boxShadow: 'none',
    borderRadius: '6px',
  },
  tableStyles: {
    backgroundColor: 'white',
    padding: 0,
  },
  headerStyles: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  actionStyles: {
    justifyContent: 'end',
    gap: 15,
  },
  textWithIcon: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoIcon: {
    width: 21,
    height: 17,
  },
  infoIconForForm: {
    width: 25,
    height: 25,
  },
}));

export default pageStyle;
