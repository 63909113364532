import { createAsyncThunk } from '@reduxjs/toolkit';

import request from '../../modules/Request/request';

export const getNotificationSettingsDetails = createAsyncThunk(
  'getNotificationSettingsDetails',
  async (object, { rejectWithValue }) => {
    try {
      const response = await request('/notification-settings', { method: 'GET', params: object.packageId });
      if (response.success) {
        return response.data;
      }
      throw new Error(response?.error?.message || response.error || 'Unknown error');
    } catch (error) {
      throw rejectWithValue(error.message || 'Something went wrong');
    }
  },
);

export const saveNotificationSettingsDetails = createAsyncThunk(
  'saveNotificationSettingsDetails',
  async ({
    frequency, type, packageId,
  }, { rejectWithValue }) => {
    const response = await request('/notification-settings', {
      method: 'POST',
      body: {
        frequency, type, packageId,
      },
      params: packageId,
    });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);
