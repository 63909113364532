import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import AppDrawer from '../../components/AppDrawer/AppDrawer';

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingBottom: theme.spacing(6),
  paddingTop: theme.spacing(2),
}));

export default function DashboardLayout(props) {
  const { isShareableScreens, permissions } = props;
  return (
    <StyledRoot>
      {!isShareableScreens
        ? <AppDrawer permissions={permissions} /> : null }
      <Main>
        <PrivateRoute isShareableScreens={isShareableScreens}>
          <Outlet />
        </PrivateRoute>
      </Main>
    </StyledRoot>
  );
}
