import { createAsyncThunk } from '@reduxjs/toolkit';

import request from '../../modules/Request/request';

export const getAccountsList = createAsyncThunk(
  'getAccountsList',
  async ({ packageId }, { rejectWithValue }) => {
    const response = await request('/all-accounts', { method: 'GET', params: packageId });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const updateAccountVisibility = createAsyncThunk(
  'updateAccountVisibility',
  async ({ orgId, packageId, visibility }, { rejectWithValue }) => {
    const response = await request('/account-visibility', { method: 'PATCH', params: [orgId, packageId], body: { visibility } });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const getLicenseUtilKpiScore = createAsyncThunk(
  'getLicenseUtilKpiScore',
  async (object, { rejectWithValue }) => {
    const response = await request('/license-assigned-kpi-score/account', { method: 'GET', query: { pkgId: object.pkgId } });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);
