import { createSlice, current } from '@reduxjs/toolkit';
import {
  getFeaturesList, getFeatureDetailList, getCustomEntityList, deleteFeature, updateFeature, addFeature,
} from './featureManagerAction';

const featureManagerList = createSlice({
  name: 'featureManagerList',
  initialState: {
    loading: false,
    featureManagerList: [],
    error: null,
  },
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(getFeaturesList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getFeaturesList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.featureManagerList = payload.responseArray;
    });
    builder.addCase(getFeaturesList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    builder.addCase(addFeature.pending, (state) => {
      const currentState = current(state);
      state.loading = true;
      state.error = null;
      state.featureManagerList = [...currentState.featureManagerList];
    });
    builder.addCase(addFeature.fulfilled, (state, { payload }) => {
      const currentState = current(state);
      const obj = {
        feature_id: payload.record?.featureId,
        feature_name: payload.record?.featureName,
        last_modified: payload.record?.createdAt,
      };
      const featureList = [...currentState.featureManagerList];
      featureList[featureList.length] = obj;
      state.loading = false;
      state.error = null;
      state.featureManagerList = featureList;
    });
    builder.addCase(addFeature.rejected, (state) => {
      const currentState = current(state);
      const featureList = [...currentState.featureManagerList];
      state.loading = false;
      state.error = null;
      state.featureManagerList = featureList;
    });
    builder.addCase(updateFeature.pending, (state) => {
      const currentState = current(state);
      state.loading = true;
      state.error = null;
      state.featureManagerList = [...currentState.featureManagerList];
    });
    builder.addCase(updateFeature.fulfilled, (state, { payload }) => {
      const currentState = current(state);
      const featureList = [...currentState.featureManagerList];
      const updateData = featureList.map((obj) => {
        const objectData = { ...obj };

        if (objectData.feature_id === payload.featureId) {
          objectData.feature_name = payload.featureName;
        }
        return objectData;
      });
      state.loading = false;
      state.error = null;
      state.featureManagerList = updateData;
    });
    builder.addCase(updateFeature.rejected, (state) => {
      const currentState = current(state);
      state.loading = true;
      state.error = null;
      state.featureManagerList = [...currentState.featureManagerList];
    });
    builder.addCase(deleteFeature.pending, (state) => {
      const currentState = current(state);
      state.loading = true;
      state.error = null;
      state.featureManagerList = [...currentState.featureManagerList];
    });
    builder.addCase(deleteFeature.fulfilled, (state, { payload }) => {
      const currentState = current(state);
      const updateData = currentState.featureManagerList && currentState.featureManagerList.length ? currentState.featureManagerList.filter((obj) => (obj.feature_id !== payload)) : [];
      state.loading = false;
      state.error = null;
      state.featureManagerList = updateData;
    });
    builder.addCase(deleteFeature.rejected, (state) => {
      const currentState = current(state);
      state.loading = true;
      state.error = null;
      state.featureManagerList = [...currentState.featureManagerList];
    });
  },
});

const featuresList = createSlice({
  name: 'featuresList',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeaturesList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFeaturesList.fulfilled, (state, { payload }) => {
      state.response = payload;
      state.loading = false;
    });
    builder.addCase(getFeaturesList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

const featureDetailList = createSlice({
  name: 'featureDetailList',
  initialState: { customEntity: [], nestedFeatures: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeatureDetailList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getFeatureDetailList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.customEntity = payload.customEntity;
      state.nestedFeatures = payload.nestedFeatures;
    });
    builder.addCase(getFeatureDetailList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

const customEntityList = createSlice({
  name: 'customEntityList',
  initialState: { customEntityList: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomEntityList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCustomEntityList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.customEntityList = payload.responseArray;
    });
    builder.addCase(getCustomEntityList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export {
  featureManagerList, featuresList, featureDetailList, customEntityList,
};
