import {
  Box, Stack, Tooltip, Typography,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import { InfoChip } from '../../../../components/CustomChip';
import { CustomIcon } from '../../../../components/CustomIcon';
import { DynamicTable } from '../../../../components/CustomTable';
import useStyles from '../../../../hooks/useStyles';
import styles from '../../../../layouts/pages/style';
import { toolTipText } from '../../../../utils/toolTipText';

function TitleElement({ title }) {
  return <Typography>{title}</Typography>;
}
function InfoElement({ info }) {
  return (
    <Tooltip title={info}>
      <Typography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: 1,
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
        }}
      >
        {info}
      </Typography>
    </Tooltip>
  );
}
function ValueElement({ value }) {
  if (!value) return null;
  return (
    <Stack alignItems="flex-end">
      <Typography
        sx={(theme) => ({
          width: 'max-content',
          backgroundColor: theme.palette.primary.lighter,
          p: '2px 10px',
          borderRadius: '5px',
          fontWeight: '600',
          color: theme.palette.primary.text,
        })}
      >
        {value}
      </Typography>
    </Stack>
  );
}

function CustomDefinitionCard({ handleOpen }) {
  const classes = useStyles(styles)();
  const highRiskAccountConfig = useSelector((state) => state.highRiskAccountConfig?.data);

  const columns = [{ field: 'title' }, { field: 'info', width: '80%' }, { field: 'value', align: 'right' }];
  const rows = [
    {
      title: <TitleElement title="Low Score" />,
      info: <InfoElement info={toolTipText.customDefinition.LOW_SCORE} />,
      value: <ValueElement value={highRiskAccountConfig?.highRiskAccount?.kpiScore} />,
    },
    {
      title: <TitleElement title="Low Utilization" />,
      info: <InfoElement info={toolTipText.customDefinition.LOW_UTIL} />,
      value: <ValueElement value={highRiskAccountConfig?.highRiskAccount?.licenseUtilization} />,
    },
    {
      title: <TitleElement title="Declining Score" />,
      info: <InfoElement info={toolTipText.customDefinition.DECL_SCORE} />,
      value: <ValueElement value={highRiskAccountConfig?.highRiskAccount?.kpiTrend === 'falling' && <TrendingDownIcon />} />,
    },
  ];

  return (
    <Box className={classes.cardStyles} sx={{ width: '100%' }}>
      {/* Header */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography sx={{ p: '10px', fontSize: '22px', fontWeight: 500 }}>
          High-Risk Accounts
        </Typography>
        <CustomIcon tooltip="Edit" onClick={handleOpen} Icon={EditIcon} iconSize="small" variant="outlined" sxProps={{ marginRight: 1 }} />
      </Stack>
      {/* Content */}
      <InfoChip sxProps={{ ml: '10px' }} icon label={highRiskAccountConfig?.highRiskAccount?.metric?.name ?? ''} />
      <DynamicTable columns={columns} rows={rows} />
    </Box>
  );
}

export default CustomDefinitionCard;
