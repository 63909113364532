import palette from '../../theme/palette';

const accountStatsStyle = () => ({
  cardStyles: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minHeight: '130px',
    backgroundColor: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px',
    borderRadius: '6px',
  },
  headerStyles: {
    paddingTop: 10,
    paddingBottom: 2,
    paddingLeft: 15,
    paddingRight: 15,
  },
  contentStyles: {
    paddingTop: 2,
    paddingBottom: '10px !important',
    paddingLeft: 15,
    paddingRight: 15,
  },
  textStyles: {
    fontSize: 14,
    color: palette.secondary.mainColor,
    marginBottom: 0.5,
    cursor: 'pointer',
  },
  subTextStyles: {
    fontSize: 13,
    marginBottom: 1.5,
  },
  riskTextStyles: {
    color: palette.error.light,
  },
});

export default accountStatsStyle;
