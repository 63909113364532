import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
  Grid, Stack, Typography, Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { percentage } from '../../../../utils/methods';
import { Graph } from '../../../../components/Graph';
import { BAR_GRAPH } from '../../../../utils/constants';
import { CustomLinearProgress } from '../../../../components/CustomLinearProgress';
import { MutedText } from '../../../../components/CustomTypography';

const FeaturePercentage = styled(Typography)(() => ({
  fontWeight: '400',
  fontSize: 13,
  '@media (min-width: 1500px)': {
    fontSize: 16,
  },
}));

const GridItemStyle = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}));

const FeatureHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.mainColor,
  cursor: 'pointer',
  marginRight: '10px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: 'auto',
}));

export default function FeatureUsageInfo(props) {
  const {
    siteWideLicense,
    totalActiveSeats,
    userPercent,
    accountCount,
    totalActiveAccounts,
    used,
    dontUsed,
    featureId,
    dashboard,
    lastAvailableMonthUserCount,
    userLastDate,
    operationLastDate,
    lastAvailableMonthOperationCount,
  } = props;

  const navigate = useNavigate();

  const adjustedUserPercent = Math.min(userPercent, 100);
  const lastMonth = moment().subtract(1, 'month').format("MMM'YY");

  const datasets = [
    {
      label: 'Accounts',
      data: [accountCount, totalActiveAccounts - accountCount],
      hoverOffset: 2,
      backgroundColor: [BAR_GRAPH.CompleteData, BAR_GRAPH.PartialData],
      borderColor: [BAR_GRAPH.CompleteData, BAR_GRAPH.PartialData],

    },
  ];
  const options = {
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
    onClick: (event, chartElement) => {
      const { index } = chartElement.length && chartElement[0];
      if (index === 0) {
        navigate('/account-list', {
          state: {
            filters: {
              featureUsageFilter: featureId, featureUsageMode: used, licenceStatus: 'Active', accountVisibility: true,
            },
          },
        });
      } else {
        navigate('/account-list', {
          state: {
            filters: {
              featureUsageFilter: featureId, featureUsageMode: dontUsed, licenceStatus: 'Active', accountVisibility: true,
            },
          },
        });
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const sets = ctx.chart.data.datasets;
          if (sets.indexOf(ctx.dataset) === sets.length - 1) {
            const sum = sets[0].data.reduce((a, b) => a + b, 0);
            const percent = `${percentage(value, sum)}%`;
            return percent;
          }
          return true;
        },
        color: '#fff',
        display: true,
      },
      legend: {
        display: false,
        align: 'center',
        position: 'bottom',
        padding: 15,
      },
      title: {
        display: true,
        text: `Accounts Usage ${lastMonth}`,
        padding: 15,
        align: 'center',
        position: 'bottom',
      },
    },
  };

  return (
    <GridItemStyle item xs={12}>
      {!siteWideLicense && totalActiveSeats && totalActiveSeats > 0 ? (
        <Stack px={2} pt={2} textAlign="center" alignItems="center">
          <CustomLinearProgress
            value={adjustedUserPercent}
            color={userPercent > 100 && 'success'}
            width="100%"
          />
          <FeaturePercentage variant="subtitle2">
            {`${
              adjustedUserPercent
            }% of the customer base used this feature ${userLastDate}`}
          </FeaturePercentage>
        </Stack>
      ) : null}
      <Stack padding={1} textAlign="center" alignItems="center" pt={!siteWideLicense && totalActiveSeats && totalActiveSeats > 0 ? 0 : 10}>
        <MutedText
          text={`Usage ${userLastDate}`}
        />
        <FeatureHeader variant="h4">{lastAvailableMonthUserCount}</FeatureHeader>
        <MutedText text="Users" />
      </Stack>
      <Stack padding={1} width="100%" textAlign="center" alignItems="center">
        <Box sx={{ width: '100%', height: '160px' }}>
          {dashboard
            && (
              <Graph
                type="pie"
                labels={['Use this KPI', 'Don\'t use this KPI']}
                datasets={datasets}
                options={options}
                plugins={[ChartDataLabels]}
              />
            )}
        </Box>
      </Stack>
      <Stack padding={1} textAlign="center" pb={10}>
        <MutedText text={`Operations ${operationLastDate}`} />
        <FeatureHeader variant="h4">{lastAvailableMonthOperationCount}</FeatureHeader>
        <MutedText text="Operations" />
      </Stack>
    </GridItemStyle>
  );
}
