import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  Chip,
  FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, OutlinedInput, Select,
} from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  highlightedFilter: {
    backgroundColor: 'aliceblue',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.mainColor,
      borderWidth: 2,
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.secondary.mainColor,
      borderWidth: 2,
    },
  },
}));

const renderValue = (selected, data) => {
  if (selected.length === 0) {
    return <em>Select feature(s)</em>;
  }

  const selectedItem = data.find((item) => item.value === selected[0]);

  if (selected.length === 1) {
    return <Chip label={selectedItem ? selectedItem.name : selected[0]} />;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
      <Box
        sx={{
          display: 'flex',
          maxWidth: '150px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Chip label={selectedItem ? selectedItem.name : selected[0]} />
      </Box>
      <Chip label={`+${selected.length - 1}`} />
    </Box>
  );
};

export default function SelectBox({
  name, title, data, value, open, onChange, onClick, width, highlighted, className, disabled = false, multiple = false, error, spacing = false,
}) {
  const classes = useStyles();
  const disabledProps = { inputProps: { readOnly: true } };
  return (
    <FormControl
      className={className}
      sx={{
        width: width ?? '100%',
        position: 'relative',
        m: spacing ? '8px 0 18px' : '8px 0',
        ...(disabled && { backgroundColor: '#f5f5f5', pointerEvents: 'none' }),
      }}
    >
      <InputLabel>
        {title}
      </InputLabel>
      {multiple ? (
        <Select
          style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
          multiple
          name={name}
          value={value}
          onChange={onChange}
          className={highlighted ? classes.highlightedFilter : null}
          input={<OutlinedInput label="Chip" />}
          renderValue={(selected) => renderValue(selected, data)}
          {...(disabled && disabledProps)}
        >
          {data && data.length > 0 ? (
            data.map((item) => {
              if (typeof item === 'string') {
                return (
                  <MenuItem key={item} value={item}>
                    <Checkbox checked={value.indexOf(item) > -1} />
                    <ListItemText primary={item} />
                  </MenuItem>
                );
              } if (typeof item === 'object') {
                return (
                  <MenuItem key={item.id} value={item.value}>
                    <Checkbox checked={value.indexOf(item.value) > -1} />
                    <ListItemText primary={item.name} />
                  </MenuItem>
                );
              }
              return null;
            })
          ) : null}
        </Select>
      )
        : (
          <Select
            name={name}
            value={value}
            onChange={onChange}
            className={highlighted ? classes.highlightedFilter : null}
            fullWidth
            open={open && open}
            {...(onChange ? { onChange } : { onClick })}
            {...(disabled && disabledProps)}
          >
            {data && data.length > 0 ? (
              data.map((item) => {
                if (typeof item === 'string') {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                } if (typeof item === 'object') {
                  return (
                    <MenuItem key={item.id} value={item.value}>
                      {item.name || item.value}
                    </MenuItem>
                  );
                }
                return null;
              })
            ) : null}
          </Select>
        )}
      <FormHelperText error={error} sx={{ position: 'absolute', bottom: '-1.5em' }}>
        {error && error}
      </FormHelperText>
    </FormControl>
  );
}

SelectBox.propTypes = {
  title: PropTypes.string,
};
