import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getHighRiskAccountConfig } from '../../features/Configuration/configurationAction';
import usePackageSelection from '../../modules/Accounts/components/PackageSelection/usePackageSelection';
import ActiveUserConfig from '../../modules/Configuration/components/ActiveUserConfig/ActiveUserConfig';
import {
  CustomDefinitionCard,
  CustomDefinitionDialog,
  CustomerSizeCard,
  EmailAlertSettingsCard,
  EmailAlertSettingsDialog,
  WatchTowerCard,
  WatchTowerDialog,
} from '../../modules/Configuration';
import { PackageHeader } from '../../components/CustomHeading';
import useToggle from '../../hooks/useToggle';
import useStyles from '../../hooks/useStyles';
import styles from '../../layouts/pages/style';
import { getCustomAlertDetails } from '../../features/CustomAlert/customAlertAction';
import { getNotificationSettingsDetails } from '../../features';

function Configuration() {
  const dispatch = useDispatch();
  const classes = useStyles(styles)();
  const { activePackage } = usePackageSelection();

  useEffect(() => {
    dispatch(getNotificationSettingsDetails({ packageId: activePackage?.packageId }));
    dispatch(getCustomAlertDetails({ pkgId: activePackage?.packageId }));
    dispatch(getHighRiskAccountConfig({ packageId: activePackage?.packageId }));
  }, [activePackage?.packageId]);

  const [open, setOpen] = useToggle();
  const [open1, setOpen1] = useToggle();
  const [openNotificationSetting, setOpenNotificationSetting] = useToggle();
  const customFilterData = useSelector((state) => state.getCustomAlertDetails.data);
  const notificationSettings = useSelector((state) => state.getNotificationSettingsSlice.data);

  return (
    <Grid container gap={2}>
      <Grid item xs={12} className={classes.cardStyles}>
        <PackageHeader heading="Configuration" />
      </Grid>

      <Grid container item xs={12} spacing={2}>
        {/* Show Settings */}
        <Grid container item xs={12}>
          <EmailAlertSettingsCard
            notificationSettings={notificationSettings}
            handleOpen={setOpenNotificationSetting}
          />
        </Grid>
        {/* Active User Config */}
        <Grid container item xs={12}>
          <ActiveUserConfig />
        </Grid>
        {/* Show Config */}
        <Grid container item xs={12}>
          <WatchTowerCard
            watchTowerData={customFilterData}
            handleOpen={setOpen}
          />
        </Grid>
        {/* Show High-Risk Accounts Definition */}
        <Grid container item xs={12}>
          <CustomDefinitionCard
            watchTowerData={customFilterData}
            handleOpen={setOpen1}
          />
        </Grid>

        <Grid container item xs={12}>
          <CustomerSizeCard packageId={activePackage?.packageId} />
        </Grid>

        {/* Dialog Box */}
        <EmailAlertSettingsDialog
          open={openNotificationSetting}
          packageId={activePackage?.packageId}
          handleClose={setOpenNotificationSetting}
          notificationSettings={notificationSettings}
        />
        <WatchTowerDialog
          open={open}
          handleClose={setOpen}
          watchTowerData={customFilterData}
          packageId={activePackage?.packageId}
        />
        <CustomDefinitionDialog
          open={open1}
          handleClose={setOpen1}
          watchTowerData={customFilterData}
        />
      </Grid>
    </Grid>
  );
}

export default Configuration;
