import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Tooltip,
} from 'chart.js';
import { useLayoutEffect } from 'react';

function BarChart({ data, highlight }) {
  const dataKeys = Object.keys(data);
  const modifiedLabel = dataKeys.map((elem, i) => (
    `${i === 0 ? '0' : +dataKeys[i - 1] + 1} - ${elem}`
  ));

  useLayoutEffect(() => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      LineElement,
      Tooltip,
    );
  }, []);

  const barData = {
    labels: modifiedLabel,
    datasets: [
      {
        backgroundColor: Object.keys(data || {}).map((val) => {
          if (val <= highlight[0]) {
            return '#FF6347';
          }
          if (val > highlight[1]) {
            return '#32CD32';
          }
          return '#FFD700';
        }),
        data: Object.values(data || {}),
        label: 'Accounts',
      },
    ],
  };

  const options = {
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => `Accounts: ${tooltipItem.raw}`,
          title: (tooltipItem) => `Target Metric: ${tooltipItem[0].label}`,
        },
      },
    },
  };

  return <Bar height="400px" width="1360px" data={barData} options={options} />;
}

export default BarChart;
