import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  run: false,
  stepIndex: 0,
  steps: [],
};

const tourSlice = createSlice({
  name: 'tour',
  initialState,
  reducers: {
    setSteps(state, { payload }) {
      state.run = false;
      state.stepIndex = 0;
      state.steps = payload.steps;
    },
    startTour(state) {
      state.run = true;
      state.stepIndex = 0;
    },
    stopTour(state) {
      state.run = false;
      state.stepIndex = 0;
    },
    nextStep(state) {
      state.stepIndex += 1;
    },
    previousStep(state) {
      if (state.stepIndex > 0) {
        state.stepIndex -= 1;
      }
    },
    restartTour(state, { payload }) {
      state.run = true;
      state.stepIndex = payload.stepIndex;
    },
  },
});

export const {
  setSteps,
  startTour,
  stopTour,
  restartTour,
  nextStep,
  previousStep,
} = tourSlice.actions;

export default tourSlice;
