import { Typography, styled } from '@mui/material';
import Box from '@mui/material/Box';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';

const RetryButton = styled(Box)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -59%)',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: '#4482FF',
  '& .MuiTypography': {
    fontSize: '16px',
  },
});

function RetryDashboardComponent(props) {
  const { refreshHandler } = props;
  return (
    <RetryButton onClick={refreshHandler}>
      <Typography>Retry</Typography>
      <ReplayOutlinedIcon />
    </RetryButton>
  );
}

export default RetryDashboardComponent;
