import { useMemo } from 'react';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../utils/constants';
import palette from '../../../../theme/palette';
import { transformGraphLabel } from '../../../../utils/getDate';
import { withLoadingAndOverlay } from '../../../../hoc';
import { Graph } from '../../../../components/Graph';

function HealthScoreTrendsGraph({ data, viewOption }) {
  const generateLabel = () => {
    return data?.map((entry) => transformGraphLabel(entry.labelDate, viewOption === 'month' ? viewOption : 'day'));
  };

  const healthScoreTrendData = useMemo(() => {
    return data.map((entry) => entry.data);
  }, [data]);

  const labels = useMemo(() => {
    return generateLabel();
  }, [data, viewOption]);

  const chartData = {
    labels,
    datasets: [
      {
        label: 'KPI Score',
        data: healthScoreTrendData,
        borderColor: palette.primary.text,
        pointRadius: data.map((dataItem) => {
          if (dataItem.events) {
            return 6;
          }
          return 5;
        }),
        pointBorderWidth: 4,
        pointBorderColor: data.map((dataItem) => {
          if (dataItem.events) {
            return '#AEFD6C';
          }
          return 'transparent';
        }),
        backgroundColor: palette.primary.text,
        fill: false,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        offset: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external(context) {
          let tooltipEl = document.getElementById('chartjs-tooltip');
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
          }

          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          tooltipEl.classList.remove('below', 'no-transform');

          if (tooltipModel.body) {
            const dataFromCurrentElement = tooltipModel.dataPoints[0];
            const currentElementIndex = dataFromCurrentElement.dataIndex;
            const { events } = data[currentElementIndex];
            const formattedValue = dataFromCurrentElement.formattedValue.trim();
            const innerHTML = `
              <div style="width: 250px; background-color: rgba(0, 0, 0, 0.7); border-radius: 5px; box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); padding: 3px;">
                  <div style="padding: 3px; background-color: transparent; border-top-left-radius: 5px; border-top-right-radius: 5px;">
                      <span style="color: white; font-size: 15px;">${dataFromCurrentElement.label}</span>
                  </div>
                  <hr style="margin: 0; border-color: white;">
                  <div style="padding: 3px;">
                  <p style="color: white; margin-left: 4px; font-size: 14px; display: flex; align-items: center; gap: 5px">
                  <span style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: #4482FF;"></span>
                  KPI Score : ${formattedValue}
                </p>
                ${events ? `
                  <p style="color: white; margin-left: 4px; font-size: 14px; display: flex; align-items: center; gap: 5px;">
                    <span style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: #AEFD6C;"></span>
                    Events : 
                  </p>
                          <div style="padding-left: 15px;">
                              ${events.licenseStatus ? `<p style="color: ${events.statusFlag ? '#AEFD6C' : 'white'}; font-size: 13px; "></span> License Status: ${events.licenseStatus}</p>` : ''}
                              ${events.versionNumber ? `<p style="color: ${events.versionFlag ? '#AEFD6C' : 'white'}; font-size: 13px; "></span> Version: ${events.versionNumber}</p>` : ''}
                              ${events.updatedAt ? `<p style="color: white; font-size: 13px; "></span> Current: ${moment(events.updatedAt).format(DATE_FORMAT.DAY_DATE_GRAPH2)}</p>` : ''}
                              ${events.renewalDate ? `<p style="color:  ${events.expFlag ? '#AEFD6C' : 'white'}; font-size: 13px; "></span> Expiration: ${moment(events.renewalDate).format(DATE_FORMAT.DAY_DATE_GRAPH2) ?? ' - - '}</p>` : ''}
                          </div>` : ''}
                  </div>
              </div>`;

            tooltipEl.querySelector('table').innerHTML = innerHTML;
          }

          const position = context.chart.canvas.getBoundingClientRect();

          tooltipEl.style.opacity = '1';
          tooltipEl.style.position = 'absolute';

          const tooltipWidth = tooltipEl.offsetWidth;
          let left = position.left + window.pageXOffset + tooltipModel.caretX;
          if (left + tooltipWidth > position.right) {
            left -= (left + tooltipWidth - position.right);
          }

          tooltipEl.style.left = `${left}px`;

          tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
          tooltipEl.style.padding = `${tooltipModel.padding}px ${tooltipModel.padding}px`;
          tooltipEl.style.pointerEvents = 'none';
        },

      },

    },
  };

  return (
    <Graph type="line" labels={chartData.labels} datasets={chartData.datasets} options={options} />
  );
}

export default withLoadingAndOverlay(HealthScoreTrendsGraph);
