import { LinearProgress } from '@mui/material';

function CustomLinearProgress({
  value, size, width, color,
}) {
  const sxProps = {
  };

  if (size) {
    sxProps.height = size;
    sxProps.borderRadius = size * 2;
  }

  if (width) {
    sxProps.width = width;
  }

  return (
    <LinearProgress
      color={color || 'secondary'}
      variant="determinate"
      value={value}
      sx={sxProps}
    />
  );
}

export default CustomLinearProgress;
