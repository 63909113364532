import { useState } from 'react';

function useProfile() {
  const [activeTab, setActiveTab] = useState('about');

  const handleChange = (event, selectedTab) => {
    setActiveTab(selectedTab);
  };

  return {
    activeTab,
    handleChange,
  };
}

export default useProfile;
