import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../../features/SnackBar/snackBarSlice';
import { createTenant } from '../../../features/Tenant/tenantAction';
import { CustomDialogBox } from '../../../components/CustomDialogBox';
import TenantForm from './TenantForm';

const validationSchema = Yup.object().shape({
  tenant: Yup.string().required('Tenant name is required!'),
});

const initialValues = {
  tenant: '',
  status: 'active',
  expiry: null,
};

function TenantDialog(props) {
  const {
    formRef,
    openDialog,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  const handleSubmit = (values) => {
    dispatch(createTenant({ data: values })).then((res) => {
      const { error, payload: { message } } = res;

      if (!error) {
        dispatch(openSnackbar({ open: true, message, type: 'success' }));
        return;
      }

      dispatch(openSnackbar({ open: true, message: 'Failed to create tenant!', type: 'error' }));
    });

    handleClose();
  };

  return (
    <CustomDialogBox
      formRef={formRef}
      title="Create Tenant"
      open={openDialog}
      handleClose={handleClose}
      content={(
        <TenantForm
          formRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        />
      )}
      actions={{ title: 'Create Tenant' }}
    />
  );
}

export default TenantDialog;
