import { Box, Typography } from '@mui/material';
import palette from '../../../theme/palette';

function DynamicHeader({ heading, subHeading }) {
  return (
    <Box style={{ display: 'flex', alignItems: 'center' }}>
      <Typography variant="h4" gutterBottom>{heading}</Typography>
      {subHeading && (
      <Typography
        gutterBottom
        style={{
          display: 'inline',
          color: palette.info.dark,
          verticalAlign: 'middle',
          marginLeft: '8px',
          fontSize: '1.25rem',
        }}
      >
        (
        {subHeading}
        )
      </Typography>
      )}
    </Box>
  );
}

export default DynamicHeader;
