import { FormDialogBox } from '../../../../components/CustomDialogBox';
import TenantDetailForm from './TenantDetailForm';

import useTenantDetailDialog from './useTenantDetailDialog';

function TenantDetailDialog(props) {
  const {
    dialogData, openDialog, onClose, tenantId,
  } = props;

  const {
    initialValues, validations, onSubmit,
  } = useTenantDetailDialog({
    tenantId,
    onClose,
    dialogData,
  });

  return (
    <FormDialogBox
      title={dialogData.title}
      open={openDialog}
      handleClose={onClose}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validations}
      formContent={<TenantDetailForm type={dialogData.type} />}
      formAction={{ title: 'Update' }}
    />
  );
}

export default TenantDetailDialog;
