import { useState, useEffect } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getCompletedDataLabel, getMomentDate } from '../../../../utils/getDate';

const useEngagementSection = ({ monthObject, weekObject }) => {
  const [recentMonth, setRecentMonth] = useState(0);
  const [monthDiff, setMonthDiff] = useState(0);
  const [weekDiff, setWeekDiff] = useState(0);
  const [recentWeek, setRecentWeek] = useState(0);
  const [monthOp, setMonthOp] = useState('~');
  const [weekOp, setWeekOp] = useState('~');

  useEffect(() => {
    const keys = Object.keys(monthObject ?? {}) || [];
    const completedDate = getCompletedDataLabel(keys, 'month') ?? '';
    const completedMonth = monthObject?.[completedDate] ?? 0;
    const secondLastMonth = monthObject?.[getMomentDate(completedDate)?.subtract(1, 'months').format('MM-YYYY')] ?? 0;
    setRecentMonth(completedMonth);
    if ((secondLastMonth === 0 && completedMonth === 0) || (secondLastMonth === completedMonth)) {
      setMonthOp('~');
    }
    if (secondLastMonth > completedMonth) {
      setMonthOp('-');
      setMonthDiff(secondLastMonth - completedMonth);
    }
    if (secondLastMonth < completedMonth) {
      setMonthOp('+');
      setMonthDiff(completedMonth - secondLastMonth);
    }
  }, [monthObject]);

  useEffect(() => {
    const keys = Object.keys(weekObject || {}) || [];
    const completedDate = getCompletedDataLabel(keys, 'week');
    const completedWeek = weekObject?.[completedDate] || 0;
    const secondLastWeek = weekObject?.[getMomentDate(completedDate).subtract(7, 'days').format('MM-DD-YYYY')] || 0;
    setRecentWeek(completedWeek);

    if ((secondLastWeek === 0 && completedWeek === 0) || (secondLastWeek === completedWeek)) {
      setWeekOp('~');
    }
    if (secondLastWeek > completedWeek) {
      setWeekOp('-');
      setWeekDiff(secondLastWeek - completedWeek);
    }
    if (secondLastWeek < completedWeek) {
      setWeekOp('+');
      setWeekDiff(completedWeek - secondLastWeek);
    }
  }, [weekObject]);

  const opObject = {
    '+': <KeyboardArrowUpIcon style={{ color: 'green', width: '0.9em', display: 'inline-table' }} />,
    '-': <KeyboardArrowDownIcon style={{ color: 'red', width: '0.9em', display: 'inline-table' }} />,
    '~': '-',
  };

  return {
    recentMonth,
    recentWeek,
    monthOp,
    weekOp,
    opObject,
    monthDiff,
    weekDiff,
  };
};

export default useEngagementSection;
