import { createSlice } from '@reduxjs/toolkit';
import { getHighRiskAccountConfig, setupHighRiskAccount } from './configurationAction';

const highRiskAccountConfigSlice = createSlice({
  name: 'highRiskAccountConfig',
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get
    builder.addCase(getHighRiskAccountConfig.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getHighRiskAccountConfig.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.data = payload.data;
    });
    builder.addCase(getHighRiskAccountConfig.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // Create / Update
    builder.addCase(setupHighRiskAccount.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(setupHighRiskAccount.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.data = payload.data;
    });
    builder.addCase(setupHighRiskAccount.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export { highRiskAccountConfigSlice };
