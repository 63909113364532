import { createAsyncThunk } from '@reduxjs/toolkit';

import request from '../../modules/Request/request';

export const savePboConnectionCode = createAsyncThunk(
  'savePboConnectionCode',
  async (data, { rejectWithValue }) => {
    const response = await request('/save-pbo-connection-code', {
      method: 'POST',
      body: data,
    });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message ? response.error.message : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const getPboConnectionDetails = createAsyncThunk(
  'getPboConnectionDetails',
  async (object, { rejectWithValue }) => {
    const response = await request('/get-pbo-connection-details', { method: 'GET' });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message ? response.error.message : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const disconnectPboConnection = createAsyncThunk(
  'disconnectPboConnection',
  async (object, { rejectWithValue }) => {
    const response = await request('/disconnect-pbo-connection', { method: 'DELETE' });
    if (response.success) {
      return response.data;
    }
    const error = response.error?.message || response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const refreshPboConnection = createAsyncThunk(
  'refreshPboConnection',
  async (object, { rejectWithValue }) => {
    const response = await request('/refresh-pbo-connection', { method: 'POST' });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message ? response.error.message : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);
