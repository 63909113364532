import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getHighRiskAccountConfig } from '../../features/Configuration/configurationAction';
import usePackageSelection from '../../modules/Accounts/components/PackageSelection/usePackageSelection';
import DashboardCards from '../../components/DashboardCard/DashboardCards';
import { useDashboardHealthScore } from './useDashboardHealthScore';
import { DashboardHealthScoreCard } from '../../modules/DashboardHealthScore';

export function DashboardHealthScore({ title, refresh, type }) {
  const {
    riskRows,
    accountNum,
    loading,
    error,
    expiredRows,
    expiredAccountNum,
    expiredInMonths,
    threeMonthsLater,
    currentDate,
    riskAccountsColumnInfo,
    expiredAccountColumnInfo,
    decliningAccountColumnInfo,
    trendAccountColumnInfo,
    decliningRows,
    trendRows,
    decliningNum,
    trendAccountNum,
    loadingState,
    setLoadingState,
  } = useDashboardHealthScore();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activePackage } = usePackageSelection();

  useEffect(() => {
    dispatch(getHighRiskAccountConfig({ packageId: activePackage?.packageId }));
    setLoadingState(true);
  }, [activePackage?.packageId]);

  const highRiskAccountConfig = useSelector((state) => state.highRiskAccountConfig?.data);

  const accountFilter = {
    riskAccounts: {
      count: `${accountNum} accounts`,
      subHeading: highRiskAccountConfig?.highRiskAccount?.metric?.name ?? 'Accounts with the Score 0 - 30',
      filters: {
        filters: {
          licenceStatus: 'Active',
          accountVisibility: true,
          condition: highRiskAccountConfig?.highRiskAccount?.condition ?? 'healthScore <= 30',
        },
        isNavigatedFromRiskAccount: true,
      },
      columns: riskAccountsColumnInfo,
      rows: riskRows,
    },
    expiredAccounts: {
      count: `${expiredAccountNum} accounts`,
      subHeading: `Accounts expiring in ${expiredInMonths} months`,
      filters: {
        filters: {
          renewalStartDate: currentDate, renewalEndDate: threeMonthsLater, licenceStatus: 'Active', accountVisibility: true,
        },
        showAdvanceFilter: true,
      },
      columns: expiredAccountColumnInfo,
      rows: expiredRows,
    },
    decliningAccounts: {
      count: `${decliningNum} accounts`,
      subHeading: 'Accounts Declining KPI Score',
      filters: {
        filters: {
          healthScoreTrend: 'falling', licenceStatus: 'Active', accountVisibility: true,
        },
        showAdvanceFilter: true,
      },
      columns: decliningAccountColumnInfo,
      rows: decliningRows,
    },
    trendingAccounts: {
      count: `${trendAccountNum} accounts`,
      subHeading: 'Accounts KPI Score Trend',
      filters: {
        filters: {
          healthScoreTrend: 'rising', licenceStatus: 'Active', accountVisibility: true,
        },
        showAdvanceFilter: true,
      },
      columns: trendAccountColumnInfo,
      rows: trendRows,
    },
  };

  const handleNavigation = () => {
    navigate('/account-list', {
      state: accountFilter[type]?.filters,
    });
  };

  return (
    <DashboardCards
      title={title}
      count={accountFilter[type]?.count}
      subHeading={accountFilter[type]?.subHeading}
      showButton
      buttonText="View Accounts"
      onClick={handleNavigation}
    >
      <DashboardHealthScoreCard
        message="No Rows"
        loading={loading || loadingState}
        error={error}
        refresh={refresh}
        data={accountFilter[type]?.rows}
        columns={accountFilter[type]?.columns}
        rows={accountFilter[type]?.rows}
      />
    </DashboardCards>
  );
}
