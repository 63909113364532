import { ToggleButton, ToggleButtonGroup } from '@mui/material';

function ToggleCondition({ value, onChange }) {
  return (
    <ToggleButtonGroup
      exclusive
      color="primary"
      value={value}
      onChange={onChange}
    >
      <ToggleButton sx={{ width: '60px', padding: '5px !important' }} value="&&">AND</ToggleButton>
      <ToggleButton sx={{ width: '60px', padding: '5px !important' }} value="||">OR</ToggleButton>
    </ToggleButtonGroup>
  );
}

export default ToggleCondition;
