import { createAsyncThunk } from '@reduxjs/toolkit';

import request from '../../modules/Request/request';

export const getAccountHealthScore = createAsyncThunk(
  'accountHealthScore',
  async ({
    packageId, orgId, isShare, tenantId, accountOrgId,
  }, { rejectWithValue }) => {
    const reqPayload = {};
    const query = {};
    reqPayload.method = 'GET';
    reqPayload.params = [orgId, packageId];
    if (isShare) {
      query.isShare = isShare;
      if (accountOrgId) {
        query.accountOrgId = accountOrgId;
      } else {
        query.tenantId = tenantId;
      }
    }
    reqPayload.query = query;
    const response = await request('/health-score-account', reqPayload);
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const setAccPrimaryHealthScore = createAsyncThunk(
  'updateHealthScore',
  async (object, { rejectWithValue }) => {
    // packageId, orgId, healthScoreId, healthScore
    const response = await request('/health-score/account-primary', { method: 'PUT', body: object });
    if (response.success) {
      return response.data;
    }
    const error = response?.error?.message || response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);
