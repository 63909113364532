import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid } from '@mui/material';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getHighRiskAccountConfig } from '../../features/Configuration/configurationAction';
import DashboardCards from '../../components/DashboardCard/DashboardCards';
import { getAccountsList } from '../../features';
import usePackageSelection from '../../modules/Accounts/components/PackageSelection/usePackageSelection';
import { Graph } from '../../components/Graph';
import { TITLES } from '../../utils/constants';
import { PackageHeader } from '../../components/CustomHeading';
import useStyles from '../../hooks/useStyles';
import styles from '../../layouts/pages/style';
import useScoreInsights from './useScoreInsights';

export default function ScoreInsights({ permissions, isShare }) {
  const {
    accountAgeLabels,
    accountAgeDatasets,
    accountAgeOptions,
    scoreCategoryLabels,
    scoreCategoryDatasets,
    seatsCategoryLabels,
    seatsCategoryDatasets,
    pieChartOptions,
    handleClick,
  } = useScoreInsights();

  const { activePackage } = usePackageSelection();
  const dispatch = useDispatch();

  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getAccountsList({ packageId: activePackage.packageId }));
      dispatch(getHighRiskAccountConfig({ packageId: activePackage.packageId }));
    }
  }, [activePackage?.packageId]);

  const classes = useStyles(styles)();

  return (
    <Grid container gap={2}>
      <Grid item xs={12} className={classes.cardStyles}>
        <PackageHeader heading={TITLES.SCORE_INSIGHTS} />
      </Grid>

      <Grid container item xs={12} justifyContent="space-between" spacing={2}>
        <Grid item xs={12} lg={6}>
          <DashboardCards title="KPI Score">
            <Box height="auto" width="min(80%, 400px)" marginX="auto">
              <Graph
                type="pie"
                labels={scoreCategoryLabels}
                datasets={scoreCategoryDatasets}
                options={pieChartOptions}
                plugins={[ChartDataLabels]}
              />
            </Box>
          </DashboardCards>
        </Grid>

        <Grid item xs={12} lg={6}>
          <DashboardCards
            title="Size Of Customers"
            showButton={(permissions.write && !isShare)}
            buttonText="Settings"
            onClick={handleClick}
          >
            <Box height="auto" width="min(80%, 400px)" marginX="auto">
              <Graph
                type="pie"
                labels={seatsCategoryLabels}
                datasets={seatsCategoryDatasets}
                options={pieChartOptions}
                plugins={[ChartDataLabels]}
              />
            </Box>
          </DashboardCards>
        </Grid>

        <Grid item xs={12}>
          <DashboardCards title="Age Of Accounts">
            <Graph
              type="line"
              height="400px"
              labels={accountAgeLabels}
              datasets={accountAgeDatasets}
              options={accountAgeOptions}
            />
          </DashboardCards>
        </Grid>
      </Grid>
    </Grid>
  );
}
