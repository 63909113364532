import {
  Button, Stack, TextField, Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { formikForm } from '../../../../hoc';
import { InfoChip } from '../../../../components/CustomChip';
import { identifyAndConvertDate } from '../../../../utils/formatTime';
import { DATE_FORMAT } from '../../../../utils/constants';

function Remarks({
  formik, setNewRemarksValue, actions, allRemarks = [],
}) {
  const {
    handleChange, values, getIn,
  } = formik;

  useEffect(() => {
    setNewRemarksValue(getIn(values, 'remarksField'));
  }, [values]);

  const handleActionClick = () => {
    actions.onClick();
  };

  return (
    <>
      <TextField
        name="remarksField"
        variant="outlined"
        margin="normal"
        fullWidth
        multiline
        rows={3}
        value={getIn(values, 'remarksField')}
        onChange={handleChange}
      />
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginTop={1}
        marginBottom={2}
      >

        <InfoChip icon label="Remarks will be visible to everyone." />
        <Button variant="contained" size="small" mt={1} onClick={handleActionClick}>{actions.title}</Button>
      </Stack>
      {allRemarks && allRemarks.length
        ? allRemarks.map((object) => {
          return (
            <Stack
              spacing={2}
              direction="column"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                display: 'flex',
                border: '1px solid rgba(145, 158, 171, 0.32)',
                padding: 1,
                borderRadius: 1,
                marginTop: 1,
              }}
              key={object.id}
            >
              <Stack spacing={2} width="95%" direction="row" alignItems="center" justifyContent="space-between">
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 700,
                  }}
                >
                  {object?.remarkedBy?.name || object?.remarkedBy?.email}
                </Typography>
                <Typography
                  sx={{
                    fontSize: 12,
                  }}
                >
                  {identifyAndConvertDate(object.updatedAt ? object.updatedAt.slice(0, 10) : null, DATE_FORMAT.DAY_DATE_GRAPH, 'YYYY-MM-DD')}
                </Typography>
              </Stack>
              <Typography
                sx={{
                  display: 'flex',
                  width: '95%',
                  whiteSpace: 'normal',
                  wordBreak: 'break-word',
                  fontSize: 14,
                }}
              >
                {object.remark}
              </Typography>
            </Stack>
          );
        })
        : null}
    </>
  );
}

export default formikForm(Remarks);
