import { Container } from '@mui/material';
import { ScoreInsights } from '../sections/ScoreInsights';
import Metatags from '../components/MetaTags/MetaTags';

function ScoreInsightsPage({ isShareableScreens, permissions }) {
  return (
    <>
      <Metatags metaTagName="Score Insights | App Insights" />
      <Container maxWidth="xl">
        <ScoreInsights isShare={isShareableScreens} permissions={permissions} />
      </Container>
    </>
  );
}

export default ScoreInsightsPage;
