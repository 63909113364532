import {
  useCallback,
  useEffect,
  useMemo, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { orderBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountsList } from '../../features';
import usePackageSelection from '../../modules/Accounts/components/PackageSelection/usePackageSelection';
import { getDate } from '../../utils/getDate';

const initialFilterValues = {
  timeFrame: '30days',
  account: '- all -',
};

const currentDate = getDate();

function useCustomObject() {
  const location = useLocation();
  const accountListData = useSelector((state) => state.accountsList);
  const [selectedFilters, setSelectedFilters] = useState(location?.state
    ? {
      timeFrame: '30days',
      account: location?.state?.orgId,
    } : initialFilterValues);
  const [defaultValue] = useState(location?.state ? { id: location?.state?.orgId, value: location?.state?.orgId, name: location?.state?.accountName } : { id: '- all -', name: '- all -' });
  // State to store API data ----->
  const [accountList, setAccountList] = useState(location?.state ? [{ id: location?.state?.orgId, value: location?.state?.orgId, name: location?.state?.accountName }] : [{ id: '- all -', name: '- all -' }]);
  const { activePackage } = usePackageSelection();
  const dispatch = useDispatch();

  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getAccountsList({ packageId: activePackage.packageId }));
    }
  }, [activePackage?.packageId]);

  // State setter functions ----->
  const setAccountListData = () => {
    const { accountsList } = accountListData;
    const filterAccount = [];
    const orgIdAccount = [];
    if (accountsList?.length) {
      accountsList.forEach((acc) => {
        const isActive = acc.status === 'Active';
        const isNeverExpire = acc.renewalDate === null;
        const isRenewalDateValid = acc.renewalDate && getDate(acc.renewalDate) >= currentDate.setHours(0, 0, 0, 0);

        if (acc.visibility && (isActive && (isRenewalDateValid || isNeverExpire))) {
          const object = {};
          object.value = acc.orgId;
          object.name = acc.accountName || acc.orgId;
          object.id = acc.orgId;
          if (!acc.accountName) {
            orgIdAccount.push(object);
          } else {
            filterAccount.push(object);
          }
        }
      });
    }
    const sortedData = orderBy(filterAccount, ['name'], ['asc']);
    setAccountList([...sortedData, ...orgIdAccount]);
  };

  useEffect(() => {
    setAccountListData();
  }, [accountListData.accountsList]);

  // To handle filters
  const dropDown = useMemo(() => {
    const timeFrame = [
      { id: 1, value: '15days', name: '15 Days' },
      { id: 2, value: '30days', name: '30 Days' },
      { id: 3, value: '60days', name: '60 Days' },
    ];
    const accountMenuList = accountList;

    return { timeFrame, accountMenuList };
  }, [accountList]);
  const handleFilterChange = useCallback(({ target: { name, value } }) => {
    const updateFilters = (newFilters) => {
      setSelectedFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    updateFilters({ [name]: value });
  }, []);

  return {
    dropDown,
    selectedFilters,
    defaultValue,
    handleFilterChange,
  };
}

export default useCustomObject;
