import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const useActiveUsers = () => {
  const [activeUsers, setActiveUsers] = useState({
    dayObject: [],
    weekObject: [],
    monthObject: [],
    error: '',
    loading: true,
  });

  const activeUsersData = useSelector((state) => state.activeUsers);

  const setActiveUsersData = () => {
    setActiveUsers({
      ...activeUsers,
      ...activeUsersData,
      error: activeUsersData.error,
    });
  };

  useEffect(() => {
    setActiveUsersData();
  }, [activeUsersData]);

  return { activeUsers };
};

export default useActiveUsers;
