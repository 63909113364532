import {
  Box, Button, Card, CardHeader, FormControl, MenuItem, Select, Typography,
} from '@mui/material';
import useStyles from '../../hooks/useStyles';
import styles from './style';

export default function DashboardCards(props) {
  const {
    children = null,
    title = '',
    count = null,
    subHeading = '',
    onClick = () => {},
    buttonText = '',
    showButton = false,
    showHeader = true,
    graphType = false,
    handleSelection = () => {},
    error,
    viewOption,
    setViewOption,
    view,
    action = null,
  } = props;

  const classes = useStyles(styles)();

  return (
    <Card className={showHeader ? classes.cardStyle : classes.noHeaderStyle}>
      {showHeader && (
        <Box className={classes.boxStyle}>
          <Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                gap: 1,
              }}
            >
              <CardHeader title={title} style={{ padding: 0 }} />
              {count && <Typography className={classes.subHeaderStyle}>{count}</Typography>}
            </Box>
            {subHeading && (
              <Typography className={classes.subHeaderStyle}>
                {subHeading}
              </Typography>
            )}
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            {(graphType || view) && !error && (
              <FormControl variant="outlined" style={{ height: '35px' }}>
                <Select
                  value={viewOption}
                  onChange={(e) => setViewOption(e.target.value)}
                  style={{ height: 'inherit' }}
                >
                  <MenuItem value="month">Monthly</MenuItem>
                  <MenuItem value="week">Weekly</MenuItem>
                </Select>
              </FormControl>
            )}
            {graphType && !error && (
            <FormControl variant="outlined" style={{ height: '35px' }}>
              <Select
                value={graphType}
                onChange={(event) => handleSelection(event)}
                style={{ height: 'inherit' }}
              >
                <MenuItem value="users">Users</MenuItem>
                <MenuItem value="operations">Operations</MenuItem>
              </Select>
            </FormControl>
            )}
            {action && action}
            {showButton && (
              <Button variant="outlined" onClick={onClick}>
                {buttonText}
              </Button>
            )}
          </Box>
        </Box>
      )}
      { children }
    </Card>
  );
}
