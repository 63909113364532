import { Container } from '@mui/material';
import { AccountDetail } from '../sections/AccountDetail';
import Metatags from '../components/MetaTags/MetaTags';

export default function AccountDetailsPage(props) {
  const {
    isShareableScreens, permissions, userInfo, tenantId, isSalesforce, organizationId,
  } = props;
  return (
    <>
      <Metatags metaTagName="Account&apos;s Dashboard | App Insights" />
      <Container maxWidth="xl">
        <AccountDetail isShareableScreens={isShareableScreens} permissions={permissions} userInfo={userInfo} tenantId={tenantId} isSalesforce={isSalesforce} accountOrgId={organizationId} />
      </Container>
    </>
  );
}
