import { generateGraphLabels, getPartialDataLabel } from '../../../../utils/getDate';
import { DASHBOARD_CARDS, partialDataBackground } from '../../../../utils/chartConfigs';
import { withLoadingAndOverlay } from '../../../../hoc';
import { Graph } from '../../../../components/Graph';

function ActiveUsersCard({ data = {}, more, viewOption = 'month' }) {
  const labels = Object.keys(data ?? {});
  const values = Object.values(data ?? {});
  const graphLabels = generateGraphLabels(labels, viewOption);
  const partialDataLabel = getPartialDataLabel(labels, viewOption);

  const datasets = [
    {
      ...DASHBOARD_CARDS.BAR_DATASET,
      data: values,
      backgroundColor: partialDataBackground(labels, partialDataLabel),
    },
  ];
  const options = {
    ...DASHBOARD_CARDS.OPTIONS,
    onHover: (event, chartElement) => {
      if (chartElement[0]) {
        event.native.target.style.cursor = 'default';
      }
    },
  };

  return (
    <Graph type="bar" labels={graphLabels} datasets={datasets} options={options} height={more ? null : 350} />
  );
}

export default withLoadingAndOverlay(ActiveUsersCard);
