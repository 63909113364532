import { useCallback, useState } from 'react';

const useToggle = (defaultState = false) => {
  const [value, setValue] = useState(defaultState);

  const toggle = useCallback((forcedVal) => {
    if (typeof forcedVal === 'boolean') {
      setValue(forcedVal);
    } else {
      setValue((prev) => !prev);
    }
  }, [setValue]);

  return [value, toggle];
};

export default useToggle;
