import { createSlice, current } from '@reduxjs/toolkit';
import {
  disconnectPboConnection, getPboConnectionDetails, refreshPboConnection, savePboConnectionCode,
} from './PboConnectionAction';

const pboConnectionSlice = createSlice({
  name: 'pboConnection',
  initialState: {
    loading: false,
    error: null,
    data: {},
  },
  reducers: {
    setPboConnectionLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(savePboConnectionCode.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(savePboConnectionCode.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.data = payload.data;
    });
    builder.addCase(savePboConnectionCode.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

const pboConnectionDetailsSlice = createSlice({
  name: 'pboConnectionDetailsSlice',
  initialState: {
    loading: false,
    error: null,
    data: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPboConnectionDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.type = 'get';
    });
    builder.addCase(getPboConnectionDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.data = payload.data;
      state.type = null;
    });
    builder.addCase(getPboConnectionDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.type = null;
    });
    builder.addCase(disconnectPboConnection.pending, (state) => {
      const currentState = current(state);
      state.loading = true;
      state.error = null;
      state.data = currentState.data;
      state.type = 'disconnect';
    });
    builder.addCase(disconnectPboConnection.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.data = payload.data;
      state.type = null;
    });
    builder.addCase(disconnectPboConnection.rejected, (state, action) => {
      const currentState = current(state);
      state.loading = false;
      state.error = action.payload;
      state.data = currentState.data;
      state.type = null;
    });
    builder.addCase(refreshPboConnection.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.type = 'refresh';
    });
    builder.addCase(refreshPboConnection.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.data = payload.data;
      state.type = null;
    });
    builder.addCase(refreshPboConnection.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.type = null;
    });
  },
});

export const { setPboConnectionLoading } = pboConnectionSlice.actions;
export { pboConnectionSlice, pboConnectionDetailsSlice };
