import moment from 'moment';
import {
  Box, Grid, List, ListItemButton, Stack, Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MutedText } from '../../../components/CustomTypography';

const today = moment().startOf('day');
const end = today.clone().add(13, 'days');

function CustomFilterContent({ data, fixedWatchList }) {
  const navigate = useNavigate();

  const handleWatchList = (metric) => {
    const state = {
      filters: {
        licenceStatus: 'Active',
        accountVisibility: true,
      },
      watchListItem: metric,
    };

    switch (metric) {
      case 'expiringSoon': {
        state.filters.renewalStartDate = today.toDate();
        state.filters.renewalEndDate = end.toDate();
        state.showAdvanceFilter = true;
        break;
      }

      case 'lowLicenseUtilization':
        state.filters.licenceAssignment = 'Less than 25%';
        break;

      case 'lowHealthScore':
        state.filters.healthScoreRange = [0, 30];
        state.showAdvanceFilter = true;
        break;

      case 'decliningHealthScore': {
        state.filters.healthScoreTrend = 'falling';
        state.showAdvanceFilter = true;
        break;
      }

      default:
        break;
    }

    navigate('/account-list', { state });
  };

  return (
    <Grid container direction="column">
      {/* Watch List */}
      <Grid item>
        <List>
          <MutedText text="Watch List" sxProps={{ mx: 2, mt: 1 }} />
          {fixedWatchList?.map((item, i) => (
            !item.hidden ? (
              <Box key={item.id}>
                <ListItemButton
                  disabled={item.disabled}
                  onClick={() => handleWatchList(item.id)}
                  sx={(theme) => ({
                    background: i % 2 !== 0 && theme.palette.grey[100],
                  })}
                >
                  <Stack justifyContent="space-between" gap={2} direction="row" width="100%" alignItems="center">
                    <Typography>{item.text}</Typography>
                    <MutedText
                      text={item.count}
                      sxProps={{
                        color: 'primary.text',
                        backgroundColor: 'primary.lighter',
                        borderRadius: '25px',
                        padding: '2px 8px',
                      }}
                    />
                  </Stack>
                </ListItemButton>
              </Box>
            ) : null
          ))}
        </List>
      </Grid>

      {/* Alerts List */}
      {data?.length
        ? (
          <Grid item>
            <List>
              <MutedText text="Alerts" sxProps={{ mx: 2 }} />
              {data.map((item, i) => (
                <Box key={item.id}>
                  <ListItemButton
                    onClick={item.onClick}
                    sx={(theme) => ({
                      background: i % 2 === 0 && theme.palette.grey[100],
                    })}
                  >
                    {item.text}
                  </ListItemButton>
                </Box>
              ))}
            </List>
          </Grid>
        )
        : null}
    </Grid>
  );
}

export default CustomFilterContent;
