import { makeStyles } from '@mui/styles';
import {
  useEffect, useLayoutEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usePackageSelection from '../../../Accounts/components/PackageSelection/usePackageSelection';
import { getHealthScoreRuleGraphData } from '../../../../features/HealthScore/HealthScoreAction';

export const useRuleComponent = ({
  ruleData, featureManagerListData, values, setFieldValue, insert, index,
}) => {
  const [str, setStr] = useState('');
  const [ruleLabel, setRuleLabel] = useState('');
  const { activePackage } = usePackageSelection();
  const packageId = activePackage && activePackage.packageId;
  const [isRuleComplete, setIsRuleComplete] = useState(false);
  const stateData = useSelector((state) => state.healthScoreGraph.healthScoreGraphData);
  const dispatch = useDispatch();
  const [graphData, setGraphData] = useState([]);
  const [toggle, setToggle] = useState(false);
  const useStyles = makeStyles(() => ({
    histogramContainer: {
      opacity: 1,
      maxHeight: 600,
      overflow: 'hidden',
      transition: 'opacity 0.3s ease, max-height 0.3s ease',
    },
    histogramClosed: {
      opacity: 0,
      maxHeight: 0,
      overflow: 'hidden',
      transition: 'opacity 0.3s ease, max-height 0.3s ease',
    },
  }));
  const {
    ruleType, ruleOption, targetMetric, threshold, rulePoints, isOpenGraph,
  } = ruleData;
  useMemo(() => {
    if (!ruleData) {
      return;
    }

    let newStr = '';

    if (targetMetric) {
      newStr += targetMetric;
    }

    const featureSelected = featureManagerListData.find((item) => item?.feature_id === ruleOption);
    if (ruleOption && featureSelected) {
      newStr += ` for "${featureSelected.feature_name}"`;
    }

    if (Array.isArray(threshold) && threshold.length === 2) {
      newStr += `. 0 points for value below ${threshold[0]} , 100 for value above ${threshold[1]}.`;
    }

    if (newStr !== str) {
      setStr(newStr);
    }
  }, [ruleData, ruleType, ruleOption, featureManagerListData, targetMetric, threshold, rulePoints]);

  useEffect(() => {
    setRuleLabel(str);
  }, [ruleData, ruleType, ruleOption, featureManagerListData, targetMetric, threshold, rulePoints]);
  useEffect(() => {
    setFieldValue(`healthScoreRules[${index}].ruleLabel`, ruleLabel);
  }, [ruleLabel]);

  useEffect(() => {
    if (!threshold?.includes('') && rulePoints && threshold[0] < threshold[1]) {
      setIsRuleComplete(true);
    } else {
      setIsRuleComplete(false);
    }
  }, [threshold, rulePoints]);

  useLayoutEffect(() => {
    if (stateData) {
      setGraphData(stateData);
    }
  }, [stateData]);

  useLayoutEffect(() => {
    if (isOpenGraph && ruleType && targetMetric?.length) {
      if (ruleType === 'Package Engagement') {
        dispatch(
          getHealthScoreRuleGraphData({
            packageId,
            body: { ruleType, targetMetric },
          }),
        );
      }
      if (ruleType === 'Feature Usage' && ruleOption?.length) {
        dispatch(
          getHealthScoreRuleGraphData({
            packageId,
            body: { ruleType, ruleOption, targetMetric },
          }),
        );
      }
    }
  }, [ruleOption, targetMetric, isOpenGraph]);

  const handleViewGraph = (idx, val) => {
    values.healthScoreRules.forEach((element, indx) => {
      if (indx === idx) {
        setFieldValue(`healthScoreRules[${indx}].isOpenGraph`, true);
      } else {
        setFieldValue(`healthScoreRules[${indx}].isOpenGraph`, false);
      }
    });
    setToggle(!!val);
  };

  const preventInvalidInput = (e) => {
    if (e.key === '-' || e.key === '.') {
      e.preventDefault();
    }
  };

  const handleDuplicateRule = (indexToDuplicate) => {
    if (values.healthScoreRules.length < 10) {
      if (indexToDuplicate >= 0 && indexToDuplicate < values.healthScoreRules.length) {
        const newRuleData = { ...values.healthScoreRules[indexToDuplicate] };
        insert(indexToDuplicate, newRuleData);
      }
    }
  };
  return {
    str,
    useStyles,
    graphData,
    handleViewGraph,
    toggle,
    isRuleComplete,
    preventInvalidInput,
    handleDuplicateRule,
  };
};
