import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useChart } from '../../components/chart';
import { getFeatureData } from '../../features/FeatureManager/featureManagerService';
import { crudOperations } from '../../modules/FeatureManager/constants';
import {
  generateGraphLabels, getCompletedDataLabel, getDate, getPartialDataLabel,
} from '../../utils/getDate';
import { editDataCommon, parse, uniqueArr } from '../../utils/methods';
import { getChartOptions, partialDataBackground } from '../../utils/chartConfigs';
import { BAR_GRAPH } from '../../utils/constants';
import { featurePinning, getAccountsList } from '../../features';
import useToggle from '../../hooks/useToggle';

const initialFilterValues = {
  sortBy: 'userCount',
  featureUsageView: 'month',
  selectedFeatures: [],
};

function useFeatureUsage({ dashboard, packageId, orgId }) {
  // Feature usage state
  const customEntityData = useSelector((state) => state.customEntityList);
  const featureData = useSelector((state) => state.featureUsage);
  const accountListData = useSelector((state) => state.accountsList?.accountsList);

  const [featureUsage, setFeatureUsage] = useState({
    data: [],
    error: '',
    loading: true,
  });
  const [filteredFeatures, setFilteredFeatures] = useState({
    data: [],
    error: '',
    loading: true,
  });
  const [pinnedFeatures, setPinnedFeatures] = useState({
    data: [],
    error: '',
    loading: true,
  });
  const [featuresList, setFeaturesList] = useState([]);

  // Remaining code
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedFilters, setSelectedFilters] = useState(initialFilterValues);
  const [siteWideLicense, setSiteWideLicense] = useState(false);
  const [totalSeats, setTotalSeats] = useState(0);
  const [toggleAll, setToggleAll] = useToggle();
  const [refreshFeatureUsage, setRefreshFeatureUsage] = useToggle();

  // Total seats without using cube
  useEffect(() => {
    if (packageId && !accountListData?.length > 0) {
      dispatch(getAccountsList({ packageId }));
    }
  }, [packageId, accountListData]);

  useEffect(() => {
    const currentDate = getDate();
    let seats = 0;

    const checkAccountStatus = (account) => {
      const isActive = account.status === 'Active';
      const isIncluded = account.visibility;
      const isNeverExpire = account.renewalDate === null;
      const isRenewalDateValid = account.renewalDate && getDate(account.renewalDate) >= currentDate.setHours(0, 0, 0, 0);
      return (isIncluded && isActive && (isRenewalDateValid || isNeverExpire));
    };

    accountListData?.forEach((account) => {
      if (orgId && account.orgId === orgId && account.totalSeats <= 0) {
        setSiteWideLicense(true);
      }
      if (checkAccountStatus(account) && parse(account.totalSeats) > 0) {
        seats += parse(account.totalSeats);
      }
    });

    setTotalSeats(seats);
  }, [accountListData]);

  // Functions
  const handlePinning = useCallback((event, featureId) => {
    event.stopPropagation();

    setFeatureUsage((prevFeatureUsage) => {
      let isPinning = false;

      const modifiedData = prevFeatureUsage.data?.map((obj) => {
        if (obj.featureId === featureId) {
          isPinning = !obj.isCoreFeature;
          return ({ ...obj, isCoreFeature: isPinning });
        }
        return (obj);
      });

      const pinned = modifiedData.filter((item) => item.isCoreFeature);
      const unpinned = modifiedData.filter((item) => !item.isCoreFeature);
      const updatedData = [...pinned, ...unpinned];
      let updateFilteredData = [];

      if (selectedFilters.selectedFeatures?.length) {
        const pinnedFiltered = pinned.filter((item) => selectedFilters?.selectedFeatures?.includes(item.featureName));
        const unpinnedFiltered = unpinned.filter((item) => selectedFilters?.selectedFeatures?.includes(item.featureName));
        updateFilteredData = pinnedFiltered.concat(unpinnedFiltered);
      }

      setFilteredFeatures((prevFilteredFeatures) => ({
        ...prevFilteredFeatures,
        data: updateFilteredData.length ? updateFilteredData : updatedData,
      }));

      setPinnedFeatures((prevPinnedFeatures) => ({
        ...prevPinnedFeatures,
        data: pinned,
      }));

      dispatch(featurePinning({ featureId, pinningData: isPinning }));

      return {
        ...prevFeatureUsage,
        data: updatedData,
      };
    });
  }, [dispatch, selectedFilters?.selectedFeatures]);

  const handleClickEdit = useCallback(async (params) => {
    const id = params.row.feature_id;
    const recordData = await getFeatureData(params.row.feature_id);
    const editData = editDataCommon(params, recordData, customEntityData, crudOperations, featuresList);
    navigate(`/kpi-manager/update-feature/${id}`, { state: { editFeatureData: editData } });
  }, [customEntityData, navigate]);

  // Graph functions
  const operationCountDataset = (entityOperationCounts, viewOption) => {
    const mergedCounts = {};

    entityOperationCounts?.forEach((item) => {
      const existingCount = mergedCounts[item.type]?.count || {};
      Object.entries(item.count).forEach(([key, value]) => {
        existingCount[key] = (existingCount[key] || 0) + Number(value);
      });
      mergedCounts[item.type] = { ...item, count: existingCount };
    });

    const result = Object.values(mergedCounts);

    const dataset = result.map((operation) => {
      const opLabels = Object.keys(entityOperationCounts[0]?.count);
      const partialDateOp = getPartialDataLabel(opLabels, viewOption);

      return {
        label: operation.type,
        data: Object.values(operation.count),
        backgroundColor: opLabels.map((dateStr) => {
          return dateStr === partialDateOp
            ? BAR_GRAPH.PartialData
            : BAR_GRAPH[operation.type];
        }),
        barThickness: 23,
        stack: 'operation count',
      };
    });

    return dataset;
  };

  const generateDatasets = (data, chart, viewOption) => {
    const operationCounts = Object.values(data.operationCount);
    const entityOperationCounts = Object.values(data.entityOperationCount);
    const userCountData = Object.values(data.userCount);
    const operationPerUser = operationCounts.map((n, i) => (n / userCountData[i]).toFixed(0));
    const opLabels = Object.keys(data?.operationCount);
    const userLabels = Object.keys(data?.userCount);
    const partialDateOp = getPartialDataLabel(opLabels, viewOption);
    const partialDateUser = getPartialDataLabel(userLabels, viewOption);

    switch (chart) {
      case 'bar':
        return operationCountDataset(entityOperationCounts, viewOption);
      case 'combo':
        return [
          {
            label: 'Users',
            data: userCountData,
            backgroundColor: partialDataBackground(userLabels, partialDateUser),
            yAxisID: 'user',
            order: 2,
            barThickness: 23,
          },
          {
            label: 'Operations per User',
            data: operationPerUser,
            backgroundColor: partialDataBackground(opLabels, partialDateOp, '#FFFFFF'),
            borderColor: '#294E99',
            type: 'line',
            yAxisID: 'operation',
            order: 1,
            borderWidth: 2.5,
          },
        ];
      default:
        return {};
    }
  };

  const chartOptionsFn = useChart();

  const getChartData = ({
    feature, config, type, viewOption,
  }) => {
    const view = viewOption || selectedFilters.featureUsageView;
    const datasets = generateDatasets(feature, config.chart, view);
    const options = getChartOptions(config);
    const chartOptions = chartOptionsFn(options);

    if (feature) {
      let keys;
      if (type === 'op') {
        const keyObj = feature?.entityOperationCount[0]?.count;
        keys = Object.keys(keyObj);
      } else {
        keys = Object.keys(feature.userCount);
        keys = uniqueArr(keys);
      }
      const labels = generateGraphLabels(keys, view);

      return {
        labels,
        datasets,
        chartOptions,
      };
    }
    return {};
  };

  // To handle filters
  const dropDown = useMemo(() => {
    const selectedFeatures = [];
    const sortBy = [
      { id: 1, value: 'userCount', name: 'Users' },
      { id: 2, value: 'operationCount', name: 'Operations' },
    ];
    if (dashboard) {
      sortBy.push({ id: 3, value: 'accountCount', name: 'Accounts' });
    }
    const featureUsageView = [
      { id: 1, value: 'month', name: 'Monthly' },
      { id: 2, value: 'week', name: 'Weekly' },
      { id: 3, value: 'day', name: 'Daily' },
    ];

    if (featureUsage?.data?.length) {
      featureUsage.data.forEach(({ featureId, featureName }) => {
        selectedFeatures.push({
          id: featureId,
          value: featureName,
          name: featureName,
        });
      });
    }

    return { sortBy, featureUsageView, selectedFeatures };
  }, [featureUsage?.data, dashboard]);

  const filterFeatures = (features) => {
    const filteredFeatureData = features?.length
      ? featureUsage?.data?.filter((element) => features.includes(element.featureName))
      : featureUsage?.data;

    setFilteredFeatures((prev) => ({ ...prev, data: filteredFeatureData }));
  };

  const sortFeatures = (list, sortBy) => {
    return orderBy(list, (obj) => {
      const item = obj[sortBy];
      return sortBy === 'accountCount' ? item : item[getCompletedDataLabel(Object.keys(item))];
    }, ['desc']);
  };

  const applySort = (sortBy) => {
    const pinnedRowsData = filteredFeatures.data?.filter((row) => row.isCoreFeature) ?? [];
    const unpinnedRowsData = filteredFeatures.data?.filter((row) => !row.isCoreFeature) ?? [];
    const sortedData = sortFeatures(unpinnedRowsData, sortBy);
    const updateData = [...pinnedRowsData, ...sortedData];
    setFilteredFeatures((prev) => ({ ...prev, data: updateData }));
  };

  const handleFilterChange = useCallback(({ target: { name, value } }) => {
    const updateFilters = (newFilters) => {
      setSelectedFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    };

    switch (name) {
      case 'sortBy':
        applySort(value);
        updateFilters({ [name]: value });
        break;

      case 'selectedFeatures':
        filterFeatures(value);
        updateFilters({ [name]: value });
        break;

      case 'featureUsageView':
        setToggleAll(false);
        updateFilters({
          [name]: value,
          selectedFeatures: [],
        });
        break;

      default:
        updateFilters({ [name]: value });
        break;
    }
  }, [applySort, filterFeatures]);

  const extractFeaturesInfo = (data) => {
    const features = [];
    const unpinned = [];
    const pinned = [];
    const pinnedRows = [];

    const pinnedRowsCondition = (row) => row.isCoreFeature && Object.keys(row.userCount || {}).length !== 0 && Object.keys(row.operationCount || {}).length !== 0;

    data?.forEach((item) => {
      const featuresObj = {
        featureId: item.featureId,
        featureName: item.featureName,
      };
      features.push(featuresObj);

      if (item.isCoreFeature) {
        pinned.push(item);
      } else {
        unpinned.push(item);
      }

      if (pinnedRowsCondition(item)) {
        pinnedRows.push(item);
      }
    });

    return {
      pinned, unpinned, features, pinnedRows,
    };
  };

  // Set feature usage data
  const setFeatureUsageData = () => {
    const {
      loading, error, data, totalActiveSeats, totalActiveAccounts,
    } = featureData;

    const {
      features, unpinned, pinned, pinnedRows,
    } = extractFeaturesInfo(data);

    const sortedData = sortFeatures(unpinned, selectedFilters.sortBy);
    const updateData = [...pinned, ...sortedData];

    setFeatureUsage({
      ...featureUsage,
      loading,
      error,
      totalActiveSeats,
      totalActiveAccounts,
      data: updateData,
    });
    setFilteredFeatures({
      ...filteredFeatures,
      loading,
      error,
      totalActiveSeats,
      totalActiveAccounts,
      data: updateData,
    });
    setPinnedFeatures({
      ...pinnedFeatures,
      ...featureData,
      data: pinnedRows,
    });
    setFeaturesList(features);
  };

  useEffect(() => {
    setFeatureUsageData();
  }, [featureData]);

  return {
    featureUsage,
    filteredFeatures,
    pinnedFeatures,
    featuresList,
    siteWideLicense,
    totalSeats,
    dropDown,
    handlePinning,
    handleClickEdit,
    selectedFilters,
    handleFilterChange,
    getChartData,
    toggleAll,
    setToggleAll,
    refreshFeatureUsage,
    setRefreshFeatureUsage,
  };
}

export default useFeatureUsage;
