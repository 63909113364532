import { withLoadingAndOverlay } from '../../../hoc';
import { CustomDataGrid } from '../../../components/CustomDataGrid';

function DashboardHealthScoreCard(props) {
  const {
    columns, rows,
  } = props;
  return (
    <CustomDataGrid
      columns={columns}
      rows={rows}
      rowId="orgId"
      pageSize={5}
    />
  );
}

export default withLoadingAndOverlay(DashboardHealthScoreCard);
