const customDataGridStyle = ((theme) => ({
  customDataGrid: {
    maxWidth: '100%',
    '.MuiDataGrid-root': {
      outline: 'none !important',
      border: 'none !important',
    },
    '& .MuiDialogContent-root': {
      overflow: 'visible',
    },
    '& .MuiDataGrid-virtualScroller': {
      overflowY: 'auto !important',
      minHeight: '7vw !important',
      '@media (max-width: 1300px)': {
        overflowY: 'auto !important',
        overflowX: 'auto !important',
      },

      '& .MuiDataGrid-virtualScrollerContent': {
        '& .MuiDataGrid-virtualScrollerRenderZone': {
        },
      },
    },
    // '& .MuiDataGrid-iconButtonContainer': {
    //   visibility: 'visible',
    //   width: '0 !important',
    // },
    '& .MuiDataGrid-columnHeaders': {
      borderTop: 'none',
      fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
      fontWeight: 'bold',
    },
    '& .MuiDataGrid-iconSeparator': {
      visibility: 'hidden',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      color: theme.palette.secondary.mainColor,
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
      outline: 'none !important',
    },
    '& .MuiDataGrid-cell': {
      textAlign: 'left',
      border: 'none !important',
      padding: '10px',
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'none',
      outlineOffset: 0,
    },
  },
  centreAlign: {
    '& .MuiDataGrid-iconButtonContainer': {
      visibility: 'visible',
      width: '0 !important',
    },
  },
  clickableRow: {
    '& .MuiDataGrid-row:hover': {
      cursor: 'pointer',
    },
  },
}));

export default customDataGridStyle;
