import {
  List, ListItemIcon, ListItemText, Collapse, Tooltip,
} from '@mui/material';
import styled from '@emotion/styled';
import AppMenuItemComponent from './AppMenuItemComponent';
import palette from '../../theme/palette';
import Iconify from '../iconify';

const AppListItemIcon = styled(ListItemIcon)(({ theme, marginLeft }) => ({
  color: theme.palette.grey[0],
  minWidth: 35,
  marginLeft,
}));
const AppListItemText = styled(ListItemText)(({ theme }) => ({
  color: theme.palette.grey[0],
}));
const DropdownStyle = styled(Collapse)(({ theme, isDrawerOpen }) => ({
  backgroundColor: isDrawerOpen ? 'transparent' : theme.palette.primary.mainColor,
  position: isDrawerOpen ? 'static' : 'absolute',
  transform: isDrawerOpen ? 'translateY(0)' : 'translateY(-48px)',
  left: '101%',
  width: isDrawerOpen ? '100%' : '250px',
  '& .dropdown': {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

function AppMenuItem(props) {
  const {
    name,
    link,
    Icon,
    marginLeft,
    isDrawerOpen,
    items = [],
    handleClick,
    open,
    setOpen,
    isChildren,
    rowIndex,
  } = props;

  const closeDropdown = () => {
    if (open && !isChildren) {
      setOpen(null);
    } else if (!isDrawerOpen) {
      setOpen(null);
    } else;
  };

  const isExpandable = items?.length > 0;

  const ListItemIconContent = (
    <AppListItemIcon marginLeft={marginLeft}>
      {Icon && <Icon />}
    </AppListItemIcon>
  );

  return (
    <>
      <AppMenuItemComponent
        link={link}
        onClick={() => handleClick(rowIndex)}
        closeDropdown={closeDropdown}
      >
        {isDrawerOpen ? ListItemIconContent : (
          <Tooltip title={!isChildren && !(open === rowIndex) ? name : null} placement="right">
            {ListItemIconContent}
          </Tooltip>
        )}

        {isDrawerOpen ? <AppListItemText primary={name} inset={!Icon} />
          : (
            <Tooltip title={!isChildren ? name : null} placement="right">
              <AppListItemText primary={name} inset={!Icon} />
            </Tooltip>
          )}

        {/* Arrow */}
        {isExpandable && open !== rowIndex && <Iconify icon="eva:chevron-right-fill" color={palette.grey[0]} height={20} width={20} sx={{ position: 'absolute', left: 0 }} />}
        {isExpandable && open === rowIndex && <Iconify icon="eva:chevron-down-fill" color={palette.grey[0]} height={20} width={20} sx={{ position: 'absolute', left: 0 }} />}
      </AppMenuItemComponent>

      {/* Dropdown */}
      {isExpandable && (
      <DropdownStyle isDrawerOpen={isDrawerOpen} in={open === rowIndex} timeout="auto" unmountOnExit>
        <List component="div" className="dropdown">
          {items.map((item, index) => (
            <AppMenuItem {...item} key={index} rowIndex={index} marginLeft={isDrawerOpen ? 15 : 0} setOpen={setOpen} open={open} handleClick={handleClick} isChildren isDrawerOpen={isDrawerOpen} />
          ))}
        </List>
      </DropdownStyle>
      )}
    </>
  );
}

export default AppMenuItem;
