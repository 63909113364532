import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const useAccountDetail = () => {
  const [customObjectPinningData, setCustomObjectPinningData] = useState({
    data: [],
    error: '',
    loading: true,
  });

  const [customObject, setCustomObject] = useState({
    data: [],
    error: '',
    loading: true,
  });

  const [generalDetail, setGeneralDetail] = useState({
    data: {},
    error: '',
    loading: true,
  });

  const [sortModel, setSortModel] = useState([
    {
      field: 'UniqueUserCount',
      sort: 'asc',
    },
  ]);
  const [visibility, setVisibility] = useState(true);

  const pinningData = useSelector((state) => state.customObjectPinningData);

  const setCustomObjectPinning = () => {
    const { response, loading, error } = pinningData;
    setCustomObjectPinningData({
      ...customObjectPinningData, data: response || [], loading, error,
    });
  };

  const customObjectsData = useSelector((state) => state.customObjects);

  const setCustomObjects = () => {
    const { response, loading, error } = customObjectsData;
    setCustomObject({
      ...customObject, data: response?.responseArray ?? [], loading, error,
    });
  };

  const generalDetailsData = useSelector((state) => state.generalDetails);

  const setGeneralDetails = () => {
    const { response, loading, error } = generalDetailsData;
    setGeneralDetail({
      ...generalDetail, data: response?.responseData, loading, error,
    });
    setVisibility(response?.responseData?.visibility !== false);
  };

  useEffect(() => {
    setCustomObjects();
  }, [customObjectsData]);

  useEffect(() => {
    setGeneralDetails();
  }, [generalDetailsData]);

  useEffect(() => {
    setCustomObjectPinning();
  }, [pinningData]);
  return {
    sortModel, setSortModel, customObject, generalDetail, customObjectPinningData, visibility, setVisibility,
  };
};

export default useAccountDetail;
