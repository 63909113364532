import { createSlice } from '@reduxjs/toolkit';
import { getFeatureParameter } from './FeatureParameterAction';

export const featureParameterSlice = createSlice({
  name: 'featureParameterSlice',
  initialState: {
    data: [],
    error: null,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeatureParameter.pending, (state) => {
      state.loading = true;
      state.data = [];
    });
    builder.addCase(getFeatureParameter.fulfilled, (state, { payload }) => {
      state.data = payload?.responseArray?.records ?? [];
      state.loading = false;
    });
    builder.addCase(getFeatureParameter.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.payload;
    });
  },
});
