import { Container } from '@mui/material';
import { Configuration } from '../sections/Configuration';
import Metatags from '../components/MetaTags/MetaTags';

export default function ConfigurationPage() {
  return (
    <>
      <Metatags metaTagName="Configuration | ISV Insights" />
      <Container maxWidth="xl">
        <Configuration />
      </Container>
    </>
  );
}
