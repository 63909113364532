import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { resetAll } from '../../../features/StateHandle/resetStateAction';
import { openSnackbar } from '../../../features/SnackBar/snackBarSlice';
import { sendVerificationCode } from '../../../features/Auth/authAction';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
});

const useResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const [initialValues, setInitialValues] = useState({ email: '' });

  const handleSubmit = (values) => {
    const { email, tenant } = values;
    dispatch(sendVerificationCode({ email, tenant })).then((res) => {
      const { error, payload } = res;
      if (payload.code === 'MULTI_TENANT') {
        dispatch(openSnackbar({ open: true, message: payload.message, type: 'info' }));
        setInitialValues({
          email,
          tenant: payload.data.tenantList[0].value,
        });
        return;
      }

      if (error) {
        const err = payload.message || payload;
        dispatch(openSnackbar({ open: true, message: err, type: 'error' }));
        return;
      }

      dispatch(openSnackbar({ open: true, message: payload.message, type: 'info' }));
      navigate('/set-password', { state: values });
    });
  };

  const handleBackToLogin = () => {
    dispatch(resetAll());
  };

  return {
    initialValues,
    auth,
    handleSubmit,
    handleBackToLogin,
    validationSchema,
  };
};

export default useResetPassword;
