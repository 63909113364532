import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useLicenseUtilizationKpiScore = () => {
  const [licenseUtilKpiScore, setLicenseUtilKpiScore] = useState({
    licenseUtilKpiScoreData: [],
    error: '',
    loading: true,
  });

  const licenseUtilKpiScoreInfo = useSelector((state) => state.licenseUtilKpiScore);

  const setVersionsData = () => {
    setLicenseUtilKpiScore({
      ...licenseUtilKpiScore,
      ...licenseUtilKpiScoreInfo,
      error: licenseUtilKpiScoreInfo.error,
    });
  };

  useEffect(() => {
    setVersionsData();
  }, [licenseUtilKpiScoreInfo]);

  return { licenseUtilKpiScore };
};
