import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import * as Yup from 'yup';
import { hasAdvanceFilters } from '../../../../sections/AccountList/accountListConfig';
import { openSnackbar } from '../../../../features/SnackBar/snackBarSlice';
import { setCustomFilterView } from '../../../../features/CustomFilterView/customFilterViewAction';
import { FormDialogBox } from '../../../../components/CustomDialogBox';
import CustomFilterForm from './CustomFilterForm';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Enter a name for Custom View'),
});

function CustomFilterDialog(props) {
  const {
    type, open, handleClose, selectedFilters, activePackage, selectedView, setSelectedView, findSelectedView,
  } = props;

  const dispatch = useDispatch();

  const handleSaveOrUpdate = (values, isUpdate) => {
    if (!activePackage?.packageId) return;

    const viewData = isUpdate ? findSelectedView(selectedView) : null;
    const toggleAdvanceFilter = hasAdvanceFilters(selectedFilters);

    const data = {
      id: isUpdate ? viewData.id : undefined,
      name: values?.name,
      alert: values?.alert,
      packageId: activePackage.packageId,
      filter: { ...selectedFilters, toggleAdvanceFilter },
    };

    dispatch(setCustomFilterView(data)).then((res) => {
      if (res?.payload?.status === 'SUCCESS') {
        const message = isUpdate ? 'Custom Filter updated successfully' : 'Custom Filter Created Successfully';
        dispatch(openSnackbar({ open: true, message, type: 'success' }));
        setSelectedView(res.payload.data?.id);
      } else {
        const errorMessage = isUpdate ? 'Failed to update Custom Filter' : 'Custom Filter Already Exists';
        dispatch(openSnackbar({ open: true, message: errorMessage, type: 'error' }));
      }
    });

    handleClose();
  };

  const onSubmitHandler = (values) => {
    if (type === 'update') {
      handleSaveOrUpdate(values, true);
    } else {
      handleSaveOrUpdate(values, false);
    }
  };

  const getInitialValues = () => {
    const viewData = findSelectedView(selectedView);
    return type === 'update' ? {
      name: viewData?.name,
      alert: viewData?.alert,
    } : {
      name: '',
      alert: false,
    };
  };

  const getTitle = useCallback(() => {
    return type === 'update' ? 'Update View' : 'Save View As...';
  }, [type]);

  const getAction = useCallback(() => {
    return type === 'update' ? 'Update' : 'Save';
  }, [type]);

  return (
    <FormDialogBox
      title={getTitle()}
      open={open}
      handleClose={handleClose}
      onSubmit={onSubmitHandler}
      initialValues={getInitialValues()}
      validationSchema={validationSchema}
      formContent={<CustomFilterForm />}
      formAction={{ title: getAction() }}
    />
  );
}

export default CustomFilterDialog;
