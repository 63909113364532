import { useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { InfoChip } from '../../../../components/CustomChip';
import { formikForm } from '../../../../hoc';
import { MutedText } from '../../../../components/CustomTypography';
import TextFieldWithError from '../../../../components/FormComponents/TextfieldWithError';

function ChangePasswordForm({ formik }) {
  const {
    values,
    getIn,
    handleChange,
    errors,
    touched,
  } = formik;

  const [passwordVisibility, setPasswordVisibility] = useState({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const handleTogglePassword = (field) => {
    setPasswordVisibility((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const isNewPasswordSameAsCurrent = () => {
    return values.newPassword && values.currentPassword && values.newPassword === values.currentPassword;
  };

  return (
    <Grid container gap={1}>
      <Grid item xs={12} mb={2}>
        <InfoChip icon label="Account Settings" />
      </Grid>

      <Grid item xs={12}>
        <MutedText text="Enter your current password." sxProps={{ marginLeft: '8px' }} />
        <TextFieldWithError
          error={touched.currentPassword && getIn(errors, 'currentPassword')}
          name="currentPassword"
          label="Current Password"
          type={passwordVisibility.currentPassword ? 'text' : 'password'}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ color: 'grey' }}
                  onClick={() => handleTogglePassword('currentPassword')}
                  edge="end"
                >
                  {passwordVisibility.currentPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <MutedText text="Set a valid new password." sxProps={{ marginLeft: '8px' }} />
        <TextFieldWithError
          name="newPassword"
          label="New Password"
          type={passwordVisibility.newPassword ? 'text' : 'password'}
          onChange={handleChange}
          error={
    (touched.newPassword && getIn(errors, 'newPassword'))
    || (isNewPasswordSameAsCurrent() && 'New password should not be the same as current password.')
  }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ color: 'grey' }}
                  onClick={() => handleTogglePassword('newPassword')}
                  edge="end"
                >
                  {passwordVisibility.newPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

      </Grid>

      <Grid item xs={12}>
        <MutedText text="Re-enter your new password" sxProps={{ marginLeft: '8px' }} />
        <TextFieldWithError
          name="confirmPassword"
          label="Confirm Password"
          type={passwordVisibility.confirmPassword ? 'text' : 'password'}
          onChange={handleChange}
          error={touched.confirmPassword && getIn(errors, 'confirmPassword')}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ color: 'grey' }}
                  onClick={() => handleTogglePassword('confirmPassword')}
                  edge="end"
                >
                  {passwordVisibility.confirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} mt={1}>
        <Button variant="outlined" size="medium" type="submit">Change Password</Button>
      </Grid>
    </Grid>
  );
}

export default formikForm(ChangePasswordForm);
