import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useEditions = () => {
  const [editions, setEditions] = useState({
    orgEdObject: {},
    error: '',
    loading: true,
  });

  const editionsData = useSelector((state) => state.editions);

  const setEditionsData = () => {
    setEditions({
      ...editions,
      ...editionsData,
      error: editionsData.error,
    });
  };

  useEffect(() => {
    setEditionsData();
  }, [editionsData]);

  return { editions };
};
