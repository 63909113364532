import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

const usePickCustomEntity = ({ type, editFeatureData }) => {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [ids, setIds] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const customEntityData = useSelector((state) => state.customEntityList);
  const featuresList = useSelector((state) => state.featuresList);

  const handleCustomEntity = () => {
    if (featuresList?.response && type.length && type[0] === 'NestedFeature') {
      const features = featuresList.response.responseArray?.filter((ele) => (ele.feature_id !== editFeatureData?.id && ele.is_nested_feature === false));
      let idList = [];
      if (editFeatureData[type[0]] && editFeatureData[type[0]].ids && editFeatureData[type[0]].ids.length) {
        idList = [...idList, ...editFeatureData[type[0]].ids];
      }
      setIds(idList);
      setData(features);
      setOriginalData(features);
    } else if (customEntityData.customEntityList.length && type.length) {
      const finalFilterData = [];
      let idList = [];
      type.forEach((ele) => {
        const filterData = customEntityData.customEntityList.filter((obj) => {
          return ele === obj.custom_entity_type.trim(' ');
        });
        finalFilterData.push(...filterData);

        if (editFeatureData[ele] && editFeatureData[ele].ids && editFeatureData[ele].ids.length) {
          idList = [...idList, ...editFeatureData[ele].ids];
        }
      });
      setIds(idList);
      setData(finalFilterData);
    }
  };

  useEffect(() => {
    if (!customEntityData.customEntityList.length) {
      setData([]);
    } else {
      handleCustomEntity();
    }
  }, [customEntityData, type]);

  const handlePickClick = (id) => {
    if (ids.includes(id)) {
      const arr = ids.filter((obj) => obj !== id);
      setIds(arr);
    } else {
      const arr = [...ids, ...[id]];
      setIds(arr);
    }
  };

  const handleSelectAll = () => {
    const arr = data.map((obj) => obj.custom_entity_id || obj.feature_id);
    setIds(arr);
  };

  const handleUnSelectAll = () => {
    setIds([]);
  };

  const handleSearchChange = (value) => {
    const term = value;
    if (featuresList?.response && type.length && type[0] === 'NestedFeature') {
      if (term) {
        const filterData = data.filter((obj) => {
          return obj.feature_name.toLowerCase().includes(term.toLowerCase());
        });
        setData(filterData);
      } else {
        setData(originalData);
      }
    } else if (customEntityData.customEntityList.length && type.length) {
      if (term) {
        const customData = [];
        type.forEach((ele) => {
          const filterDataByType = customEntityData.customEntityList.filter((obj) => {
            return ele === obj.custom_entity_type.trim(' ');
          });
          customData.push(...filterDataByType);
        });
        const filterData = customData.filter((obj) => {
          return obj.custom_entity_label.toLowerCase().includes(term.toLowerCase());
        });
        setData(filterData);
      } else {
        const filterData = [];
        type.forEach((ele) => {
          const filterDataByType = customEntityData.customEntityList.filter((obj) => {
            return ele === obj.custom_entity_type.trim(' ');
          });
          filterData.push(...filterDataByType);
        });
        setData(filterData);
      }
    }
    setSearchTerm(term);
  };

  return {
    data,
    ids,
    searchTerm,
    handlePickClick,
    handleSelectAll,
    handleUnSelectAll,
    handleSearchChange,
  };
};

export default usePickCustomEntity;
