import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '../../modules/Request/request';

export const getLogAnalyzer = createAsyncThunk(
  'getLogAnalyzer',
  async ({
    packageId, orgId, view, customEntityObjects,
  }, { rejectWithValue }) => {
    const response = await request('/log-analysis', {
      method: 'POST',
      params: [packageId, orgId],
      body: { customEntityObjects },
      query: { view },
    });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const getLogAnalyzerDetails = createAsyncThunk(
  'getLogAnalyzerDetails',
  async ({
    packageId, orgId, view, customEntityObjects,
  }, { rejectWithValue }) => {
    const response = await request('/object-log-details', {
      method: 'POST',
      params: [packageId, orgId],
      body: { customEntityObjects },
      query: { view },
    });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);
