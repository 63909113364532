import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function Row(props) {
  const {
    rowIndex, row, columns, onRowExpand, open, setOpen,
  } = props;

  const handleRowExpand = () => {
    if (onRowExpand) {
      onRowExpand(row.id);
    }
    setOpen(open === row.id ? null : row.id);
  };

  return (
    <>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        {/* Icon */}
        <TableCell>
          {row.content && (
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleRowExpand}
          >
            {open === row.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          )}
        </TableCell>
        {/*  Row */}
        {columns.map((column) => (
          <TableCell key={`row-${rowIndex}-${column.field}`} component="th" scope="row">
            {row[column.field]}
          </TableCell>
        ))}
      </TableRow>
      {/* Content */}
      {row.content && (
      <TableRow>
        <TableCell sx={{ border: open !== row.id && 'none', py: 0 }} colSpan={6}>
          <Collapse in={open === row.id} timeout="auto" unmountOnExit>
            <Box sx={{ m: 1, py: 2 }}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              {row.content}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      )}
    </>
  );
}

export default function CollapsibleTable(props) {
  const { columns, rows, onRowExpand } = props;
  const [open, setOpen] = React.useState(null);

  return (
    columns?.length && rows?.length ? (
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          {/* Header */}
          <TableHead>
            <TableRow>
              <TableCell width="10px" />
              {columns.map((column, columnIndex) => (
                <TableCell key={columnIndex}>{column.headerName || column.field}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* Body */}
          <TableBody>
            {rows.map((row, rowIndex) => (
              <Row
                key={`row-${rowIndex}`}
                rowIndex={rowIndex}
                row={row}
                columns={columns}
                onRowExpand={onRowExpand}
                open={open}
                setOpen={setOpen}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    ) : null
  );
}
