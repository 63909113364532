import {
  IconButton, LinearProgress, Stack, Typography,
} from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { GridPagination } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import DashboardCards from '../../../../components/DashboardCard/DashboardCards';
import palette from '../../../../theme/palette';
import { CustomNoDataOverlay } from '../../../../components/CustomNoDataOverlay';
import RetryDashboardComponent from '../../../../components/RetryDashboardComponent';
import { fCommaSeparated } from '../../../../utils/formatNumber';
import { customObjectPinningAction } from '../../../../features';
import { CustomDataGrid } from '../../../../components/CustomDataGrid';

function TopCustomObject(props) {
  const {
    customObject,
    moreButton = false,
    pageSize,
    accountName,
    orgId,
    pkgId,
    setRefreshCustomObjHandler,
    customObjectPinningData,
    isShare,
    permissions,
    selectedFilters,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data, loading, error } = customObject;
  const [customObjectData, setCustomObjectData] = useState([]);
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const timeFrame = {
    '15days': '15 Days',
    '30days': '30 Days',
    '60days': '60 Days',
  };

  useEffect(() => {
    if (customObjectPinningData?.data?.pinning_list?.length) {
      const pinned = [];
      const unpinned = [];
      if (data && data.length) {
        data.forEach((obj) => {
          const copyObj = { ...obj };
          copyObj.isPinned = customObjectPinningData.data.pinning_list.includes(copyObj.customObject);
          if (copyObj.isPinned) {
            pinned.push(copyObj);
          } else {
            unpinned.push(copyObj);
          }
        });
        unpinned.sort((a, b) => {
          return b.recordCount - a.recordCount;
        });
        setCustomObjectData([...pinned, ...unpinned]);
      }
    } else {
      setCustomObjectData(data);
    }
  }, [customObjectPinningData, customObject]);

  const handlePinning = (params) => {
    const pinned = [];
    const pinnedList = [];
    const unpinned = [];
    customObjectData.forEach((obj) => {
      const copyObj = { ...obj };
      if (params.row.id === copyObj.id && pinnedList.length < 10) {
        copyObj.isPinned = !copyObj.isPinned;
      }
      if (copyObj.isPinned) {
        pinned.push(copyObj);
        pinnedList.push(copyObj.customObject);
      } else {
        unpinned.push(copyObj);
      }
    });

    if (pinnedList.length <= 10) {
      dispatch(customObjectPinningAction({ pinningData: pinnedList, packageId: pkgId }));
      setCustomObjectData([...pinned, ...unpinned]);
    }
  };

  const sortedRows = (field, direction) => {
    if (customObjectData && customObjectData.length) {
      const pinnedRowsData = customObjectData.filter((row) => row.isPinned);
      const unpinnedRowsData = customObjectData.filter((row) => !row.isPinned);

      unpinnedRowsData.sort((a, b) => {
        switch (field) {
          case 'customObject':
            return direction === 'asc'
              ? a.customObject.localeCompare(b.customObject)
              : b.customObject.localeCompare(a.customObject);

          case 'recordCount':
          case 'recordVariation':
          case 'read':
          case 'insert':
          case 'update':
          case 'delete':
            return direction === 'asc'
              ? a[field] - b[field]
              : b[field] - a[field];

          default:
            return 0;
        }
      });

      setCustomObjectData([...pinnedRowsData, ...unpinnedRowsData]);
    }
  };

  const handleSortChange = (field) => {
    if (sortField === field) {
      setSortDirection((prevSortDirection) => (prevSortDirection === 'asc' ? 'desc' : 'asc'));
      sortedRows(field, sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
      sortedRows(field, 'asc');
    }
  };

  const getCellDetail = (value, type) => {
    if (value > 0) {
      const cellData = { color: '#329E00', background: '#D6ECCC', value: `+ ${value}` };
      return cellData[type];
    } if (value < 0) {
      const cellData = { color: '#FF0000', background: '#FFCCCC', value: `- ${Math.abs(value)}` };
      return cellData[type];
    }
    const cellData = { color: '#555555', background: '#DDDDDD', value: 0 };
    return cellData[type];
  };

  const opColumns = [
    {
      field: 'read',
      headerName: 'Read',
      flex: 0.6,
      sortable: false,
      headerClassName: 'sortable-header',
      renderHeader: () => (
        <Stack direction="row" spacing={1} alignItems="center" sx={{ color: 'secondary.mainColor' }}>
          <strong>Read</strong>
          <IconButton
            size="small"
            onClick={() => handleSortChange('read')}
            sx={{ p: 0 }}
            className={`sort-icon ${sortField === 'read' ? 'active' : ''}`}
          >
            {sortField === 'read' && sortDirection === 'asc' ? (
              <ArrowUpwardIcon fontSize="small" />
            ) : (
              <ArrowDownwardIcon fontSize="small" />
            )}
          </IconButton>
        </Stack>
      ),
    },
    {
      field: 'insert',
      headerName: 'Insert',
      flex: 0.6,
      sortable: false,
      headerClassName: 'sortable-header',
      renderHeader: () => (
        <Stack direction="row" spacing={1} alignItems="center" sx={{ color: 'secondary.mainColor' }}>
          <strong>Insert</strong>
          <IconButton
            size="small"
            onClick={() => handleSortChange('insert')}
            sx={{ p: 0 }}
            className={`sort-icon ${sortField === 'insert' ? 'active' : ''}`}
          >
            {sortField === 'insert' && sortDirection === 'asc' ? (
              <ArrowUpwardIcon fontSize="small" />
            ) : (
              <ArrowDownwardIcon fontSize="small" />
            )}
          </IconButton>
        </Stack>
      ),
    },
    {
      field: 'update',
      headerName: 'Update',
      flex: 0.6,
      sortable: false,
      headerClassName: 'sortable-header',
      renderHeader: () => (
        <Stack direction="row" spacing={1} alignItems="center" sx={{ color: 'secondary.mainColor' }}>
          <strong>Update</strong>
          <IconButton
            size="small"
            onClick={() => handleSortChange('update')}
            sx={{ p: 0 }}
            className={`sort-icon ${sortField === 'update' ? 'active' : ''}`}
          >
            {sortField === 'update' && sortDirection === 'asc' ? (
              <ArrowUpwardIcon fontSize="small" />
            ) : (
              <ArrowDownwardIcon fontSize="small" />
            )}
          </IconButton>
        </Stack>
      ),
    },
    {
      field: 'delete',
      headerName: 'Delete',
      flex: 0.6,
      sortable: false,
      headerClassName: 'sortable-header',
      renderHeader: () => (
        <Stack direction="row" spacing={1} alignItems="center" sx={{ color: 'secondary.mainColor' }}>
          <strong>Delete</strong>
          <IconButton
            size="small"
            onClick={() => handleSortChange('delete')}
            sx={{ p: 0 }}
            className={`sort-icon ${sortField === 'delete' ? 'active' : ''}`}
          >
            {sortField === 'delete' && sortDirection === 'asc' ? (
              <ArrowUpwardIcon fontSize="small" />
            ) : (
              <ArrowDownwardIcon fontSize="small" />
            )}
          </IconButton>
        </Stack>
      ),
    },
  ];

  const baseColumns = [
    {
      field: 'actions',
      type: 'actions',
      width: 20,
      renderCell: (params) => (
        <IconButton
          onClick={(permissions.write && !isShare) ? (() => handlePinning(params)) : null}
          disabled={!(permissions.write && !isShare)}
        >
          {params.row.isPinned ? (
            <PushPinIcon sx={{ color: palette.text.primary }} />
          ) : (
            <PushPinOutlinedIcon
              sx={{ color: palette.text.primary, transform: 'matrix(0.87, 0.5, -0.5, 0.87, 0, 0)' }}
            />
          )}
        </IconButton>
      ),
    },
    {
      field: 'customObject',
      headerName: 'Custom Objects',
      flex: 1,
      sortable: false,
      headerClassName: 'sortable-header',
      renderHeader: () => (
        <Stack direction="row" spacing={1} alignItems="center" sx={{ color: 'secondary.mainColor' }}>
          <strong>Custom Objects</strong>
          <IconButton
            size="small"
            onClick={() => handleSortChange('customObject')}
            sx={{ p: 0 }}
            className={`sort-icon ${sortField === 'customObject' ? 'active' : ''}`}
          >
            {sortField === 'customObject' && sortDirection === 'asc' ? (
              <ArrowUpwardIcon fontSize="small" />
            ) : (
              <ArrowDownwardIcon fontSize="small" />
            )}
          </IconButton>
        </Stack>
      ),
    },
    {
      field: 'recordCount',
      headerName: 'Count',
      flex: 1,
      sortable: false,
      headerClassName: 'sortable-header',
      renderHeader: () => (
        <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center" sx={{ color: 'secondary.mainColor' }}>
          <strong>Total Rows</strong>
          <IconButton
            size="small"
            onClick={() => handleSortChange('recordCount')}
            sx={{ p: 0 }}
            className={`sort-icon ${sortField === 'recordCount' ? 'active' : ''}`}
          >
            {sortField === 'recordCount' && sortDirection === 'asc' ? (
              <ArrowUpwardIcon fontSize="small" />
            ) : (
              <ArrowDownwardIcon fontSize="small" />
            )}
          </IconButton>
        </Stack>
      ),
      renderCell: (params) => (
        <Typography color={params.row.recordCount < 0 && 'red'}>
          {fCommaSeparated(params.value)}
        </Typography>
      ),
    },
    {
      field: 'recordVariation',
      headerName: 'Variation',
      flex: 1,
      sortable: false,
      headerClassName: 'sortable-header',
      renderHeader: () => (
        <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center" sx={{ color: 'secondary.mainColor' }}>
          <strong>{`Variation (${selectedFilters?.timeFrame ? timeFrame[selectedFilters?.timeFrame] : '30 Days'})`}</strong>
          <IconButton
            size="small"
            onClick={() => handleSortChange('recordVariation')}
            sx={{ p: 0 }}
            className={`sort-icon ${sortField === 'recordVariation' ? 'active' : ''}`}
          >
            {sortField === 'recordVariation' && sortDirection === 'asc' ? (
              <ArrowUpwardIcon fontSize="small" />
            ) : (
              <ArrowDownwardIcon fontSize="small" />
            )}
          </IconButton>
        </Stack>
      ),
      renderCell: (params) => (
        <Typography sx={{
          display: 'flex',
          flexDirection: 'row',
          borderRadius: '25px',
          padding: '6px 10px',
          color: `${getCellDetail(params.value, 'color')}`,
          background: `${getCellDetail(params.value, 'background')}`,
          alignItems: 'center',
          fontSize: 13,
        }}
        >
          {getCellDetail(params.value, 'value')}
        </Typography>
      ),
    },
  ];

  const columns = moreButton ? baseColumns : [...baseColumns, ...opColumns];

  return (
    <DashboardCards
      showHeader={!!moreButton}
      title="Top Custom Objects"
      showButton
      buttonText="more"
      onClick={() => navigate('/custom-objects', {
        state: {
          customObject, orgId, accountName, customObjectPinningData, pkgId,
        },
      })}
    >
      {loading && <LinearProgress />}
      {!loading && error ? (
        <RetryDashboardComponent refreshHandler={setRefreshCustomObjHandler} />
      ) : null}
      {!loading && data && !data.length ? <CustomNoDataOverlay message="No Data" /> : null}
      {!loading && data && data.length ? (
        <CustomDataGrid
          columns={columns || []}
          rows={customObjectData}
          pageSize={pageSize}
          hideFooter
          components={{
            NoRowsOverlay: CustomNoDataOverlay,
            LoadingOverlay: LinearProgress,
            Pagination: GridPagination,
          }}
          rowId="id"
        />
      ) : null}
    </DashboardCards>
  );
}

export default TopCustomObject;
