import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useVersions = () => {
  const [versions, setVersions] = useState({
    versionObject: {},
    error: '',
    loading: true,
  });

  const versionsData = useSelector((state) => state.versions);

  const setVersionsData = () => {
    setVersions({
      ...versions,
      ...versionsData,
      error: versionsData.error,
    });
  };

  useEffect(() => {
    setVersionsData();
  }, [versionsData]);

  return { versions };
};
