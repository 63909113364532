import { useLayoutEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import {
  Bar, Line, Pie, Bubble,
} from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import { useChart } from '../chart';

function Graph(props) {
  const {
    type, labels, datasets, options, plugins, height, width, chartRef,
  } = props;
  // Register ChartJs
  useLayoutEffect(() => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      LineElement,
      PointElement,
      ArcElement,
      Title,
      Tooltip,
      Legend,
      Filler,
      zoomPlugin,
    );
    return () => {
      ChartJS.unregister(
        CategoryScale,
        LinearScale,
        BarElement,
        LineElement,
        PointElement,
        ArcElement,
        Title,
        Tooltip,
        Legend,
        Filler,
        zoomPlugin,
      );
    };
  }, [type, labels, datasets, options, plugins]);

  // Define the chart based on the 'type' prop
  const ChartComponent = {
    bar: Bar,
    line: Line,
    pie: Pie,
    bubble: Bubble,
  }[type] || Bar;

  const chartData = { labels, datasets };
  const chartOptionsFn = useChart();
  const chartOptions = chartOptionsFn(options);

  return (
    <ChartComponent
      ref={chartRef}
      data={chartData}
      options={chartOptions}
      plugins={plugins || null}
      height={height || null}
      width={width || null}
    />
  );
}

export default Graph;
