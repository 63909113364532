/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import { getDate } from '../../utils/getDate';
import { resetLogAnalyzer } from '../../features/LogAnalyzer/logAnalyzerSlice';
import { openSnackbar } from '../../features/SnackBar/snackBarSlice';
import { getLogAnalyzer, getLogAnalyzerDetails } from '../../features/LogAnalyzer/logAnalyzerAction';
import { getAccountsList, getCustomEntityList } from '../../features';
import usePackageSelection from '../Accounts/components/PackageSelection/usePackageSelection';

const currentDate = getDate();
const defaultFilter = '- all -';
const defaultAccountFilter = { id: defaultFilter, name: defaultFilter, value: defaultFilter };
const defaultFilterValue = {
  account: defaultAccountFilter,
  customEntityType: [],
  customEntity: {},
  timeFrame: '',
};

function useLogAnalyzer() {
  const { activePackage } = usePackageSelection();
  const dispatch = useDispatch();

  const accountListData = useSelector((state) => state.accountsList);
  const customEntityData = useSelector((state) => state.customEntityList);
  const logAnalyzerData = useSelector((state) => state.logAnalyzer);

  // State to store API data ----->
  const [accountList, setAccountList] = useState([defaultAccountFilter]);
  const [customEntityList, setCustomEntityList] = useState([]);
  const [logAnalyzerInfo, setLogAnalyzerInfo] = useState({
    loading: false,
    error: null,
    data: {},
  });

  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getAccountsList({ packageId: activePackage.packageId }));
      dispatch(getCustomEntityList({ packageId: activePackage.packageId }));
    }
  }, [activePackage?.packageId]);

  //   rest of the hook ----->
  const [selectedFilters, setSelectedFilters] = useState(defaultFilterValue);
  const [customEntityTpe, setCustomEntityTpe] = useState('');

  const handleFilterChange = (name, value) => {
    if (!value && name === 'account') {
      setSelectedFilters((prev) => ({ ...prev, [name]: defaultAccountFilter }));
    } else {
      setSelectedFilters((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleApplyFilters = () => {
    const { packageId } = activePackage;
    const { timeFrame, account, customEntity } = selectedFilters;

    if (timeFrame && account && Object.keys(customEntity).length) {
      dispatch(getLogAnalyzer({
        packageId,
        view: timeFrame,
        customEntityObjects: customEntity,
        ...(account?.value !== defaultFilter && { orgId: account.value }),
      }));
    } else {
      dispatch(openSnackbar({ open: true, message: 'Please select all the filters!', type: 'error' }));
    }
  };

  const handleExpandEntity = (type) => {
    const { packageId } = activePackage;
    const { timeFrame, account, customEntity } = selectedFilters;
    setCustomEntityTpe(type);

    if (timeFrame && account && customEntity[type]) {
      dispatch(getLogAnalyzerDetails({
        packageId,
        view: timeFrame,
        customEntityObjects: { [type]: customEntity[type] },
        ...(account?.value !== defaultFilter && { orgId: account.value }),
      }));
    } else {
      dispatch(openSnackbar({ open: true, message: 'Please select all the filters!', type: 'error' }));
    }
  };

  const handleResetFilters = () => {
    setSelectedFilters(defaultFilterValue);
    dispatch(resetLogAnalyzer());
  };

  // State setter functions ----->
  const setCustomEntityListData = (selectedCustomEntityType) => {
    if (!selectedCustomEntityType.length) {
      setCustomEntityList([]);
      return;
    }

    const filteredCustomEntityList = [];
    if (customEntityData?.customEntityList?.length) {
      customEntityData.customEntityList.forEach((entity) => {
        const { custom_entity_id, custom_entity_label, custom_entity_type } = entity;
        const isApex = selectedCustomEntityType.includes('ApexExecution') && (custom_entity_type === 'ApexClass' || custom_entity_type === 'ApexTrigger');
        if (selectedCustomEntityType.includes(custom_entity_type) || isApex) {
          const object = {
            id: custom_entity_id,
            name: custom_entity_label,
            value: custom_entity_id,
            type: custom_entity_type,
          };
          filteredCustomEntityList.push(object);
        }
      });
    }
    setCustomEntityList(filteredCustomEntityList);
  };

  const setLogAnalyzerData = () => {
    if (logAnalyzerData) {
      setLogAnalyzerInfo({
        loading: logAnalyzerData.loading,
        error: logAnalyzerData.error,
        data: logAnalyzerData.data,
      });
    }
  };

  const setAccountListData = () => {
    const { accountsList } = accountListData;
    const filterAccount = [];
    const orgIdAccount = [];
    if (accountsList?.length) {
      accountsList.forEach((acc) => {
        const isActive = acc.status === 'Active';
        const isNeverExpire = acc.renewalDate === null;
        const isRenewalDateValid = acc.renewalDate && getDate(acc.renewalDate) >= currentDate.setHours(0, 0, 0, 0);

        if (acc.visibility && (isActive && (isRenewalDateValid || isNeverExpire))) {
          const object = {};
          object.value = acc.orgId;
          object.name = acc.accountName || acc.orgId;
          object.id = acc.orgId;
          if (!acc.accountName) {
            orgIdAccount.push(object);
          } else {
            filterAccount.push(object);
          }
        }
      });
    }
    const sortedData = orderBy(filterAccount, ['name'], ['asc']);
    setAccountList([defaultAccountFilter, ...sortedData, ...orgIdAccount]);
  };

  useEffect(() => {
    if (selectedFilters?.customEntityType) {
      setCustomEntityListData(selectedFilters.customEntityType);
    }
  }, [customEntityData.customEntityList, selectedFilters.customEntityType]);

  useEffect(() => {
    setAccountListData();
  }, [accountListData.accountsList]);

  useEffect(() => {
    setLogAnalyzerData();
  }, [logAnalyzerData.data]);

  useEffect(() => {
    return () => {
      dispatch(resetLogAnalyzer());
    };
  }, []);

  return {
    accountList,
    customEntityList,
    logAnalyzerInfo,
    selectedFilters,
    customEntityTpe,
    handleFilterChange,
    handleApplyFilters,
    handleResetFilters,
    handleExpandEntity,
  };
}

export default useLogAnalyzer;
