import {
  Box, Grid, Stack, Typography,
} from '@mui/material';
import { useMemo, useState } from 'react';
import { BUTTONS } from '../../../../utils/constants';
import { CustomChip, InfoChip } from '../../../../components/CustomChip';
import { SearchBar } from '../../../../components/FormComponents';

function CustomEntityDialog({ data, selectedIds, setSelectedIds }) {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchClear = () => {
    setSearchQuery('');
  };

  // Filter data based on search
  const filteredData = useMemo(() => {
    if (!searchQuery) return data;

    return data.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()));
  }, [data, searchQuery]);

  // Group data according to type
  const groupedData = useMemo(() => {
    return filteredData.reduce((acc, item) => {
      const { type } = item;
      let entityType = type;
      if (type === 'ApexClass' || type === 'ApexTrigger') {
        entityType = 'ApexExecution';
      }
      if (!acc[entityType]) {
        acc[entityType] = [];
      }
      acc[entityType].push(item);
      return acc;
    }, {});
  }, [filteredData]);

  // Select / Un-select
  const handleChipClick = (id) => {
    setSelectedIds((prevSelectedIds) => {
      const newSelectedIds = new Set(prevSelectedIds);
      if (newSelectedIds.has(id)) {
        newSelectedIds.delete(id);
      } else {
        newSelectedIds.add(id);
      }
      return newSelectedIds;
    });
  };

  return (
    <Box>
      <Stack direction="column" justifyContent="start" gap={2}>
        {/* Info */}
        <InfoChip
          icon
          type="multiline"
          label={`In this step, please select the custom objects for which you want to see graph. You can then include them in your new KPI by clicking '${BUTTONS.NEW_KPI}' button.`}
        />
        {/* Search */}
        <SearchBar
          label="Search"
          value={searchQuery}
          onChange={handleSearchChange}
          onClear={handleSearchClear}
        />
      </Stack>

      {/* Data */}
      <Box mt={2}>
        {Object.keys(groupedData)?.length > 0 && Object.keys(groupedData).map((type) => (
          <Stack key={type} gap={1} mt={4}>
            <Typography variant="h6" fontWeight={400} ml="5px">
              {type}
            </Typography>
            <Grid container spacing={1} sx={{ minWidth: 'inherit' }}>
              {groupedData[type].map((obj) => (
                <Grid key={obj.id} item xs={6}>
                  <CustomChip
                    tooltip={obj.name}
                    type="info"
                    label={obj.name}
                    selected={selectedIds.has(obj.id)}
                    onClick={() => handleChipClick(obj.id)}
                    textAlign="left"
                  />
                </Grid>
              ))}
            </Grid>
          </Stack>
        ))}
      </Box>
    </Box>
  );
}

export default CustomEntityDialog;
