import {
  Button, Grid, Stack, Typography,
} from '@mui/material';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { CustomIcon } from '../../../../components/CustomIcon';
import { TextFieldWithError } from '../../../../components/FormComponents';

const defaultCategoryTitle = ['Startup', 'Small', 'Medium', 'Enterprise', 'Site Wide'];

function CustomerSizeForm({ formik }) {
  const { values, setFieldValue } = formik;

  // Handle adding a new category
  const addCategory = () => {
    setFieldValue('categories', [...values.categories, { title: '', from: '', to: '' }]);
  };

  // Handle removing a category
  const removeCategory = (index) => {
    setFieldValue('categories', values.categories.filter((_, i) => i !== index));
  };

  // Handle changing category
  const handleInputChange = (index, field, value) => {
    const newCategories = [...values.categories];
    newCategories[index] = { ...newCategories[index], [field]: value };
    setFieldValue('categories', newCategories);
  };

  return (
    <Grid container gap={1} p={2}>
      {values.categories.map((category, index) => (
        <Grid item xs={12} key={`category-${index}`}>
          <Stack direction="row" gap={4} alignItems="center">
            <TextFieldWithError
              name={`title-${index}`}
              label="Label"
              value={category.title}
              onChange={(e) => handleInputChange(index, 'title', e.target.value)}
            />
            <Typography>=</Typography>
            <Stack direction="row" gap={4} alignItems="center" width="100%">
              {category.title === 'Site Wide' ? (
                <Typography color="success.dark">Site Wide Licenses</Typography>
              ) : (
                <>
                  <TextFieldWithError
                    name={`from-${index}`}
                    type="number"
                    label="From"
                    value={category.from}
                    onChange={(e) => handleInputChange(index, 'from', e.target.value)}
                  />
                  <TextFieldWithError
                    name={`to-${index}`}
                    type="number"
                    label="To"
                    value={category.to}
                    onChange={(e) => handleInputChange(index, 'to', e.target.value)}
                  />
                </>
              )}
            </Stack>
            <CustomIcon disabled={defaultCategoryTitle.includes(category.title)} Icon={RemoveCircleOutlineIcon} onClick={() => removeCategory(index)} />
          </Stack>
        </Grid>
      ))}

      <Grid item xs={12}>
        <Button onClick={addCategory}>+ Add More</Button>
      </Grid>
    </Grid>
  );
}

export default CustomerSizeForm;
