import { Badge, Grid } from '@mui/material';
// import { MutedText } from '../../../../components/CustomTypography';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { MutedText } from '../../../../../components/CustomTypography';
import { DATE_FORMAT } from '../../../../../utils/constants';

export default function StepsDetail({ event }) {
  return (
    <Grid container spacing={3}>
      <Grid item container direction="column" xs={5}>
        <Grid item>
          <MutedText text="Current" />
        </Grid>
        <Grid item>{moment(event.updatedAt).format(DATE_FORMAT.DAY_DATE_GRAPH2)}</Grid>
      </Grid>
      <Grid item xs={2}>
        <ArrowRightAltIcon />
      </Grid>
      <Grid item container direction="column" xs={5}>
        <Grid item>
          <Badge badgeContent={event?.expFlag && <CheckCircleIcon fontSize="small" color="success" />} size="small">
            <MutedText text="Expiration" />
          </Badge>
        </Grid>
        <Grid item>
          {moment(event.expiration).format(DATE_FORMAT.DAY_DATE_GRAPH2) ?? ' - - '}
        </Grid>
      </Grid>
    </Grid>
  );
}
