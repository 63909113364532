import { Grid } from '@mui/material';
import { fCommaSeparated } from '../../../../utils/formatNumber';
import { getCompletedDataDate, getGraphDateRange } from '../../../../utils/getDate';
import { modifiedDateString, percentage } from '../../../../utils/methods';
import FeatureUsageInfo from '../FeatureUsageGraph/FeatureUsageInfo';
import FeatureUsageGraph from '../FeatureUsageGraph/FeatureUsageGraph';
import { featureUsageModeConst } from '../../../../sections/AccountList/accountListConfig';

function FeatureUsageBody(props) {
  const {
    feature, accountCount, userCountLen, operationCountLen, siteWideLicense, totalActiveSeats, totalActiveAccounts, getChartData, viewOption, dashboard, graphTitle = null,
  } = props;

  const dateRange = getGraphDateRange(viewOption);
  const lastUserCountDate = getCompletedDataDate(Object.keys(feature.userCount || {}), viewOption);
  const lastOperationCountDate = getCompletedDataDate(Object.keys(feature.operationCount || {}), viewOption);
  const lastMonthUserCount = feature.userCount?.[lastUserCountDate];
  const lastMonthOperationCount = feature.operationCount?.[lastOperationCountDate];
  const lastAvailableMonthUserCount = fCommaSeparated(lastMonthUserCount);
  const lastAvailableMonthOperationCount = fCommaSeparated(lastMonthOperationCount);
  const userPercent = percentage(lastMonthUserCount, totalActiveSeats) || 0;
  const userLastDate = modifiedDateString(lastUserCountDate, viewOption);
  const operationLastDate = modifiedDateString(lastOperationCountDate, viewOption);
  return (
    <Grid container spacing={4} justifyContent="space-between">
      <Grid item xs={12} sm={2.2}>
        <FeatureUsageInfo
          siteWideLicense={siteWideLicense}
          totalActiveSeats={totalActiveSeats}
          userPercent={userPercent}
          accountCount={accountCount}
          totalActiveAccounts={totalActiveAccounts}
          used={featureUsageModeConst.USE}
          dontUsed={featureUsageModeConst.DONT_USE}
          dashboard={dashboard}
          featureId={feature.featureId}
          lastAvailableMonthUserCount={lastAvailableMonthUserCount}
          userLastDate={userLastDate}
          operationLastDate={operationLastDate}
          lastAvailableMonthOperationCount={lastAvailableMonthOperationCount}
        />
      </Grid>
      <Grid item xs={12} sm={9.8}>
        {userCountLen && operationCountLen && (
          <FeatureUsageGraph
            getChartData={getChartData}
            feature={feature}
            viewOption={viewOption}
            dateRange={dateRange}
            graphTitle={graphTitle}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default FeatureUsageBody;
