/* eslint-disable no-unused-vars */
/* eslint-disable no-eval */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  addMonths, parseISO, differenceInDays,
} from 'date-fns';
import {
  expiredAccountColumns, riskAccountsColumns, decliningAccountColumns, trendAccountColumns,
} from '../../utils/dataGridColumns';
import { getDate, isAfter90Days } from '../../utils/getDate';
import {
  AccountNameCell, AccountSignalCell, HealthScore, HealthScoreTrendThumbnail, RenewalDateCell, SeatsCell,
} from '../@dashboard/account-details/accountDetailsTable';

function isWithinDateRange(date, startDate, endDate) {
  if (!startDate || !endDate) {
    return true;
  }
  const currentDate = new Date(date);
  const start = new Date(startDate);
  const end = new Date(endDate);
  return currentDate >= start && currentDate <= end;
}

const healthScoreRange = [0, 30];
const expiredInMonths = 3;
const currentDate = new Date();
const threeMonthsLater = addMonths(currentDate, expiredInMonths);

export const useDashboardHealthScore = () => {
  const [riskRows, setRiskRows] = useState([]);
  const [expiredRows, setExpiredRows] = useState([]);
  const [decliningRows, setDecliningRows] = useState([]);
  const [trendRows, setTrendRows] = useState([]);
  const [decliningAccountsList, setDecliningAccountsList] = useState([]);
  const [trendAccountsList, setTrendAccountsList] = useState([]);
  const [riskAccountList, setRiskAccountList] = useState([]);
  const [expiredAccountsList, setExpiredAccountsList] = useState([]);
  const [loadingState, setLoadingState] = useState(false);

  const allAccounts = useSelector((state) => state.accountsList);
  const highRiskAccountConfig = useSelector((state) => state.highRiskAccountConfig?.data);
  const customFilterData = useSelector((state) => state.getCustomAlertDetails.data);

  const handleSortTrendValueAccounts = (array, field, type = 'desc') => {
    const modifiedRiskAccount = array
      ?.sort((a, b) => (type === 'asc' && a[field] ? (a[field] - b[field]) : (b[field] - a[field])));
    return modifiedRiskAccount;
  };
  const handleScoreAccounts = (array) => {
    const modifiedScoreAccount = array
      ?.sort((a, b) => a.healthScore - b.healthScore);
    return modifiedScoreAccount;
  };

  const handleExpiredAccount = (array) => {
    const modifiedExpiredData = array
      ?.filter((item) => {
        const isRenewalDateRangeMatch = isWithinDateRange(
          item.renewalDate,
          currentDate,
          threeMonthsLater,
        );
        return isRenewalDateRangeMatch;
      }).sort((a, b) => {
        const dateA = parseISO(a.renewalDate);
        const dateB = parseISO(b.renewalDate);
        return differenceInDays(dateA, dateB);
      });
    return modifiedExpiredData;
  };

  useEffect(() => {
    const modifiedRiskAccounts = riskAccountList ? handleScoreAccounts(riskAccountList) : [];
    setRiskRows(modifiedRiskAccounts);
    setLoadingState(false);
  }, [riskAccountList]);

  useEffect(() => {
    const modifiedExpiredAccount = expiredAccountsList ? handleExpiredAccount(expiredAccountsList) : [];
    setExpiredRows(modifiedExpiredAccount);
    setLoadingState(false);
  }, [expiredAccountsList]);

  useEffect(() => {
    const modifiedDecliningAccounts = decliningAccountsList ? handleSortTrendValueAccounts(decliningAccountsList, (customFilterData?.[0]?.view === 'weekly' ? 'weeklyTrendValue' : 'trendValue'), 'asc') : [];
    setDecliningRows(modifiedDecliningAccounts);
    setLoadingState(false);
  }, [decliningAccountsList]);

  useEffect(() => {
    const modifiedTrendAccounts = trendAccountsList ? handleSortTrendValueAccounts(trendAccountsList, (customFilterData?.[0]?.view === 'weekly' ? 'weeklyTrendValue' : 'trendValue')) : [];
    setTrendRows(modifiedTrendAccounts);
    setLoadingState(false);
  }, [trendAccountsList]);

  const riskAccountsColumnInfo = riskAccountsColumns((customFilterData?.[0]?.view === 'weekly') ? 'weeklyTrendValue' : 'trendValue');
  riskAccountsColumnInfo.forEach((item) => {
    if (item.field === 'healthScore') {
      item.renderCell = (params) => <HealthScore params={params} />;
    } else if (item.field === 'seatsAssigned') {
      item.renderCell = (params) => <SeatsCell params={params} />;
    } else if (item.field === 'accountName') {
      item.renderCell = (params) => <AccountNameCell params={params} />;
    } else if (item.field === 'healthScoreTrendThumbnail') {
      item.renderCell = (params) => <HealthScoreTrendThumbnail view={customFilterData?.[0]?.view ?? 'monthly'} trendsData={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrendsData || []) : (params?.row?.trendsData || [])} />;
    } else if (item.field === 'trendValue') {
      item.renderCell = (params) => <AccountSignalCell healthScoreTrend={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrend) : (params?.row?.healthScoreTrend)} trendValue={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrendValue) : (params?.row?.trendValue)} />;
    } else if (item.field === 'weeklyTrendValue') {
      item.renderCell = (params) => <AccountSignalCell healthScoreTrend={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrend) : (params?.row?.healthScoreTrend)} trendValue={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrendValue) : (params?.row?.trendValue)} />;
    }
    return false;
  });

  const decliningAccountColumnInfo = decliningAccountColumns((customFilterData?.[0]?.view === 'weekly') ? 'weeklyTrendValue' : 'trendValue');
  decliningAccountColumnInfo.forEach((item) => {
    if (item.field === 'healthScore') {
      item.renderCell = (params) => <HealthScore params={params} />;
    } else if (item.field === 'seatsAssigned') {
      item.renderCell = (params) => <SeatsCell params={params} />;
    } else if (item.field === 'accountName') {
      item.renderCell = (params) => <AccountNameCell params={params} />;
    } else if (item.field === 'healthScoreTrendThumbnail') {
      item.renderCell = (params) => <HealthScoreTrendThumbnail view={customFilterData?.[0]?.view ?? 'monthly'} trendsData={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrendsData || []) : (params?.row?.trendsData || [])} />;
    } else if (item.field === 'trendValue') {
      item.renderCell = (params) => <AccountSignalCell healthScoreTrend={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrend) : (params?.row?.healthScoreTrend)} trendValue={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrendValue) : (params?.row?.trendValue)} />;
    } else if (item.field === 'weeklyTrendValue') {
      item.renderCell = (params) => <AccountSignalCell healthScoreTrend={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrend) : (params?.row?.healthScoreTrend)} trendValue={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrendValue) : (params?.row?.trendValue)} />;
    }
    return false;
  });

  const expiredAccountColumnInfo = expiredAccountColumns((customFilterData?.[0]?.view === 'weekly') ? 'weeklyTrendValue' : 'trendValue');
  expiredAccountColumnInfo.forEach((item) => {
    if (item.field === 'healthScore') {
      item.renderCell = (params) => <HealthScore params={params} />;
    } else if (item.field === 'renewalDate') {
      item.renderCell = (params) => <RenewalDateCell params={params} />;
    } else if (item.field === 'accountName') {
      item.renderCell = (params) => <AccountNameCell params={params} />;
    } else if (item.field === 'healthScoreTrendThumbnail') {
      item.renderCell = (params) => <HealthScoreTrendThumbnail view={customFilterData?.[0]?.view ?? 'monthly'} trendsData={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrendsData || []) : (params?.row?.trendsData || [])} />;
    } else if (item.field === 'trendValue') {
      item.renderCell = (params) => <AccountSignalCell healthScoreTrend={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrend) : (params?.row?.healthScoreTrend)} trendValue={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrendValue) : (params?.row?.trendValue)} />;
    } else if (item.field === 'weeklyTrendValue') {
      item.renderCell = (params) => <AccountSignalCell healthScoreTrend={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrend) : (params?.row?.healthScoreTrend)} trendValue={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrendValue) : (params?.row?.trendValue)} />;
    }
    return false;
  });

  const trendAccountColumnInfo = trendAccountColumns((customFilterData?.[0]?.view === 'weekly') ? 'weeklyTrendValue' : 'trendValue');
  trendAccountColumnInfo.forEach((item) => {
    if (item.field === 'healthScore') {
      item.renderCell = (params) => <HealthScore params={params} />;
    } else if (item.field === 'seatsAssigned') {
      item.renderCell = (params) => <SeatsCell params={params} />;
    } else if (item.field === 'accountName') {
      item.renderCell = (params) => <AccountNameCell params={params} />;
    } else if (item.field === 'healthScoreTrendThumbnail') {
      item.renderCell = (params) => <HealthScoreTrendThumbnail view={customFilterData?.[0]?.view ?? 'monthly'} trendsData={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrendsData || []) : (params?.row?.trendsData || [])} />;
    } else if (item.field === 'trendValue') {
      item.renderCell = (params) => <AccountSignalCell healthScoreTrend={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrend) : (params?.row?.healthScoreTrend)} trendValue={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrendValue) : (params?.row?.trendValue)} />;
    } else if (item.field === 'weeklyTrendValue') {
      item.renderCell = (params) => <AccountSignalCell healthScoreTrend={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrend) : (params?.row?.healthScoreTrend)} trendValue={customFilterData?.[0]?.view === 'weekly' ? (params?.row?.weeklyTrendValue) : (params?.row?.trendValue)} />;
    }
    return false;
  });

  const filterDataByCondition = (array, condition = 'healthScore <= 30') => {
    return array.filter(({
      healthScore, healthScoreTrend, assignedSeatsPercent, weeklyTrend, installDate, visibility, renewalDate, status,
    }) => {
      return !(installDate && isAfter90Days(installDate)) && (visibility) && ((renewalDate && getDate(renewalDate) >= currentDate && status === 'Active') || (renewalDate === null && status === 'Active')) && eval(condition);
    });
  };

  useEffect(() => {
    if (allAccounts?.accountsList?.length > 0) {
      const filteredAccountList = filterDataByCondition(allAccounts.accountsList, highRiskAccountConfig?.highRiskAccount?.condition);
      setRiskAccountList(filteredAccountList);
      const expiredAccountList = allAccounts?.accountsList.filter((item) => ((item.visibility) && ((item.renewalDate && getDate(item.renewalDate) >= currentDate && item.status === 'Active') || (item.renewalDate === null && item.status === 'Active'))));
      setExpiredAccountsList(expiredAccountList);
      const decliningAccountList = allAccounts?.accountsList.filter((item) => ((item.visibility) && ((customFilterData?.[0]?.view === 'weekly' ? (item.weeklyTrend && item.weeklyTrend === 'falling') : (item.healthScoreTrend && item.healthScoreTrend === 'falling')) && ((item.renewalDate && getDate(item.renewalDate) >= currentDate && item.status === 'Active') || (item.renewalDate === null && item.status === 'Active')))));
      setDecliningAccountsList(decliningAccountList);
      const trendAccountList = allAccounts?.accountsList.filter((item) => ((item.visibility) && ((customFilterData?.[0]?.view === 'weekly' ? (item.weeklyTrend && item.weeklyTrend === 'rising') : (item.healthScoreTrend && item.healthScoreTrend === 'rising')) && ((item.renewalDate && getDate(item.renewalDate) >= currentDate && item.status === 'Active') || (item.renewalDate === null && item.status === 'Active')))));
      setTrendAccountsList(trendAccountList);
    } else {
      setRiskAccountList([]);
      setExpiredAccountsList([]);
      setDecliningAccountsList([]);
      setTrendAccountsList([]);
    }
  }, [allAccounts?.accountsList, highRiskAccountConfig]);

  const accountNum = riskRows?.length;
  const expiredAccountNum = expiredRows?.length;
  const decliningNum = decliningAccountsList?.length;
  const trendAccountNum = trendAccountsList?.length;

  return {
    riskAccountList,
    riskRows,
    expiredRows,
    accountNum,
    healthScoreRange,
    loading: allAccounts?.loading,
    error: allAccounts?.error,
    riskAccountsColumnInfo,
    expiredAccountColumnInfo,
    expiredAccountNum,
    expiredInMonths,
    threeMonthsLater,
    currentDate,
    decliningAccountColumnInfo,
    trendAccountColumnInfo,
    decliningRows,
    trendRows,
    decliningNum,
    trendAccountNum,
    loadingState,
    setLoadingState,
  };
};
