import { CustomDataGrid } from '../../../../components/CustomDataGrid';
import { ConfirmDialogBox } from '../../../../components/CustomDialogBox';
import { withLoadingAndOverlay } from '../../../../hoc';

function HealthScoreList({
  columns, data, openDialog, handleClose, actionType, handleMakePrimaryConfirm, handleDeleteConfirm,
}) {
  return (
    <>
      <CustomDataGrid columns={columns} rows={data} rowId="healthScoreId" footer />
      <ConfirmDialogBox
        title={actionType === 'makePrimary' ? 'Make Primary KPI Score' : 'Delete KPI Score'}
        message={
          actionType === 'makePrimary'
            ? 'Are you sure you want to make this the primary KPI score? This may take some time.'
            : 'Are you sure you want to delete this KPI score?'
        }
        openDialog={openDialog}
        onClose={handleClose}
        onConfirm={actionType === 'makePrimary' ? handleMakePrimaryConfirm : handleDeleteConfirm}
      />
    </>
  );
}

export default withLoadingAndOverlay(HealthScoreList);
