import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useLayoutEffect } from 'react';
import zoomPlugin from 'chartjs-plugin-zoom';

function ComboChart({ chartData, graphHeight, chartRef }) {
  const {
    labels,
    datasets,
    chartOptions,
  } = chartData;
  useLayoutEffect(() => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      LineElement,
      PointElement,
      ArcElement,
      Title,
      Tooltip,
      Legend,
      Filler,
      zoomPlugin,
    );
    return () => {
      ChartJS.unregister(
        CategoryScale,
        LinearScale,
        BarElement,
        LineElement,
        PointElement,
        ArcElement,
        Title,
        Tooltip,
        Legend,
        Filler,
        zoomPlugin,
      );
    };
  }, [labels, datasets]);

  const data = { labels, datasets };
  return (
    <div>
      <Chart
        height={graphHeight || 340}
        type="bar"
        data={data}
        options={chartOptions}
        ref={chartRef}
      />
    </div>
  );
}

export default ComboChart;
