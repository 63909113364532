import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Stack, useMediaQuery } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CustomIcon from '../../../../components/CustomIcon/CustomIcon';
import { explainHealthScore } from '../../../../features/HealthScore/HealthScoreAction';
import useToggle from '../../../../hooks/useToggle';
import { CustomCircularProgress } from '../../../../components/CustomCircularProgress';
import { LabelCell } from '../../../../sections/HealthScoreConfig/useHealthScoreConfig';

const useAccountHealthScore = ({
  orgId, packageId, isShare, tenantId, accountOrgId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accountHealthScoreData = useSelector((state) => state.accountHealthScore);
  const [accountHealthScore, setAccountHealthScore] = useState({
    accountsLicense: {},
    error: null,
    loading: true,
  });

  const getSortedHealthScore = (unsortedData) => {
    const rows = unsortedData && unsortedData.length > 0
      ? unsortedData.map((item) => ({
        id: item.healthScoreId,
        healthScoreLabel: item.healthScoreLabel,
        progress: item.healthScore,
        isPrimary: item.isPrimary,
      }))
      : [];
    const sortedRows = rows.slice().sort((a, b) => b.isPrimary - a.isPrimary);
    return sortedRows;
  };

  const setAccountHealthScoreData = () => {
    setAccountHealthScore({
      accountsLicense: getSortedHealthScore(accountHealthScoreData?.accountsLicense),
      loading: accountHealthScoreData?.loading,
      error: accountHealthScoreData?.error,
    });
  };

  const handleClickSettings = () => {
    navigate('/kpi-score-configuration');
  };

  const [open, setOpen] = useToggle();
  const [selectedHealthScore, setSelectedHealthScore] = useState(null);

  const handleClickExplain = (id, score) => {
    setOpen();
    setSelectedHealthScore(score);
    if (id) {
      dispatch(explainHealthScore({
        orgId, packageId, healthScoreId: id, isShare, tenantId, accountOrgId,
      }));
    }
  };

  const columns = [
    {
      field: 'healthScoreLabel',
      minWidth: useMediaQuery('(max-width:600px)') ? ' ' : 300,
      flex: 1.5,
      renderCell: (params) => (
        <Stack direction="row" gap={2} alignItems="center">
          <Stack
            onClick={() => handleClickExplain(params.row.id, params.row.progress)}
            alignItems="center"
            sx={{ cursor: 'pointer' }}
          >
            <CustomCircularProgress width={35} height={35} value={params.row.progress} />
          </Stack>
          <LabelCell isPrimary={params.row.isPrimary} label={params.value} />
        </Stack>
      ),
    },
    {
      field: 'primary',
      type: 'number',
      minWidth: 120,
      flex: 0.5,
      renderCell: (params) => (
        <Stack direction="row" gap={1} alignItems="center">
          <CustomIcon
            tooltip="Explain KPI Score"
            Icon={InfoOutlinedIcon}
            onClick={() => handleClickExplain(params.row.id, params.row.progress)}
          />
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    setAccountHealthScoreData();
  }, [accountHealthScoreData]);

  return {
    open,
    setOpen,
    selectedHealthScore,
    accountHealthScore,
    columns,
    handleClickSettings,
    error: accountHealthScore?.error,
    loading: accountHealthScore?.loading,
  };
};

export default useAccountHealthScore;
