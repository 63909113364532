import { Grid, TextField } from '@mui/material';
import useAuthorization from '../../../hooks/useAuthorization';
import { InfoChip } from '../../../components/CustomChip';

function ProfileInfo() {
  const { userInfo } = useAuthorization();
  return (
    <Grid container gap={4}>
      <Grid item xs={12}>
        <InfoChip icon label="Account Information" />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Name"
          value={userInfo?.name}
          InputProps={{
            readOnly: true,
            style: { pointerEvents: 'none' },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Email"
          value={userInfo?.email}
          InputProps={{
            readOnly: true,
            style: { pointerEvents: 'none' },
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label="Access"
          value={userInfo?.role}
          InputProps={{
            readOnly: true,
            style: { pointerEvents: 'none' },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default ProfileInfo;
