import {
  Button,
  Dialog,
  IconButton, Stack, Tooltip, Typography,
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';

function CustomDialogBox({
  title, open, handleClose, content, actions, table, formRef,
}) {
  const handleActionClick = () => {
    if (formRef?.current) {
      formRef.current.handleSubmit();
    } else {
      actions.onClick();
    }
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle align="center">
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="space-between">
          {typeof title === 'string' ? (
            <Tooltip title={title}>
              <Typography noWrap variant="h6">{title}</Typography>
            </Tooltip>
          ) : title}
          <IconButton onClick={handleClose} sx={{ color: (theme) => theme.palette.grey[500], transform: 'translateX(10px)' }}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers sx={{ padding: table ?? '10px 24px' }}>
        {content}
      </DialogContent>
      {actions && (
      <DialogActions sx={{ m: 1, justifyContent: 'space-between' }}>
        <Typography>{actions.info || ''}</Typography>
        {actions.title && <Button variant="contained" size="small" mt={1} disabled={actions.disabled} onClick={handleActionClick}>{actions.title}</Button>}
      </DialogActions>
      )}
    </Dialog>
  );
}

export default CustomDialogBox;
