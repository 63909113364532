import { ENV } from '../../config';

const baseUrl = {
  PROD: 'https://api.appinsights.ai',
  UAT: 'https://stage-api.appinsights.ai',
  QA: 'https://isv-api-qa.examsbook.com',
  DEV: 'http://localhost:8888',
};

const config = {
  '/auth-login': '/api/v1/auth/login',
  '/auth-logout': '/api/v1/auth/logout',
  '/auth/forgot-password': '/api/v1/auth/forgot-password',
  '/auth/change-password': '/api/v1/auth/change-password',
  '/auth-refresh': '/api/v1/auth/refresh-session',
  '/auth/impersonate': '/api/v1/auth/impersonate',
  '/tenants-list': '/api/v1/tenants-list',
  '/tenants-create': '/api/v1/tenants-create',
  '/tenants-delete': '/api/v1/tenants-delete',
  '/tenants-disable': '/api/v1/tenants-disable',
  '/tenant-details': '/api/v1/tenants-details',
  '/tenant-info': '/api/v1/tenant-info',
  '/update-tenant-info': '/api/v1/update-tenant-info',
  '/user-list': '/api/v1/users-list',
  '/create-user': '/api/v1/create-user',
  '/delete-user': '/api/v1/delete-user',
  '/update-user': '/api/v1/update-user',
  '/update-user-status': '/api/v1/auth/update-user-status',
  '/feature-accounts': '/api/v2/feature-accounts',
  '/feature-counts': '/api/v2/feature-counts',
  '/custom-object': '/api/v1/custom-object',
  '/general-detail': '/api/v1/account/general-detail',
  '/active-user': '/api/v1/active-user',
  'event-browser/custom-object': '/api/v1/event-browser/custom-object',
  'event-browser/custom-object/list': '/api/v1/event-browser/custom-object/list',
  '/version-breakdown': '/api/v1/version-breakdown/account',
  '/edition-breakdown': '/api/v1/edition-breakdown/account',
  '/statistics': '/api/v1/statistics',
  '/feature-matrix': '/api/v1/feature-matrix',
  '/all-accounts': '/api/v1/all-accounts',
  '/account-visibility': '/api/v2/update-visibility-account',
  '/risk-accounts': '/api/v1/risk-accounts',
  '/account-license': '/api/v1/account-license',
  '/package-details': '/api/v1/package-details',
  '/acc-package-details': '/api/v1/account-package-details',
  '/feature-list': '/api/v2/feature-management',
  '/custom-entity-list': '/api/v2/feature-management/custom-entity-list',
  '/feature-details': '/api/v2/feature-management/feature-details',
  '/create-feature': '/api/v2/feature-management/create-feature',
  '/update-feature': '/api/v2/feature-management/update-feature',
  '/delete-feature': '/api/v2/feature-management/delete-feature',
  '/account-count-by-country-lang': '/api/v1/account-count/filter-country-language',
  '/custom-object-pinning-data': '/api/v1/custom-object-pinning-data',
  '/custom-object-pinning-action': '/api/v1/custom-object-pinning-action',
  '/feature-pinning': '/api/v1/feature-pinning',
  '/update-account': '/api/v1/account-update',
  '/health-score/list': '/api/v1/health-score/list',
  '/health-score/delete': '/api/v1/health-score/delete',
  '/health-score/update': '/api/v1/health-score/update',
  '/health-score/account-primary': '/api/v1/health-score/account-primary',
  '/health-score': '/api/v1/health-score',
  '/health-score/details': '/api/v1/health-score/details',
  '/health-score-account': '/api/v1/health-score/account',
  '/health-score-graph': '/api/v1/health-score/graph',
  '/health-score/explain': '/api/v1/health-score/explain',
  '/active-user-config': '/api/v1/active-user-config',
  '/active-user-setup': '/api/v1/active-user-setup',
  '/license-timeline-data': '/api/v1/license-timeline-data',
  '/health-score-trends': '/api/v1/health-score-trends',
  '/custom-filter-view': '/api/v1/custom-filter',
  '/custom-filter-view-list': '/api/v1/custom-filter',
  '/delete-custom-filter-view': '/api/v1/custom-filter',
  '/save-pbo-connection-code': '/api/v1/salesforce-pbo-connection',
  '/get-pbo-connection-details': '/api/v1/get-salesforce-pbo-connection',
  '/disconnect-pbo-connection': '/api/v1/disconnect-pbo-connection',
  '/refresh-pbo-connection': '/api/v1/refresh-pbo-connection',
  '/watch-list-accounts': '/api/v1/watch-list-accounts',
  '/get-custom-alert-details': '/api/v1/custom-alert',
  '/save-custom-alert-details': '/api/v1/custom-alert',
  '/redirect-url': '/api/v1/redirect-url',
  '/notification-settings': '/api/v1/notification-settings',
  '/high-risk-account-setup': '/api/v1/high-risk-account-setup',
  '/get-high-risk-acc-config': '/api/v1/high-risk-account-config',
  '/log-analysis': '/api/v2/analysis-log-details',
  '/version-kpi-score/account': '/api/v1/version-kpi-score/account',
  '/country-kpi-score/account': '/api/v1/country-kpi-score/account',
  '/license-assigned-kpi-score/account': '/api/v1/license-assigned-kpi-score/account',
  '/get-remarks': '/api/v1/get-remarks',
  '/remarks': '/api/v1/remarks',
  '/kpi-preview': '/api/v1/kpi-preview',
  '/package-disable': '/api/v1/package-disable',
  '/object-log-details': '/api/v2/object-log-details',
  '/feature-parameter': '/api/v1/feature-parameter',
};

export const generateUrl = (path, environment) => {
  if (baseUrl[environment] && config[path]) {
    return `${baseUrl[environment]}${config[path]}`;
  }
  return null;
};

export const getBaseUrl = () => {
  return baseUrl[ENV] ?? null;
};
