import { cloneElement } from 'react';
import { permissions } from '../config';
import Error404Page from '../components/ErrorBoundaries/Error404Page';

function usePermissions() {
  const getPermissions = (page, role) => {
    const specificPermissions = permissions[page]?.[role];
    const defaultPermissions = permissions.defaultPermissions[role];
    return specificPermissions || defaultPermissions;
  };

  const filterRoute = (route, role) => {
    let routePermissions = {};
    if (route.page) {
      routePermissions = getPermissions(route.page, role);
    } else {
      routePermissions = { read: true, write: true };
    }

    if (routePermissions.read === false) {
      return { ...route, element: <Error404Page title="404" message="Sorry, You Can not access This Page!" /> };
    }

    return { ...route, element: cloneElement(route.element, { permissions: routePermissions }) };
  };

  const filterRoutes = (routes, role = 'user') => {
    return routes?.map((route) => {
      if (route.children) {
        const parentRoute = filterRoute(route, role);
        const filteredChildren = filterRoutes(route.children, role);
        return filteredChildren.length ? { ...parentRoute, children: filteredChildren } : null;
      }

      return filterRoute(route, role);
    }).filter(Boolean);
  };

  return {
    filterRoutes,
    getPermissions,
  };
}

export default usePermissions;
