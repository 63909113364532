import DashboardCards from '../../components/DashboardCard/DashboardCards';
import LicenseUtilizationKpiScoreCard from '../../modules/LicenseUtilization/components/LicenseUtilizationKpiScoreCard';
import { useLicenseUtilizationKpiScore } from './useLicenseUtilizationKpiScore';

export default function LicenseUtilizationKpiScore(props) {
  const { title, refresh } = props;
  const { licenseUtilKpiScore } = useLicenseUtilizationKpiScore();

  return (
    <DashboardCards
      title={title}
      showButton={false}
    >
      <LicenseUtilizationKpiScoreCard
        loading={licenseUtilKpiScore?.loading}
        error={licenseUtilKpiScore?.error}
        data={licenseUtilKpiScore?.licenseUtilKpiScoreData}
        refresh={refresh}
        message="No Data"
      />
    </DashboardCards>
  );
}
