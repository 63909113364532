import { withLoadingAndOverlay } from '../../../hoc';
import { CustomDataGrid } from '../../../components/CustomDataGrid';
import { accountTopFeatureColumns, dashboardTopFeatureColumns } from '../../../utils/dataGridColumns';

function TopFeaturesCard(props) {
  const {
    orgId, data, sortModel, handleSortModelChange,
  } = props;

  return (
    <CustomDataGrid
      columns={orgId ? accountTopFeatureColumns : dashboardTopFeatureColumns}
      rows={data}
      rowId="featureId"
      sortModel={sortModel}
      onSortModelChange={handleSortModelChange}
    />
  );
}

export default withLoadingAndOverlay(TopFeaturesCard);
