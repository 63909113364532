import {
  IconButton, Stack, Tooltip, Typography, styled,
} from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import palette from '../../../../theme/palette';
import { MutedText } from '../../../../components/CustomTypography';
import { fCommaSeparated } from '../../../../utils/formatNumber';

const FeatureHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.mainColor,
  cursor: 'pointer',
  marginRight: '10px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  width: 'auto',
}));

function FeatureUsageHeader(props) {
  const {
    feature,
    accountCount,
    dashboard,
    handlePinning,
    handleOnRowClick,
    permissions,
  } = props;

  const activeAccounts = fCommaSeparated(accountCount);

  return (
    <Stack direction="row" alignItems="center">
      {(feature?.isCoreFeature || permissions.write) && (
        <IconButton
          disabled={!permissions.write}
          onClick={(event) => handlePinning(event, feature.featureId, feature.featureName)}
        >
          {feature?.isCoreFeature ? (
            <PushPinIcon sx={{ color: palette.text.primary }} />
          ) : (
            <PushPinOutlinedIcon
              sx={{ color: palette.text.primary, transform: 'matrix(0.87, 0.5, -0.5, 0.87, 0, 0)' }}
            />
          )}
        </IconButton>
      )}

      <Tooltip title={feature.featureName}>
        <FeatureHeader
          onClick={(event) => handleOnRowClick(event, { ...feature })}
          variant="h5"
        >
          {feature.featureName}
        </FeatureHeader>
      </Tooltip>
      {dashboard && <MutedText text={`(${activeAccounts} active accounts)`} tooltip="Active accounts in last month" />}
    </Stack>
  );
}

export default FeatureUsageHeader;
