import { Tab, Tabs } from '@mui/material';

function ProfileActions({ activeTab, handleChange }) {
  return (
    <Tabs value={activeTab} onChange={handleChange}>
      <Tab label="Profile" value="about" sx={{ fontSize: 18 }} />
      <Tab label="Settings" value="settings" sx={{ fontSize: 18 }} />
    </Tabs>
  );
}

export default ProfileActions;
