import ProfileInfo from './ProfileInfo';
import ProfileSettings from './ProfileSettings';
import ProfileTab from './ProfileTab';

function ProfileCard({ tab }) {
  let selectedTab;

  if (tab === 'settings') {
    selectedTab = <ProfileSettings />;
  } else {
    selectedTab = <ProfileInfo />;
  }

  return <ProfileTab tab={tab}>{selectedTab}</ProfileTab>;
}

export default ProfileCard;
