import { Button, ButtonGroup, Tooltip } from '@mui/material';
import { ZoomIn, ZoomOut, Refresh } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import { useEffect, useState } from 'react';
import { toolTipText } from '../../utils/toolTipText';

function ZoomControls({ chartRef, data }) {
  const [zoomInClickCount, setZoomInClickCount] = useState(0);

  const handleZoomIn = () => {
    setZoomInClickCount((prevCount) => prevCount + 1);
    if (chartRef.current) {
      chartRef.current.zoom(1.1);
    }
  };

  const handleZoomOut = () => {
    setZoomInClickCount((prevCount) => prevCount - 1);

    chartRef.current.zoom(0.9);
  };

  const handleResetZoom = () => {
    if (chartRef.current) {
      setZoomInClickCount(0);
      chartRef.current.resetZoom();
    }
  };

  useEffect(() => {
    if (chartRef.current && data) {
      chartRef.current.resetZoom();
    }
  }, [data]);

  return (
    <ButtonGroup variant="contained" size="small" sx={{ alignItems: 'center' }}>
      <Button onClick={handleZoomOut}>
        <ZoomOut />
      </Button>
      <Button onClick={handleResetZoom}>
        <Refresh />
      </Button>
      <Button onClick={handleZoomIn} disabled={zoomInClickCount >= 7}>
        <ZoomIn />
      </Button>
      <Tooltip title={toolTipText.activeUsersEngagement.ZOOM_INFO}>
        <InfoIcon sx={{ ml: 1 }} color="primary" />
      </Tooltip>
    </ButtonGroup>
  );
}

export default ZoomControls;
