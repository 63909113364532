import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CollapsibleTable, DynamicTable } from '../../../../components/CustomTable';

const columns = [
  {
    field: 'customEntityType',
    headerName: 'Object Type',

  },
  {
    field: 'accounts',
    headerName: 'Accounts',
  },
  {
    field: 'users',
    headerName: 'Users',
  },
  {
    field: 'operations',
    headerName: 'Operations',
  },
];

const entityColumns = [
  {
    field: 'customEntity',
    headerName: 'Entity',

  },
  {
    field: 'type',
    headerName: 'Type',
  },
  {
    field: 'accCount',
    headerName: 'Accounts',
  },
  {
    field: 'userCount',
    headerName: 'Users',
  },
  {
    field: 'opCount',
    headerName: 'Operations',
  },
];

function LogAnalyzerTable(props) {
  const {
    customEntityTpe, onRowExpand, selectedFilters, logAnalyzerInfo,
  } = props;

  const logAnalyzerDetails = useSelector((state) => state.logAnalyzerDetails?.data);

  const modifiedData = logAnalyzerDetails?.map((item) => {
    const [baseEntity, type] = item.customEntity.split(':');

    return ({
      type,
      customEntity: baseEntity,
      opCount: item.opCount,
      userCount: item.userCount,
      accCount: item.accCount,
    });
  });

  const modifiedColumns = useMemo(() => {
    if (selectedFilters.account?.value === '- all -') {
      return columns;
    }
    return columns.filter((column) => column.field !== 'accounts');
  }, [logAnalyzerInfo?.entityOpCount]);

  const modifiedEntityColumns = useCallback((type) => {
    let localColumns = entityColumns;
    if (selectedFilters.account?.value !== '- all -') {
      localColumns = localColumns.filter((column) => column.field !== 'accCount');
    }
    if (type !== 'CustomObject') {
      return localColumns.filter((column) => column.field !== 'type');
    }
    return localColumns;
  }, [logAnalyzerInfo?.entityOpCount]);

  const rows = useMemo(() => {
    const result = logAnalyzerInfo?.entityOpCount?.map((item) => {
      const obj = {
        id: item.type,
        customEntityType: item.type,
        operations: item.operationCount,
        ...(selectedFilters.account?.value === '- all -' && { accounts: item.accountCount }),
        users: item.userCount,
        content: <DynamicTable showHeader columns={modifiedEntityColumns(item.type)} rows={customEntityTpe === 'CustomObject' ? modifiedData : logAnalyzerDetails} />,
      };
      return obj;
    });

    return result ?? [];
  }, [logAnalyzerInfo?.entityOpCount, logAnalyzerDetails]);

  return (
    <CollapsibleTable onRowExpand={onRowExpand} columns={modifiedColumns} rows={rows} />
  );
}

export default LogAnalyzerTable;
