import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';
import moment from 'moment';
import { updateTenantInfo } from '../../../../features/Tenant/tenantAction';
import { openSnackbar } from '../../../../features/SnackBar/snackBarSlice';

const useTenantDetailDialog = ({ tenantId, onClose, dialogData }) => {
  const dispatch = useDispatch();

  const formData = useMemo(() => {
    const initialValues = {
      status: dialogData?.status,
      expiry: dialogData?.expiry,
    };

    const validations = Yup.object().shape({
      status: Yup.string().required('Email is required'),
    });

    const onSubmit = ({ status, expiry }) => {
      const momentDate = expiry ? moment(expiry) : null;
      const expirationDate = momentDate?.isValid() ? momentDate.format('YYYY-MM-DD') : null;

      dispatch(updateTenantInfo({ tenantId, status, expirationDate })).then((res) => {
        if (res.payload.status === 'SUCCESS') {
          dispatch(openSnackbar({ open: true, message: res.payload.message, type: 'success' }));
        } else {
          dispatch(openSnackbar({ open: true, message: res.payload.message, type: 'error' }));
        }
      });
      onClose();
    };

    return {
      initialValues,
      validations,
      onSubmit,
    };
  }, [tenantId, dialogData]);

  return formData;
};
export default useTenantDetailDialog;
