import { createSlice } from '@reduxjs/toolkit';
import { getHealthScoreTrends } from './healthScoreTrendsAction';

const healthScoreTrendsSlice = createSlice({
  name: 'healthScoreTrends',
  initialState: {
    loading: false,
    healthScoreTrendsData: [],
    error: null,
  },
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(getHealthScoreTrends.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getHealthScoreTrends.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.healthScoreTrendsData = payload.dateRange;
    });
    builder.addCase(getHealthScoreTrends.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export {
  healthScoreTrendsSlice,
};
