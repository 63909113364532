import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  defaultFilterValue, defaultView, fixedWatchList, initialFilterValue,
} from '../../../sections/AccountList/accountListConfig';

function useCustomFilter(props) {
  const {
    setSelectedFilters,
    setSelectedView,
    findSelectedView,
    setToggleAdvanceFilters,
  } = props;

  const [customFilters, setCustomFilters] = useState([defaultView, ...fixedWatchList]);
  const customFilterViewList = useSelector((state) => state.customFilterView.data);

  const handleCustomView = (e) => {
    const { value } = e.target;
    setSelectedView(value);

    if (value === defaultFilterValue) {
      setSelectedFilters(initialFilterValue);
      setToggleAdvanceFilters(false);
      return null;
    }

    const data = findSelectedView(value);
    if (data?.filter) {
      setSelectedFilters({ ...initialFilterValue, ...data.filter });
      setToggleAdvanceFilters(data.filter.toggleAdvanceFilter);
    }
    return null;
  };

  useEffect(() => {
    const defaultOptions = [defaultView, ...fixedWatchList];
    if (customFilterViewList) {
      const customFilterOptions = customFilterViewList.map((item) => ({
        name: item.name,
        value: item.id,
        id: item.id,
      }));
      setCustomFilters([...defaultOptions, ...customFilterOptions]);
    }
  }, [customFilterViewList]);

  return {
    customFilters,
    handleCustomView,
  };
}

export default useCustomFilter;
