export const HourGlassJson = {
  v: '5.0.1',
  fr: 10,
  ip: 0,
  op: 25,
  w: 297,
  h: 297,
  assets: [],
  layers: [{
    ind: 1,
    nm: 'hourGlass',
    ks: {
      p: { a: 0, k: [130.501, 148.5] },
      a: { a: 0, k: [130.501, 148.5, 0] },
      s: { a: 0, k: [100, 100, 100] },
      r: {
        a: 1,
        k: [{
          t: 0, s: [0], i: { x: [0.55], y: [0.95] }, o: { x: [0.445], y: [0.05] }, e: [0],
        }, {
          t: 20, s: [0], i: { x: [0.55], y: [0.95] }, o: { x: [0.445], y: [0.05] }, e: [-5],
        }, {
          t: 23, s: [-5], i: { x: [0.55], y: [0.95] }, o: { x: [0.445], y: [0.05] }, e: [180],
        }, { t: 25, s: [180] }],
      },
      o: { a: 0, k: 100 },
    },
    ao: 0,
    ip: 0,
    op: 25,
    st: 0,
    bm: 0,
    sr: 1,
    ty: 4,
    shapes: [{
      ty: 'gr',
      it: [{
        ty: 'sh',
        d: 1,
        ks: {
          a: 0,
          k: {
            i: [[4, 0], [5.244, 9.829], [0.031, 22.439], [4.97, 34.245000000000005], [0, 46.527], [4.971, 58.957], [-0.132, 71.71900000000001], [5, 83.301], [0.089, 94.297], [4.919, 106.28699999999999], [-0.159, 119.70700000000001], [5.106, 131.92000000000002], [0, 142.391], [5.06, 152.432], [1.667, 162.361]], o: [[5.667, 5.046], [4.808, 19.305], [-0.032, 30.929000000000002], [5.031, 43.029], [0, 55.459], [5.03, 68.037], [0.115, 80.35600000000001], [5, 91.353], [-0.099, 102.787], [5.089, 115.664], [0.135, 128.787], [4.905, 139.827], [0, 149.869], [4.944, 159.68499999999997], [0, 165.456]], v: [[4, 0], [5, 15.137], [0, 26.611], [5, 38.563], [0, 50.993], [5, 63.423], [0, 76.331], [5, 87.327], [0, 98.322], [5, 110.752], [0, 124.617], [5, 136.09], [0, 146.13], [5, 156.17], [0, 165.456]], c: false,
          },
          hd: false,
        },
        _render: true,
      }, {
        ty: 'st',
        c: { a: 0, k: [1, 1, 1, 1] },
        o: { a: 0, k: 100 },
        w: { a: 0, k: 4 },
        lc: 2,
        lj: 2,
        ml: 4,
        d: [{ n: 'd', nm: 'dash', v: { a: 0, k: 181.74 } }, { n: 'g', nm: 'gap', v: { a: 0, k: 181.74 } }, {
          n: 'o',
          nm: 'offset',
          v: {
            a: 1,
            k: [{
              t: 0, s: [181.74], i: { x: [0.55], y: [0.95] }, o: { x: [0.445], y: [0.05] }, e: [0],
            }, {
              t: 10, s: [0], i: { x: [0.55], y: [0.95] }, o: { x: [0.445], y: [0.05] }, e: [181.74],
            }, { t: 20, s: [181.74] }],
          },
        }],
        _render: true,
      }, {
        ty: 'tr',
        p: { a: 0, k: [148.5, 106.877] },
        a: { a: 0, k: [0, 0] },
        s: { a: 0, k: [100, 100] },
        r: { a: 0, k: 0 },
        o: {
          a: 1,
          k: [{
            t: 14, s: [100], i: { x: [0.55], y: [0.95] }, o: { x: [0.445], y: [0.05] }, e: [0],
          }, { t: 15, s: [0] }],
        },
        sk: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        _render: true,
      }],
      nm: 'water',
      ln: 'water',
      hd: false,
      _render: true,
    }, {
      ty: 'gr',
      it: [{
        ty: 'sh',
        d: 1,
        ks: {
          a: 1,
          k: [{
            i: { x: [0.55], y: [0.95] },
            o: { x: [0.445], y: [0.05] },
            t: 0,
            s: [{
              i: [[0, 0], [61.561, -0.097], [19.362, -81.25699999999999], [-53.18, -8.088], [-20.519, 0], [0, 0]], o: [[20.52, 0], [54.753, -9.354999999999999], [-18.865000000000002, -98.207], [-61.557, -0.097], [0, 0], [0, 0]], v: [[0, 0], [61.561, -0.097], [0.002, -88.892], [-61.557, -0.097], [0, 0], [0, 0]], c: true,
            }],
            e: [{
              i: [[0.002, -7.161], [61.561, -0.097], [19.362, -81.25699999999999], [-53.18, -8.088], [-20.472, -14.408], [0.002, -7.161]], o: [[19.346999999999998, -0.31399999999999917], [54.753, -9.354999999999999], [-18.865000000000002, -98.207], [-61.557, -0.097], [0.002, -7.161], [0.002, -7.161]], v: [[0.002, -7.161], [61.561, -0.097], [0.002, -88.892], [-61.557, -0.097], [0.002, -7.161], [0.002, -7.161]], c: true,
            }],
          }, {
            i: { x: [0.55], y: [0.95] },
            o: { x: [0.445], y: [0.05] },
            t: 6,
            s: [{
              i: [[0.002, -7.161], [61.561, -0.097], [19.362, -81.25699999999999], [-53.18, -8.088], [-20.472, -14.408], [0.002, -7.161]], o: [[19.346999999999998, -0.31399999999999917], [54.753, -9.354999999999999], [-18.865000000000002, -98.207], [-61.557, -0.097], [0.002, -7.161], [0.002, -7.161]], v: [[0.002, -7.161], [61.561, -0.097], [0.002, -88.892], [-61.557, -0.097], [0.002, -7.161], [0.002, -7.161]], c: true,
            }],
            e: [{
              i: [[0.002, -7.161], [61.561, -0.097], [19.362, -81.25699999999999], [-53.18, -8.088], [-19.881, 1.5790000000000006], [0.002, -7.161]], o: [[19, -15.511], [54.753, -9.354999999999999], [-18.865000000000002, -98.207], [-61.557, -0.097], [0.002, -7.161], [0.002, -7.161]], v: [[0.002, -7.161], [61.561, -0.097], [0.002, -88.892], [-61.557, -0.097], [0.002, -7.161], [0.002, -7.161]], c: true,
            }],
          }, {
            i: { x: [0.55], y: [0.95] },
            o: { x: [0.445], y: [0.05] },
            t: 8,
            s: [{
              i: [[0.002, -7.161], [61.561, -0.097], [19.362, -81.25699999999999], [-53.18, -8.088], [-19.881, 1.5790000000000006], [0.002, -7.161]], o: [[19, -15.511], [54.753, -9.354999999999999], [-18.865000000000002, -98.207], [-61.557, -0.097], [0.002, -7.161], [0.002, -7.161]], v: [[0.002, -7.161], [61.561, -0.097], [0.002, -88.892], [-61.557, -0.097], [0.002, -7.161], [0.002, -7.161]], c: true,
            }],
            e: [{
              i: [[0.002, -7.161], [61.561, -0.097], [19.362, -81.25699999999999], [-53.18, -8.088], [-22.168000000000003, -22.296999999999997], [0.002, -7.161]], o: [[17.141, 4.540000000000001], [54.753, -9.354999999999999], [-18.865000000000002, -98.207], [-61.557, -0.097], [0.002, -7.161], [0.002, -7.161]], v: [[0.002, -7.161], [61.561, -0.097], [0.002, -88.892], [-61.557, -0.097], [0.002, -7.161], [0.002, -7.161]], c: true,
            }],
          }, {
            i: { x: [0.55], y: [0.95] },
            o: { x: [0.445], y: [0.05] },
            t: 10,
            s: [{
              i: [[0.002, -7.161], [61.561, -0.097], [19.362, -81.25699999999999], [-53.18, -8.088], [-22.168000000000003, -22.296999999999997], [0.002, -7.161]], o: [[17.141, 4.540000000000001], [54.753, -9.354999999999999], [-18.865000000000002, -98.207], [-61.557, -0.097], [0.002, -7.161], [0.002, -7.161]], v: [[0.002, -7.161], [61.561, -0.097], [0.002, -88.892], [-61.557, -0.097], [0.002, -7.161], [0.002, -7.161]], c: true,
            }],
            e: [{
              i: [[0.002, -7.161], [61.561, -0.097], [19.362, -81.25699999999999], [-53.18, -8.088], [-20.594, -24.378], [0.002, -7.161]], o: [[0.002, -7.161], [54.753, -9.354999999999999], [-18.865000000000002, -98.207], [-61.557, -0.097], [0.002, -7.161], [0.002, -7.161]], v: [[0.002, -7.161], [61.561, -0.097], [0.002, -88.892], [-61.557, -0.097], [0.002, -7.161], [0.002, -7.161]], c: true,
            }],
          }, { t: 13 }],
          hd: false,
        },
        _render: true,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1] }, hd: false, o: { a: 0, k: 100 }, r: 1, _render: true,
      }, {
        ty: 'tr',
        p: { a: 0, k: [152.5, 109.5] },
        a: { a: 0, k: [-4.001, -89.515] },
        s: {
          a: 1,
          k: [{
            t: 2, s: [100, 100], i: { x: [0.55], y: [0.95] }, o: { x: [0.445], y: [0.05] }, e: [13.909, 13.909],
          }, { t: 13, s: [13.909, 13.909] }],
        },
        r: { a: 0, k: 180 },
        o: {
          a: 1,
          k: [{
            t: 14, s: [100], i: { x: [0.55], y: [0.95] }, o: { x: [0.445], y: [0.05] }, e: [0],
          }, { t: 15, s: [0] }],
        },
        sk: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        _render: true,
      }],
      nm: 'top',
      ln: 'top',
      hd: false,
      _render: true,
    }, {
      ty: 'gr',
      it: [{
        ty: 'sh',
        d: 1,
        ks: {
          a: 1,
          k: [{
            i: { x: [0.55], y: [0.95] },
            o: { x: [0.445], y: [0.05] },
            t: 0,
            s: [{
              i: [[0, 0], [61.561, -0.097], [24.003, -72.635], [-61.557, -0.097], [0, 0]], o: [[0, 0], [61.561, -0.097], [-23.997, -72.635], [-61.557, -0.097], [0, 0]], v: [[0, 0], [61.561, -0.097], [0.003, -72.635], [-61.557, -0.097], [0, 0]], c: true,
            }],
            e: [{
              i: [[0, 0], [61.561, -0.097], [30.503, -93.32900000000001], [-61.557, -0.097], [0, 0]], o: [[0, 0], [61.561, -0.097], [-13.001000000000001, -52.517], [-61.557, -0.097], [0, 0]], v: [[0, 0], [61.561, -0.097], [4.503, -68.938], [-61.557, -0.097], [0, 0]], c: true,
            }],
          }, {
            i: { x: [0.55], y: [0.95] },
            o: { x: [0.445], y: [0.05] },
            t: 9,
            s: [{
              i: [[0, 0], [61.561, -0.097], [30.503, -93.32900000000001], [-61.557, -0.097], [0, 0]], o: [[0, 0], [61.561, -0.097], [-13.001000000000001, -52.517], [-61.557, -0.097], [0, 0]], v: [[0, 0], [61.561, -0.097], [4.503, -68.938], [-61.557, -0.097], [0, 0]], c: true,
            }],
            e: [{
              i: [[0, 0], [61.561, -0.097], [13.503, -50.394000000000005], [-61.557, -0.097], [0, 0]], o: [[0, 0], [61.561, -0.097], [-5.975999999999999, -90.529], [-61.557, -0.097], [0, 0]], v: [[0, 0], [61.561, -0.097], [4.503, -68.938], [-61.557, -0.097], [0, 0]], c: true,
            }],
          }, {
            i: { x: [0.55], y: [0.95] },
            o: { x: [0.445], y: [0.05] },
            t: 10,
            s: [{
              i: [[0, 0], [61.561, -0.097], [13.503, -50.394000000000005], [-61.557, -0.097], [0, 0]], o: [[0, 0], [61.561, -0.097], [-5.975999999999999, -90.529], [-61.557, -0.097], [0, 0]], v: [[0, 0], [61.561, -0.097], [4.503, -68.938], [-61.557, -0.097], [0, 0]], c: true,
            }],
            e: [{
              i: [[0, 0], [61.561, -0.097], [19.563000000000002, -83.011], [-61.557, -0.097], [0, 0]], o: [[0, 0], [61.561, -0.097], [-5.497, -59.593], [-61.557, -0.097], [0, 0]], v: [[0, 0], [61.561, -0.097], [4.503, -68.938], [-61.557, -0.097], [0, 0]], c: true,
            }],
          }, {
            i: { x: [0.55], y: [0.95] },
            o: { x: [0.445], y: [0.05] },
            t: 12,
            s: [{
              i: [[0, 0], [61.561, -0.097], [19.563000000000002, -83.011], [-61.557, -0.097], [0, 0]], o: [[0, 0], [61.561, -0.097], [-5.497, -59.593], [-61.557, -0.097], [0, 0]], v: [[0, 0], [61.561, -0.097], [4.503, -68.938], [-61.557, -0.097], [0, 0]], c: true,
            }],
            e: [{
              i: [[0, 0], [61.561, -0.097], [20.503, -68.938], [-61.557, -0.097], [0, 0]], o: [[0, 0], [61.561, -0.097], [-9.184, -68.938], [-61.557, -0.097], [0, 0]], v: [[0, 0], [61.561, -0.097], [4.503, -68.938], [-61.557, -0.097], [0, 0]], c: true,
            }],
          }, { t: 14 }],
          hd: false,
        },
        _render: true,
      }, {
        ty: 'fl', c: { a: 0, k: [1, 1, 1, 1] }, hd: false, o: { a: 0, k: 100 }, r: 1, _render: true,
      }, {
        ty: 'tr',
        p: { a: 0, k: [148.497, 277.112] },
        a: { a: 0, k: [0, 0] },
        s: {
          a: 1,
          k: [{
            t: 5, s: [100, 0], i: { x: [0.55], y: [0.95] }, o: { x: [0.445], y: [0.05] }, e: [100, 100],
          }, { t: 17, s: [100, 100] }],
        },
        r: { a: 0, k: 0 },
        o: { a: 0, k: 100 },
        sk: { a: 0, k: 0 },
        sa: { a: 0, k: 0 },
        _render: true,
      }],
      nm: 'down',
      ln: 'down',
      hd: false,
      _render: true,
    }, {
      ty: 'gr',
      it: [{
        ty: 'tr', p: { a: 0, k: [0, 0] }, a: { a: 0, k: [0, 0] }, s: { a: 0, k: [100, 100] }, r: { a: 0, k: 0 }, o: { a: 0, k: 100 }, sk: { a: 0, k: 0 }, sa: { a: 0, k: 0 }, _render: true,
      }],
      nm: 'Object',
      hd: false,
      _render: true,
    }, {
      ty: 'gr',
      it: [{
        ty: 'sh',
        d: 1,
        ks: {
          a: 0,
          k: {
            i: [[251.01, 277.015], [233.327, 277.015], [233.325, 245.456], [215.967, 184.73000000000002], [177.983, 155.105], [180.461, 136.127], [233.325, 83.18299999999999], [233.327, 19.985], [251.01, 19.985], [261.002, 15.510000000000002], [256.527, 0], [45.99, 0], [35.998, 4.4750000000000005], [40.473, 19.985], [63.673, 19.985], [63.673, 51.543], [81.03, 112.27199999999999], [119.015, 141.894], [116.537, 160.873], [63.673, 213.81599999999997], [63.673, 277.015], [45.99, 277.015], [35.998, 281.49], [40.473, 297], [251.01, 297], [261.002, 292.52599999999995], [256.527, 277.015]], o: [[251.01, 277.015], [233.327, 277.015], [233.325, 213.81699999999998], [180.461, 160.87300000000002], [177.983, 141.895], [215.967, 112.269], [233.327, 51.543], [233.327, 19.985], [256.52799999999996, 19.985], [261.002, 4.474000000000001], [251.01, 0], [40.472, 0], [35.998, 15.511000000000001], [45.99, 19.985], [63.673, 19.985], [63.673, 83.185], [116.537, 136.127], [119.015, 155.105], [81.03, 184.72899999999998], [63.673, 245.456], [63.673, 277.015], [40.472, 277.015], [35.998, 292.527], [45.99, 297], [256.52799999999996, 297], [261.002, 281.489], [251.01, 277.015]], v: [[251.01, 277.015], [233.327, 277.015], [233.325, 245.456], [184.449, 163.555], [177.983, 148.5], [184.447, 133.447], [233.327, 51.543], [233.327, 19.985], [251.01, 19.985], [261.002, 9.992], [251.01, 0], [45.99, 0], [35.998, 9.992], [45.99, 19.985], [63.673, 19.985], [63.673, 51.543], [112.548, 133.446], [119.015, 148.5], [112.549, 163.553], [63.673, 245.456], [63.673, 277.015], [45.99, 277.015], [35.998, 287.008], [45.99, 297], [251.01, 297], [261.002, 287.008], [251.01, 277.015]], c: true,
          },
          hd: false,
        },
        _render: true,
      }, {
        ty: 'sh',
        d: 1,
        ks: {
          a: 0,
          k: {
            i: [[138.508, 110.362], [138.508, 110.362]], o: [[138.508, 104.844], [138.508, 110.362]], v: [[138.508, 110.362], [138.508, 110.362]], c: true,
          },
          hd: false,
        },
        _render: true,
      }, {
        ty: 'sh',
        d: 1,
        ks: {
          a: 0,
          k: {
            i: [[141.433, 173.956], [141.433, 173.956]], o: [[143.291, 172.099], [141.433, 173.956]], v: [[141.433, 173.956], [141.433, 173.956]], c: true,
          },
          hd: false,
        },
        _render: true,
      }, {
        ty: 'sh',
        d: 1,
        ks: {
          a: 0,
          k: {
            i: [[86.94, 277.112], [86.94, 277.112]], o: [[95.092, 246.20600000000002], [86.94, 277.112]], v: [[86.94, 277.112], [86.94, 277.112]], c: true,
          },
          hd: false,
        },
        _render: true,
      }, {
        ty: 'fl', c: { a: 0, k: [0.3255, 0.349, 0.9922, 1] }, hd: false, o: { a: 0, k: 100 }, r: 1, _render: true,
      }, {
        ty: 'tr', p: { a: 0, k: [0, 0] }, a: { a: 0, k: [0, 0] }, s: { a: 0, k: [100, 100] }, r: { a: 0, k: 0 }, o: { a: 0, k: 100 }, sk: { a: 0, k: 0 }, sa: { a: 0, k: 0 }, _render: true,
      }],
      nm: 'Object',
      hd: false,
      _render: true,
    }],
    ln: 'hourGlass',
    completed: true,
  }],
  __complete: true,
};
