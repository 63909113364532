import { FormDialogBox } from '../../../../components/CustomDialogBox';
import ActiveUserConfigForm from './ActiveUserConfigForm';

function ActiveUserConfigDialog(props) {
  const {
    open, handleClose, onSubmit, filteredFeatures, activeUserConfig,
  } = props;

  const initialValues = {
    config: activeUserConfig?.data?.activeUserConfig ?? false,
    selectedKpi: activeUserConfig?.data?.featureId ?? '',
  };

  return (
    <FormDialogBox
      title="Configure Active User"
      open={open}
      handleClose={handleClose}
      initialValues={initialValues}
      onSubmit={onSubmit}
      formContent={<ActiveUserConfigForm filteredFeatures={filteredFeatures} />}
      formAction={{ title: 'Update' }}
    />
  );
}

export default ActiveUserConfigDialog;
