import { Box, Grid } from '@mui/material';

function ProfileTab({ children, tab }) {
  return (
    <Grid container py={5}>
      {/* Image */}
      <Grid container item xs={4} alignItems="start" justifyContent="center">
        <Box
          width="80%"
          sx={(theme) => ({
            backgroundColor: theme.palette.secondary.lighter,
            borderRadius: 5,
            padding: 5,
          })}
        >
          <img src={tab === 'settings' ? '/assets/accSettings.svg' : '/assets/accInfo.svg'} width="100%" alt="" />
        </Box>
      </Grid>

      {/* Info */}
      <Grid container item xs={5} direction="column" justifyContent="start" alignItems="start">
        {children}
      </Grid>
    </Grid>
  );
}

export default ProfileTab;
