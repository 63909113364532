import { Helmet } from 'react-helmet-async';

export default function Metatags({ metaTagName }) {
  return (
    <Helmet>
      <title>
        {metaTagName}
      </title>
    </Helmet>
  );
}
