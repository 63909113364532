import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useLocation, useNavigate } from 'react-router-dom';
import { openSnackbar } from '../../../features/SnackBar/snackBarSlice';
import { loginUser, setPassword } from '../../../features/Auth/authAction';

const setPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  fullName: Yup.string().required('Name is required'),
  newPassword: Yup.string()
    .required('Password is required')
    .min(8, 'Password should be at least 8 characters long')
    .test('contains-lowercase', 'Password should contain at least one lowercase letter', (value) => {
      return /[a-z]/.test(value);
    })
    .test('contains-uppercase', 'Password should contain at least one uppercase letter', (value) => {
      return /[A-Z]/.test(value);
    })
    .test('contains-digit', 'Password should contain at least one digit', (value) => {
      return /[0-9]/.test(value);
    })
    .test('contains-special-char', 'Password should contain at least one special character ', (value) => {
      return /[@$!%*?&]/.test(value);
    }),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match'),
});

const resetPasswordValidationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  newPassword: Yup.string()
    .required('Password is required')
    .min(8, 'Password should be at least 8 characters long')
    .test('contains-lowercase', 'Password should contain at least one lowercase letter', (value) => {
      return /[a-z]/.test(value);
    })
    .test('contains-uppercase', 'Password should contain at least one uppercase letter', (value) => {
      return /[A-Z]/.test(value);
    })
    .test('contains-digit', 'Password should contain at least one digit', (value) => {
      return /[0-9]/.test(value);
    })
    .test('contains-special-char', 'Password should contain at least one special character ', (value) => {
      return /[@$!%*?&]/.test(value);
    }),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match'),
  code: Yup.string().required('Confirm Password is required').min(6, 'Invalid verification code'),
});

const useSetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const stateData = location.state;
  const storedEmail = stateData.email;
  const storedTenant = stateData.tenant;
  const storedPass = stateData.password;
  const isReset = !storedPass?.length;

  const auth = useSelector((state) => state.auth);

  const [showPassword, setShowPassword] = useState(false);
  const initialValuesReset = {
    email: storedEmail,
    tenant: storedTenant,
    newPassword: '',
    confirmPassword: '',
    code: '',
  };

  const initialValuesSet = {
    email: storedEmail,
    tenant: storedTenant,
    password: '',
    fullName: '',
    newPassword: '',
    confirmPassword: '',
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleApiCall = (apiCall, values, successPath) => {
    dispatch(apiCall(values))
      .then((res) => {
        const { error, payload: { message, status } } = res;
        if (error) {
          const err = message || res.payload;
          dispatch(openSnackbar({ open: true, message: err, type: 'error' }));
        }
        if (status === 'SUCCESS') {
          dispatch(openSnackbar({ open: true, message, type: 'success' }));
          navigate(successPath);
        }
      });
  };

  const handleSubmit = (values) => {
    if (isReset) {
      handleApiCall(setPassword, values, '/login');
    } else {
      const modifiedValues = {
        ...values,
        email: storedEmail,
        password: storedPass,
        type: 'setupAccount',
      };
      handleApiCall(loginUser, modifiedValues, '/dashboard');
    }
  };

  const validationSchema = isReset ? resetPasswordValidationSchema : setPasswordValidationSchema;
  const initialValues = isReset ? initialValuesReset : initialValuesSet;

  return {
    initialValues,
    auth,
    handleSubmit,
    validationSchema,
    showPassword,
    handleTogglePassword,
    storedEmail,
    isReset,
  };
};
export default useSetPassword;
