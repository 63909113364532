import { useLocation } from 'react-router-dom';

const useShareableScreen = () => {
  const location = useLocation();
  const { pathname, search } = location;

  let isShareableScreen = false;
  let isSalesforce = false;
  let tenantId = '';
  let organizationId = '';
  const shareableRoute = ['/account-page'];

  if (search) {
    const urlParams = new URLSearchParams(search);

    const isShare = urlParams.get('isShare') === 'true';
    if (isShare) {
      isShareableScreen = shareableRoute.includes(pathname);
      tenantId = urlParams.get('tenantId') || '';
      isSalesforce = urlParams.get('isSalesforce') === 'true';
    }

    if (isSalesforce) {
      organizationId = urlParams.get('organizationId') || '';
    }
  }

  return {
    isShareableScreen, tenantId, isSalesforce, organizationId,
  };
};

export default useShareableScreen;
