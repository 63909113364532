/* eslint-disable react/jsx-one-expression-per-line */
import { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { toolTipText } from '../../../../utils/toolTipText';
import { MutedText } from '../../../../components/CustomTypography';
import DashboardCards from '../../../../components/DashboardCard/DashboardCards';
import { DynamicTable } from '../../../../components/CustomTable';
import { CustomNoDataOverlay } from '../../../../components/CustomNoDataOverlay';
import { CustomLinearProgress } from '../../../../components/CustomLinearProgress';
import { updateAccount, saveRemarks } from '../../../../features/GeneralDetails/generalDetailAction';
import { DATE_FORMAT } from '../../../../utils/constants';
import { identifyAndConvertDate } from '../../../../utils/formatTime';
import BaselineUserDialogBox from './BaselineUserDialogBox';
import RemarksDialogBox from './RemarksDialogBox';
import { openSnackbar } from '../../../../features/SnackBar/snackBarSlice';

const isSiteWideLicense = (totalSeats) => totalSeats && parseInt(totalSeats, 10) > 0;

function StatusElement({ data }) {
  const isSuspendedOrChurned = data?.status === 'Suspended' || data?.status === 'Uninstalled';
  const activationDate = identifyAndConvertDate(data?.activationDate, DATE_FORMAT.DAY_DATE_GRAPH, 'YYYY-MM-DD') || 0;
  const expirationDate = identifyAndConvertDate(data?.expiration, DATE_FORMAT.DAY_DATE_GRAPH, 'YYYY-MM-DD') || 0;

  return (
    <Stack spacing={0.5}>
      <Typography>
        <b>{data?.status}</b>
        {isSuspendedOrChurned
          ? null
          : ` from ${activationDate}`}
      </Typography>
      {isSuspendedOrChurned ? <MutedText variant="body2" text={`Activation Date: ${activationDate}`} /> : null}
      <MutedText
        variant="body2"
        text={`Expiration Date: ${
          data?.expiration === null
            ? 'Does Not Expire'
            : expirationDate
        }`}
      />
    </Stack>

  );
}
function SeatAssignmentElement({ progressValue, data }) {
  if (isSiteWideLicense(data?.totalSeats)) {
    return (
      <Stack spacing={0.5}>
        {progressValue >= 0 && (
        <CustomLinearProgress
          value={progressValue}
          size={15}
          width="50%"
        />
        )}
        <MutedText variant="body2" text={`${data?.assignedSeats || 0} of ${data?.totalSeats || 0} is assigned `} />
      </Stack>
    );
  }
  return (
    <Stack spacing={0.5}>
      <Typography sx={{ fontStyle: 'italic' }}>Site-Wide License</Typography>
    </Stack>
  );
}
function BaselineElement({
  baselineUsers,
  handleClickBaseline,
  isShare,
  permissions,
}) {
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Typography>{baselineUsers || ' -- '}</Typography>
      {(permissions.write && !isShare) && (
      <IconButton size="small" onClick={handleClickBaseline}>
        <EditIcon
          fontSize="10px"
          color="primary"
        />
      </IconButton>
      )}
    </Stack>
  );
}
function RemarksElement({
  remarksValue,
  handleClickRemarks,
}) {
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Typography>{remarksValue}</Typography>
      <IconButton size="small" onClick={handleClickRemarks}>
        <EditIcon
          fontSize="10px"
          color="primary"
        />
      </IconButton>
    </Stack>
  );
}

export default function GeneralDetailSection({ generalDetail, isShare, permissions }) {
  const { data, loading } = generalDetail;
  const dataRedux = useSelector((state) => state.generalDetails.data);
  const allRemarks = useSelector((state) => state.allRemarks.data);
  const [baselineUsers, setBaselineUsers] = useState(0);
  const [remarksValue, setRemarksValue] = useState('');
  const progressValue = !isEmpty(data) && (data.assignedSeats / Number(data.totalSeats)) * 100;
  const location = useLocation();
  const dispatch = useDispatch();

  const [openBaselineDialog, setOpenBaselineDialog] = useState(false);
  const [openRemarksDialog, setOpenRemarksDialog] = useState(false);

  const handleClickBaseline = () => {
    setOpenBaselineDialog(true);
  };
  const handleCloseBaselineDialog = () => {
    setOpenBaselineDialog(false);
  };

  const handleClickRemarks = () => {
    setOpenRemarksDialog(true);
  };
  const handleCloseRemarksDialog = () => {
    setOpenRemarksDialog(false);
  };

  useEffect(() => {
    setBaselineUsers(dataRedux.ftUsers);
  }, [dataRedux.ftUsers, dataRedux.remarks]);

  useEffect(() => {
    if (allRemarks?.length) {
      setRemarksValue((allRemarks?.[0] ? allRemarks[0].remark : ''));
    } else {
      setRemarksValue('');
    }
  }, [allRemarks]);

  const handleSaveDialog = async ({ ftUsers, remarks }) => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('orgId');
    const pkgId = searchParams.get('pkgId');

    const newData = {};

    if (ftUsers) {
      newData.key = 'ftUsers';
      newData.ftUsers = ftUsers;
      setOpenBaselineDialog(false);
    }

    if (typeof remarks === 'string') {
      newData.key = 'remarks';
      newData.remarks = remarks;
      setOpenRemarksDialog(false);
    }

    dispatch(updateAccount({ id, pkgId, newData }));
  };

  const handleSaveRemarks = async ({ remarks }) => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('orgId');
    const pkgId = searchParams.get('pkgId');

    const newData = {};
    newData.remark = remarks;
    if (!remarks) {
      const errorMessage = 'Please fill Remarks';
      dispatch(openSnackbar({ open: true, message: errorMessage, type: 'error' }));
    } else {
      setOpenRemarksDialog(false);
      dispatch(saveRemarks({ accountId: id, packageId: pkgId, body: newData }));
      const message = 'Add Remarks Successfully';
      dispatch(openSnackbar({ open: true, message, type: 'success' }));
    }
  };

  const columns = [{ field: 'header', width: '30%' }, { field: 'element' }];
  const rows = [
    {
      header: <Typography>Status</Typography>,
      element: <StatusElement data={data} />,
    },
    {
      header: <Typography>Seats Assignment</Typography>,
      element: <SeatAssignmentElement
        progressValue={progressValue}
        data={data}
      />,
    },
    ...(isSiteWideLicense(data?.totalSeats) ? [{
      header:
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography>Baseline Users</Typography>
        <Tooltip title={toolTipText.accountDetail.BASELINE_USERS}>
          <InfoIcon color="primary" />
        </Tooltip>
      </Box>,
      element: <BaselineElement
        baselineUsers={baselineUsers}
        handleClickBaseline={handleClickBaseline}
        isShare={isShare}
        permissions={permissions}
      />,
    }] : []),
    {
      header: <Typography>Version Number</Typography>,
      element: <Typography>{data?.versionNumber}</Typography>,
    },
    {
      header: <Typography>Remarks</Typography>,
      element: <RemarksElement
        remarksValue={remarksValue}
        handleClickRemarks={handleClickRemarks}
        isShare={isShare}
        permissions={permissions}
      />,
    },
  ];

  return (
    <DashboardCards title="General">
      {loading ? <LinearProgress /> : null}
      {data && Object.keys(data)?.length > 0 && !loading && (
        <DynamicTable columns={columns} rows={rows} dialog />
      )}
      {(!data || !Object.keys(data)?.length) && !loading && (
        <CustomNoDataOverlay />
      )}
      <BaselineUserDialogBox
        openDialog={openBaselineDialog}
        onClose={handleCloseBaselineDialog}
        onSave={handleSaveDialog}
        baselineUsers={baselineUsers}
      />
      <RemarksDialogBox
        openDialog={openRemarksDialog}
        onClose={handleCloseRemarksDialog}
        onSave={handleSaveRemarks}
        remarksValue=""
        allRemarks={allRemarks}
      />
    </DashboardCards>
  );
}
