import { createSlice } from '@reduxjs/toolkit';
import { getAccountsList, updateAccountVisibility, getLicenseUtilKpiScore } from './accountListAction';

export const accountsListSlice = createSlice({
  name: 'accountsList',
  initialState: {},
  reducers: {
    resetAccountsList: (state) => {
      state.accountsList = [];
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccountsList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAccountsList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.accountsList = payload.responseArray;
      state.error = null;
    });
    builder.addCase(getAccountsList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const accountVisibilitySlice = createSlice({
  name: 'accountVisibility',
  initialState: {
    loading: true,
    error: '',
    message: '',
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateAccountVisibility.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateAccountVisibility.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.message = payload.message;
    });
    builder.addCase(updateAccountVisibility.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const licenseUtilKpiScoreSlice = createSlice({
  name: 'licenseUtilKpiScore',
  initialState: {
    loading: false,
    licenseUtilKpiScoreData: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLicenseUtilKpiScore.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getLicenseUtilKpiScore.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.licenseUtilKpiScoreData = payload.licenseAssignedData;
      state.error = null;
    });
    builder.addCase(getLicenseUtilKpiScore.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { resetAccountsList } = accountsListSlice.actions;
