import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useAuthorization from '../../hooks/useAuthorization';

function Broadcast() {
  const bc = new BroadcastChannel('IsvBroadcastChannel');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isTokenValidFn } = useAuthorization();
  const isTokenValid = isTokenValidFn();

  useEffect(() => {
    bc.onmessage = (event) => {
      if (event?.data === 'LogoutUser') {
        localStorage.clear();
        window.location.href = '/login';
      }

      if (event?.data === 'LoginUser' && isTokenValid) {
        navigate('/');
      }
    };
    return () => {
      bc.close();
    };
  }, [dispatch]);

  return null;
}

export default Broadcast;
