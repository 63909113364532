import {
  Grid, Stack, Button, Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { openSnackbar } from '../../features/SnackBar/snackBarSlice';
import { SelectBox } from '../../components/FormComponents';
import palette from '../../theme/palette';
import { resetAll } from '../../features/StateHandle/resetStateAction';
import { resetPackageList } from '../../features/Package/packageSlice';
import usePackageSelection from '../../modules/Accounts/components/PackageSelection/usePackageSelection';
import useAuthorization from '../../hooks/useAuthorization';
import useToggle from '../../hooks/useToggle';
import TenantDetailDialog from '../../modules/TenantDetail/component/TenantDetailDialog/TenantDetailDialog';
import { ConfirmDialogBox } from '../../components/CustomDialogBox';
import { UserManagerDialog } from '../../modules/UserManager';
import { fetchTenantDetails } from '../../features/Tenant/tenantAction';
import { impersonate, userList } from '../../features/Auth/authAction';
import { TenantDetailActions, TenantDetailTab } from '../../modules/TenantDetail';
import useTenantDetail from './useTenantDetail';
import { BUTTONS } from '../../utils/constants';
import useStyles from '../../hooks/useStyles';
import styles from '../../layouts/pages/style';
import useUserManager from '../UserManager/useUserManager';

function TenantDetail() {
  const classes = useStyles(styles)();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const tenant = location.state?.tenant;
  const permissions = location.state?.permissions;
  const { userInfo } = useAuthorization();
  const [dialogData, setDialogData] = useState({});
  const [openDialog, setOpenDialog] = useToggle();

  const handleTenantDialog = (data) => {
    setOpenDialog(true);
    setDialogData(data);
  };

  const {
    activeTab,
    handleChange,
  } = useTenantDetail();

  useEffect(() => {
    if (tenant?.id) {
      dispatch(userList({ tenantId: tenant.id }));
      dispatch(fetchTenantDetails({ tenantId: tenant.id }));
    }
  }, [tenant?.id]);

  const {
    userListData,
    userManagerColumns,
    openUserDialog,
    setOpenUserDialog,
    actionDialogData,
    setActionDialogData,
    openActionDialog,
    setOpenActionDialog,
    userDialogData,
    handleOpenUserDialog,
  } = useUserManager({ permissions, tenantId: tenant.id });

  const handleImpersonate = () => {
    let impersonateRole;
    const handleImpersonateFn = () => {
      const data = {
        email: userInfo?.email,
        tenant: tenant?.id,
        role: impersonateRole,
        refreshToken: localStorage.getItem('refreshToken'),
      };
      dispatch(impersonate(data)).then((res) => {
        if (res.payload.status === 'SUCCESS') {
          dispatch(resetAll());
          navigate('/');
          localStorage.removeItem('activePackage');
          dispatch(resetPackageList());
          usePackageSelection();
          dispatch(openSnackbar({ open: true, message: `Impersonating as ${impersonateRole} in ${tenant?.name}!`, type: 'success' }));
        } else {
          dispatch(openSnackbar({ open: true, message: `Failed to Impersonate in ${tenant?.name}!`, type: 'error' }));
        }
      });
      setOpenActionDialog();
    };

    setActionDialogData({
      title: 'Impersonate',
      message: (
        <Stack gap={2}>
          <Typography>
            Please select the role you want to impersonate in
            <span style={{ color: palette.primary.main }}>{` ${tenant?.name}`}</span>
          </Typography>
          <SelectBox
            name="role"
            title="Access Role"
            onChange={(e) => {
              impersonateRole = e.target.value;
            }}
            data={[{ id: 1, name: 'User', value: 'user' }, { id: 2, name: 'Admin', value: 'admin' }]}
          />
        </Stack>
      ),
      onConfirm: () => {
        if (impersonateRole) {
          handleImpersonateFn();
        } else {
          dispatch(openSnackbar({ open: true, message: 'Please select a role!', type: 'error' }));
        }
      },
    });
    setOpenActionDialog();
  };

  return (
    <Grid container gap={2}>
      <Grid item container xs={12} className={classes.cardStyles} p="5px !important">
        <Stack
          className={classes.headerStyles}
          sx={{ width: '100%', alignItems: 'center !important' }}
        >
          <TenantDetailActions activeTab={activeTab} handleChange={handleChange} />
          <Stack direction="row" gap={2}>
            <Button variant="outlined" onClick={handleImpersonate}>{BUTTONS.IMPERSONATE_TENANT}</Button>
            <Button variant="outlined" onClick={handleOpenUserDialog}>{BUTTONS.CREATE_NEW_USER}</Button>
          </Stack>
        </Stack>
      </Grid>

      <Grid item container xs={12} className={activeTab === 'users' && classes.tableStyles}>
        <TenantDetailTab
          tab={activeTab}
          userListData={userListData}
          userManagerColumns={userManagerColumns}
          handleChange={handleChange}
          handleTenantDialog={handleTenantDialog}
        />
      </Grid>

      <UserManagerDialog
        userDialogData={userDialogData}
        openDialog={openUserDialog}
        onClose={setOpenUserDialog}
        permissions={permissions}
        tenant={tenant}
      />

      <ConfirmDialogBox
        title={actionDialogData.title}
        message={actionDialogData.message}
        openDialog={openActionDialog}
        onClose={setOpenActionDialog}
        onConfirm={actionDialogData.onConfirm}
      />

      <TenantDetailDialog
        dialogData={dialogData}
        openDialog={openDialog}
        onClose={setOpenDialog}
        tenantId={tenant?.id}
      />
    </Grid>
  );
}

export default TenantDetail;
