import { Box, Switch, Typography } from '@mui/material';
import useStyles from '../../hooks/useStyles';
import switchStyle from './styles/switchStyle';

function CustomSwitch(props) {
  const {
    name, label, value, onChange, disabled = false,
  } = props;

  const classes = useStyles(switchStyle)();

  return (
    <Box
      className={disabled ? classes.disabled : classes.enabled}
      sx={{
        display: 'flex',
        alignItems: 'center',
        borderRadius: '8px',
        pr: 1,
        pl: 2,
      }}
    >
      <Typography variant="subtitle1">{label}</Typography>
      <Switch color={disabled ? 'default' : 'primary'} {...(name && { name })} disabled={disabled} onChange={onChange} checked={value} />
    </Box>
  );
}

export default CustomSwitch;
