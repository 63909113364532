import React, { useState, useCallback, useEffect } from 'react';
import {
  Box,
  TextField,
} from '@mui/material';
import { InfoChip } from '../../../../components/CustomChip';
import { CustomDialogBox } from '../../../../components/CustomDialogBox';
import useAccountDetail from '../../../../sections/AccountDetail/useAccountDetail';

function DialogContentElement({
  data, localBaselineUsers, handleChange, error,
}) {
  return (
    <Box>
      <InfoChip
        icon
        type="multiline"
        label="Baseline users represent the number of users from the sold seats who are expected to use your App regularly. If left blank, the total number of sold seats will be considered."
      />
      <TextField
        label="Total Seats"
        value={data.totalSeats}
        InputProps={{
          readOnly: true,
          style: { backgroundColor: '#f5f5f5', pointerEvents: 'none' },
        }}
        fullWidth
        margin="normal"
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        label="Baseline User Count"
        value={localBaselineUsers}
        onChange={handleChange}
        fullWidth
        margin="normal"
        variant="outlined"
        type="number"
        validate
        error={!!error}
        helperText={error}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Box>
  );
}
const BaselineUserDialogBox = React.memo(({
  onClose, onSave, openDialog, baselineUsers,
}) => {
  const { generalDetail } = useAccountDetail();
  const { data } = generalDetail;
  const [error, setError] = useState('');
  const [localBaselineUsers, setLocalBaselineUsers] = useState('-');
  useEffect(() => {
    setLocalBaselineUsers(baselineUsers);
  }, [baselineUsers]);

  const handleSave = useCallback(() => {
    onSave({ ftUsers: localBaselineUsers });
  }, [data?.totalSeats, localBaselineUsers, onSave]);

  const handleChange = (e) => {
    setLocalBaselineUsers(parseInt(e.target.value, 10));
    const totalSeats = data.totalSeats ? parseInt(data.totalSeats, 10) : 0;
    if (parseInt(e.target.value, 10) > totalSeats) {
      setError('Baseline user count can\'t be greater than total seats.');
    } else if (parseInt(e.target.value, 10) < 0) {
      setError('Please enter a positive numeric value.');
    } else {
      setError('');
    }
  };

  return (
    <CustomDialogBox
      title="Update Baseline Users"
      open={openDialog}
      handleClose={onClose}
      content={(
        <DialogContentElement localBaselineUsers={localBaselineUsers} handleChange={handleChange} data={data} error={error} />
    )}
      actions={{ title: 'Save', onClick: handleSave, disabled: !!error }}
    />
  );
});

export default BaselineUserDialogBox;
