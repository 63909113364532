import Metatags from '../components/MetaTags/MetaTags';
import { FeatureUsage } from '../sections/FeatureUsage';

function FeatureUsagePage({ dashboard, permissions }) {
  return (
    <>
      <Metatags metaTagName="KPI Usage | App Insights" />
      <FeatureUsage dashboard={dashboard} permissions={permissions} />
    </>
  );
}

export default FeatureUsagePage;
