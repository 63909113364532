/* eslint-disable no-nested-ternary */
import {
  Card, CardContent, CardHeader, IconButton, Stack, Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import { useMemo } from 'react';
import moment from 'moment';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import useStyles from '../../../hooks/useStyles';
import styles from '../../../sections/AccountStats/style';

function ContentElement({ title, value, icon }) {
  return (
    <Stack width={{ xs: '100%', sm: '50%' }} mt={2} pr={2}>
      <Typography sx={{ color: 'grey' }}>{title}</Typography>
      <Typography sx={(theme) => ({
        fontWeight: 400,
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        ...(title === 'Current Status' && {
          fontWeight: 500,
          color: value === 'Active'
            ? theme.palette.success.dark
            : value === 'Trial'
              ? theme.palette.warning.alt
              : theme.palette.error.main,
        }),
        ...(title === 'Tenant Status' && {
          fontWeight: 500,
          color: value === 'Enabled'
            ? theme.palette.success.dark
            : theme.palette.error.main,
        }),
      })}
      >
        {value}
        {icon && icon}
      </Typography>
    </Stack>
  );
}

function ClickableIcon({ Icon, onClick }) {
  return (
    <IconButton size="small" onClick={onClick}>
      <Icon color="primary" fontSize="small" />
    </IconButton>
  );
}

function TenantDetailSection({ handleChange, handleTenantDialog }) {
  const classes = useStyles(styles)();
  const userListData = useSelector((state) => state.userList?.data);
  const tenantDetailsData = useSelector((state) => state.tenantDetails?.data);

  const tenantInfo = useMemo(() => {
    let tenantDetails = [];
    let orgDetails = [];

    if (tenantDetailsData) {
      const {
        tenant, tenantStatus, currentStatus, updatedDate, createdDate, expirationDate,
        orgName, orgId, primaryContact, country, timeZone,
      } = tenantDetailsData;

      const expiryDate = expirationDate ? moment(expirationDate).format("DD MMM 'YY") : 'Does Not Expire';

      const handleClickEdit = (type, title) => {
        handleTenantDialog({
          type,
          title,
          status: currentStatus,
          expiry: expirationDate,
        });
      };

      tenantDetails = [
        { title: 'Tenant Name', value: tenant },
        {
          title: 'Total Users',
          value: `${userListData?.length ?? 0} Users`,
          icon: <ClickableIcon Icon={VisibilityOutlinedIcon} onClick={(e) => handleChange(e, 'users')} />,
        },
        { title: 'Tenant Status', value: tenantStatus ? 'Enabled' : 'Disabled' },
        {
          title: 'Current Status',
          value: capitalize(currentStatus),
          icon: <ClickableIcon
            Icon={EditOutlinedIcon}
            onClick={() => handleClickEdit('status', 'Edit Status')}
          />,
        },
        { title: 'Last Modified', value: moment(updatedDate).format("DD MMM 'YY") },
        {
          title: 'Expiry Date',
          value: expiryDate,
          icon: <ClickableIcon
            Icon={EditOutlinedIcon}
            onClick={() => handleClickEdit('expiry', 'Edit Expiration')}
          />,
        },
        { title: 'Created On', value: moment(createdDate).format("DD MMM 'YY") },
      ];

      orgDetails = [
        { title: 'Organization Name', value: orgName },
        { title: 'Contact Person', value: primaryContact },
        { title: 'Country', value: country },
        { title: 'Time Zone', value: timeZone },
        { title: 'Organization ID', value: orgId },
      ];
    }

    return { tenantDetails, orgDetails };
  }, [tenantDetailsData, userListData?.length]);

  return (
    <Stack container gap={2} flexDirection={{ xs: 'column', md: 'row' }} width="100%" height="100%">
      <Card className={classes.cardStyles} style={{ width: '100%', padding: 10 }}>
        <CardHeader title="Tenant Details" className={classes.headerStyles} />
        <CardContent className={classes.contentStyles}>
          <Stack style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
          >
            {tenantInfo.tenantDetails.map(({ title, value, icon }) => (
              <ContentElement title={title} value={value} icon={icon} />
            ))}
          </Stack>
        </CardContent>
      </Card>

      <Card className={classes.cardStyles} style={{ width: '100%', padding: 10 }}>
        <CardHeader title="Organization Details" className={classes.headerStyles} />
        <CardContent className={classes.contentStyles}>
          <Stack style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
          >
            {tenantInfo.orgDetails.map(({ title, value }) => (
              <ContentElement title={title} value={value} />
            ))}
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
}

export default TenantDetailSection;
