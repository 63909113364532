import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CustomDataGrid } from '../../../../components/CustomDataGrid';
import useToggle from '../../../../hooks/useToggle';
import FeatureLabelModal from '../FeatureLabelModal/FeatureLabelModal';
import { getFeatureDetailList } from '../../../../features';
import useAddEditFeature from '../AddEditFeature/useAddEditFeature';

export default function FeatureManagerTable(props) {
  const {
    rows,
    columns,
    handleClickEdit,
    permissions,
  } = props;

  const dispatch = useDispatch();
  const { customTypes } = useAddEditFeature();
  const [open, setOpen] = useToggle();
  const [selectDisabled] = useState(false);
  const [label, setLabel] = useState('');
  const [featureId, setFeatureId] = useState('');

  const handleClose = () => {
    setOpen();
  };

  const handleOnRowClick = (params) => {
    const { colDef } = params;
    if (colDef.isRowSelectable) {
      setOpen();
      setLabel(params.row.feature_name);
      setFeatureId(params.row.feature_id);
      dispatch(getFeatureDetailList(params.row.feature_id));
    }
  };

  const editFeature = () => {
    const params = {};
    params.row = { feature_id: featureId, feature_name: label };
    handleClickEdit(params);
  };

  return (
    <>
      <CustomDataGrid
        getRowId={(row) => row.feature_name + row.feature_name.length}
        rows={rows || []}
        columns={columns}
        onCellClick={(params) => handleOnRowClick(params)}
        rowsPerPageOptions={[20]}
        pageSize={20}
        footer
      />
      {open && (
        <FeatureLabelModal
          permissions={permissions}
          open={open}
          handleClose={handleClose}
          selectDisabled={selectDisabled}
          handleClickEdit={editFeature}
          label={label}
          featureId={featureId}
          customTypes={customTypes}
        />
      )}
    </>

  );
}
