import { useEffect, useMemo } from 'react';
import jwtDecode from 'jwt-decode';
import List from '@mui/material/List';
import { Box, styled } from '@mui/material';
import AppMenuItem from './AppMenuItem';
import {
  appMenuItems, tenantMenu,
} from './appMenuConfig';
import useAppMenu from './useAppMenu';
import { getLocalStorage } from '../../utils/methods';

const AppList = styled(List)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.mainColor,
}));

function AppMenu({ handleDrawerOpen, isDrawerOpen, permissions }) {
  const {
    open,
    setOpen,
    handleClick,
    bottomMenuItems,
  } = useAppMenu({ permissions });
  const idToken = getLocalStorage('idToken');
  const isSalesforce = useMemo(() => {
    const decode = idToken ? jwtDecode(idToken) : null;
    return decode?.salesforce ?? false;
  }, [idToken]);

  useEffect(() => {
    if (!isDrawerOpen) {
      setOpen(null);
    }
  }, [isDrawerOpen]);

  return (
    <AppList
      component="nav"
      disablePadding
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <Box>
        {permissions?.manage && tenantMenu?.map((item, index) => (
          <AppMenuItem permissions={permissions} {...item} key={index} index={index} handleDrawerOpen={handleDrawerOpen} isDrawerOpen={isDrawerOpen} open={open} setOpen={setOpen} handleClick={(...args) => handleClick(...args)} />
        ))}

        {!permissions?.manage && appMenuItems?.map((item, index) => (!item.permission || permissions[item.permission])
          && (
          <AppMenuItem
            {...item}
            key={index}
            rowIndex={`appMenu-${index}`}
            open={open}
            setOpen={setOpen}
            handleDrawerOpen={handleDrawerOpen}
            isDrawerOpen={isDrawerOpen}
            handleClick={handleClick}
          />
          ))}
      </Box>
      {!isSalesforce
        ? (
          <Box>
            {bottomMenuItems?.map((item, index) => (
              (!item.permission || permissions[item.permission]) && (
              <AppMenuItem
                {...item}
                key={index}
                rowIndex={index}
                open={open}
                setOpen={setOpen}
                handleDrawerOpen={handleDrawerOpen}
                isDrawerOpen={isDrawerOpen}
                handleClick={item.onClick ?? handleClick}
              />
              )
            ))}
          </Box>
        )
        : null }
    </AppList>
  );
}

export default AppMenu;
