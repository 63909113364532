import orderBy from 'lodash/orderBy';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useToggle from '../../hooks/useToggle';

const useTopFeatures = () => {
  const [refreshTopFeatures, setRefreshTopFeatures] = useToggle();
  const [sortModel, setSortModel] = useState([{ field: 'operationCount', sort: 'desc' }]);
  const [topFeatureUsage, setTopFeatureUsage] = useState({
    topFeatures: [],
    timePeriod: [],
    error: null,
    loading: true,
  });

  const topFeaturesData = useSelector((state) => state.topFeatures);

  const getTopFive = (arrayObj = []) => {
    const sortedArrayObj = orderBy(arrayObj, ['operationCount'], ['desc'])
      .filter((obj) => (obj.operationCount > 0 && typeof obj.operationCount !== 'object'))
      .slice(0, 5);
    return sortedArrayObj;
  };

  const setTopFeatureData = () => {
    setTopFeatureUsage({
      topFeatures: getTopFive(topFeaturesData?.topFeatures),
      timePeriod: topFeaturesData?.timePeriod,
      loading: topFeaturesData?.loading,
      error: topFeaturesData?.error,
    });
  };

  const sortedRows = (field, direction) => {
    if (topFeaturesData.topFeatures && topFeaturesData.topFeatures.length > 0) {
      const topFeatureList = [...topFeaturesData.topFeatures];
      topFeatureList?.sort((a, b) => {
        if (field) {
          return direction === 'asc'
            ? a[field] - b[field]
            : b[field] - a[field];
        }
        return 0;
      });
      setTopFeatureUsage({
        topFeatures: topFeatureList.splice(0, 5),
        timePeriod: topFeatureUsage.timePeriod,
        error: '',
        loading: topFeatureUsage.loading,
      });
    }
  };

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
    const [sortData] = newSortModel;
    if (sortData?.field) {
      sortedRows(sortData.field, sortData.sort);
    } else {
      sortedRows('operationCount', 'asc');
    }
  };

  useEffect(() => {
    setTopFeatureData();
  }, [topFeaturesData]);

  return {
    topFeatureUsage,
    refreshTopFeatures,
    setRefreshTopFeatures,
    handleSortModelChange,
    sortModel,
  };
};

export default useTopFeatures;
