/* eslint-disable react/jsx-one-expression-per-line */
import {
  Grid, Stack, Typography,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import useAddEditHeathInsights from './useAddEditHeathInsights';
import RuleForm from '../RuleForm/RuleForm';

function AddEditHealthInsights() {
  const location = useLocation();
  const isCopyClick = location.state?.isCopyClick ?? false;

  const {
    scoreControlsEnabled,
    activePackage,
    validationSchema,
    handleSubmit,
    isEditPage,
    isAddPage,
    healthScoreDetailsData,
    handleEditSubmit,
    handleRuleChange,
    featureManagerListData,
    initialRuleState,
    healthScoreCriteria,
    setHealthScoreCriteria,
    handleAddCriteriaRow,
    primaryHealthScore,
  } = useAddEditHeathInsights();

  const ruleFormInitialValues = isEditPage
    ? healthScoreDetailsData
    : { healthScoreRules: initialRuleState.healthScoreRules };

  const text = isEditPage ? 'Edit' : 'Add';
  const submitHandler = isEditPage ? handleEditSubmit : handleSubmit;

  return (
    <Stack spacing={2} style={{ padding: '0 24px' }}>
      <Grid style={{ backgroundColor: 'white', padding: 15 }}>
        <Typography variant="h4">
          {text} KPI Score Configuration
        </Typography>
      </Grid>

      {((isEditPage && healthScoreDetailsData) || isAddPage) && (
        <RuleForm
          scoreControlsEnabled={scoreControlsEnabled}
          initialValues={ruleFormInitialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
          handleRuleChange={handleRuleChange}
          featureManagerListData={featureManagerListData}
          isEditPage={isEditPage}
          initialRuleState={initialRuleState}
          isCopyClick={isCopyClick}
          healthScoreCriteria={healthScoreCriteria}
          setHealthScoreCriteria={setHealthScoreCriteria}
          primaryHealthScore={primaryHealthScore}
          handleAddCriteriaRow={handleAddCriteriaRow}
          activePackage={activePackage}
        />
      )}
    </Stack>
  );
}

export default AddEditHealthInsights;
