import { Box, Grid } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { InfoChip } from '../../../../components/CustomChip';
import TextFieldWithError from '../../../../components/FormComponents/TextfieldWithError';
import { Switch } from '../../../../components/FormComponents';

function CustomFilterForm({ formik }) {
  const {
    handleChange, getIn, values, errors, touched,
  } = formik;

  return (
    <Grid container gap={1} p={2}>
      <Grid item container xs={12} mb={2} justifyContent="space-between" alignItems="center" gap={1}>
        <Box width={{ sm: '60%', xl: 'auto' }}>
          <InfoChip
            type="multiline"
            icon={<InfoIcon color="primary" />}
            label="You can create an alert for this view."
          />
        </Box>
        <Box width="180px">
          <Switch
            name="alert"
            label="Enable Alert"
            value={values.alert}
            onChange={handleChange}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <TextFieldWithError
          name="name"
          label="Name"
          value={values.name}
          onChange={handleChange}
          error={touched && getIn(errors, 'name')}
        />
      </Grid>
    </Grid>
  );
}

export default CustomFilterForm;
