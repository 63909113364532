import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStorage, parse, setLocalStorage } from '../../../../utils/methods';
import { getPackageList } from '../../../../features';

const usePackageSelection = (object = {}) => {
  const { pkgId, isShare } = object;
  const dispatch = useDispatch();
  const activeSelectedPackage = getLocalStorage('activePackage');
  const [activePackage, setActivePackage] = useState(activeSelectedPackage ? JSON.parse(activeSelectedPackage) : '');
  const [packageList, setPackageList] = useState({
    packages: [],
    error: '',
    loading: true,
  });

  const [initialFetchDone, setInitialFetchDone] = useState(false);
  useEffect(() => {
    if (!initialFetchDone && (!activePackage || !packageList?.packages) && !isShare) {
      dispatch(getPackageList({ isShare }));
      setInitialFetchDone(true);
    }
  }, [activePackage, packageList?.packages, isShare, initialFetchDone, dispatch]);
  const packageListData = useSelector((state) => state.packageList);

  const setPackageListData = () => {
    const filterPackageMenuList = (packageListData?.packageMenuList ?? []).filter((obj) => obj.isEnabled);
    setPackageList({
      ...packageList,
      packages: packageListData?.packageMenuList ? filterPackageMenuList : packageListData?.packageMenuList,
      error: packageListData.error,
    });
    if (filterPackageMenuList && filterPackageMenuList?.length && !isShare) {
      if (pkgId) {
        const activePkgData = filterPackageMenuList.filter((obj) => obj.packageId === pkgId);
        setActivePackage(...activePkgData);
        setLocalStorage('activePackage', JSON.stringify(...activePkgData));
      } else if (activeSelectedPackage) {
        setActivePackage(JSON.parse(activeSelectedPackage));
      } else {
        setActivePackage(filterPackageMenuList[0]);
        setLocalStorage('activePackage', JSON.stringify(filterPackageMenuList[0]));
      }
    }
  };

  const setActivePackageData = () => {
    if (activeSelectedPackage) {
      setActivePackage(JSON.parse(activeSelectedPackage));
    }
  };

  const handleDropdownChange = (event) => {
    const value = parse(event.target.value);
    setActivePackage(value);
    setLocalStorage('activePackage', JSON.stringify(value));
  };

  useEffect(() => {
    setPackageListData();
  }, [packageListData?.packageMenuList]);

  useEffect(() => {
    setActivePackageData();
  }, [activeSelectedPackage]);

  return { packageList, activePackage, handleDropdownChange };
};

export default usePackageSelection;
