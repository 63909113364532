import { Box, Stack } from '@mui/material';
import { SelectBox, Switch } from '../../../../components/FormComponents';

function FeatureUsageAction(props) {
  const {
    toggleAll,
    setToggleAll,
    dropDown,
    selectedFilters,
    handleFilterChange,
  } = props;

  return (
    <Stack
      width="100%"
      justifyContent="space-between"
      direction={{ xs: 'column', md: 'row' }}
      alignItems={{ xs: 'end', md: 'center' }}
    >
      <Stack
        spacing={1}
        width={{ xs: '100%', md: '70%', lg: '50%' }}
        direction={{ xs: 'column', sm: 'row' }}
      >
        <Box flex={1} className="tg-sortby-filter">
          <SelectBox
            name="sortBy"
            title="Sort By"
            data={dropDown.sortBy}
            value={selectedFilters.sortBy}
            onChange={handleFilterChange}
          />
        </Box>
        <Box flex={1} className="tg-view-filter">
          <SelectBox
            name="featureUsageView"
            title="View"
            data={dropDown.featureUsageView}
            value={selectedFilters.featureUsageView}
            onChange={handleFilterChange}
          />
        </Box>
        <Box flex={1} className="tg-kpi-filter">
          <SelectBox
            multiple
            name="selectedFeatures"
            title="KPIs"
            data={dropDown.selectedFeatures}
            value={selectedFilters?.selectedFeatures}
            onChange={handleFilterChange}
          />
        </Box>
      </Stack>
      <Stack width="auto" className="tg-expand-all">
        <Switch label="Expand All" value={toggleAll} onChange={setToggleAll} />
      </Stack>
    </Stack>
  );
}

export default FeatureUsageAction;
