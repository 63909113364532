import { Container } from '@mui/material';
import { AccountList } from '../sections/AccountList';
import Metatags from '../components/MetaTags/MetaTags';

export default function AccountListPage({ permissions }) {
  return (
    <>
      <Metatags metaTagName="Accounts | App Insights" />
      <Container maxWidth="xl">
        <AccountList permissions={permissions} />
      </Container>
    </>
  );
}
