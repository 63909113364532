import {
  Box, IconButton, Stack, Tooltip,
} from '@mui/material';

export default function ActionCell({ actionCellData, params }) {
  return (
    <Box sx={{ padding: (theme) => theme.spacing(0, 3) }}>
      <Box
        sx={{
          padding: '10px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {actionCellData.length
          ? actionCellData.map((obj) => {
            const Icon = obj.icon;
            if (Icon) {
              return (
                <Stack
                  key={obj.id}
                  ml={1.5}
                >
                  <Tooltip title={obj.info}>
                    <IconButton
                      className={obj.headerClassName}
                      variant="outlined"
                      disabled={obj.disabled}
                      onClick={() => obj.handleFunction(params)}
                    >
                      <Icon color={obj.disabled ? 'disabled' : 'primary'} fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              );
            }

            return obj.component;
          })
          : null}
      </Box>
    </Box>
  );
}
