import request from '../../modules/Request/request';

const getFeatureData = async (id) => {
  const response = await request('/feature-details', { method: 'GET', params: id });
  if (response.success) {
    return response.data;
  }
  const error = response.error && response.error.message
    ? response.error.message
    : response.error;
  throw error(error || 'Something went wrong');
};

export {
  getFeatureData,
};
