import {
  Box, Grid, IconButton, Tooltip,
} from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Masonry } from '@mui/lab';
import Lottie from 'lottie-react';
import { useLocation } from 'react-router-dom';
import useAuthorization from '../../hooks/useAuthorization';
import { fetchTenantInfo } from '../../features/Tenant/tenantAction';
import { CustomFilterAlert, StatusAndExpiry } from '../../modules/Dashboard';
import { AccountStats } from '../AccountStats';
import usePackageSelection from '../../modules/Accounts/components/PackageSelection/usePackageSelection';
import { getAccountsList, getActiveUsers, getLicenseUtilKpiScore } from '../../features';
import ActiveUsers from '../../modules/Accounts/components/ActiveUsers/ActiveUsers';
import VersionsCard from '../../modules/Versions/components/VersionsCard/VersionsCard';
import useActiveUsers from '../../modules/Accounts/components/ActiveUsers/useActiveUsers';
import useToggle from '../../hooks/useToggle';
import { PackageSelection } from '../../modules/Accounts/components/PackageSelection';
import { DashboardHealthScore } from '../DashboardHealthScore';
import { TopFeatures } from '../TopFeatures';
import { LicenseUtilization } from '../AccountLicense';
import LicenseUtilizationKpiScore from '../AccountLicense/LicenseUtilizationKpiScore';
import { HourGlassJson } from './HourGlassJson';
import { getCustomAlertDetails } from '../../features/CustomAlert/customAlertAction';

export default function Dashboard({ isNewProp }) {
  const [refreshAccountList, setRefreshAccountList] = useToggle();
  const [refreshUsers, setRefreshUsers] = useToggle();
  const [refreshLicenseUtilKpiScore, setRefreshLicenseUtilKpiScore] = useToggle();
  const { userInfo } = useAuthorization();
  const { activeUsers } = useActiveUsers();
  const { packageList, activePackage, handleDropdownChange } = usePackageSelection();

  const dispatch = useDispatch();
  const location = useLocation();
  const startTourState = location?.state?.startTour ?? false;

  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getAccountsList({ packageId: activePackage.packageId }));
      dispatch(getCustomAlertDetails({ pkgId: activePackage.packageId }));
    }
  }, [activePackage?.packageId, refreshAccountList]);

  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getLicenseUtilKpiScore({ pkgId: activePackage.packageId }));
    }
  }, [activePackage?.packageId, refreshLicenseUtilKpiScore]);

  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getActiveUsers({ page: 'dashboard', packageId: activePackage.packageId }));
    }
  }, [refreshUsers, activePackage?.packageId]);

  useEffect(() => {
    if (userInfo?.tenantId) {
      dispatch(fetchTenantInfo({ tenantId: userInfo.tenantId }));
    }
  }, [userInfo?.tenantId]);

  const { monthObject, error, loading } = activeUsers;
  const { packages } = packageList;

  return (
    <Grid container gap={2}>
      <Grid container item xs={12} direction={packages?.length ? 'row' : 'row-reverse'} justifyContent="space-between" alignItems="center">
        <PackageSelection
          startTourState={startTourState}
          dropDownList={packages}
          activeDropdown={activePackage}
          handleChange={handleDropdownChange}
        />
        <Box display="flex" flexDirection="row" gap="5px">
          {/* Status & Expiry */}
          <StatusAndExpiry />

          {isNewProp && (
          <Tooltip title="Data is coming, Please wait.">
            <IconButton>
              <Lottie
                animationData={HourGlassJson}
                loop
                autoplay
                speed={1.5}
                style={{ width: 25, height: 25 }}
              />
            </IconButton>
          </Tooltip>
          )}
          <CustomFilterAlert activePackage={activePackage} />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <AccountStats packageId={activePackage.packageId} />
      </Grid>

      <Grid item xs={12} className="tg-dashboard-cards">
        <Masonry
          columns={{ sm: 1, md: 2 }}
          spacing={2}
          sx={{ width: 'auto' }}
        >
          <Box>
            <DashboardHealthScore type="riskAccounts" title="High-Risk Accounts" refresh={setRefreshAccountList} />
          </Box>
          <Box>
            <DashboardHealthScore type="expiredAccounts" title="Accounts Expiring" refresh={setRefreshAccountList} />
          </Box>
          <Box>
            <DashboardHealthScore type="decliningAccounts" title="Declining KPI Scores" refresh={setRefreshAccountList} />
          </Box>
          <Box>
            <DashboardHealthScore type="trendingAccounts" title="Trending KPI Scores" refresh={setRefreshAccountList} />
          </Box>
          <Box>
            <ActiveUsers
              title="Monthly Active Users"
              resultSet={monthObject}
              error={error}
              loading={loading}
              orgId="all"
              setRefreshUsers={setRefreshUsers}
              page="dashboard"
              more
            />
          </Box>
          <Box>
            <VersionsCard activePackage={activePackage} />
          </Box>
          <Box>
            <TopFeatures activePackage={activePackage} />
          </Box>
          <Box>
            <LicenseUtilization title="Account License Assignment" refresh={setRefreshAccountList} />
          </Box>
          <Box>
            <LicenseUtilizationKpiScore title="License Assignment vs Avg. KPI Score" refresh={setRefreshLicenseUtilKpiScore} />
          </Box>
        </Masonry>
      </Grid>
    </Grid>
  );
}
