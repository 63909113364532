import { FormHelperText, TextField, FormControl } from '@mui/material';

export default function TextFieldWithError(props) {
  const {
    error, name, label, type, onChange, InputProps, touched, value, multiline, defaultValue, width, errorDisabled = false,
  } = props;

  return (
    <FormControl fullWidth sx={{ position: 'relative', m: errorDisabled ? '8px 0' : '8px 0 18px', width: width ?? '100%' }}>
      <TextField
        touched={touched}
        error={error}
        name={name}
        label={label}
        multiline={multiline}
        type={type}
        variant="outlined"
        onChange={onChange}
        fullWidth
        InputProps={InputProps}
        value={value}
        rows={4}
        defaultValue={defaultValue}
        {...props}
      />
      {!errorDisabled && (
      <FormHelperText error={error} sx={{ position: 'absolute', bottom: '-1.5em' }}>
        {error && error}
      </FormHelperText>
      )}
    </FormControl>
  );
}
