import { createAsyncThunk } from '@reduxjs/toolkit';

import request from '../../modules/Request/request';

export const getHealthScoreTrends = createAsyncThunk(
  'getHealthScoreTrends',
  async ({
    licenseId, view, pkgId, isShare, tenantId, accountOrgId,
  }, { rejectWithValue }) => {
    const reqPayload = {};
    const query = {};
    reqPayload.method = 'GET';
    reqPayload.params = licenseId;
    query.pkgId = pkgId;
    query.view = view;
    if (isShare) {
      query.isShare = isShare;
      if (accountOrgId) {
        query.accountOrgId = accountOrgId;
      } else {
        query.tenantId = tenantId;
      }
    }
    reqPayload.query = query;
    const response = await request('/health-score-trends', reqPayload, query);
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);
