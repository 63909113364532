import {
  Grid, Button, Box, Typography,
  Stack,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { KPIPreview } from '../../modules/FeatureManager/components/KPIPreview';
import useLogAnalyzer from '../../modules/LogAnalyzer/useLogAnalyzer';
import { LogAnalyzerFilters, LogAnalyzerTable } from '../../modules/LogAnalyzer';
import { BUTTONS, TITLES } from '../../utils/constants';
import { PackageHeader } from '../../components/CustomHeading';
import useStyles from '../../hooks/useStyles';
import styles from '../../layouts/pages/style';

function LogAnalyzer({ permissions }) {
  const classes = useStyles(styles)();
  const {
    accountList,
    customEntityList,
    logAnalyzerInfo,
    selectedFilters,
    customEntityTpe,
    handleFilterChange,
    handleApplyFilters,
    handleResetFilters,
    handleExpandEntity,
  } = useLogAnalyzer();

  const navigate = useNavigate();
  const handleNewKpi = () => {
    if (selectedFilters?.customEntity) {
      const kpiData = Object.keys(selectedFilters.customEntity).reduce((acc, type) => {
        const localType = type === 'ApexTrigger' ? 'ApexClass' : type;
        if (!acc[localType]) acc[localType] = [];
        acc[localType].push(...selectedFilters.customEntity[type]);
        return acc;
      }, {});
      navigate('/kpi-manager/new-feature', { state: { editFeatureData: kpiData, redirectFromLogAnalyzer: true } });
    }
  };

  return (
    <Grid container gap={2} minHeight="calc(100vh - 64px)" direction="column">
      <Grid item xs="auto" className={classes.cardStyles}>
        <Box className={classes.headerStyles}>
          <PackageHeader heading={TITLES.LOG_ANALYZER} />
          {permissions.write && (
          <Button disabled={!Object.keys(selectedFilters.customEntity)?.length} variant="outlined" onClick={handleNewKpi}>
            {BUTTONS.NEW_KPI}
          </Button>
          )}
        </Box>
      </Grid>

      <Grid item className={classes.cardStyles} xs="auto">
        <LogAnalyzerFilters
          accountList={accountList}
          customEntityList={customEntityList}
          selectedFilters={selectedFilters}
          handleFilterChange={handleFilterChange}
          handleApplyFilters={handleApplyFilters}
          handleResetFilters={handleResetFilters}
        />
      </Grid>

      {logAnalyzerInfo?.data?.featureInfo?.entityOpCount?.length > 0 ? (
        <>
          <Grid item className={classes.cardStyles} xs="auto">
            <KPIPreview
              view="day"
              previewType="component"
              previewData={logAnalyzerInfo.data}
              dashboard={selectedFilters.account?.value === '- all -'}
              graphTitle={{ title1: 'Users and Accounts per Day', title2: 'Operations per Day' }}
            />
          </Grid>

          <Grid item className={classes.cardStyles} xs="auto">
            <LogAnalyzerTable customEntityTpe={customEntityTpe} onRowExpand={handleExpandEntity} selectedFilters={selectedFilters} logAnalyzerInfo={logAnalyzerInfo.data.featureInfo} />
          </Grid>
        </>
      )
        : (
          <Grid container item className={classes.cardStyles} xs="auto" alignItems="center" justifyContent="center" style={{ flex: 1 }}>
            <Stack gap={2} p={2}>
              <img src="/logAnalyzer.svg" alt="log analyzer" />
              <Typography variant="h4" color="grey.600" fontWeight={500}>Apply Filters to Analyze Logs!</Typography>
            </Stack>
          </Grid>
        )}
    </Grid>
  );
}

export default LogAnalyzer;
