import { createSlice, current } from '@reduxjs/toolkit';
import {
  createTenant, deleteTenant, disableTenant, fetchTenantDetails, fetchTenantInfo, fetchTenants,
  updateTenantInfo,
} from './tenantAction';

export const tenantSlice = createSlice({
  name: 'tenants',
  initialState: {
    loading: false,
    error: null,
    message: '',
    data: [],
  },
  extraReducers: (builder) => {
    // Tenant List
    builder.addCase(fetchTenants.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchTenants.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
      state.message = payload.message || '';
      state.error = null;
    });
    builder.addCase(fetchTenants.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || 'An error occurred';
      state.data = [];
    });
    // Create Tenant
    builder.addCase(createTenant.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createTenant.fulfilled, (state, { payload }) => {
      const currentState = current(state);
      const modifiedData = [
        ...currentState.data,
        payload.data,
      ];
      state.loading = false;
      state.data = modifiedData;
      state.message = payload.message || '';
      state.error = null;
    });
    builder.addCase(createTenant.rejected, (state, { payload }) => {
      const currentState = current(state);
      state.loading = false;
      state.error = payload || 'An error occurred';
      state.data = currentState.data;
    });
    // Delete Tenant
    builder.addCase(deleteTenant.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteTenant.fulfilled, (state, { payload }) => {
      const currentState = current(state);
      const modifiedData = currentState.data?.filter((item) => item.tenantId !== payload.tenantId);
      state.loading = false;
      state.data = modifiedData;
      state.message = payload.message || '';
      state.error = null;
    });
    builder.addCase(deleteTenant.rejected, (state, { payload }) => {
      const currentState = current(state);
      state.loading = false;
      state.error = payload || 'An error occurred';
      state.data = currentState.data;
    });
    // Disable Tenant
    builder.addCase(disableTenant.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(disableTenant.fulfilled, (state, { payload }) => {
      const currentState = current(state);
      const modifiedData = currentState.data.map((item) => {
        const { tenantId, ...rest } = payload.data;
        if (item.tenantId === tenantId) {
          return { ...item, ...rest };
        }
        return item;
      });
      state.loading = false;
      state.data = modifiedData;
      state.message = payload.message || '';
      state.error = null;
    });
    builder.addCase(disableTenant.rejected, (state, { payload }) => {
      const currentState = current(state);
      state.loading = false;
      state.error = payload || 'An error occurred';
      state.data = currentState.data;
    });
  },
});

export const tenantDetailsSlice = createSlice({
  name: 'tenantDetails',
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  extraReducers: (builder) => {
    // All Details
    builder.addCase(fetchTenantDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchTenantDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
      state.error = null;
    });
    builder.addCase(fetchTenantDetails.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || 'An error occurred';
      state.data = [];
    });
    // Status & Expiry
    builder.addCase(fetchTenantInfo.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchTenantInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
      state.error = null;
    });
    builder.addCase(fetchTenantInfo.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || 'An error occurred';
      state.data = [];
    });
    // Update Status & Expiry
    builder.addCase(updateTenantInfo.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateTenantInfo.fulfilled, (state, { payload }) => {
      state.loading = false;
      const currentState = current(state);
      const modifiedData = {
        ...currentState.data,
        ...payload.data,
      };
      state.data = modifiedData;
      state.error = null;
    });
    builder.addCase(updateTenantInfo.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || 'An error occurred';
      state.data = [];
    });
  },
});
