import { createAsyncThunk } from '@reduxjs/toolkit';
import request from '../../modules/Request/request';

export const fetchTenants = createAsyncThunk('fetchTenants', async (object, { rejectWithValue }) => {
  const response = await request('/tenants-list', { method: 'GET' });
  if (response.success) {
    return response.data;
  }
  const { error } = response;
  throw rejectWithValue(error || 'Something went wrong');
});

export const createTenant = createAsyncThunk('createTenant', async ({ data }, { rejectWithValue }) => {
  const response = await request('/tenants-create', { method: 'POST', body: data });
  if (response.success) {
    return response.data;
  }
  const { error } = response;
  throw rejectWithValue(error || 'Something went wrong');
});

export const deleteTenant = createAsyncThunk('deleteTenant', async ({ tenantId }, { rejectWithValue }) => {
  const response = await request('/tenants-delete', { method: 'DELETE', params: [tenantId] });
  if (response.success) {
    return response.data;
  }
  const { error } = response;
  throw rejectWithValue(error || 'Something went wrong');
});

export const disableTenant = createAsyncThunk('disableTenant', async ({ tenantId }, { rejectWithValue }) => {
  const response = await request('/tenants-disable', { method: 'PUT', params: [tenantId] });
  if (response.success) {
    return response.data;
  }
  const { error } = response;
  throw rejectWithValue(error || 'Something went wrong');
});

// All Details
export const fetchTenantDetails = createAsyncThunk('fetchTenantDetails', async ({ tenantId }, { rejectWithValue }) => {
  const response = await request('/tenant-details', { method: 'GET', params: [tenantId] });
  if (response.success) {
    return response.data;
  }
  const { error } = response;
  throw rejectWithValue(error || 'Something went wrong');
});

// Status & Expiry
export const fetchTenantInfo = createAsyncThunk('fetchTenantInfo', async ({ tenantId }, { rejectWithValue }) => {
  const response = await request('/tenant-info', { method: 'GET', params: [tenantId] });
  if (response.success) {
    return response.data;
  }
  const { error } = response;
  throw rejectWithValue(error || 'Something went wrong');
});

// Update Status & Expiry
export const updateTenantInfo = createAsyncThunk('updateTenantInfo', async (object, { rejectWithValue }) => {
  const response = await request('/update-tenant-info', { method: 'PUT', body: object });
  if (response.success) {
    return response.data;
  }
  const { error } = response;
  throw rejectWithValue(error || 'Something went wrong');
});
