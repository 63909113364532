import TextField from '@mui/material/TextField';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Grid, Typography } from '@mui/material';
import { DATE_FORMAT } from '../../utils/constants';

export default function DatePicker(props) {
  const {
    fromDate,
    label,
    customDesign,
    toDate,
    handleStartDateChange,
    handleEndDateChange,
  } = props;

  return (
    <Box sx={{ width: '100%' }}>
      <Typography sx={{ mb: 1, fontSize: 14 }}>{label}</Typography>
      <Grid container sx={{ justifyContent: 'space-between' }}>
        <Grid item lg={5.8}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MUIDatePicker
              label="From"
              value={fromDate}
              inputFormat={DATE_FORMAT.DATE_PICKER}
              onChange={handleStartDateChange}
              renderInput={(params) => (<TextField {...params} sx={customDesign} />)}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item lg={5.8}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MUIDatePicker
              label="To"
              value={toDate}
              inputFormat={DATE_FORMAT.DATE_PICKER}
              onChange={handleEndDateChange}
              renderInput={(params) => (<TextField {...params} sx={customDesign} />)}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
    </Box>
  );
}
