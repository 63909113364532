/* eslint-disable react/destructuring-assignment */
import styled from '@emotion/styled';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Badge, Box, Stack, Tooltip, Typography,
} from '@mui/material';
import { toolTipText } from '../../../../../utils/toolTipText';
import palette from '../../../../../theme/palette';

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: '#dee5ec',
  width: 140,
  height: 140,
  borderRadius: '50%',
  boxShadow: '2px 2px 10px 1px rgba(0, 0, 0, 0.2)',
}));

const StepIconRoot = styled('div')(() => ({
  backgroundColor: palette.secondary.mainColor,
  zIndex: 1,
  color: '#fff',
  width: 120,
  height: 120,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
}));

export function StepIconComponent(event) {
  return (
    <StyledBox>
      <StepIconRoot>
        <Stack alignItems="center" gap={0.5}>
          <Tooltip title={toolTipText.eventTimeline.VERSION_NUMBER}>
            <Badge badgeContent={event?.versionFlag && <CheckCircleIcon fontSize="small" color="success" />} size="small">
              <Typography sx={{ borderRadius: '15px', border: '2px solid white', px: 1 }}>{event?.versionNumber ?? ' - - '}</Typography>
            </Badge>
          </Tooltip>
          <Tooltip title={toolTipText.eventTimeline.LICENSE_STATUS}>
            <Badge badgeContent={event?.statusFlag && <CheckCircleIcon fontSize="small" color="success" />} size="small">
              <Typography variant="h5">{event?.licenseStatus ?? ' - - '}</Typography>
            </Badge>
          </Tooltip>

        </Stack>
      </StepIconRoot>
    </StyledBox>
  );
}
