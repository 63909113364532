import { useEffect, useState } from 'react';
import { Grid, Slider, Typography } from '@mui/material';
import BarChart from './BarChart';

function Histogram({
  modifiedBarData, targetMetric, setFieldValue, index, threshold,
}) {
  const [rangeMax, setRangeMax] = useState(0);
  const [highlight, setHighlight] = useState([0, rangeMax]);
  const handleChange = (event, newValue) => {
    setHighlight(newValue);
  };
  const handleUpdate = () => {
    setFieldValue(`healthScoreRules[${index}].threshold`, highlight);
  };
  useEffect(() => {
    const tempRangeLen = Object.keys(modifiedBarData)?.length;
    const tempRangeMax = +Object.keys(modifiedBarData).at(-1);
    if (threshold?.length) {
      setHighlight(threshold);
    } else if (tempRangeLen) {
      setHighlight([0, tempRangeMax]);
    }

    if (tempRangeLen) {
      setRangeMax(tempRangeMax);
    }
  }, [modifiedBarData, threshold]);

  return (
    <Grid container style={{ margin: '2% 10% 1%', width: '80%' }} spacing={2}>
      <Grid
        item
        xs={1}
        sx={{
          display: 'flex', justifyContent: 'end', alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            writingMode: 'tb-rl',
            textOrientation: 'sideways',
            transform: 'rotate(-180deg)',
          }}
        >
          Accounts
        </Typography>
      </Grid>
      <Grid item xs={11} display="flex" flexDirection="column" justifyContent="center">
        <BarChart data={modifiedBarData} highlight={highlight} />
        <Slider
          valueLabelDisplay="auto"
          max={rangeMax}
          onChange={handleChange}
          onChangeCommitted={handleUpdate}
          value={highlight}
          sx={{
            color: (theme) => theme.palette.secondary.mainColor, p: '0 !important', height: '2px',
          }}
        />
      </Grid>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography>{targetMetric}</Typography>
      </Grid>
    </Grid>

  );
}

export default Histogram;
