import * as Yup from 'yup';
import { useState } from 'react';
import { CustomDialogBox } from '../../../../components/CustomDialogBox';
import Remarks from './Remarks';

function RemarksDialogBox({
  onClose, onSave, openDialog, remarksValue, allRemarks,
}) {
  const validationSchema = Yup.object().shape({
    remarksField: Yup.string().max(200, 'Remarks should be less than 200 characters.'),
  });

  const [newRemarksValue, setNewRemarksValue] = useState();

  const initialValues = {
    remarksField: remarksValue,
  };

  const handleSave = () => {
    onSave({ remarks: newRemarksValue });
  };

  return (
    <CustomDialogBox
      title="Remarks"
      open={openDialog}
      handleClose={onClose}
      content={<Remarks initialValues={initialValues} validationSchema={validationSchema} setNewRemarksValue={setNewRemarksValue} allRemarks={allRemarks} actions={{ title: 'Save', onClick: handleSave }} />}
    />
  );
}

export default RemarksDialogBox;
