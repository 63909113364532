import { DataGrid } from '@mui/x-data-grid';
import { uniqueId } from 'lodash';
import { LinearProgress } from '@mui/material';
import { CustomNoDataOverlay } from '../CustomNoDataOverlay';
import useStyles from '../../hooks/useStyles';
import styles from './style';

export function CustomDataGrid(props) {
  const {
    rowId, pageSize, footer = false,
  } = props;

  const classes = useStyles(styles)();
  return (
    <DataGrid
      className={classes.customDataGrid}
      components={{
        NoRowsOverlay: () => <CustomNoDataOverlay />,
        LoadingOverlay: LinearProgress,
      }}
      pageSize={pageSize}
      hideFooterPagination={!pageSize}
      hideFooter={pageSize ? false : !footer}
      disableColumnMenu
      disableColumnReorder
      autoHeight
      disableSelectionOnClick
      getRowId={(row) => (rowId ? row[rowId] : uniqueId())}
      {...props}
    />
  );
}
