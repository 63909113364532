import { createSlice, current } from '@reduxjs/toolkit';
import {
  changePassword,
  createUser,
  deleteUser,
  impersonate,
  loginUser,
  logoutUser,
  refreshSession,
  sendVerificationCode,
  setPassword,
  updateUser,
  userList,
} from './authAction';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loading: false,
    user: {
      accessToken: null,
    },
    message: '',
    error: null,
  },
  reducers: {
    setAccessToken: (state, action) => {
      state.user.accessToken = action.payload;
    },
    setSessionTokens: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    // login slice
    builder.addCase(loginUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.user = payload.data;
      state.message = payload.message;
      state.error = null;
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || 'An error occurred';
    });
    // logout slice
    builder.addCase(logoutUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logoutUser.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.user = {};
      state.message = payload.message;
      state.error = null;
    });
    builder.addCase(logoutUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || 'An error occurred';
    });
    // refresh session slice / stop impersonate
    builder.addCase(refreshSession.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(refreshSession.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.user = payload.data;
      state.message = payload.message;
      state.error = null;
    });
    builder.addCase(refreshSession.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || 'An error occurred';
    });
    // impersonate slice
    builder.addCase(impersonate.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(impersonate.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.user = payload.data;
      state.message = payload.message;
      state.error = null;
    });
    builder.addCase(impersonate.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || 'An error occurred';
    });
    // resetPassword slice
    builder.addCase(setPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setPassword.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.user = {};
      state.message = payload.message;
      state.error = null;
    });
    builder.addCase(setPassword.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || 'An error occurred';
    });
    // changePassword slice
    builder.addCase(changePassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changePassword.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.user = {};
      state.message = payload.message;
      state.error = null;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || 'An error occurred';
    });
    // verificationCode slice
    builder.addCase(sendVerificationCode.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendVerificationCode.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.user = payload.data;
      state.message = payload.message;
      state.error = null;
    });
    builder.addCase(sendVerificationCode.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || 'An error occurred';
    });
  },
});

export const userManagementSlice = createSlice({
  name: 'userList',
  initialState: {
    loading: false,
    error: null,
    message: '',
    data: [],
  },
  reducers: {
    setAccessToken: (state, action) => {
      state.user.accessToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    // User List
    builder.addCase(userList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(userList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.data;
      state.message = payload.message || '';
      state.error = null;
    });
    builder.addCase(userList.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload || 'An error occurred';
      state.data = [];
    });
    // Create User
    builder.addCase(createUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      const currentState = current(state);
      const modifiedData = [
        ...currentState.data,
        payload.data,
      ];
      state.loading = false;
      state.data = modifiedData;
      state.message = payload.message || '';
      state.error = null;
    });
    builder.addCase(createUser.rejected, (state, { payload }) => {
      const currentState = current(state);
      state.loading = false;
      state.error = payload || 'An error occurred';
      state.data = currentState.data;
    });
    // Delete User
    builder.addCase(deleteUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      const currentState = current(state);
      const modifiedData = currentState.data?.filter((item) => item.email !== payload.data);
      state.loading = false;
      state.data = modifiedData;
      state.message = payload.message || '';
      state.error = null;
    });
    builder.addCase(deleteUser.rejected, (state, { payload }) => {
      const currentState = current(state);
      state.loading = false;
      state.error = payload || 'An error occurred';
      state.data = currentState.data;
    });
    // Update User
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      const currentState = current(state);
      const modifiedData = currentState.data.map((user) => {
        const { email, ...rest } = payload.data;
        if (user.email === payload.data?.email) {
          return { ...user, ...rest };
        }
        return user;
      });
      state.loading = false;
      state.data = modifiedData;
      state.message = payload.message || '';
      state.error = null;
    });
    builder.addCase(updateUser.rejected, (state, { payload }) => {
      const currentState = current(state);
      state.loading = false;
      state.error = payload || 'An error occurred';
      state.data = currentState.data;
    });
  },
});

export const { setAccessToken, setSessionTokens } = authSlice.actions;
