import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from 'react-simple-maps';
import {
  Box, IconButton, Stack, Tooltip, Typography,
} from '@mui/material';
import { alpha2ToAlpha3, alpha3ToAlpha2 } from 'i18n-iso-countries';
import { useEffect, useRef, useState } from 'react';
import { geoPath } from 'd3-geo';
import { geoTimes } from 'd3-geo-projection';
import ReactCountryFlag from 'react-country-flag';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import palette from '../../theme/palette';

const mapCenter = [0, 40];
const defaultZoom = 1;
const maxZoom = 5;
const minZoom = 0.5;

function GeoMap({ data, country, setCountry }) {
  const [geoData, setGeoData] = useState([]);
  const [center, setCenter] = useState(mapCenter);
  const [zoom, setZoom] = useState(defaultZoom);
  const [tooltipContent, setTooltipContent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const containerRef = useRef(null);
  const geoUrl = '/features.json';

  // Define color scale based on account count
  const getColor = (countryCode) => {
    const countryDataItem = data.find(
      (item) => item.organizationCountry === alpha3ToAlpha2(countryCode),
    );
    if (countryDataItem) {
      const accounts = parseInt(countryDataItem.per, 10);
      if (accounts >= 0 && accounts <= 10) return '#ecf3ff';
      if (accounts > 10 && accounts <= 20) return '#c5d8ff';
      if (accounts > 20 && accounts <= 30) return '#a9c6ff';
      if (accounts > 30 && accounts <= 40) return '#82abff';
      if (accounts > 40 && accounts <= 50) return '#699bff';
      if (accounts > 50 && accounts <= 60) return '#4482ff';
      if (accounts > 60 && accounts <= 70) return '#3e76e8';
      if (accounts > 70 && accounts <= 80) return '#305cb5';
      if (accounts > 80 && accounts <= 90) return '#25488c';
      if (accounts > 90) return '#1d376b';
    }
    return '#FCFDFF';
  };

  const projection = () => {
    return geoTimes()
      .translate([800 / 2, 450 / 2])
      .scale(160);
  };

  // To show tooltip on mouse hover
  const handleMouseEnter = (event, geo) => {
    const { clientX, clientY } = event;
    const countryCode = alpha3ToAlpha2(geo.id);
    const countryDataItem = data.find((item) => item.organizationCountry === countryCode) || {
      organizationCountry: countryCode,
      UniqueOrgCount: 0,
      per: 0,
    };

    setTooltipContent({
      id: countryDataItem.organizationCountry,
      name: geo.properties.name,
      accounts: countryDataItem.UniqueOrgCount,
      per: countryDataItem.per,
    });

    setTooltipPosition({ top: clientY - 10, left: clientX });
  };

  const handleMouseLeave = () => {
    setTooltipContent(null); // Clear tooltip content
  };

  // To set focus on country from the map
  const handleGeographyClick = (geography) => {
    const path = geoPath().projection(projection());
    const centroid = projection().invert(path.centroid(geography));
    setCenter(centroid);
    setZoom(3);
  };

  // To set focus on country from the list
  const setGeoCoordinates = (countryCode) => {
    const centerCountry = geoData.find((geo) => geo.id === countryCode);
    if (centerCountry) {
      handleGeographyClick(centerCountry);
    }
  };

  // Handle refresh
  const handleRefresh = () => {
    setCountry('');
    if (zoom === defaultZoom && center[0] === mapCenter[0] && center[1] === mapCenter[1]) {
      window.location.reload();
    } else {
      setZoom(1);
      setCenter(mapCenter);
    }
  };

  // Handle Unselect
  const handleUnselect = () => {
    setCountry('');
  };

  // Handle zoom in
  const handleZoomIn = () => {
    if (zoom < maxZoom) {
      setZoom((prevZoom) => prevZoom + 0.5);
    }
  };

  // Handle zoom out
  const handleZoomOut = () => {
    if (zoom > minZoom) {
      setZoom((prevZoom) => prevZoom - 0.5);
    }
  };

  useEffect(() => {
    if (geoData?.length && country) {
      setGeoCoordinates(alpha2ToAlpha3(country));
    }
  }, [geoData, country]);

  return (
    <>
      <Box
        ref={containerRef}
        height="80vh"
        width="100%"
        sx={{
          position: 'relative',
          backgroundColor: '#FFF',
          overflow: 'hidden',
          borderRadius: '5px',
        }}
      >
        <ComposableMap projection="geoMercator" style={{ height: '100%', width: '100%' }} onClick={handleUnselect}>
          <ZoomableGroup center={center} zoom={zoom} maxZoom={maxZoom} minZoom={minZoom}>
            <Geographies geography={geoUrl}>
              {({ geographies }) => {
                setGeoData(geographies);
                return (geographies.map((geo) => (
                  <Geography
                    stroke={alpha3ToAlpha2(geo.id) === country ? palette.grey[900] : '#6689cf'}
                    strokeWidth={alpha3ToAlpha2(geo.id) === country ? 0.8 : 0.2}
                    key={geo.rsmKey}
                    geography={geo}
                    fill={getColor(geo.id)}
                    onClick={() => handleGeographyClick(geo)}
                    onMouseEnter={(e) => handleMouseEnter(e, geo)}
                    onMouseLeave={handleMouseLeave}
                    style={{
                      default: { outline: 'none' },
                      hover: { outline: 'none' },
                      pressed: { outline: 'none' },
                    }}
                  />
                )));
              }}
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>

        <Stack sx={{ position: 'absolute', top: 10, right: 10 }} gap={1}>
          <IconButton sx={{ backgroundColor: palette.primary.background, borderRadius: '5px' }} onClick={handleZoomIn} disabled={zoom >= maxZoom} color="primary">
            <AddIcon />
          </IconButton>

          <IconButton sx={{ backgroundColor: palette.primary.background, borderRadius: '5px' }} onClick={handleZoomOut} disabled={zoom <= minZoom} color="primary">
            <RemoveIcon />
          </IconButton>

          <IconButton sx={{ backgroundColor: palette.primary.background, borderRadius: '5px' }} onClick={handleRefresh} color="primary">
            <RestartAltIcon />
          </IconButton>
        </Stack>

      </Box>

      {/* Tooltip */}
      {tooltipContent && (
        <Tooltip
          open={!!tooltipContent}
          title={(
            <Box p={1}>
              <Stack direction="row" alignItems="center" gap={1}>
                <ReactCountryFlag
                  countryCode={tooltipContent?.id || ''}
                  style={{ fontSize: '1.3em' }}
                />
                <Typography variant="subtitle1">
                  {tooltipContent?.name || ''}
                </Typography>
              </Stack>
              <Typography variant="subtitle2">
                {`Accounts ${tooltipContent?.accounts ?? ''} (${tooltipContent.per ? +tooltipContent.per : 0}%)`}
              </Typography>
            </Box>
          )}
          placement="top"
          arrow
          style={{
            position: 'absolute', top: tooltipPosition.top, left: tooltipPosition.left, transform: 'translate(-50%, -100%)',
          }}
        >
          <span />
        </Tooltip>
      )}
    </>

  );
}

export default GeoMap;
