import { FormDialogBox } from '../../../../components/CustomDialogBox';
import CreateUser from '../UserManagerForm/CreateUser';
import EditUser from '../UserManagerForm/EditUser';
import useUserManagerDialog from './useUserManagerDialog';

function UserManagerDialog(props) {
  const {
    userDialogData, openDialog, onClose, permissions, tenant,
  } = props;

  const {
    initialValues, validations, onSubmit,
  } = useUserManagerDialog({
    tenantId: tenant?.id,
    onClose,
    userData: userDialogData.userData,
    type: userDialogData.type,
  });

  function FormComponent({ formik }) {
    if (userDialogData.type === 'edit') {
      return <EditUser formik={formik} />;
    }
    return <CreateUser permissions={permissions} tenant={tenant} formik={formik} />;
  }

  return (
    <FormDialogBox
      title={userDialogData.title}
      open={openDialog}
      handleClose={onClose}
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validations}
      formContent={<FormComponent />}
      formAction={{ title: userDialogData.action }}
    />
  );
}

export default UserManagerDialog;
