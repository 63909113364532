import { createAsyncThunk } from '@reduxjs/toolkit';

import request from '../../modules/Request/request';

export const getEventTimeLine = createAsyncThunk(
  'getEventTimeLine',
  async ({
    packageId, orgId, isShare, tenantId, accountOrgId,
  }, { rejectWithValue }) => {
    const reqPayload = {};
    const query = {};
    reqPayload.method = 'GET';
    reqPayload.params = orgId;
    query.pkgId = packageId;
    if (isShare) {
      query.isShare = isShare;
      if (accountOrgId) {
        query.accountOrgId = accountOrgId;
      } else {
        query.tenantId = tenantId;
      }
    }
    reqPayload.query = query;
    const response = await request('/license-timeline-data', reqPayload);
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);
