import { createAsyncThunk } from '@reduxjs/toolkit';

import request from '../../modules/Request/request';

export const addHealthScore = createAsyncThunk('addHealthScore', async ({ data, packageId }, { rejectWithValue }) => {
  const response = await request('/health-score', { method: 'POST', body: data, params: packageId });
  if (response.success) {
    return response.data;
  }
  const error = response?.error?.message || response.error;
  throw rejectWithValue(error || 'Something went wrong');
});

export const getHealthInsightsList = createAsyncThunk(
  'healthInsightsList',
  async ({ packageId }, { rejectWithValue }) => {
    const response = await request('/health-score/list', { method: 'GET', params: packageId });
    if (response.success) {
      return response.data;
    }
    const error = response?.error?.message || response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const updateHealthScore = createAsyncThunk(
  'updateHealthScore',
  async ({ data, packageId }, { rejectWithValue }) => {
    const response = await request('/health-score/update', { method: 'PUT', body: data, params: packageId });
    if (response.success) {
      return response.data.response;
    }
    const error = response?.error?.message || response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const deleteHealthScore = createAsyncThunk('deleteHealthScore', async (id, { rejectWithValue }) => {
  const response = await request('/health-score/delete', { method: 'DELETE', params: id });
  if (response.success) {
    return id;
  }
  const error = response?.error?.message || response.error;
  throw rejectWithValue(error || 'Something went wrong');
});

export const getHealthInsightsDetails = createAsyncThunk(
  'healthInsightsDetails',
  async ({ healthScoreId }, { rejectWithValue }) => {
    const response = await request('/health-score/details', { method: 'GET', params: healthScoreId });
    if (response.success) {
      return response.data;
    }
    const error = response?.error?.message || response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const getHealthScoreRuleGraphData = createAsyncThunk(
  'RuleGraphData',
  async ({ packageId, body }, { rejectWithValue }) => {
    const response = await request('/health-score-graph', { method: 'POST', params: packageId, body });
    if (response.success) {
      return response.data;
    }
    const error = response?.error?.message || response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const explainHealthScore = createAsyncThunk(
  'explainHealthScore',
  async ({
    orgId, packageId, healthScoreId, isShare, tenantId, accountOrgId,
  }, { rejectWithValue }) => {
    const reqPayload = {};
    reqPayload.method = 'GET';
    reqPayload.params = [orgId, packageId, healthScoreId];
    const query = {};
    if (isShare) {
      query.isShare = isShare;
      if (accountOrgId) {
        query.accountOrgId = accountOrgId;
      } else {
        query.tenantId = tenantId;
      }
    }
    reqPayload.query = query;
    const response = await request('/health-score/explain', reqPayload);
    if (response.success) {
      return response.data;
    }
    const error = response?.error?.message || response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);
