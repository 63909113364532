import * as Yup from 'yup';
import { useFormik, getIn } from 'formik';
import { Box } from '@mui/material';

const formikForm = (WrappedComponent, formFields) => {
  return function FormikFormHOC(props) {
    const {
      children, initialValues, onSubmit, validationSchema, formRef,
    } = props;

    const generateValidationSchema = (fields) => {
      const schema = {};

      if (fields.includes('email')) {
        schema.email = Yup.string().email('Invalid email address').required('Email is required');
      }

      if (fields.includes('password')) {
        schema.password = Yup.string().required('Password is required');
      }

      if (fields.includes('fullName')) {
        schema.fullName = Yup.string().required('Name is required');
      }

      return Yup.object(schema);
    };

    const defaultValidationSchema = generateValidationSchema(formFields ?? []);

    const validations = validationSchema || defaultValidationSchema;

    const formik = useFormik({
      initialValues,
      onSubmit,
      validationSchema: validations,
      enableReinitialize: true,
    });

    if (formRef) {
      formRef.current = formik;
    }

    const formikProps = { getIn, ...formik };

    return (
      <form
        onSubmit={formik.handleSubmit}
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <Box width="100%">
          <WrappedComponent formik={formikProps} {...props}>{children}</WrappedComponent>
        </Box>
      </form>
    );
  };
};

export default formikForm;
