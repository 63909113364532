import { useEffect, useState, useCallback } from 'react';

const useCustomAccordion = ({ accordionData, defaultExpanded = [], toggleAll = false }) => {
  const [expandedAccordions, setExpandedAccordions] = useState(defaultExpanded);

  const accordionToggle = useCallback((id) => {
    setExpandedAccordions((prevExpanded) => (
      prevExpanded.includes(id)
        ? prevExpanded.filter((number) => number !== id)
        : [...prevExpanded, id]
    ));
  }, []);

  useEffect(() => {
    if (toggleAll === true) {
      setExpandedAccordions(accordionData.map((data) => data.id));
    } else {
      setExpandedAccordions([]);
    }
  }, [toggleAll, accordionData]);

  useEffect(() => {
    if (defaultExpanded.length) {
      setExpandedAccordions(defaultExpanded);
    }
  }, [defaultExpanded]);

  return {
    expandedAccordions,
    accordionToggle,
  };
};

export default useCustomAccordion;
