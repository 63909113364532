import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { transformGraphLabel } from '../../../../utils/getDate';

const useHealthScoreExplain = () => {
  const navigate = useNavigate();

  const healthScoreExplainData = useSelector((state) => state.healthScoreExplain);
  const [healthScoreExplain, setHealthScoreExplain] = useState({
    healthScoreExplainData: {},
    error: null,
    loading: true,
  });

  const healthScoreDialogData = useMemo(() => {
    const { healthScoreExplainData: explainData } = healthScoreExplain;
    if (!explainData) return {};

    const {
      healthScoreId, healthScoreLabel, startDate, endDate, ruleData,
    } = explainData;

    const healthScoreData = ruleData?.map((rule) => ({
      label: rule.ruleLabel,
      value: rule.ruleValue,
      threshold: rule.ruleThreshold,
      points: rule.rulePoints,
    })) || [];

    const startLabel = transformGraphLabel(startDate, 'day');
    const endLabel = transformGraphLabel(endDate, 'day');
    const healthScoreRange = `${startLabel} - ${endLabel}`;

    return {
      healthScoreId,
      healthScoreLabel,
      healthScoreRange,
      healthScoreData,
    };
  }, [healthScoreExplain?.healthScoreExplainData]);

  const handleEditHealthScore = (id) => {
    navigate(`/kpi-score-configuration/update-health-score/${id}`);
  };

  const setHealthScoreExplainData = () => {
    setHealthScoreExplain({
      ...healthScoreExplain,
      ...healthScoreExplainData,
    });
  };

  useEffect(() => {
    setHealthScoreExplainData();
  }, [healthScoreExplainData]);

  return {
    healthScoreDialogData, handleEditHealthScore,
  };
};

export default useHealthScoreExplain;
