/* eslint-disable no-nested-ternary */
import { Box, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useSelector } from 'react-redux';

function StatusAndExpiry() {
  const tenantDetails = useSelector((state) => state.tenantDetails?.data);

  const daysLeft = (targetDate) => {
    if (!targetDate) return null;
    const currentDate = moment();
    const target = moment(targetDate);
    return target.diff(currentDate, 'days');
  };

  const daysRemaining = daysLeft(tenantDetails?.expirationDate);
  const isExpiring = daysRemaining !== null && daysRemaining <= 30;
  const isTrial = tenantDetails?.currentStatus === 'trial';
  const isSuspended = tenantDetails?.currentStatus === 'suspended';
  const isActive = tenantDetails?.currentStatus === 'active';

  if (isActive && !isExpiring) return null;

  return (
    <Stack
      flexDirection="row"
      alignItems="center"
      gap={2}
      mr={2}
    >
      {isTrial && (
      <Stack
        flexDirection="row"
        alignItems="center"
        gap={1}
        sx={(theme) => ({
          border: `1px solid ${theme.palette.warning.alt}`,
          alignSelf: 'center',
          color: theme.palette.grey[600],
          borderRadius: 10,
          py: '2px',
          px: 2,
        })}
      >
        <Box sx={(theme) => ({
          width: 10,
          height: 10,
          backgroundColor: theme.palette.warning.alt,
          borderRadius: '50%',
        })}
        />
        <Typography fontWeight={500}>Trial</Typography>
      </Stack>
      )}

      {isSuspended && (
      <Stack
        flexDirection="row"
        alignItems="center"
        gap={1}
        sx={(theme) => ({
          border: `1px solid ${theme.palette.warning.alt}`,
          alignSelf: 'center',
          color: theme.palette.grey[600],
          borderRadius: 10,
          py: '2px',
          px: 2,
        })}
      >
        <Box sx={(theme) => ({
          width: 10,
          height: 10,
          backgroundColor: theme.palette.warning.alt,
          borderRadius: '50%',
        })}
        />
        <Typography fontWeight={500}>Suspended</Typography>
      </Stack>
      )}
      {(isExpiring && !isSuspended) && (
      <Stack
        flexDirection="row"
        alignItems="center"
        gap={1}
        sx={(theme) => ({
          border: `1px solid ${theme.palette.error.light}`,
          alignSelf: 'center',
          color: theme.palette.grey[600],
          borderRadius: 10,
          py: '2px',
          px: 2,
        })}
      >
        <Box sx={(theme) => ({
          width: 10,
          height: 10,
          backgroundColor: theme.palette.error.main,
          borderRadius: '50%',
        })}
        />
        <Typography fontWeight={500}>
          {daysRemaining > 0
            ? `Expires in ${daysRemaining} days`
            : daysRemaining === 0
              ? 'Expiring Today'
              : 'Expired'}
        </Typography>
      </Stack>
      )}
    </Stack>
  );
}

export default StatusAndExpiry;
