import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { Graph } from '../../../../components/Graph';
import { DASHBOARD_CARDS } from '../../../../utils/chartConfigs';
import { withLoadingAndOverlay } from '../../../../hoc';

function VersionBreakdown({ data, more, plugins }) {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const labels = Object.keys(data);

  const datasets = [
    {
      ...DASHBOARD_CARDS.BAR_DATASET,
      data: Object.values(data),
    },
  ];

  const options = {
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
    onClick: (event, chartElement) => {
      const { index } = chartElement.length && chartElement[0];
      if (index || index === 0) {
        const versionBar = Object.keys(data)[index];
        navigate('/account-list', { state: { filters: { packageVersion: versionBar, licenceStatus: 'Active', accountVisibility: true } } });
      }
    },
    ...DASHBOARD_CARDS.OPTIONS,
    maintainAspectRatio: !!more,
    ...(plugins
      ? {
        plugins: {
          title: {
            display: true,
            text: 'Version Highlights',
            padding: 15,
            align: 'center',
            position: 'top',
            font: {
              size: 14,
            },
          },
          legend: {
            display: false,
          },
        },
      }
      : {}),

  };

  let widthValue;
  if (more) {
    widthValue = null;
  } else if (isSmallScreen) {
    widthValue = 300;
  } else {
    widthValue = 650;
  }

  return (
    <Graph type="bar" labels={labels} datasets={datasets} options={options} height={more ? null : 400} width={widthValue} />
  );
}
export default withLoadingAndOverlay(VersionBreakdown);
