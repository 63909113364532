import { createAsyncThunk } from '@reduxjs/toolkit';

import request from '../../modules/Request/request';

export const getObjectFilters = createAsyncThunk(
  'getObjectFilters',
  async (object, { rejectWithValue }) => {
    if (object.body) {
      const response = await request('event-browser/custom-object', { method: 'POST', body: object.body, query: { pkgId: object.pkgId } });
      if (response.success) {
        return { records: response.data, object };
      }
      const error = response.error && response.error.message
        ? response.error.message
        : response.error;
      throw rejectWithValue(error || 'Something went wrong');
    } else {
      const response = await request('event-browser/custom-object/list', { method: 'GET', query: { pkgId: object.pkgId } });
      if (response.success) {
        return { filters: response.data };
      }
      const error = response.error && response.error.message
        ? response.error.message
        : response.error;
      throw rejectWithValue(error || 'Something went wrong');
    }
  },
);
