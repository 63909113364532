const DashboardCardsStyle = (theme) => ({
  cardStyle: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 15,
    minHeight: '100%',
    boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px',
    borderRadius: '6px',
  },
  noHeaderStyle: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: 0,
    minHeight: '100%',
    boxShadow: 'rgba(0, 0, 0, 0.04) 0px 3px 5px',
    borderRadius: '6px',
  },
  boxStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  buttonStyle: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  subHeaderStyle: {
    color: theme.palette.text.greyed,
    fontSize: '15px',
  },
});

export default DashboardCardsStyle;
