import { Typography, styled } from '@mui/material';

const TypographyStyle = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.mainColor,
  cursor: 'pointer',
  textDecoration: 'underline',
}));

function ClickableCell({ value, className, onClick = () => null }) {
  return (
    <TypographyStyle className={className} onClick={onClick}>{value}</TypographyStyle>
  );
}

export default ClickableCell;
