import { Stack, Tooltip, Typography } from '@mui/material';
import { CustomCircularProgress } from '../../../../components/CustomCircularProgress';
import { DynamicTable } from '../../../../components/CustomTable';
import { CustomDialogBox } from '../../../../components/CustomDialogBox';
import useHealthScoreExplain from './useHealthScoreExplain';

function DialogContentElement({ data = [] }) {
  const dataLen = data?.length;

  const columns = [{ field: 'explain' }];
  const rows = dataLen && data.map((rule) => ({
    explain: (
      <>
        <Typography>{rule.label}</Typography>
        <Stack direction="row" justifyContent="space-between" spacing={2}>
          <Typography variant="subtitle2">{`Value: ${rule.value}`}</Typography>
          <Typography variant="subtitle2">{`Weightage: ${rule.points}`}</Typography>
        </Stack>
      </>
    ),
  }));

  return (
    <DynamicTable columns={columns} rows={rows} dialog />
  );
}

function TitleElement({ label, value }) {
  return (
    <Stack flexDirection="row" alignItems="center" gap={1}>
      <CustomCircularProgress value={value} height={35} width={35} />
      <Tooltip title={label}>
        <Typography fontWeight={700}>{label}</Typography>
      </Tooltip>
    </Stack>
  );
}

function HealthScoreExplain(props) {
  const {
    open, setOpen, isShare, permissions, healthScore,
  } = props;

  const {
    healthScoreDialogData, handleEditHealthScore,
  } = useHealthScoreExplain();

  return (
    <CustomDialogBox
      title={<TitleElement label={healthScoreDialogData?.healthScoreLabel} value={healthScore} />}
      open={open}
      handleClose={setOpen}
      content={<DialogContentElement data={healthScoreDialogData?.healthScoreData} />}
      actions={(permissions.write && !isShare) && { info: healthScoreDialogData?.healthScoreRange, title: 'Edit KPI Score', onClick: () => handleEditHealthScore(healthScoreDialogData.healthScoreId) }}
    />
  );
}

export default HealthScoreExplain;
