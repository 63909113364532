import {
  Grid, Stack, styled,
} from '@mui/material';
import { useRef } from 'react';
import { ZoomControls } from '../../../../components/ZoomControls';
import { ComboChart } from '../../../../components/ComboChart';

const ZoomContainer = styled(Stack)(() => ({
  width: '100%',
  alignItems: 'end',
  pt: 2,
  pr: 2,
}));

const GraphContainer = styled(Stack)(() => ({
  width: '100%',
  padding: 0,
}));

export default function FeatureUsageUsers(props) {
  const chartRefUsers = useRef(null);
  const chartRefOperations = useRef(null);

  const {
    getChartData,
    feature,
    viewOption,
    graphHeight,
    graphWidth,
    featureName,
    dateRange,
    graphType,
    graphTitle,
  } = props;

  return (
    <Grid container spacing={graphType ? 0 : 4}>
      {(!graphType || graphType === 'users') && (
      <Grid item xs={12}>
        {viewOption === 'day' && <ZoomContainer><ZoomControls chartRef={chartRefUsers} data={feature} /></ZoomContainer>}
        <GraphContainer>
          <ComboChart
            chartData={getChartData({
              feature,
              config: {
                title: graphTitle?.title1 ?? (featureName ? `KPI Usage for ${featureName}` : `KPI Usage last ${dateRange}`),
                chart: 'combo',
              },
              viewOption,
            })}
            graphHeight={graphHeight}
            chartRef={chartRefUsers}
          />
        </GraphContainer>
      </Grid>
      )}

      {(!graphType || graphType === 'operations') && (
      <Grid item xs={12}>
        {viewOption === 'day' && <ZoomContainer><ZoomControls chartRef={chartRefOperations} data={feature} /></ZoomContainer>}
        <GraphContainer>
          <ComboChart
            chartRef={chartRefOperations}
            chartData={getChartData({
              feature,
              config: {
                title: graphTitle?.title2 ?? (featureName ? `Operations for ${featureName}` : `Operations last ${dateRange}`),
                chart: 'bar',
              },
              type: 'op',
              viewOption,
            })}
            graphHeight={graphHeight}
            graphWidth={graphWidth}
          />
        </GraphContainer>
      </Grid>
      )}
    </Grid>
  );
}
