const switchStyle = ((theme) => ({
  enabled: {
    border: `1px solid ${theme.palette.primary.lighter}`,
    color: theme.palette.primary.text,
    borderColor: theme.palette.primary.border,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.lighter,
      borderColor: theme.palette.primary.lighter,
    },
  },
  disabled: {
    border: `1px solid ${theme.palette.grey[400]}`,
    color: theme.palette.grey[500],
    borderColor: theme.palette.grey[400],
  },
}));

export default switchStyle;
