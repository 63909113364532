import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useVersionKpiScore = () => {
  const [versionsKpiScore, setVersionsKpiScore] = useState({
    versionData: [],
    error: '',
    loading: true,
  });

  const versionsKpiScoreData = useSelector((state) => state.versionKpiScore);

  const setVersionsData = () => {
    setVersionsKpiScore({
      ...versionsKpiScore,
      ...versionsKpiScoreData,
      error: versionsKpiScoreData.error,
    });
  };

  useEffect(() => {
    setVersionsData();
  }, [versionsKpiScoreData]);

  return { versionsKpiScore };
};
