import { useState } from 'react';
import {
  Badge, Box, Divider, List, ListItem, ListItemButton, Paper, Popover, Tooltip,
} from '@mui/material';
import { keyframes } from '@mui/material/styles';
import { CustomIcon } from '../CustomIcon';

export default function CustomPopover({
  Icon, imageSrc, badge, data, tooltip, content, onClick,
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    if (onClick) onClick();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const ImgComponent = (
    <img
      alt="wt"
      src={imageSrc}
      width="80%"
      onClick={handleClick}
      style={{ cursor: 'pointer' }}
    />
  );

  const blink = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

  const IconComponent = (
    badge ? (
      <Badge variant="dot" sx={{ '& .MuiBadge-badge': { animation: `${blink} 1.5s linear infinite`, background: 'orange' } }}>
        {ImgComponent}
      </Badge>
    ) : (
      ImgComponent
    )
  );

  return (
    <Box className="tg-watch-list">
      {Icon && (
      <CustomIcon
        badge={badge}
        tooltip={tooltip}
        onClick={handleClick}
        Icon={open ? (Icon.open ?? Icon) : (Icon.close ?? Icon)}
      />
      )}

      {imageSrc
      && (
      <Tooltip title={tooltip}>
        {IconComponent}
      </Tooltip>
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ mt: 1 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Paper mt={2}>
          {content && content}
          {data && <ContentElement data={data} />}
        </Paper>
      </Popover>
    </Box>
  );
}

function ContentElement({ data }) {
  return (
    <List>
      {data?.length ? data.map((item, i) => (
        <Box key={item.id}>
          {i !== 0 && <Divider variant="middle" component="li" />}
          <ListItemButton onClick={item.onClick}>
            {item.content}
          </ListItemButton>
        </Box>
      )) : (
        <ListItem>
          No Alerts!
        </ListItem>
      )}
    </List>
  );
}
