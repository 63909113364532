import { createAsyncThunk } from '@reduxjs/toolkit';

import request from '../../modules/Request/request';

export const fetchWatchListAccounts = createAsyncThunk(
  'fetchWatchListAccounts',
  async ({ packageId, metric }, { rejectWithValue }) => {
    const response = await request('/watch-list-accounts', { method: 'POST', body: { packageId, metric } });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const getCustomAlertDetails = createAsyncThunk(
  'getCustomAlertDetails',
  async (object, { rejectWithValue }) => {
    const reqPayload = {};
    const query = {};
    reqPayload.method = 'GET';
    query.pkgId = object.pkgId;
    if (object?.isShare) {
      query.isShare = object.isShare;
      if (object.accountOrgId) {
        query.accountOrgId = object.accountOrgId;
      } else {
        query.tenantId = object.tenantId;
      }
    }
    reqPayload.query = query;
    try {
      const response = await request('/get-custom-alert-details', reqPayload);
      if (response.success) {
        return response.data;
      }
      throw new Error(response?.error?.message || response.error || 'Unknown error');
    } catch (error) {
      throw rejectWithValue(error.message || 'Something went wrong');
    }
  },
);

export const saveCustomAlertDetails = createAsyncThunk(
  'saveCustomAlertDetails',
  async ({
    duration, threshold, averageMonths, type, view, packageId,
  }, { rejectWithValue }) => {
    const response = await request('/save-custom-alert-details', {
      method: 'POST',
      body: {
        duration, threshold, averageMonths, type, view, packageId,
      },
    });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message
      ? response.error.message
      : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);
