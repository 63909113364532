import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import { Typography } from '@mui/material';
import { getUserRole } from '../../utils/methods';
import useToggle from '../../hooks/useToggle';
import { openSnackbar } from '../../features/SnackBar/snackBarSlice';
import { deleteUser } from '../../features/Auth/authAction';
import useAuthorization from '../../hooks/useAuthorization';
import { ActionCell } from '../../modules/FeatureManager';
import palette from '../../theme/palette';

function useUserManager({ permissions, tenantId }) {
  const userListData = useSelector((state) => state.userList);
  const [userList, setUserList] = useState({
    loading: false,
    error: null,
    data: [],
  });

  const setUserListData = () => {
    setUserList((prev) => ({
      ...prev,
      ...userListData,
    }));
  };

  useEffect(() => {
    setUserListData();
  }, [userListData]);

  const [userDialogData, setUserDialogData] = useState({
    type: 'create',
    title: 'Create User',
    action: 'Create User',
  });
  const [openUserDialog, setOpenUserDialog] = useToggle();

  const { userInfo } = useAuthorization();
  const { email } = userInfo;
  const dispatch = useDispatch();

  const [actionDialogData, setActionDialogData] = useState({});
  const [openActionDialog, setOpenActionDialog] = useToggle();

  const handleDeleteUser = (params) => {
    const deleteUserFn = () => {
      dispatch(deleteUser({ tenantId, email: params.row.email })).then((res) => {
        if (res?.payload?.status === 'SUCCESS') {
          dispatch(openSnackbar({ open: true, message: res.payload.message, type: 'success' }));
        } else {
          dispatch(openSnackbar({ open: true, message: res.payload.message, type: 'error' }));
        }
      });
      setOpenActionDialog();
    };

    setActionDialogData({
      title: 'Delete User',
      message: (
        <Typography>
          Are you sure you want to delete the account
          {' '}
          <span style={{ color: palette.primary.main }}>{params.row.email}</span>
          {' ?'}
        </Typography>
      ),
      onConfirm: deleteUserFn,
    });
    setOpenActionDialog();
  };

  const handleEditUser = (params) => {
    setUserDialogData({
      type: 'edit',
      title: 'Edit User',
      action: 'Save Changes',
      userData: params.row,
    });
    setOpenUserDialog();
  };

  const getActionCellData = (params) => {
    const isDisabled = !permissions.write || params.row.email === email;
    const actionCellData = [
      {
        id: 'edit',
        name: 'Edit',
        icon: DriveFileRenameOutlineRoundedIcon,
        disabled: !permissions.write,
        handleFunction: permissions.write ? handleEditUser : (() => null),
      },
      {
        id: 'delete',
        info: 'Delete User',
        icon: DeleteIcon,
        disabled: isDisabled,
        handleFunction: permissions.write ? handleDeleteUser : (() => null),
      },
    ];

    return actionCellData;
  };

  const userManagerColumns = [
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 0.8,
    },
    {
      field: 'group',
      headerName: 'Access',
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
      renderCell: (params) => (
        <span style={{ color: params.value === 'admin' && palette.primary.main }}>
          {getUserRole(params.value)?.role}
        </span>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
      renderCell: (params) => (
        <span style={{ color: params.value ? palette.success.dark : palette.error.main }}>
          {params.value ? 'Enabled' : 'Disabled'}
        </span>
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
      renderCell: (params) => <ActionCell actionCellData={getActionCellData(params)} params={params} />,
    },
  ];

  const handleOpenUserDialog = () => {
    setUserDialogData({
      type: 'create',
      title: 'Create User',
      action: 'Create User',
    });
    setOpenUserDialog();
  };

  return {
    userListData: userList,
    userManagerColumns,
    openUserDialog,
    setOpenUserDialog,
    actionDialogData,
    setActionDialogData,
    openActionDialog,
    setOpenActionDialog,
    userDialogData,
    handleOpenUserDialog,
  };
}

export default useUserManager;
