import { io } from 'socket.io-client';
import { getBaseUrl } from '../modules/Request/proxy.config';

const SOCKET_SERVER_URL = getBaseUrl();
let socket = null;

// Connect to the WebSocket server
export const connectSocket = () => {
  if (!socket) {
    socket = io(SOCKET_SERVER_URL, {
      path: '/socket.io',
      transports: ['websocket'],
    });
  }
};

// Disconnect from the WebSocket server
export const disconnectSocket = () => {
  if (socket && socket.connected) {
    socket.disconnect();
    socket = null;
  }
};

// Listen for an event
export const onSocketEvent = (event, callback) => {
  if (socket) {
    socket.on(event, callback);
  }
};

// Emit an event
export const emitSocketEvent = (event, data) => {
  if (socket) {
    socket.emit(event, data);
  }
};
