import { Container } from '@mui/material';
import { TenantManager } from '../sections/TenantManager';
import Metatags from '../components/MetaTags/MetaTags';

function TenantManagementPage({ permissions }) {
  return (
    <>
      <Metatags metaTagName="Tenant Manager | App Insights" />
      <Container maxWidth="xl">
        <TenantManager permissions={permissions} />
      </Container>
    </>
  );
}

export default TenantManagementPage;
