import { createSlice } from '@reduxjs/toolkit';
import { getEventTimeLine } from './EventTimelineAction';

const eventTimelineSlice = createSlice({
  name: 'eventTimeline',
  initialState: {
    loading: false,
    eventTimelineData: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEventTimeLine.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getEventTimeLine.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.eventTimelineData = payload.response;
    });
    builder.addCase(getEventTimeLine.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export {
  eventTimelineSlice,
};
