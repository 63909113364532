import { utils, writeFile } from 'xlsx';
import { uniq } from 'lodash';
import { DATE_FORMAT } from './constants';
import { getWeekRange } from './getDate';
import { fDate } from './formatTime';

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const stringify = (str) => {
  return JSON.stringify(str);
};

export const parseStrObj = (value, defaultValue = null) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    // If parsing fails, return the original string
    return value ?? defaultValue;
  }
};

export const parse = (str) => {
  return JSON.parse(str);
};

export const findById = (id, array) => {
  return array.find((obj) => obj.id === id);
};

export const percentage = (upper, lower) => {
  const upperValue = parseInt(upper, 10);
  const lowerValue = parseInt(lower, 10);
  if (upperValue > 0 && lowerValue > 0) {
    return Math.round((upperValue / lowerValue) * 100);
  }
  return 0;
};

export const uniqueArr = (arr) => uniq(arr);

export const editDataCommon = (params, recordData, customEntityData, crudOperations, featureManager = []) => {
  const editData = {
    id: params.row.feature_id,
    name: params.row.feature_name,
  };

  const processCustomObject = (dataObject, matchingData) => {
    dataObject.operation_type = JSON.parse(matchingData.operation_type);
    if (dataObject.operation_type && dataObject.operation_type.length) {
      dataObject.allEvent = crudOperations.every((curdData) => {
        return dataObject.operation_type.includes(curdData.value);
      });
      // TODO do later
      // dataObject.allSource = otherOperations.every((otherData) => {
      //   return dataObject.operation_type.includes(otherData.value);
      // });
    } else {
      dataObject.allEvent = false;
      // dataObject.allSource = false;
    }
  };

  const processEntity = (obj, type, entityKey, idKey, nameKey) => {
    if (recordData[entityKey] && recordData[entityKey].length) {
      const matchingData = recordData[entityKey].filter((data) => data[entityKey === 'nestedFeatures' ? 'nested_feature_id' : idKey] === obj[idKey]);
      if (matchingData.length) {
        if (!editData[type]) {
          editData[type] = { ids: [], data: [] };
        }
        const dataObject = {
          [entityKey === 'nestedFeatures' ? 'nested_feature_name' : nameKey]: obj[nameKey],
          [entityKey === 'nestedFeatures' ? 'nested_feature_id' : idKey]: obj[idKey],
        };
        if (type === 'CustomObject') {
          processCustomObject(dataObject, matchingData[0]);
        }
        editData[type].data.push(dataObject);
        editData[type].ids.push(obj[idKey]);
      }
    }
  };

  if (customEntityData?.customEntityList?.length) {
    const entityKey = 'customEntity';
    const idKey = 'custom_entity_id';
    const nameKey = 'custom_entity_label';
    customEntityData.customEntityList.forEach((obj) => {
      const type = obj.custom_entity_type.trim(' ');
      processEntity(obj, type, entityKey, idKey, nameKey);
    });
  }

  if (featureManager) {
    const entityKey = 'nestedFeatures';
    const type = 'NestedFeature';
    let idKey = 'featureId';
    let nameKey = 'featureName';
    let array = featureManager;

    if (featureManager.featureManagerList?.length) {
      idKey = 'feature_id';
      nameKey = 'feature_name';
      array = featureManager.featureManagerList;
    }

    array.forEach((obj) => {
      processEntity(obj, type, entityKey, idKey, nameKey);
    });
  }

  return editData;
};

export const modifiedDateString = (date, type = 'default') => {
  const formatMap = {
    month: DATE_FORMAT.MONTH_DATE_GRAPH,
    week: DATE_FORMAT.WEEK_DATE_GRAPH,
    day: DATE_FORMAT.DAY_DATE_GRAPH,
    default: DATE_FORMAT.DAY_DATE,
  };
  const formattedDate = type === 'week' ? getWeekRange(date) : fDate(date, formatMap[type]);
  return formattedDate;
};

export const exportData = (fileName, packageName, data) => {
  const modifiedFileName = `${fileName} (${packageName}).xlsx`;
  // Convert accountsList to worksheet
  const ws = utils.json_to_sheet(data);

  // Create a workbook
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, fileName);

  // Save the workbook to a file
  writeFile(wb, modifiedFileName);
};

export const getUserRole = (role = 'user') => {
  const userRoles = {
    superAdmin: { level: 1, access: 'superAdmin', role: 'Super Admin' },
    admin: { level: 2, access: 'admin', role: 'Admin' },
    user: { level: 3, access: 'user', role: 'User' },
  };

  return role in userRoles ? userRoles[role] : userRoles.user;
};
