import {
  Stack, Box, Typography, Tooltip, IconButton,
} from '@mui/material';
import React from 'react';
import TuneIcon from '@mui/icons-material/Tune';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import styled from '@emotion/styled';
import { toolTipText } from '../../../../utils/toolTipText';
import { entityName } from '../../constants';
import palette from '../../../../theme/palette';

export default function CustomComponents({
  customObject, handleRefineFilter, handleRemove, type, customIcon,
}) {
  const EntityLabel = styled(Typography)(() => ({
    fontWeight: 'bold',
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
  }));

  const FilterLabel = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
    fontSize: 12,
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 4,
    paddingRight: 4,
    border: `1px solid ${theme.palette.grey[300]}`,
  }));

  const IconBox = styled(IconButton)(({ theme }) => ({
    borderRadius: 5,
    display: 'flex',
    alignItems: 'end',
    border: `1px solid ${theme.palette.grey[300]}`,
  }));

  const IconLabel = styled(Typography)(() => ({
    fontWeight: 'bold',
    fontSize: 15,
    paddingLeft: 4,
    marginRight: 6,
  }));

  return (
    customObject.data && customObject.data.length ? (
      <Stack direction="row" alignItems="center" justifyContent="start" flexWrap="wrap" marginTop={2}>
        {customObject.data.map((obj) => (
          <Box
            key={obj.custom_entity_id || obj.nested_feature_id}
            style={{
              borderRadius: 5,
              border: `1px solid ${palette.grey[300]}`,
              marginTop: 5,
              marginBottom: 5,
              marginRight: 10,
              height: 'inherit',
              width: 210,
              padding: '0.3em 0.5em',
            }}
          >
            {/* Heading */}
            <Stack justifyContent="start" gap={1} direction="row" padding="2px 8px" alignItems="center">
              {React.cloneElement(customIcon, { fontSize: 'inherit' })}
              <Tooltip title={obj.custom_entity_label || obj.nested_feature_name}>
                <EntityLabel>{obj.custom_entity_label || obj.nested_feature_name}</EntityLabel>
              </Tooltip>
            </Stack>

            {/* Subheading */}
            {type === entityName.CUSTOM_OBJECT && (
            <Stack spacing={1} alignItems="center" justifyContent="space-between" direction="row" m={1} mt={2} height={type === entityName.CUSTOM_OBJECT ? 20 : 'inherit'}>
              <FilterLabel>{obj.allEvent ? 'All Events' : 'Modified Events'}</FilterLabel>
              {obj.allSource ? (<FilterLabel>All Sources</FilterLabel>) : null}
            </Stack>
            )}

            {/* Buttons */}
            <Stack ml={1} direction={type === entityName.CUSTOM_OBJECT ? 'row' : 'row-reverse'} display="flex" justifyContent="space-between">
              {type === entityName.CUSTOM_OBJECT ? (
                <IconBox onClick={() => handleRefineFilter(obj)}>
                  <IconLabel>Filter</IconLabel>
                  <TuneIcon color="primary" fontSize="small" />
                </IconBox>
              ) : null}
              <Tooltip title={toolTipText.featureManager.REMOVE_CUSTOM_ENTITY}>
                <IconButton onClick={() => handleRemove(obj.custom_entity_id || obj.nested_feature_id, type)}>
                  <RemoveCircleOutlineIcon color="primary" fontSize="small" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Box>
        ))}
      </Stack>
    ) : null
  );
}
