import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { Grid, Container } from '@mui/material';
import Metatags from '../components/MetaTags/MetaTags';
import useStyles from '../hooks/useStyles';
import styles from '../layouts/pages/style';
import { PackageHeader } from '../components/CustomHeading';
import { useVersions } from '../sections/Versions/useVersions';
import Versions from '../sections/Versions';
import { getEditions, getVersions, getVersionKpiScore } from '../features';
import { useEditions } from '../sections/Versions/useEditions';
import usePackageSelection from '../modules/Accounts/components/PackageSelection/usePackageSelection';
import useToggle from '../hooks/useToggle';
import { useVersionKpiScore } from '../sections/Versions/useVersionKpiScore';

function VersionsPage() {
  const [refreshVersionBreakdown, setRefreshVersionBreakdown] = useToggle();
  const [refreshOrgEdBreakdown, setRefreshOrgEdBreakdown] = useToggle();
  const [refreshVersionKpiScore, setRefreshVersionKpiScore] = useToggle();

  const dispatch = useDispatch();
  const { activePackage } = usePackageSelection();

  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getVersions({ pkgId: activePackage.packageId }));
    }
  }, [refreshVersionBreakdown, activePackage?.packageId]);

  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getEditions({ pkgId: activePackage.packageId }));
    }
  }, [refreshOrgEdBreakdown, activePackage?.packageId]);

  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getVersionKpiScore({ pkgId: activePackage.packageId }));
    }
  }, [refreshVersionKpiScore, activePackage?.packageId]);

  const { versions } = useVersions();
  const { editions } = useEditions();
  const { versionsKpiScore } = useVersionKpiScore();
  const location = useLocation();
  const more = location.state && location.state.more;
  const classes = useStyles(styles)();

  return (
    <>
      <Metatags metaTagName="Versions | App Insights" />
      <Container maxWidth="xl">
        <Grid container gap={2}>
          <Grid item xs={12} className={classes.cardStyles}>
            <PackageHeader heading="Versions" />
          </Grid>
          <Versions
            versions={versions}
            editions={editions}
            versionKpiScore={versionsKpiScore}
            more={more}
            setRefreshVersionBreakdown={setRefreshVersionBreakdown}
            setRefreshOrgEdBreakdown={setRefreshOrgEdBreakdown}
            setRefreshVersionKpiScore={setRefreshVersionKpiScore}
          />
        </Grid>
      </Container>
    </>
  );
}

export default VersionsPage;
