import Button from '@mui/material/Button';
import {
  Box, TextField, Typography, Stack, Grid, Container,
} from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import useStyles from '../../../../hooks/useStyles';
import usePackageSelection from '../../../Accounts/components/PackageSelection/usePackageSelection';
import styles from '../../../../layouts/pages/style';
import { PickCustomEntity } from '../PickCustomEntity';
import { RefineFilter } from '../RefineFilter';
import { CustomComponents } from '../CustomComponents';
import useAddEditFeature from './useAddEditFeature';
import { getCustomEntityList, getFeaturesList } from '../../../../features';
import { KPIPreview } from '../KPIPreview';

export default function AddEditFeature() {
  const {
    openPick,
    editData,
    name,
    openRefineFilter,
    type,
    label,
    customFilter,
    operationsTypes,
    disabled,
    previewDisabled,
    customTypes,
    openPreview,
    previewData,
    handleClosePreview,
    handlePickOpen,
    handlePickClose,
    handleChange,
    handleSelect,
    handleRemove,
    handleSaveBtn,
    handlePreviewBtn,
    handleRefineFilter,
    handleCloseRefineFilter,
    handleRefineFilterSave,
    isCopyClick,
  } = useAddEditFeature();
  const dispatch = useDispatch();
  const { activePackage } = usePackageSelection();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (activePackage) {
      dispatch(getCustomEntityList({ packageId: activePackage.packageId }));
      dispatch(getFeaturesList({ packageId: activePackage.packageId }));
    }
  }, [activePackage]);

  const classes = useStyles(styles)();
  return (
    <Container maxWidth="xl">
      <Grid container gap={2}>
        <Grid item xs={12} className={classes.cardStyles}>
          <Box className={classes.headerStyles}>
            <Typography variant="h4">
              {location.pathname === '/kpi-manager/new-feature' || isCopyClick ? 'New KPI' : 'Update KPI'}
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} className={classes.cardStyles}>
          <Stack style={{ padding: '10px 0px' }}>
            <Typography>KPI Label</Typography>
            <TextField
              value={name}
              onChange={handleChange}
              id="outlined-basic"
              fullWidth
              type="text"
              placeholder="Required*"
              variant="outlined"
              required
              focused={isCopyClick}
              autoFocus={isCopyClick}
            />
          </Stack>
        </Grid>

        {operationsTypes.map((obj) => (
          <Grid item xs={12} className={classes.cardStyles}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="start"
              background="#919eab3d"
              borderColor="#919eab3d"
              spacing={1.5}
            >
              <Button
                size="small"
                variant="outlined"
                onClick={() => handlePickOpen(obj.type)}
              >
                Pick
              </Button>
              <Typography>{obj.name}</Typography>
            </Stack>
            <CustomComponents customObject={obj.value} handleRemove={handleRemove} type={obj.type} customIcon={customTypes[obj.type]} handleRefineFilter={handleRefineFilter} />
          </Grid>
        ))}

        <Grid item xs={12}>
          <Stack spacing={1} direction="row" alignItems="center" justifyContent="end" sx={{ pt: 2 }}>
            <Button variant="outlined" size="small" onClick={() => navigate('/kpi-manager')}>
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={previewDisabled}
              onClick={handlePreviewBtn}
            >
              Preview
            </Button>
            <Button
              variant="contained"
              size="small"
              disabled={disabled}
              onClick={handleSaveBtn}
            >
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>

      {openPick && (
        <PickCustomEntity
          open={openPick}
          handleClose={handlePickClose}
          handleSelect={handleSelect}
          type={type}
          label={label}
          editFeatureData={editData}
        />
      )}
      {openRefineFilter && (
        <RefineFilter
          open={openRefineFilter}
          customFilter={customFilter}
          handleClose={handleCloseRefineFilter}
          handleRefineFilterSave={handleRefineFilterSave}
        />
      )}
      {openPreview && (
        <KPIPreview
          open={openPreview}
          previewData={previewData}
          handleClose={handleClosePreview}
        />
      )}
    </Container>
  );
}
