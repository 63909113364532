import { useEffect, useState } from 'react';
import useToggle from '../../../../hooks/useToggle';
import { CustomDialogBox } from '../../../../components/CustomDialogBox';
import { CustomSelect } from '../../../../components/FormComponents';
import CustomEntityDialog from './CustomEntityDialog';

function PickCustomEntity({ data, handleFilterChange }) {
  const [openCustomEntityPopup, setOpenCustomEntityPopup] = useToggle();
  const [selectedIds, setSelectedIds] = useState(new Set());

  const handleSave = () => {
    const newSelectedData = Array.from(selectedIds).reduce((acc, id) => {
      const item = data.find((obj) => obj.id === id);
      if (item) {
        const { type } = item;
        if (!acc[type]) {
          acc[type] = [];
        }
        acc[type].push(id);
      }
      return acc;
    }, {});
    handleFilterChange('customEntity', newSelectedData);
    setOpenCustomEntityPopup();
  };

  useEffect(() => {
    if (data?.length === 0) {
      setSelectedIds(new Set());
    }
  }, [data]);

  return (
    <>
      <CustomSelect value={selectedIds} title="Object" open={false} onClick={setOpenCustomEntityPopup} />
      <CustomDialogBox
        title="Select Objects"
        open={openCustomEntityPopup}
        handleClose={setOpenCustomEntityPopup}
        content={<CustomEntityDialog data={data} selectedIds={selectedIds} setSelectedIds={setSelectedIds} />}
        actions={{ title: 'Save', onClick: handleSave }}
      />
    </>
  );
}

export default PickCustomEntity;
