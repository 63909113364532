import { createSlice } from '@reduxjs/toolkit';

export const scoreControlsSlice = createSlice({
  name: 'scoreControls',
  initialState: {
    enabled: true,
  },
  reducers: {
    enableScoreControls: (state) => {
      state.enabled = true;
    },
    disableScoreControls: (state) => {
      state.enabled = false;
    },
  },
});

export const { enableScoreControls, disableScoreControls } = scoreControlsSlice.actions;
