import {
  IconButton, Stack, useMediaQuery,
} from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import { ClickableCell } from '../../components/CustomDataGrid';
import { openSnackbar } from '../../features/SnackBar/snackBarSlice';
import { ActionCell } from '../../modules/FeatureManager';
import palette from '../../theme/palette';
import {
  getCustomEntityList,
  deleteFeature,
  featurePinning,
  getFeaturesList,
} from '../../features';
import {
  getFeatureData,
} from '../../features/FeatureManager/featureManagerService';

import { crudOperations } from '../../modules/FeatureManager/constants';
import { identifyAndConvertDate } from '../../utils/formatTime';
import usePackageSelection from '../../modules/Accounts/components/PackageSelection/usePackageSelection';
import { editDataCommon } from '../../utils/methods';
import { DATE_FORMAT } from '../../utils/constants';

const useFeatureManager = ({ searchTerm, permissions }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteId, setDeleteId] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [editFeatureData, setEditFeatureData] = useState({ id: '' });
  const [featureData, setFeatureData] = useState({ featureManagerList: [] });
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('asc');
  const featureManager = useSelector((state) => state.featureManagerList);
  const customEntityData = useSelector((state) => state.customEntityList);

  const { activePackage } = usePackageSelection();

  const handleClose = () => {
    setOpenDialog(false);
  };
  useEffect(() => {
    if (featureManager) {
      const { featureManagerList, loading } = featureManager;

      if (featureManagerList && featureManagerList.length) {
        const pinnedRowsData = featureManagerList.filter((row) => row.is_core_feature);
        const unpinnedRowsData = featureManagerList.filter((row) => !row.is_core_feature);
        const updateData = [...pinnedRowsData, ...unpinnedRowsData];
        setFeatureData({ featureManagerList: updateData, loading });
      } else {
        setFeatureData({ featureManagerList: [], loading: true });
      }
    }
  }, [featureManager]);

  useEffect(() => {
    if (activePackage?.packageId) {
      dispatch(getFeaturesList({ packageId: activePackage.packageId }));
      dispatch(getCustomEntityList({ packageId: activePackage.packageId }));
    }
  }, [activePackage?.packageId]);

  const handleClickFeatureOpen = () => {
    navigate('/kpi-manager/new-feature');
  };
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleFeatureClose = () => {
    setEditFeatureData({ id: '' });
  };

  const handleClickEdit = async (params) => {
    const id = params.row.feature_id;
    const recordData = await getFeatureData(id);
    const editData = editDataCommon(params, recordData, customEntityData, crudOperations, featureManager);
    setEditFeatureData(editData);
    navigate(`/kpi-manager/update-feature/${id}`, { state: { editFeatureData: editData } });
  };
  const handleCopyClick = async (params) => {
    const id = params.row.feature_id;
    const recordData = await getFeatureData(params.row.feature_id);
    const editData = editDataCommon(params, recordData, customEntityData, crudOperations, featureManager);
    setEditFeatureData(editData);
    navigate(`/kpi-manager/update-feature/${id}`, {
      state: {
        editFeatureData: editData,
        isCopyClick: true,
      },
    });
  };

  const handleClickDelete = async (params) => {
    setOpenDialog(true);
    setDeleteId(params.row.feature_id);
  };
  const handleDeleteConfirm = useCallback(() => {
    dispatch(deleteFeature(deleteId)).then((res) => {
      if (res?.error) {
        dispatch(openSnackbar({ open: true, message: res?.payload, type: 'error' }));
      } else {
        dispatch(openSnackbar({ open: true, message: 'KPI deleted successfully', type: 'success' }));
      }
    });
    setOpenDialog(false);
  }, [deleteId]);

  const actionCellData = [
    {
      id: 'copy',
      info: 'Copy KPI',
      icon: ContentCopyIcon,
      disabled: !permissions.write,
      handleFunction: permissions.write ? handleCopyClick : (() => null),
      headerClassName: 'tg-kpi-copy',
    },
    {
      id: 'edit',
      info: 'Edit KPI',
      icon: DriveFileRenameOutlineRoundedIcon,
      disabled: !permissions.write,
      handleFunction: permissions.write ? handleClickEdit : (() => null),
      headerClassName: 'tg-kpi-edit',
    },
    {
      id: 'delete',
      info: 'Delete KPI',
      icon: DeleteIcon,
      disabled: !permissions.write,
      handleFunction: permissions.write ? handleClickDelete : (() => null),
      headerClassName: 'tg-kpi-delete',
    },
  ];

  const handlePinning = (params) => {
    const pinned = [];
    const unpinned = [];
    let isPinning = false;
    const { featureManagerList, loading } = featureData;
    featureManagerList.forEach((obj) => {
      const copyObj = { ...obj };
      if (params.row.feature_id === copyObj.feature_id) {
        copyObj.is_core_feature = !copyObj.is_core_feature;
        isPinning = copyObj.is_core_feature;
      }
      if (copyObj.is_core_feature) {
        pinned.push(copyObj);
      } else {
        unpinned.push(copyObj);
      }
    });
    dispatch(featurePinning({ featureId: params.row.feature_id, pinningData: isPinning }));

    const updateData = [...pinned, ...unpinned];
    setFeatureData({ featureManagerList: updateData, loading });
  };

  const sortedRows = (field, direction) => {
    const { featureManagerList, loading } = featureData;
    if (featureManagerList && featureManagerList.length) {
      const pinnedRowsData = featureManagerList.filter((row) => row.is_core_feature);
      const unpinnedRowsData = featureManagerList.filter((row) => !row.is_core_feature);

      unpinnedRowsData.sort((a, b) => {
        if (field === 'feature_name') {
          return direction === 'asc'
            ? a.feature_name.localeCompare(b.feature_name)
            : b.feature_name.localeCompare(a.feature_name);
        } if (field === 'last_modified') {
          const dateA = a.last_modified ? new Date(a.last_modified).getTime() : 0;
          const dateB = b.last_modified ? new Date(b.last_modified).getTime() : 0;
          return direction === 'asc' ? dateA - dateB : dateB - dateA;
        } if (field === 'recordCount') {
          return direction === 'asc'
            ? a.recordCount - b.recordCount
            : b.recordCount - a.recordCount;
        }
        return 0;
      });
      const updateData = [...pinnedRowsData, ...unpinnedRowsData];
      setFeatureData({ featureManagerList: updateData, loading });
    }
  };

  const handleSortChange = (field) => {
    if (sortField === field) {
      setSortDirection((prevSortDirection) => (prevSortDirection === 'asc' ? 'desc' : 'asc'));
      sortedRows(field, sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
      sortedRows(field, 'asc');
    }
  };

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      width: isSmallScreen ? 50 : 20,
      isRowSelectable: false,
      renderCell: (params) => (
        <IconButton
          className="tg-kpi-pin-unpin"
          onClick={() => handlePinning(params)}
          disabled={!permissions.write}
        >
          {params.row.is_core_feature ? (
            <PushPinIcon sx={{ color: palette.text.primary }} />
          ) : (
            <PushPinOutlinedIcon
              sx={{ color: palette.text.primary, transform: 'matrix(0.87, 0.5, -0.5, 0.87, 0, 0)' }}
            />
          )}
        </IconButton>
      ),
    },
    {
      field: 'feature_name',
      headerName: 'Label',
      minWidth: 250,
      flex: 1,
      sortable: false,
      isRowSelectable: true,
      renderHeader: () => (
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
        >
          <strong style={{ color: palette.secondary.mainColor }}>Label</strong>
          <IconButton
            size="small"
            onClick={() => handleSortChange('feature_name')}
            sx={{ p: 0 }}
            className={`sort-icon ${sortField === 'feature_name' ? 'active' : ''}`}
          >
            {sortField === 'feature_name' && sortDirection === 'asc' ? (
              <ArrowUpwardIcon fontSize="small" />
            ) : (
              <ArrowDownwardIcon fontSize="small" />
            )}
          </IconButton>
        </Stack>
      ),
      renderCell: (params) => <ClickableCell className="tg-kpi-heading" value={params.row.feature_name} />,
    },
    {
      field: 'last_modified',
      headerName: 'Last Modified',
      flex: 1,
      minWidth: 200,
      sortable: false,
      isRowSelectable: false,
      renderCell: (params) => <span>{identifyAndConvertDate(params.row.last_modified, DATE_FORMAT.DAY_DATE_GRAPH, 'YYYY-MM-DD')}</span>,
      renderHeader: () => (
        <Stack direction="row" spacing={1} alignItems="center">
          <strong style={{ color: palette.secondary.mainColor }}>Last Modified</strong>
          <IconButton
            size="small"
            onClick={() => handleSortChange('last_modified')}
            sx={{ p: 0 }}
            className={`sort-icon ${sortField === 'last_modified' ? 'active' : ''}`}
          >
            {sortField === 'last_modified' && sortDirection === 'asc' ? (
              <ArrowUpwardIcon fontSize="small" />
            ) : (
              <ArrowDownwardIcon fontSize="small" />
            )}
          </IconButton>
        </Stack>
      ),
    },
    {
      field: 'Action',
      headerName: 'Action',
      sortable: false,
      filterable: false,
      width: isSmallScreen ? 150 : 250,
      align: 'center',
      headerAlign: 'center',
      isRowSelectable: false,
      renderCell: (params) => <ActionCell actionCellData={actionCellData} params={params} />,
    },
  ];

  const filteredData = useMemo(() => {
    return featureData?.featureManagerList?.filter((item) => item.feature_name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [searchTerm, featureData]);

  return {
    filteredData,
    editFeatureData,
    setEditFeatureData,
    handleFeatureClose,
    handleClickDelete,
    handleClickEdit,
    handleClickFeatureOpen,
    columns,
    openDialog,
    handleClose,
    handleDeleteConfirm,
  };
};

export default useFeatureManager;
