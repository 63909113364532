import { createSlice } from '@reduxjs/toolkit';
import { getLogAnalyzer, getLogAnalyzerDetails } from './logAnalyzerAction';

export const logAnalyzerSlice = createSlice({
  name: 'logAnalyzer',
  initialState: {
    loading: false,
    error: null,
    data: {
      operationCountObj: {},
      userCountObj: {},
      accountCountObj: {},
      entityOpCount: [],
    },
  },
  reducers: {
    resetLogAnalyzer: (state) => {
      state.loading = false;
      state.error = null;
      state.data = {
        operationCountObj: {},
        userCountObj: {},
        accountCountObj: {},
        entityOpCount: [],
      };
    },
  },
  extraReducers: (builder) => {
    // Get
    builder.addCase(getLogAnalyzer.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getLogAnalyzer.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      const data = {
        featureInfo: payload.featureInfo,
        timePeriod: payload.timePeriod,
        totalActiveSeats: payload.totalActiveSeats,
        totalActiveAccount: payload.totalActiveAccount,
      };
      state.data = data;
    });
    builder.addCase(getLogAnalyzer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const logAnalyzerDetailsSlice = createSlice({
  name: 'logAnalyzerDetails',
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    // Get
    builder.addCase(getLogAnalyzerDetails.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getLogAnalyzerDetails.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.data = payload.responseArray;
    });
    builder.addCase(getLogAnalyzerDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const { resetLogAnalyzer } = logAnalyzerSlice.actions;
