import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

function MemoizedTableCell({
  content, align, isFirstColumn, dialog, width,
}) {
  return (
    <TableCell align={align || 'left'} sx={{ pl: isFirstColumn && dialog ? 0 : 'auto' }} width={width || 'auto'}>
      {content}
    </TableCell>
  );
}

function DynamicTable(props) {
  const {
    showHeader = false,
    dialog = false,
    columns = [],
    rows = [],
  } = props;

  return (
    columns?.length && rows?.length ? (
      <Table sx={{ pt: showHeader && dialog ? 0 : 'auto' }}>
        {showHeader && (
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <MemoizedTableCell
                key={column.field}
                content={column.headerName || column.field}
                align={column.align}
                width={column.width}
              />
            ))}
          </TableRow>
        </TableHead>
        )}
        <TableBody>
          {rows.map((row, index) => (
            <TableRow
              key={`row-${index}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              {columns.map((column, columnIndex) => (
                <MemoizedTableCell
                  key={`row-${index}-${column.field}`}
                  content={row[column.field]}
                  align={column.align}
                  isFirstColumn={columnIndex === 0}
                  dialog={dialog}
                  width={column.width}
                />
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    ) : null
  );
}

export default React.memo(DynamicTable);
