import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../../features/SnackBar/snackBarSlice';
import useAuthorization from '../../../hooks/useAuthorization';
import { changePassword } from '../../../features/Auth/authAction';
import { ChangePasswordForm } from './ProfileForms';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email address').required('Email is required'),
  currentPassword: Yup.string().required('Current Password is required'),
  newPassword: Yup.string()
    .required('Password is required')
    .min(8, 'Password should be at least 8 characters long')
    .test('contains-lowercase', 'Password should contain at least one lowercase letter', (value) => {
      return /[a-z]/.test(value);
    })
    .test('contains-uppercase', 'Password should contain at least one uppercase letter', (value) => {
      return /[A-Z]/.test(value);
    })
    .test('contains-digit', 'Password should contain at least one digit', (value) => {
      return /[0-9]/.test(value);
    })
    .test('contains-special-char', 'Password should contain at least one special character ', (value) => {
      return /[@$!%*?&]/.test(value);
    }),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords do not match'),
});

function ProfileSettings() {
  const dispatch = useDispatch();
  const { userInfo } = useAuthorization();

  const initialValues = {
    email: userInfo?.email ?? '',
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };

  const handleChangePassword = (values) => {
    dispatch(changePassword(values)).then((res) => {
      const { error, payload } = res;
      if (error) {
        dispatch(openSnackbar({ open: true, message: payload.message || payload, type: 'error' }));
      } else {
        dispatch(openSnackbar({ open: true, message: payload?.message, type: 'success' }));
      }
    });
  };

  return (
    <ChangePasswordForm
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleChangePassword}
    />
  );
}

export default ProfileSettings;
