import { Grid } from '@mui/material';
import { DateField, SelectBox } from '../../../../components/FormComponents';
import { InfoChip } from '../../../../components/CustomChip';

function TenantDetailForm(props) {
  const { formik, type } = props;

  const {
    values, errors, handleChange, touched, getIn, setFieldValue,
  } = formik;

  let info = '';
  if (type === 'status') {
    info = 'Choose between Active, Trial and Suspended.';
  } else if (type === 'expiry') {
    info = 'Enter expiry date if applicable.';
  }

  return (
    <Grid container gap={1} p={2}>
      <Grid item xs={12} mb={2}>
        <InfoChip icon label={info} />
      </Grid>

      {type === 'expiry' && (
      <Grid item xs={12}>
        <DateField label="Expiration Date" value={values.expiry} onChange={(value) => setFieldValue('expiry', value)} />
      </Grid>
      )}

      {type === 'status' && (
      <Grid item xs={12}>
        <SelectBox
          name="status"
          title="Current Status"
          value={values.status}
          data={[
            { id: 'active', value: 'active', name: 'Active' },
            { id: 'trial', value: 'trial', name: 'Trial' },
            { id: 'suspended', value: 'suspended', name: 'Suspended' },
          ]}
          spacing
          fullWidth
          onChange={handleChange}
          error={touched.status && getIn(errors, 'status')}
        />
      </Grid>
      )}

    </Grid>
  );
}

export default TenantDetailForm;
