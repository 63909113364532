import { Grid } from '@mui/material';
import { MutedText } from '../../../components/CustomTypography';
import { DateField, SelectBox, TextFieldWithError } from '../../../components/FormComponents';
import { InfoChip } from '../../../components/CustomChip';
import { formikForm } from '../../../hoc';

function TenantForm({ formik }) {
  const {
    handleChange, touched, errors, values, getIn, setFieldValue,
  } = formik;

  return (
    <Grid container gap={1} p={2}>
      <Grid item xs={12} mb={2}>
        <InfoChip icon label="Tenant name should be unique. Once created can not be edited later." />
      </Grid>

      <Grid item xs={12}>
        <MutedText text="Provide a name for Tenant." sxProps={{ marginLeft: '8px' }} />
        <TextFieldWithError
          name="tenant"
          label="Tenant"
          onChange={handleChange}
          error={touched.tenant && getIn(errors, 'tenant')}
        />
      </Grid>

      <Grid item xs={12}>
        <MutedText text="Choose between Active, Trial and Suspended." sxProps={{ marginLeft: '8px' }} />
        <SelectBox
          name="status"
          title="Current Status"
          value={values.status}
          data={[
            { id: 'active', value: 'active', name: 'Active' },
            { id: 'trial', value: 'trial', name: 'Trial' },
            { id: 'suspended', value: 'suspended', name: 'Suspended' },
          ]}
          spacing
          fullWidth
          onChange={handleChange}
          error={touched.status && getIn(errors, 'status')}
        />
      </Grid>

      <Grid item xs={12}>
        <MutedText text="Enter expiry date if applicable." sxProps={{ marginLeft: '8px' }} />
        <DateField value={values.expiry} onChange={(value) => setFieldValue('expiry', value)} />
      </Grid>
    </Grid>
  );
}

export default formikForm(TenantForm);
