import { createAsyncThunk } from '@reduxjs/toolkit';

import request from '../../modules/Request/request';

export const getCustomFilterViewList = createAsyncThunk(
  'getCustomFilterViewList',
  async ({ packageId }, { rejectWithValue }) => {
    const response = await request('/custom-filter-view-list', { method: 'GET', params: packageId });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message ? response.error.message : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const setCustomFilterView = createAsyncThunk(
  'setCustomFilterView',
  async (data, { rejectWithValue }) => {
    const {
      id, name, alert, packageId, filter,
    } = data;
    const response = await request('/custom-filter-view', {
      method: 'POST',
      body: {
        id,
        name,
        alert,
        packageId,
        filter,
      },
    });
    if (response.success) {
      return response.data;
    }
    const error = response.error && response.error.message ? response.error.message : response.error;
    throw rejectWithValue(error || 'Something went wrong');
  },
);

export const deleteCustomFilterView = createAsyncThunk('deleteCustomFilterView', async ({ id }, { rejectWithValue }) => {
  const response = await request('/delete-custom-filter-view', { method: 'DELETE', params: [id] });
  if (response.success) {
    return response.data;
  }
  const error = response.error?.message || response.error;
  throw rejectWithValue(error || 'Something went wrong');
});
