import { useMemo } from 'react';
import { Grid } from '@mui/material';
import { generateGraphLabels } from '../../../../utils/getDate';
import { Graph } from '../../../../components/Graph';
import palette from '../../../../theme/palette';

function LogAnalyzerGraph({ selectedFilters, logAnalyzerInfo }) {
  const labels = generateGraphLabels(Object.keys(logAnalyzerInfo?.accountCountObj ?? {}), 'day');

  const datasetsOp = [
    {
      type: 'bar',
      label: 'Operations',
      data: Object.values(logAnalyzerInfo?.operationCountObj ?? {}),
      barThickness: 20,
      backgroundColor: palette.info.darker,
    },
  ];

  const datasetsAccUser = [
    {
      type: 'line',
      label: 'Account',
      fill: false,
      data: Object.values(logAnalyzerInfo?.accountCountObj ?? {}),
      radius: 5,
      backgroundColor: palette.grey[0],
      borderColor: palette.primary.mainColor,
      borderWidth: 2.5,
      hoverRadius: 6.5,
      hoverBorderWidth: 2.5,
    },
    {
      type: 'bar',
      label: 'User',
      data: Object.values(logAnalyzerInfo?.userCountObj ?? {}),
      borderColor: 'white',
      barThickness: 20,
      backgroundColor: palette.info.main,
    },
  ];

  const getChartOptions = (title) => {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        x: { grid: { display: false } },
        y: { grid: { display: false } },
      },
      plugins: {
        title: {
          text: title,
          display: true,
          align: 'start',
          font: {
            size: 14,
          },
        },
        legend: {
          align: 'end',
          labels: {
            usePointStyle: true,
            pointStyle: 'circle',
            boxWidth: 7,
            boxHeight: 7,
          },
          onHover(e) {
            const { style } = e.native.target;
            style.cursor = 'pointer';
          },
          onLeave(e) {
            const { style } = e.native.target;
            style.cursor = 'default';
          },
        },
      },
      elements: {
        point: {
          radius: 5,
          borderWidth: 2.5,
          hoverRadius: 6.5,
          hoverBorderWidth: 2.5,
        },
      },
    };
    return options;
  };

  const modifiedData = useMemo(() => {
    if (selectedFilters.account?.value === '- all -') {
      return {
        datasets: datasetsAccUser,
        options: getChartOptions('Accounts & Users Graph'),
      };
    }

    const modifiedDatasets = datasetsAccUser.filter((column) => column.label !== 'Account');
    return {
      datasets: modifiedDatasets,
      options: getChartOptions('Users Graph'),
    };
  }, [logAnalyzerInfo?.entityOpCount]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Graph type="bar" height={400} labels={labels} {...modifiedData} />
      </Grid>
      <Grid item xs={12}>
        <Graph type="bar" height={400} labels={labels} datasets={datasetsOp} options={getChartOptions('Operations Graph')} />
      </Grid>
    </Grid>
  );
}

export default LogAnalyzerGraph;
