import { createAsyncThunk } from '@reduxjs/toolkit';
import { broadcastMessage } from '../../utils/broadcastEvents';
import request from '../../modules/Request/request';

const handleSession = (tokenData) => {
  const { idToken, accessToken, refreshToken } = tokenData;
  localStorage.setItem('idToken', idToken);
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
};

export const loginUser = createAsyncThunk('loginUser', async (object, { rejectWithValue }) => {
  const response = await request('/auth-login', { method: 'POST', body: object });

  if (response.success && response.data) {
    const { data } = response.data;
    if (data && !data.tenant && !data.tenantList) {
      handleSession(response.data.data);
      broadcastMessage('LoginUser');
    }
    return response.data;
  }
  const { error } = response;
  throw rejectWithValue(error || 'Something went wrong');
});

export const logoutUser = createAsyncThunk('logoutUser', async (tokens, { rejectWithValue }) => {
  const response = await request('/auth-logout', { method: 'POST', body: tokens });
  if (response.success) {
    return response.data;
  }
  const error = response?.error?.message || response.error;
  throw rejectWithValue(error || 'Something went wrong');
});

export const sendVerificationCode = createAsyncThunk('sendVerificationEmail', async (data, { rejectWithValue }) => {
  const response = await request('/auth/forgot-password', { method: 'POST', body: data });
  if (response.success) {
    return response.data;
  }
  const error = response?.error?.message || response.error;
  throw rejectWithValue(error || 'Something went wrong');
});

export const setPassword = createAsyncThunk('setPassword', async (data, { rejectWithValue }) => {
  const response = await request('/auth/forgot-password', { method: 'POST', body: data });
  if (response.success) {
    return response.data;
  }
  const error = response?.error?.message || response.error;
  throw rejectWithValue(error || 'Something went wrong');
});

export const changePassword = createAsyncThunk('changePassword', async (data, { rejectWithValue }) => {
  const response = await request('/auth/change-password', { method: 'POST', body: data });
  if (response.success) {
    return response.data;
  }
  const error = response?.error?.message || response.error;
  throw rejectWithValue(error || 'Something went wrong');
});

export const refreshSession = createAsyncThunk('refreshSession', async ({ refreshToken }, { rejectWithValue }) => {
  const response = await request('/auth-refresh', { method: 'POST', body: { refreshToken } });
  if (response.success && response.data) {
    handleSession(response.data.data);
    return response.data;
  }
  const { error } = response;
  throw rejectWithValue(error || 'Something went wrong');
});

export const impersonate = createAsyncThunk('impersonate', async (object, { rejectWithValue }) => {
  const {
    email, tenant, role, refreshToken,
  } = object;
  const response = await request('/auth/impersonate', {
    method: 'POST',
    body: {
      email, tenant, role, refreshToken,
    },
  });
  if (response.success && response.data) {
    handleSession(response.data.data);
    return response.data;
  }
  const { error } = response;
  throw rejectWithValue(error || 'Something went wrong');
});

export const stopImpersonate = createAsyncThunk('stopImpersonate', async ({ refreshToken }, { rejectWithValue }) => {
  const response = await request('/auth/stop-impersonate', { method: 'POST', body: { refreshToken } });
  if (response.success) {
    if (response?.data?.data) {
      handleSession(response.data.data);
    }
    return response.data;
  }
  const { error } = response;
  throw rejectWithValue(error || 'Something went wrong');
});

// User Management
export const userList = createAsyncThunk('userList', async (data, { rejectWithValue }) => {
  const response = await request('/user-list', { method: 'POST', body: data });
  if (response.success) {
    return response.data;
  }
  const { error } = response;
  throw rejectWithValue(error || 'Something went wrong');
});

export const createUser = createAsyncThunk('createUser', async (data, { rejectWithValue }) => {
  const response = await request('/create-user', { method: 'POST', body: data });
  if (response.success) {
    return response.data;
  }
  const { error } = response;
  throw rejectWithValue(error || 'Something went wrong');
});

export const deleteUser = createAsyncThunk('deleteUser', async (data, { rejectWithValue }) => {
  const response = await request('/delete-user', { method: 'POST', body: data });
  if (response.success) {
    return response.data;
  }
  const { error } = response;
  throw rejectWithValue(error || 'Something went wrong');
});

export const updateUser = createAsyncThunk('updateUser', async (data, { rejectWithValue }) => {
  const response = await request('/update-user', { method: 'POST', body: data });
  if (response.success) {
    return response.data;
  }
  const { error } = response;
  throw rejectWithValue(error || 'Something went wrong');
});
