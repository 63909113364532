/* eslint-disable react/jsx-one-expression-per-line */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toMonthString } from '../../utils/formatTime';
import useTopFeatures from './useTopFeatures';
import { getTopFeatures } from '../../features';
import { TopFeaturesCard } from '../../modules/TopFeatures';
import DashboardCards from '../../components/DashboardCard/DashboardCards';

export default function TopFeatures({
  activePackage,
  orgId,
  accountName,
  isShare,
  tenantId,
  pkgId,
  accountOrgId,
}) {
  const dispatch = useDispatch();

  const {
    topFeatureUsage,
    refreshTopFeatures,
    setRefreshTopFeatures,
    handleSortModelChange,
    sortModel,
  } = useTopFeatures();

  useEffect(() => {
    if (pkgId || activePackage?.packageId) {
      if (orgId) {
        dispatch(getTopFeatures({
          packageId: pkgId || activePackage?.packageId, orgId, isShare, tenantId, accountOrgId,
        }));
      } else {
        dispatch(getTopFeatures({
          packageId: activePackage.packageId, isShare, tenantId, accountOrgId,
        }));
      }
    }
  }, [activePackage?.packageId, refreshTopFeatures, tenantId, pkgId, accountOrgId]);

  const navigate = useNavigate();

  const handleClick = () => {
    if (orgId || accountName) {
      navigate(`/account-page/kpi-usage?orgId=${orgId}`, { state: { accountName, orgId } });
    } else {
      navigate('/kpi-usage');
    }
  };

  return (
    <DashboardCards
      title="Top KPIs"
      subHeading={`Data ${orgId ? '' : 'of Active Accounts '}for ${toMonthString(topFeatureUsage?.timePeriod)}`}
      showButton={!isShare}
      buttonText="more"
      onClick={handleClick}
    >
      <TopFeaturesCard
        loading={topFeatureUsage?.loading}
        error={topFeatureUsage?.error}
        data={topFeatureUsage?.topFeatures}
        refresh={setRefreshTopFeatures}
        message="No Rows"
        orgId={orgId}
        sortModel={sortModel}
        handleSortModelChange={handleSortModelChange}
      />
    </DashboardCards>
  );
}
