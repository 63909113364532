import { useMemo } from 'react';

function useHistogram({ data }) {
  const maxRange = Math.max(...(data || []));
  const divisionFactor = 10 ** (Math.floor(Math.log10(maxRange)) - 1);

  const modifiedBarData = useMemo(() => {
    const modifiedData = {};
    const graphSegmentLength = maxRange / divisionFactor;

    let value = 0;
    for (let index = 0; index < graphSegmentLength; index++) {
      const prevVal = value;
      value += divisionFactor;

      const newVal = value;
      modifiedData[newVal] = 0;
      (data || []).forEach((elem) => {
        if (elem > prevVal && elem <= newVal) {
          modifiedData[newVal]++;
        }
      });
    }
    return modifiedData;
  }, [data]);

  return { modifiedBarData };
}

export default useHistogram;
