import { Chip, Tooltip } from '@mui/material';
import styles from './style';
import useStyles from '../../hooks/useStyles';

function CustomChip(props) {
  const {
    label, selected, onClick, textAlign, type, tooltip,
  } = props;

  const classes = useStyles(styles)();

  const isMultiline = type === 'multiline';
  const isLeftAlign = textAlign === 'left';
  const isRightAlign = textAlign === 'right';
  const classString = `${classes.main} ${isMultiline && classes.multilineChipSelect} ${isLeftAlign && classes.leftAlignChip} ${isRightAlign && classes.rightAlignChip}`;

  return (
    <Tooltip title={tooltip || ''}>
      <Chip
        {...props}
        className={classString}
        label={label}
        clickable
        onClick={onClick}
        color={selected ? 'primary' : 'secondary'}
        variant={selected ? 'contained' : 'outlined'}
      />
    </Tooltip>
  );
}

export default CustomChip;
