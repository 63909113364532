import { createSlice } from '@reduxjs/toolkit';
import { getFeatureUsage, getTopFeatures, getFeatureUsageAccounts } from './featureUsageAction';

export const featureUsageSlice = createSlice({
  name: 'featureUsage',
  initialState: {
    loading: false,
    data: [],
    totalActiveAccounts: 0,
    totalActiveSeats: 0,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeatureUsage.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFeatureUsage.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.responseArray;
      state.totalActiveAccounts = payload.totalActiveAccount;
      state.totalActiveSeats = payload.totalActiveSeats;
      state.error = null;
    });
    builder.addCase(getFeatureUsage.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const topFeaturesSlice = createSlice({
  name: 'topFeatures',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTopFeatures.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTopFeatures.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.topFeatures = payload.responseArray;
      state.timePeriod = payload.timePeriod;
      state.error = null;
    });
    builder.addCase(getTopFeatures.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export const featureUsageAccountsSlice = createSlice({
  name: 'featureUsageAccounts',
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeatureUsageAccounts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFeatureUsageAccounts.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.data = payload.responseArray;
      state.error = null;
    });
    builder.addCase(getFeatureUsageAccounts.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});
