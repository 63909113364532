const defaultPermissions = {
  user: {
    read: true,
    write: false,
  },
  admin: {
    read: true,
    write: true,
  },
  superAdmin: {
    read: false,
    write: false,
    manage: true,
  },
};

// Your specific permissions
const specificPermissions = {
  readOnly: {
    read: true,
    write: false,
  },
  readWrite: {
    read: true,
    write: true,
  },
  noAccess: {
    read: false,
    write: false,
  },
  fullAccess: {
    read: true,
    write: true,
    manage: true,
  },
};

const permissions = {
  // sidebar layout
  layout: {
    superAdmin: specificPermissions.fullAccess,
  },
  // multi tenant
  tenantManager: {
    user: specificPermissions.noAccess,
    admin: specificPermissions.noAccess,
    superAdmin: specificPermissions.fullAccess,
  },
  tenantDetails: {
    user: specificPermissions.noAccess,
    admin: specificPermissions.noAccess,
    superAdmin: specificPermissions.fullAccess,
  },
  // setup pages
  userManager: {
    user: specificPermissions.noAccess,
    superAdmin: specificPermissions.fullAccess,
  },
  featureManager: {
    user: specificPermissions.noAccess,
  },
  configuration: {
    user: specificPermissions.noAccess,
  },
  featureManagerCreate: {
    user: specificPermissions.noAccess,
  },
  featureManagerUpdate: {
    user: specificPermissions.noAccess,
  },
  healthScoreConfig: {
    user: specificPermissions.noAccess,
  },
  healthScoreConfigCreate: {
    user: specificPermissions.noAccess,
  },
  healthScoreConfigUpdate: {
    user: specificPermissions.noAccess,
  },
  activeUserConfig: {
    user: specificPermissions.noAccess,
  },
  // other pages
};

export default { ...permissions, defaultPermissions };
