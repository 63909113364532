import React from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';

export default function OTPInputs({ setFieldValue }) {
  const [otp, setOtp] = React.useState('');
  const handleChange = (newValue) => {
    setOtp(newValue);
    setFieldValue('code', newValue);
  };

  return (
    <MuiOtpInput
      value={otp}
      onChange={handleChange}
      length={6}
      inputMode="numeric"
      pattern="[0-9]*"
    />
  );
}
