import {
  Box, Button, Tooltip, Typography,
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { CustomDropdown } from '../../../components/CustomDropdown';
import { exportData } from '../../../utils/methods';
import useCustomFilter from './useCustomFilter';

function AccountListHeader(props) {
  const {
    classes,
    accountList,
    activePackage,
    setSelectedFilters,
    selectedView,
    setSelectedView,
    findSelectedView,
    setToggleAdvanceFilters,
  } = props;

  const {
    customFilters,
    handleCustomView,
  } = useCustomFilter({
    setSelectedFilters, setSelectedView, findSelectedView, setToggleAdvanceFilters,
  });

  return (
    <Box className={classes.headerStyles}>
      <Box className="tg-filter-view">
        <CustomDropdown background value={selectedView} data={customFilters} onChange={handleCustomView} />
        <Typography sx={{ ml: 1, fontSize: 14, color: (theme) => theme.palette.secondary.mainColor }}>
          {accountList ? `${accountList.length} Records` : 'Loading...'}
        </Typography>
      </Box>
      <Tooltip title="Export filtered accounts list as excel">
        <Button
          className="tg-export-list"
          variant="outlined"
          endIcon={<FileDownloadIcon color="primary" fontSize="small" />}
          onClick={() => exportData('Accounts', activePackage.packageLabel, accountList)}
        >
          Export
        </Button>
      </Tooltip>
    </Box>
  );
}

export default AccountListHeader;
