import Joyride, { STATUS, ACTIONS, EVENTS } from 'react-joyride';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import useAuthorization from '../../hooks/useAuthorization';
import {
  nextStep, previousStep, restartTour, setSteps, stopTour,
} from '../../features/TourGuide/tourGuideSlice';
import { allSteps, setupRoutes } from './tourGuideSteps';

function TourGuide() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { run, stepIndex, steps } = useSelector((state) => state.tourGuide);
  const { userInfo } = useAuthorization();

  const multiRouteSteps = userInfo?.access !== 'superAdmin'
    ? Object.keys(allSteps).reduce((acc, step) => {
      if (userInfo?.access === 'admin' || !setupRoutes.includes(step)) {
        acc.push(...allSteps[step]);
      }
      return acc;
    }, [])
    : [];

  useEffect(() => {
    dispatch(setSteps({ steps: multiRouteSteps }));
  }, [userInfo?.access]);

  const handleJoyrideCallback = (params) => {
    const {
      status, type, action, index,
    } = params;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    const data = multiRouteSteps[index];

    if (finishedStatuses.includes(status) || action === ACTIONS.CLOSE) {
      dispatch(stopTour());
      navigate('/dashboard');
      return;
    }

    if (type === EVENTS.TARGET_NOT_FOUND) {
      if (action === ACTIONS.NEXT) {
        if (data.next) navigate(data.next);
        dispatch(restartTour({ stepIndex: index + 1 }));
        return;
      }
      if (action === ACTIONS.PREV) {
        if (data.prev) navigate(data.prev);
        dispatch(restartTour({ stepIndex: index - 1 }));
      }
    }

    if (type === EVENTS.STEP_AFTER) {
      if (action === ACTIONS.PREV) {
        if (data.prev) navigate(data.prev);
        dispatch(previousStep());
      } else {
        if (data.next) navigate(data.next);
        dispatch(nextStep());
      }
    }
  };

  return (
    <Joyride
      steps={steps}
      run={run}
      stepIndex={stepIndex}
      continuous
      scrollToFirstStep
      showProgress
      callback={handleJoyrideCallback}
      locale={{ skip: 'End', last: 'Finish Tour' }}
      styles={{
        tooltipContent: {
          textAlign: 'left',
        },
        options: {
          zIndex: 1300,
          primaryColor: '#1976d2',
        },
        buttonNext: {
          backgroundColor: '#1976d2',
          color: '#fff',
          borderRadius: '20px',
          padding: '0.5rem 1rem',
        },
        buttonBack: {
          color: '#1976d2',
        },
        buttonSkip: {
          border: '1px solid #1976d2',
          borderRadius: '20px',
          padding: '0.5rem 1rem',
          color: '#1976d2',
        },
      }}
      spotlightPadding={10}
      floaterProps={{
        styles: {
          arrow: {
            color: '#1976d2',
          },
          floater: {
            filter: 'drop-shadow(0 0 0.5rem rgba(0, 0, 0, 0.2))',
          },
        },
      }}
    />
  );
}

export default TourGuide;
