import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import palette from '../../theme/palette';
import AppMenu from './AppMenu';

const drawerWidth = 240;

const openedMixin = (theme, isSmallScreen) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: theme.palette.secondary.mainColor,
  position: isSmallScreen ? 'fixed' : null,
  zIndex: isSmallScreen ? theme.zIndex.drawer + 1 : null,
  top: isSmallScreen ? 0 : null,
  left: isSmallScreen ? 0 : null,
  '& nav': {
    '& .MuiCollapse-root': {
      '& a': {
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
  },
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflow: 'inherit',
  width: `calc(${theme.spacing(6.5)} + 1px)`,
  backgroundColor: theme.palette.secondary.mainColor,
  '@media (min-width: 100px)': {
    width: `calc(${theme.spacing(7)} + 1px)`,
    '& nav': {
      overflow: 'inherit',
      '& .MuiCollapse-root': {
        '& a': {
          paddingTop: 8,
          paddingBottom: 8,
          '& span': {
            visibility: 'visible',
          },
        },
      },
      '& .dropdownTitle': {
        '& span': {
          visibility: 'hidden',
        },
      },
      '& a': {
        overflow: 'hidden',
        '& span': {
          visibility: 'hidden',
        },
      },
    },
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: theme.spacing(1, 1),
  backgroundColor: theme.palette.primary.mainColor,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, isSmallScreen }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.secondary.mainColor,
    ...(open && {
      ...openedMixin(theme, isSmallScreen),
      '& .MuiDrawer-paper': openedMixin(theme, isSmallScreen),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function AppDrawer({ permissions }) {
  const isSmallScreen = useMediaQuery('(max-width:800px)');
  const [isDrawerOpen, setIsDrawerOpen] = useState(!isSmallScreen);

  useEffect(() => {
    setIsDrawerOpen(!isSmallScreen);
  }, [isSmallScreen]);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer variant="permanent" open={isDrawerOpen} isSmallScreen={isSmallScreen}>
        <DrawerHeader style={{ display: 'flex', alignItems: 'baseline' }}>
          <IconButton sx={{ color: palette.grey[0] }} onClick={handleDrawerOpen}>
            <MenuIcon />
          </IconButton>
          {isDrawerOpen
           && <img width="65%" src="/App-Insights-logo-dark-bg.svg" alt="dv" />}
        </DrawerHeader>
        <AppMenu handleDrawerOpen={handleDrawerOpen} isDrawerOpen={isDrawerOpen} permissions={permissions} />
      </Drawer>
    </Box>
  );
}
