import { createSlice, current } from '@reduxjs/toolkit';
import { deleteCustomFilterView, setCustomFilterView, getCustomFilterViewList } from './customFilterViewAction';

const customFilterViewSlice = createSlice({
  name: 'customFilterView',
  initialState: {
    loading: false,
    error: null,
    data: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder.addCase(getCustomFilterViewList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCustomFilterViewList.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.error = null;
      state.data = payload.data;
    });
    builder.addCase(getCustomFilterViewList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // create / update
    builder.addCase(setCustomFilterView.pending, (state) => {
      const currentState = current(state);
      state.loading = true;
      state.error = null;
      state.data = currentState.data;
    });
    builder.addCase(setCustomFilterView.fulfilled, (state, { payload }) => {
      const currentState = current(state);
      let updateData;
      if (payload.data?.type === 'update') {
        updateData = currentState.data.map((item) => (item.id === payload.data.id ? payload.data : item));
      } else {
        updateData = [...currentState.data, payload.data];
      }
      state.loading = false;
      state.error = null;
      state.data = updateData;
    });
    builder.addCase(setCustomFilterView.rejected, (state, action) => {
      const currentState = current(state);
      state.loading = false;
      state.error = action.payload;
      state.data = currentState.data;
    });
    // delete
    builder.addCase(deleteCustomFilterView.pending, (state) => {
      const currentState = current(state);
      state.loading = true;
      state.error = null;
      state.data = currentState.data;
    });
    builder.addCase(deleteCustomFilterView.fulfilled, (state, { payload }) => {
      const currentState = current(state);
      const updateData = currentState.data?.filter((obj) => (obj.id !== payload?.data?.id)) ?? [];
      state.loading = false;
      state.error = null;
      state.data = updateData;
    });
    builder.addCase(deleteCustomFilterView.rejected, (state, action) => {
      const currentState = current(state);
      state.loading = false;
      state.error = action.payload;
      state.data = currentState.data;
    });
  },
});

export default customFilterViewSlice;
